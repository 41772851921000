import React from 'react';

import FormControl from '@mui/joy/FormControl';
import Input from '@mui/joy/Input';

import { Control, Controller } from 'react-hook-form';
import { Divider, Typography } from '@mui/joy';
import { styled } from '@mui/joy/styles';
import { iconLibrary } from '../../../assets/icons/icon-library';

const StyledInput = styled('input')({
  border: 'none', // remove the native input border
  minWidth: 0, // remove the native input width
  outline: 0, // remove the native input outline
  padding: 0, // remove the native input padding
  paddingTop: '1em',
  flex: 1,
  color: 'inherit',
  backgroundColor: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  fontStyle: 'inherit',
  fontWeight: 'inherit',
  lineHeight: 'inherit',
  textOverflow: 'ellipsis',
  '&::placeholder': {
    opacity: 0,
    transition: '0.1s ease-out',
  },
  '&:focus::placeholder': {
    opacity: 1,
  },
  '&:focus ~ label, &:not(:placeholder-shown) ~ label, &:-webkit-autofill ~ label':
    {
      top: '-1rem',
      fontSize: '0.75rem',
    },
  '&:focus ~ label': {
    color: 'var(--Input-focusedHighlight)',
  },
  '&:-webkit-autofill': {
    alignSelf: 'stretch', // to fill the height of the root slot
  },
  '&:-webkit-autofill:not(* + &)': {
    marginInlineStart: 'calc(-1 * var(--Input-paddingInline))',
    paddingInlineStart: 'var(--Input-paddingInline)',
    borderTopLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
    borderBottomLeftRadius:
      'calc(var(--Input-radius) - var(--variant-borderWidth, 0px))',
  },
  '::-webkit-calendar-picker-indicator': {
    backgroundImage: `url(${iconLibrary.common.calendar})`,
    width: 24,
    height: 24,
  },
  '::-webkit-datetime-edit': {
    flex: 'none',
  },
});

const StyledLabel = styled('label')(({ theme }) => ({
  position: 'absolute',
  lineHeight: 1,
  top: 'calc((var(--Input-minHeight) - 1em) / 2)',
  // color: theme.vars.palette.text.primary,
  color: 'inherit',
  fontWeight: theme.vars.fontWeight.md,
  transition: 'all 150ms cubic-bezier(0.4, 0, 0.2, 1)',
  fontSize: '12px',
}));

const InnerInput = React.forwardRef<
  HTMLInputElement,
  JSX.IntrinsicElements['input']
>(function InnerInput(props, ref) {
  const id = React.useId();
  return (
    <React.Fragment>
      <StyledInput {...props} ref={ref} id={id} />
      <StyledLabel htmlFor={id}>{props['aria-label']}</StyledLabel>
    </React.Fragment>
  );
});

const getCurrentDate = (): string => {
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  const year = yesterday.getFullYear();
  const month = String(yesterday.getMonth() + 1).padStart(2, '0');
  const day = String(yesterday.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}

interface InputCLProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  type?: 'email' | 'text' | 'password' | 'date';
  addDivider?: boolean;
  label?: string;
  datatestid?: string;
  maxLength?: number;
  value?: string;
  disabled?: boolean;
  replayBlock?: boolean;
  replayEncrypt?: boolean;
  validateOnChange?: () => void;
}

export default function InputBoxCL({
  control,
  fieldName,
  datatestid,
  type,
  addDivider,
  label,
  maxLength,
  disabled,
  value,
  replayBlock,
  replayEncrypt,
  validateOnChange,
}: InputCLProps) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={value || ''}
      render={({ field, fieldState }) => (
        <>
          <FormControl sx={{ flex: 1, margin: 0 }} color="neutral">
            <Input
              autoComplete="off"
              disabled={disabled}
              placeholder="sdf"
              slots={{ input: InnerInput }}
              variant="plain"
              slotProps={{
                input: {
                  value: field.value,
                  'data-qm-block': replayBlock,
                  'data-qm-encrypt': replayEncrypt,
                  placeholder: label,
                  onChange: (event) => {
                    field.onChange(event);
                    validateOnChange?.();
                  },
                  type: type,
                  maxLength: maxLength,
                  'aria-label': label,
                  'data-testid': datatestid || `${field.name}-input`,
                  ...(type === 'date' && { max: getCurrentDate() }),
                },
              }}
              sx={{
                '--Input-focusedInset': 'var(--any, )',
                '--Input-focusedThickness': '0rem',
                paddingInline: '0',
                fontSize: '12px',
                margin: '0',
              }}
            />

            {fieldState.error && (
              <Typography level="body-sm" color="danger">
                {fieldState.error.message}
              </Typography>
            )}
          </FormControl>
          {addDivider && <Divider />}
        </>
      )}
    />
  );
}
