import React from 'react';

import './signupPage.css';

import SignupForm from './forms/signupForm';
import { Stack } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { iconLibrary } from '../../../assets/icons/icon-library';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import PageHeader from '../../../components/common/page-header/pageHeader';

function SignupPage() {
  const { t } = useTranslation();

  const { isLargerScreen } = useScreenQuery();

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Sign up page',
  });

  return (
    <>
      <Helmet>
        <title> {t('onboarding.createAccount.title')}</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '2em' : '1em' }}>
        <PageHeader
          image={iconLibrary.heading.createAccount}
          imageAlt="onboarding.createAccount.title.icon"
          titleKey="onboarding.createAccount.title"
        />

        <SignupForm />
      </Stack>
    </>
  );
}

export default SignupPage;
