import React from 'react';

import './section.css';
import { Stack } from '@mui/joy';

export default function Requirements() {
  return (
    <>
      <h2>Requirements</h2>
      <Stack spacing={2}></Stack>
    </>
  );
}
