export enum EarningsTable {
  Earnings = 'Earnings',
  GrossPay = 'Gross Pay',
  TotalEarnings = 'Total Earnings',
  Shift = 'Shift',
  ShiftStatus = 'Shift Status',
  EarningsAmmount = 'Earnings Amount',
}

export enum DpTransfersTable {
  DailyPayTransfers = 'DailyPay transfers and fees',
  TransferAmount = 'Transfer amount',
  DailyPayFees = 'DailyPay fees',
  TotalTranfsers = 'Total Transfers',
  Date = 'Date',
  DestAcc = 'Destination account',
  Amount = 'Amount',
  Fees = 'Fees',
  TotalAmount = 'Total Amount',
}

export enum PayTable {
  Pay = 'Pay',
  Earnings = 'Earnings',
  DailyPayTransfers = 'DailyPay Transfers',
  PaycheckAmount = 'Paycheck Amount',
  Date = 'Date',
  Amount = 'Amount',
  TransferAmount = 'Transfer amount',
  PayAmount = 'Pay Amount',
}

export const supportedDpStatus = [
  {
    id: 'active',
    label: 'Active',
  },
  {
    id: 'pending',
    label: 'Pending',
  },
  {
    id: 'deactivated',
    label: 'Deactivated',
  },
  {
    id: 'canceled',
    label: 'Canceled',
  },
];

export const supportedEmploymentStatus = [
  {
    id: 'employed',
    label: 'Employed',
  },
  {
    id: 'terminated',
    label: 'Terminated',
  },
];

interface BankInfo {
  accountType: string | undefined;
  bankName: string | undefined;
  accountNumber: string | undefined;
  routingNumber: string | undefined;
}

export interface BankDetails {
  details: BankInfo[];
}
