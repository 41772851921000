export const acrisureSupportedCompanyCountRange = [
  {
    id: '1-10',
    label: '1-10',
  },
  {
    id: '11-50',
    label: '11-50',
  },
  {
    id: '51-100',
    label: '51-100',
  },
  {
    id: '101-300',
    label: '101-300',
  },
  {
    id: '300+',
    label: '300+',
  },
];

export const supportedCompanyCountRange = [
  {
    id: '1-10',
    label: '1-10',
  },
  {
    id: '11-50',
    label: '11-50',
  },
  {
    id: '51-200',
    label: '51-200',
  },
  {
    id: '201-400',
    label: '201-400',
  },
  {
    id: '401-500',
    label: '401-500',
  },
  {
    id: '500+',
    label: '500+',
  },
];
