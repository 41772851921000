import React, { useEffect } from 'react';

import './checkEmailVerificationPage.css';

import { Box, Button, Grid, Stack } from '@mui/joy';

import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { iconLibrary } from '../../../assets/icons/icon-library';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { TextCL } from '../../../components/library/TextCL';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { TEST_IDS } from '../../../test-utils/test-ids';

function CheckEmailVerificationPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLargerScreen } = useScreenQuery();
  const location = useLocation();

  useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailPageViewed,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title> {t('onboarding.createAccount.title')}</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '2em' : '1em' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <PageHeader
            image={iconLibrary.heading.createAccount}
            imageAlt="onboarding.createAccount.title.icon"
            titleKey="onboarding.createAccount.title"
          />
        </Stack>
        <FormFoundations.FormWrapperGrid>
          <Grid xs={12} md={12} lg={12} className="formSection">
            <TextCL variant="h1Bold">{t('onboarding.email.title')}</TextCL>
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={12}
            className="formSection"
            sx={{ marginTop: '48px', marginBottom: '64px' }}
          >
            <TextCL variant="h5">{t('onboarding.email.description')}</TextCL>
          </Grid>
        </FormFoundations.FormWrapperGrid>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: '50px',
            }}
            onClick={() => {
              logAnalyticsEventForSelfService({
                type: SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailOKButton,
              });
              navigate('/onboarding/verifyAccount', {
                state: {
                  email: location.state?.email,
                },
              });
            }}
            data-testid={TEST_IDS.register.okayButton}
          >
            {t('onboarding.registration.notSupported.buttonText')}
          </Button>
        </Box>
      </Stack>
    </>
  );
}

export default CheckEmailVerificationPage;
