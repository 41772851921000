import { iconLibrary } from '../../../assets/icons/icon-library';
import { IconButton } from '@mui/joy';
import React, { FC } from 'react';

interface Props {
  setShowEditInputs?: (showEditInputs: boolean) => void;
}

const EditButton: FC<Props> = ({ setShowEditInputs }) => {
  const handleClick = () => {
    setShowEditInputs?.(true);
  };

  return (
    <IconButton
      onClick={handleClick}
      variant="plain"
      color="neutral"
      size="sm"
      sx={{ marginLeft: '1rem' }}
    >
      <img
        width={24}
        src={iconLibrary.common.editCircle}
        alt="Edit Admin Button"
      ></img>
    </IconButton>
  );
};

export default EditButton;
