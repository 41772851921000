import React, { useEffect, useState } from 'react';

import { Button, Divider, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import './selectPlanForm.css';

import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';
import { AppContext } from '../../../../state/appContext';
import { Types } from '../../../../state/onboarding/onboardingReducer';
import { setupSubscriptionAction } from '../../../../state/subscription/subscriptionActions';
// import {useStripe} from '@stripe/react-stripe-js';  // Note: This can be used instead if we wrap the whole page in an Elements provider
import { loadStripe, Stripe } from '@stripe/stripe-js';

const SelectPlanForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const stripeKey = state.subscription.stripeConfig?.publishableKey;

  useEffect(() => {
    if (stripeKey) {
      loadStripe(stripeKey).then(setStripe);
    }
  }, [stripeKey]);

  const subscribe = (priceId: string) => {
    console.log('Setting up subscription to ' + priceId);

    setupSubscriptionAction(
      dispatch,
      state.subscription.stripeCustomer?.id,
      state.subscription.stripeCustomer?.email ||
        'anders.cassidy+' + Date.now() + '@dailypay.com',
      state.auth.username,
      priceId,
    )
      .then(() => {
        dispatch({
          type: Types.SubcriptionTypeSelected,
          payload: {
            priceId,
          },
        });
      })
      .catch((e) => {
        console.error(e);
      });
  };

  return (
    <>
      <FormFoundations.FormWrapperBox>
        <Typography level="h3">
          {t('onboarding.registration.personalDetails.title')}
        </Typography>

        <Divider />
      </FormFoundations.FormWrapperBox>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'center',
          justifyContent: 'center',
          overflow: 'wrap',
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        {state.subscription.stripeConfig?.prices?.map((item) => {
          return (
            <Button
              disabled={!stripe}
              variant="solid"
              // color="neutral"
              size="sm"
              sx={{ borderRadius: '50px' }}
              onClick={() => subscribe(item.id)}
              key={item.id}
            >
              {'$' + item.amount / 100 + '/' + item.interval}
            </Button>
          );
        })}
      </Box>
    </>
  );
};

export default SelectPlanForm;
