import React, { FC } from 'react';
import { Grid } from '@mui/joy';
import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { ConnectFormData } from '../../payrollConfigForm';
import {
  ADDITIONAL_PAY_GROUP_KEYS,
  DEFAULT_ADDITIONAL_PAY_GROUP,
  DEFAULT_PAY_FREQUENCY,
  DEFAULT_PAY_GROUP,
  PAY_FREQUENCY_OPTS,
} from './constants';
import { PayGroupContainer } from './payGroupContainer';
import { difference, omit } from 'lodash';

export type PayFrequency = (typeof PAY_FREQUENCY_OPTS)[number];

export interface PayGroup {
  payFrequency: PayFrequency;
  startDate: string;
  endDate: string;
  processDate: string;
  payDate: string;
  startDate2?: string;
  endDate2?: string;
  processDate2?: string;
  payDate2?: string;
}

const PayGroups: FC = () => {
  const { control, trigger } = useFormContext<ConnectFormData>();

  const {
    fields: payGroups,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    name: 'payGroups',
  });

  const watchPayGroups = useWatch({
    name: 'payGroups',
    control,
  });

  const controlledPayGroups = payGroups.map((payGroup, index) => ({
    ...payGroup,
    ...(watchPayGroups && watchPayGroups[index]),
  }));

  const lastElementIndex = controlledPayGroups.length - 1;

  const payFrequencyOptsInUse = controlledPayGroups.map(
    (payGroup) => payGroup.payFrequency,
  );
  const availablePayFrequencyOpts = difference(
    PAY_FREQUENCY_OPTS,
    payFrequencyOptsInUse,
  );
  const defaultPayFrequencyTaken = payFrequencyOptsInUse.includes(
    DEFAULT_PAY_FREQUENCY,
  );

  const handleUpdate = (index: number, newValue: PayFrequency) => {
    let newObj;
    if (newValue === 'semimonthly') {
      newObj = {
        payFrequency: controlledPayGroups[index].payFrequency,
        ...DEFAULT_ADDITIONAL_PAY_GROUP,
        ...omit(controlledPayGroups[index], ['id', 'payFrequency']),
      };
    } else {
      newObj = omit(controlledPayGroups[index], ADDITIONAL_PAY_GROUP_KEYS);
    }

    update(index, newObj as PayGroup);
  };

  const onPayFrequencyChange = (index: number, newValue: PayFrequency) => {
    const currentValue = watchPayGroups?.[index].payFrequency;
    if (
      newValue === currentValue ||
      ![newValue, currentValue].includes('semimonthly')
    ) {
      return;
    }

    handleUpdate(index, newValue);
  };

  function removePayGroup(index: number): void {
    remove(index);
  }

  async function addPayGroup() {
    const arePayGroupsValid = await trigger('payGroups');

    if (arePayGroupsValid) {
      const payFrequency = defaultPayFrequencyTaken
        ? availablePayFrequencyOpts[0]
        : DEFAULT_PAY_FREQUENCY;

      append({
        ...DEFAULT_PAY_GROUP,
        payFrequency,
      });
    }
  }

  if (!controlledPayGroups.length) {
    append(DEFAULT_PAY_GROUP);
  }

  return controlledPayGroups.map((payGroup, payGroupIdx) => {
    const { id, ...rest } = payGroup;
    const payFrequencyOpts = [
      payGroup.payFrequency,
      ...availablePayFrequencyOpts,
    ];

    return (
      <Grid
        container
        xs={12}
        spacing={4}
        mb={1}
        mt={payGroupIdx == 0 ? 0 : 1}
        key={id}
        width="unset"
      >
        <PayGroupContainer
          payGroup={rest}
          payGroupIdx={payGroupIdx}
          isLastElement={payGroupIdx == lastElementIndex}
          payFrequencyOpts={payFrequencyOpts}
          onAddPayGroup={addPayGroup}
          onRemovePayGroup={removePayGroup}
          onPayFrequencyChange={onPayFrequencyChange}
        />
      </Grid>
    );
  });
};

export default PayGroups;
