import * as React from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { Employee } from '@dailypay/self-service-ts-sdk';
import { useTranslation } from 'react-i18next';
import { Horizon } from '../../../../../theme';
import InputBoxCL from '../../../../../components/library/forms/InputBoxCL';
import { TEST_IDS } from '../../../../../test-utils/test-ids';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { formatPhoneNumber } from '../../../../../formatters/string-formatters';
import {
  standardEmailYupValidation,
  standardPhoneValidation,
} from '../../../../../utils/validation';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import EditFormHeader from '../../../../../components/common/form-header/EditFormHeader';
export interface ContactInfoProps {
  employee: Employee;
  updateEmployee: (body: object) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
}

const schema = yup
  .object({
    phone: standardPhoneValidation(),
    email: standardEmailYupValidation,
  })
  .required();

export type ContactFormData = {
  phone?: string;
  email: string;
};

export default function ContactInfo({
  employee,
  updateEmployee,
  hasError,
  setHasError,
}: ContactInfoProps) {
  const { t } = useTranslation();
  const [isEditingContact, setIsEditingContact] = React.useState(false);
  const [displayedEmail, setDisplayedEmail] = React.useState<string>(
    employee.email || '',
  );
  const [displayedPhone, setDisplayedPhone] = React.useState<string>(
    employee.phone_number || '',
  );

  const { control, handleSubmit } = useForm<ContactFormData>({
    resolver: yupResolver(schema),
  });

  const handleUpdateEmployee = React.useCallback(async () => {
    const body = {
      phone_number: `+1${control._formValues.phone}`, // TODO: Followup to support multiple countries -> SELF-226
      email: control._formValues.email,
    };
    setDisplayedEmail(control._formValues.email);
    setDisplayedPhone(control._formValues.phone);
    updateEmployee(body);
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.EditEmployeeContactSubmit,
      payload: {
        employeeUUID: employee.employee_uuid,
      },
    });
  }, [
    control._formValues.email,
    control._formValues.phone,
    employee.employee_uuid,
    updateEmployee,
  ]);

  React.useEffect(() => {
    if (hasError) {
      setIsEditingContact(true);
      setDisplayedPhone(employee.phone_number || '');
      setDisplayedEmail(employee.email || '');
    } else {
      setIsEditingContact(false);
    }
  }, [
    employee.email,
    employee.phone_number,
    displayedEmail,
    displayedPhone,
    hasError,
  ]);

  return (
    <div style={{ marginBottom: '24px' }}>
      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        sx={{ marginBottom: '-32px', paddingLeft: '40px' }}
      >
        <EditFormHeader
          titleKey={t('admin.employees.accountInfo.contact.title')}
          isEditing={isEditingContact}
          setShowEditInputs={(val: boolean) => {
            setIsEditingContact(val);
            if (val) {
              logAnalyticsEventForSelfService({
                type: SelfServiceAnalyticsEventTypes.EditEmployeeContact,
                payload: {
                  employeeUUID: employee.employee_uuid,
                },
              });
            }
          }}
          onSave={() => {
            handleSubmit(handleUpdateEmployee)();
          }}
          onCancel={() => {
            setIsEditingContact(false);
            setHasError(false);
          }}
        />
      </Grid>
      <Grid
        container
        spacing={0}
        xs={12}
        md={12}
        lg={12}
        rowSpacing={3}
        sx={{
          paddingTop: '-16px',
        }}
      >
        <Grid xs={12} md={12} lg={12} className="formField"></Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.contact.firstName')}
            </TextCL>
          </div>
          <div style={{ paddingLeft: '24px', paddingTop: '14px' }}>
            <TextCL variant="input" replayEncrypt={true}>
              {employee.first_name}
            </TextCL>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline" replayEncrypt={true}>
              {t('admin.employees.accountInfo.contact.lastName')}
            </TextCL>
          </div>
          <div
            style={{
              paddingTop: '14px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="input" replayEncrypt={true}>
              {employee.last_name}
            </TextCL>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.contact.phone')}
            </TextCL>
          </div>
          {isEditingContact ? (
            <>
              <InputBoxCL
                control={control}
                fieldName="phone"
                datatestid={TEST_IDS.admin.employees.phoneInput}
                value={displayedPhone}
                replayEncrypt={true}
              />
            </>
          ) : (
            <>
              <div
                style={{
                  paddingTop: '14px',
                  paddingLeft: '24px',
                }}
              >
                <TextCL variant="input" replayEncrypt={true}>
                  {formatPhoneNumber(displayedPhone)}
                </TextCL>
              </div>
            </>
          )}
        </Grid>

        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.contact.email')}
            </TextCL>
          </div>
          {isEditingContact ? (
            <InputBoxCL
              control={control}
              fieldName="email"
              datatestid={TEST_IDS.admin.employees.emailAddressInput}
              value={employee.email}
              replayEncrypt={true}
            />
          ) : (
            <div
              style={{
                paddingTop: '14px',
                paddingLeft: '24px',
              }}
            >
              <TextCL variant="input" replayEncrypt={true}>
                {displayedEmail}
              </TextCL>
            </div>
          )}
        </Grid>
        {hasError && isEditingContact && (
          <Grid xs={12} md={12} lg={12} className="formField">
            <div style={{ color: 'red', textAlign: 'center' }}>
              Something went wrong, please try again.
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
