import React from 'react';
import './signinPage.css';
import SigninForm from './forms/signinForm';
import { Box, Grid, Stack, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import PageHeader from '../../../components/common/page-header/pageHeader';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import logo from '../../../assets/images/dailypay-logo.svg';
import ResetPasswordForm from './forms/resetPasswordForm';
import ConfirmPasswordResetForm from './forms/confirmPasswordResetForm';
import { useLocation } from 'react-router-dom';
import CheckEmailForCodeForm from './forms/checkEmailForCodeForm';
import useRouteProtection, {
  loginPageRedirectMap,
} from '../../useRouteProtection';

export enum SignupPageVariants {
  SIGNIN = 'signin',
  FORGOT_PASSWORD = 'forgotPassword',
  CHECK_EMAIL_FOR_LINK = 'checkEmailForLink',
  CONFIRM_RESET_PASSWORD = 'confirmResetPassword',
}

function SigninPage() {
  const location = useLocation();
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();
  const theme = useTheme();
  const [pageVariant, setPageVariant] = React.useState<SignupPageVariants>(
    SignupPageVariants.SIGNIN,
  );

  useRouteProtection({
    redirectRules: loginPageRedirectMap,
    context: 'Login Page',
  });

  React.useEffect(() => {
    if (location.state && location.state.variant === 'forgotPassword') {
      setPageVariant(SignupPageVariants.FORGOT_PASSWORD);
    }
  }, [location.state]);

  const [cachedEmail, setCachedEmail] = React.useState<string>();

  return (
    <Box
      sx={{ height: '100vh', backgroundColor: theme.palette.background.body }}
    >
      <Helmet>
        <title> {t('admin.login.title')}</title>
      </Helmet>

      <Grid
        flex={1}
        flexGrow={1}
        flexDirection={'column'}
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: theme.palette.background.body,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          display={'flex'}
          xs={12}
          md={6}
          lg={6}
          flex={1}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: '10em', padding: isLargerScreen ? '2em' : '1em' }}
        >
          <img src={logo} width={isLargerScreen ? '360px' : '240px'} />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={6}
          flex={1}
          flexGrow={1}
          padding={isLargerScreen ? '2em' : '1em'}
        >
          <FormFoundations.FormOutlineFlex
            sx={{ flexDirection: 'column', flex: 1 }}
          >
            <Stack
              spacing={4}
              sx={{ padding: isLargerScreen ? '2em' : '1em' }}
              flex={1}
            >
              {SignupPageVariants.SIGNIN === pageVariant && (
                <>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PageHeader
                      excludeBackButton={true}
                      titleKey="admin.login.title"
                    />
                  </Stack>

                  <SigninForm setPageVariant={setPageVariant} />
                </>
              )}

              {SignupPageVariants.FORGOT_PASSWORD === pageVariant && (
                <>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PageHeader
                      excludeBackButton={true}
                      titleKey="admin.forgotPassword.title"
                    />
                  </Stack>

                  <ResetPasswordForm
                    setPageVariant={setPageVariant}
                    setCachedEmail={setCachedEmail}
                  />
                </>
              )}

              {SignupPageVariants.CHECK_EMAIL_FOR_LINK === pageVariant && (
                <>
                  <Stack
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <PageHeader
                      excludeBackButton={true}
                      titleKey="admin.checkEmailForCode.title"
                    />
                  </Stack>

                  <CheckEmailForCodeForm setPageVariant={setPageVariant} />
                </>
              )}

              {SignupPageVariants.CONFIRM_RESET_PASSWORD === pageVariant &&
                cachedEmail && (
                  <>
                    <Stack
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <PageHeader
                        excludeBackButton={true}
                        titleKey="admin.confirmResetPassword.title"
                      />
                    </Stack>

                    <ConfirmPasswordResetForm
                      cachedEmail={cachedEmail}
                      setPageVariant={setPageVariant}
                    />
                  </>
                )}
            </Stack>
          </FormFoundations.FormOutlineFlex>
        </Grid>
      </Grid>
    </Box>
  );
}

export default SigninPage;
