import React from 'react';

import { Button, Grid, Link, Stack, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import './signinForm.css';

import { useForm } from 'react-hook-form';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Auth from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { TextCL } from '../../../../components/library/TextCL';
import { dawnOrangeLight } from '../../../../theme/self-service-joy-theme';
import { SignupPageVariants } from '../signinPage';
import TooltipCL from '../../../../components/library/TooltipCL';
import {
  standard6DigitCodeValidation,
  standardConfirmPasswordValidation,
  standardEmailYupValidation,
  standardPasswordYupValidation,
} from '../../../../utils/validation';
import { Horizon } from '../../../../theme';
import PasswordStrengthMeter from '../../../onboarding/signupPage/passwordStrengthMeter/passwordStrengthMeter';

interface ConfirmPasswordResetFormProps {
  setPageVariant: (pageVariant: SignupPageVariants) => void;
  cachedEmail: string;
}

export type ConfirmResetPasswordFormData = {
  emailAddress: string;
  password: string;
  confirmPassword: string;
  verificationCode: string;
};

const schema = yup
  .object({
    emailAddress: standardEmailYupValidation,
    password: standardPasswordYupValidation,
    confirmPassword: standardConfirmPasswordValidation,
    verificationCode: standard6DigitCodeValidation,
  })
  .required();

const ConfirmPasswordResetForm: React.FC<ConfirmPasswordResetFormProps> = ({
  setPageVariant,
  cachedEmail,
}) => {
  const [errorText, setErrorText] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const { control, handleSubmit, formState, watch } =
    useForm<ConfirmResetPasswordFormData>({
      resolver: yupResolver(schema),
      defaultValues: { emailAddress: cachedEmail },
    });

  const handleFinalizePasswordReset = React.useCallback(async () => {
    setErrorText(null);

    if (control._formValues.password !== control._formValues.confirmPassword) {
      setErrorText('Passwords do not match');
    } else {
      Auth.confirmResetPassword({
        username: control._formValues.emailAddress,
        confirmationCode: control._formValues.verificationCode,
        newPassword: control._formValues.password,
      })
        .then(async () => {
          setErrorText(null);
          setPageVariant(SignupPageVariants.SIGNIN);
        })
        .catch((err) => {
          // {"__type":"ExpiredCodeException","message":"Invalid code provided, please request a code again."}
          if (
            err.message ===
            'Invalid code provided, please request a code again.'
          ) {
            setErrorText(
              'Unable to change password password. Please try again.',
            );
          } else {
            setErrorText(err.message);
          }
        });
    }
  }, [
    control._formValues.confirmPassword,
    control._formValues.emailAddress,
    control._formValues.password,
    control._formValues.verificationCode,
    setPageVariant,
  ]);

  return (
    <>
      <Stack
        spacing={4}
        flex={1}
        flexGrow={1}
        alignItems={'stretch'}
        justifyContent={'center'}
      >
        <InputBoxCL
          disabled
          control={control}
          fieldName="emailAddress"
          label="EMAIL ADDRESS"
          type="email"
          replayEncrypt={true}
        />

        <Grid
          container
          sx={{
            marginLeft: '0',
          }}
        >
          <InputBoxCL
            control={control}
            fieldName="password"
            label="NEW PASSWORD"
            type="password"
            replayBlock={true}
          />

          <TooltipCL
            tooltipText={t('onboarding.createAccount.tooltip')}
            placement="right"
            infoBackgroundColor={true}
            showCloseIcon={false}
            iconColor={Horizon.info[2]}
          />
        </Grid>
        <PasswordStrengthMeter password={watch('password')} />
        <InputBoxCL
          control={control}
          fieldName="confirmPassword"
          label="CONFIRM NEW PASSWORD"
          type="password"
          replayBlock={true}
        />

        <InputBoxCL
          control={control}
          fieldName="verificationCode"
          label="VERIFICATION CODE"
          type="password"
          replayBlock={true}
        />

        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={handleSubmit(handleFinalizePasswordReset)}
        >
          Next
        </Button>

        {errorText && (
          <Grid
            xs={12}
            md={12}
            lg={12}
            sx={{
              padding: '10px 24px',
              marginBottom: '24px',
              backgroundColor: dawnOrangeLight,
              borderRadius: '4px',
            }}
          >
            <TextCL variant="body">{errorText}</TextCL>
          </Grid>
        )}
        {formState.errors.root && (
          <Box sx={{ paddingTop: '2rem' }}>
            <Typography level="body-sm" sx={{ paddingTop: '2em' }}>
              {JSON.stringify(formState.errors)}
            </Typography>
          </Box>
        )}

        <Link
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => setPageVariant(SignupPageVariants.SIGNIN)}
          underline="always"
        >
          {t('admin.forgotPassword.cancel')}
        </Link>
      </Stack>
    </>
  );
};

export default ConfirmPasswordResetForm;
