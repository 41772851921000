import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Divider, Grid, Typography } from '@mui/joy';
import { confirmSignUp, signIn } from 'aws-amplify/auth';
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { ButtonCL } from '../../../../components/library/ButtonCL';
import { TextCL } from '../../../../components/library/TextCL';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { AppContext } from '../../../../state/appContext';
import { TEST_IDS } from '../../../../test-utils/test-ids';
import { dawnOrangeLight } from '../../../../theme/self-service-joy-theme';
import {
  standard6DigitCodeValidation,
  standardEmailYupValidation,
  standardPasswordYupValidation,
} from '../../../../utils/validation';
import {
  dispatchSignInVerified,
  dispatchTriggerBusinessNameCheck,
} from '../../../../state/onboarding/onboardingReducer';
import { scheduleSaveProspectAction } from '../../../../state/onboarding/onboardingActions';
import ResendVerification from '../resendVerification/resendVerification';
import { useLocation, useNavigate } from 'react-router-dom';

export type SigninFormData = {
  emailAddress: string;
  password: string;
  verificationCode: string;
};

const schema = yup
  .object({
    emailAddress: standardEmailYupValidation,
    password: standardPasswordYupValidation,
    verificationCode: standard6DigitCodeValidation,
  })
  .required();

export type ErrorText = {
  level1: string;
};

const SignInForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const [errorText, setErrorText] = React.useState<ErrorText | undefined>();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { control, handleSubmit, reset, formState } = useForm<SigninFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.VerifyAccountPageViewed,
    });
  }, []);

  useEffect(() => {
    reset(state.onboarding.prospectData?.data as SigninFormData);
  }, [state.onboarding.prospectData?.data, reset]);

  const handleConfirmSignUp = React.useCallback(async () => {
    try {
      await confirmSignUp({
        username: control._formValues.emailAddress,
        confirmationCode: control._formValues.verificationCode,
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (
        err.message === 'Invalid code provided, please request a code again.'
      ) {
        setErrorText({
          level1: 'Unable to change password password. Please try again.',
        });

        if (err.name === 'ExpiredCodeException') {
          setErrorText({
            level1: 'Your verification code has expired',
          });
        }
      } else {
        setErrorText({ level1: err.message });
      }
    }
  }, [control._formValues.emailAddress, control._formValues.verificationCode]);

  const handleSignIn = React.useCallback(async () => {
    try {
      await signIn({
        username: control._formValues.emailAddress,
        password: control._formValues.password,
      });

      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.VerifyAccountSuccess,
        payload: {
          email: control._formValues.emailAddress,
        },
      });

      scheduleSaveProspectAction(dispatch);
      dispatchSignInVerified(dispatch);
      dispatchTriggerBusinessNameCheck(dispatch);

      if (location.state?.email !== undefined) {
        navigate('/onboarding', {
          state: {
            email: location.state?.email,
          },
        });
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        setErrorText({
          level1: 'onboarding.verifyAccount.errors.userNotConfirmed',
        });
      }

      if (err.code === 'NotAuthorizedException') {
        setErrorText({
          level1: 'onboarding.verifyAccount.errors.userNotAuthorized',
        });
      }

      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.VerifyAccountFailure,
        payload: {
          email: control._formValues.emailAddress,
          reason: err.code,
        },
      });
    }
  }, [
    control._formValues.emailAddress,
    control._formValues.password,
    dispatch,
    location.state?.email,
    navigate,
  ]);

  const handleVerifySigninRequest = React.useCallback(async () => {
    setErrorText(undefined);
    if (control._formValues.verificationCode !== '999999') {
      await handleConfirmSignUp();
    }
    await handleSignIn();
  }, [control._formValues.verificationCode, handleSignIn, handleConfirmSignUp]);

  const setLevel1ErrorText = (errorText: string) => {
    setErrorText({ level1: errorText });
  };

  return (
    <>
      <FormFoundations.FormWrapperGrid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h3">{t('onboarding.verifyAccount.title')}</TextCL>
        </Grid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h5">
            {t('onboarding.verifyAccount.description')}
          </TextCL>
          <Divider sx={{ marginTop: '16px' }} />
        </Grid>

        {errorText && (
          <Grid xs={12} md={12} lg={12}>
            <Grid
              xs={12}
              md={12}
              lg={21}
              sx={{
                padding: '10px 24px',
                marginBottom: '24px',
                backgroundColor: dawnOrangeLight,
              }}
            >
              <TextCL variant="body">{t(errorText.level1)}</TextCL>
            </Grid>
          </Grid>
        )}
        <Grid xs={12} md={12} lg={6}>
          <Grid xs={12} md={12} lg={6} sx={{ paddingBottom: '24px' }}>
            <InputBoxCL
              control={control}
              fieldName="emailAddress"
              label="USERNAME"
              value={location.state?.email}
              type="email"
              replayEncrypt={true}
            />
          </Grid>

          <Grid xs={12} md={12} lg={6} sx={{ paddingBottom: '24px' }}>
            <InputBoxCL
              control={control}
              fieldName="password"
              label="PASSWORD"
              type="password"
              addDivider
              replayBlock={true}
            />
          </Grid>

          <Grid xs={12} md={12} lg={6} sx={{ paddingBottom: '72px' }}>
            <InputBoxCL
              control={control}
              fieldName="verificationCode"
              label="VERIFICATION CODE"
              type="password"
              replayBlock={true}
            />
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <ResendVerification
              email={control._formValues.emailAddress}
              setErrorText={setLevel1ErrorText}
            />
          </Grid>
        </Grid>
      </FormFoundations.FormWrapperGrid>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'flex-end',
          justifyContent: 'space-between',
          overflow: 'wrap',
          display: 'flex',
          gap: 1,
        }}
      >
        <ButtonCL color="neutral" onClick={() => reset()} text="Cancel" />
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={handleSubmit(handleVerifySigninRequest)}
          data-testid={TEST_IDS.register.nextButton}
        >
          Next
        </Button>
      </Box>
      {formState.errors.root && (
        <Box sx={{ paddingTop: '2rem' }}>
          <Typography level="body-sm" sx={{ paddingTop: '2em' }}>
            {JSON.stringify(formState.errors)}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default SignInForm;
