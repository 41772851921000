import { Config, Subscription, Customer } from '@dailypay/self-service-ts-sdk';

export type SubscriptionStateType = {
  stripeConfig: Config | undefined;
  stripeCustomer: Customer | undefined;
  stripeSubscription: Subscription | undefined;
  stripeSubscriptionError: string | undefined;
};

const initialSubscriptionState = {
  stripeConfig: undefined,
  stripeCustomer: undefined,
  stripeSubscription: undefined,
  stripeSubscriptionError: undefined,
};

export { initialSubscriptionState };
