import React from 'react';
import { Autocomplete, FormControl, FormLabel, Typography } from '@mui/joy';
import { Control, Controller } from 'react-hook-form';
import { EmployeeStatusChipCL } from './EmployeeStatusChipCL';
import { DailyPayStatus } from '@dailypay/self-service-ts-sdk';

interface AutocompleteCLProps {
  fieldName: string;
  options: { id: string; label: string }[];
  label?: string;
  datatestid: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  hasSelectedOther?: (val: boolean) => void;
  hasSelectedPayPeriod?: (val: string) => void;
  isStatusDropdown?: boolean;
  replayBlock?: boolean;
  replayEncrypt?: boolean;
  defaultValue?: { id: string; label: string };
  onBeforeChange?: (newValue: string) => void;
  onChange?: (newValue: string) => void;
}

export const AutocompleteCL = ({
  fieldName,
  options,
  label,
  datatestid,
  control,
  hasSelectedOther,
  hasSelectedPayPeriod,
  isStatusDropdown,
  replayBlock,
  replayEncrypt,
  defaultValue,
  onBeforeChange,
  onChange,
}: AutocompleteCLProps) => {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={defaultValue || null}
      render={({ field, fieldState }) => (
        <FormControl>
          <FormLabel>{label}</FormLabel>
          <Autocomplete
            {...field}
            placeholder={label}
            options={options}
            sx={{
              borderBottomWidth: '2px',
              borderBottomColor: '#C6C7CA',
              borderTopWidth: '0px',
              borderLeftWidth: '0px',
              borderRightWidth: '0px',
              borderRadius: '0px',
              boxShadow: 'none',
              backgroundColor: 'transparent',
            }}
            isOptionEqualToValue={(option, value) => {
              return option.id === value;
            }}
            getOptionLabel={(option) => option.label || ''}
            onChange={(_, data) => {
              onBeforeChange?.(data?.id);
              field.onChange(data?.id);
              onChange?.(data?.id);
              hasSelectedOther?.(data?.id === 'other');
              hasSelectedPayPeriod?.(data?.id);
            }}
            slotProps={{
              input: {
                'data-testid': datatestid || `${label}-dropdown`,
                'data-qm-block': replayBlock,
                'data-qm-encrypt': replayEncrypt,
                value:
                  options.find((x) => x.id === field.value)?.label ||
                  defaultValue?.label ||
                  '',
              },
              option: {
                'data-qm-block': replayBlock,
                'data-qm-encrypt': replayEncrypt,
              },
            }}
            disableClearable={true}
            renderOption={
              isStatusDropdown
                ? (props, option) => (
                    <li {...props}>
                      {fieldName === 'dpStatus' && (
                        <EmployeeStatusChipCL
                          dpStatus={option.id as DailyPayStatus}
                        />
                      )}
                    </li>
                  )
                : undefined
            }
          />
          {fieldState.error && (
            <Typography level="body-sm" color="danger">
              {fieldState.error.message}
            </Typography>
          )}
        </FormControl>
      )}
    />
  );
};
