import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Drawer from '@mui/joy/Drawer';
import DialogTitle from '@mui/joy/DialogTitle';
import ModalClose from '@mui/joy/ModalClose';
import StateViewer from './StateViewer';
import EnvironmentViewer from './EnvironmentViewer';
import APITester from './APITester';

export default function DevAssist() {
  const [open, setOpen] = React.useState(false);

  return (
    <Box
      sx={{
        display: 'flex',
        position: 'absolute',
        bottom: '2rem',
        left: '1rem',
      }}
    >
      <Button
        sx={{ paddingInline: '1rem' }}
        variant="outlined"
        color="neutral"
        onClick={() => setOpen(true)}
      >
        🔧 DEV
      </Button>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <ModalClose />
        <DialogTitle>
          {`${process.env.REACT_APP_NAME} ${process.env.REACT_APP_VERSION}`}
        </DialogTitle>

        <StateViewer />
        <EnvironmentViewer />
        <APITester />
      </Drawer>
    </Box>
  );
}
