import React from 'react';
import { usePasswordStrengthMeter } from './usePasswordStrengthMeter';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Moolah } from '../../../../theme';
import { bgColorMap } from './bgColorMap';
import { TextCL } from '../../../../components/library/TextCL';

type PasswordStrengthMeterProps = {
  password: string;
};

const PasswordStrengthMeter = ({ password }: PasswordStrengthMeterProps) => {
  const { t } = useTranslation();
  const { getPasswordInfo, isLoading } = usePasswordStrengthMeter();

  if (isLoading || !password) return null;
  const info = getPasswordInfo(password);
  const score = info?.score || 0;

  return (
    <Box
      p={1}
      sx={{
        backgroundColor: bgColorMap[score].colors.light,
      }}
      mb={4}
    >
      <Box px={2}>
        <TextCL color={Moolah.gray[100]}>
          {`${t('onboarding.createAccount.passwordStrengthMeter.label')}: `}
          <TextCL fontWeight={'bold'} color={bgColorMap[score].colors.strong}>
            {t(bgColorMap[score].tKey)}
          </TextCL>
        </TextCL>
        <TextCL color={Moolah.gray[100]}>{info.feedback.warning}</TextCL>
        {info.feedback.suggestions.map((suggestion, idx) => (
          <TextCL key={idx} color={Moolah.gray[100]}>
            {suggestion}
          </TextCL>
        ))}
      </Box>
    </Box>
  );
};

export default PasswordStrengthMeter;
