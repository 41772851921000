import {
  ProspectDataIndicators,
  TransientOnboardingState,
} from '../onboarding/onboardingState';

export type AuthStateType = {
  username: string;
  isLoggedIn: boolean;
  selfServiceProgress: SelfServiceProgressStatus;
  identityAccountExists: boolean;
};

export enum SelfServiceProgressStatus {
  Undetermined = 'Undetermined',
  NotStarted = 'Not Started',
  ProspectUnsaved = 'Prospect Unsaved',
  IdentityCreated = 'Identity Created',
  IdentityVerified = 'Identity Verified',
  AuthAccountNeedsDataCheck = 'Auth Account Needs Data Check',
  ProviderAlreadyExists = 'Provider Already Exists',
  TermsAccepted = 'Terms Accepted',
  PayrollValidated = 'Payroll Validated',
  PayrollConnectError = 'Payroll Connect Error',
  Complete = 'Complete',
}

export const SelfServiceProgressOrder: Record<
  SelfServiceProgressStatus,
  number
> = {
  [SelfServiceProgressStatus.Undetermined]: 0,
  [SelfServiceProgressStatus.NotStarted]: 1,
  [SelfServiceProgressStatus.ProspectUnsaved]: 2,
  [SelfServiceProgressStatus.IdentityCreated]: 3,
  [SelfServiceProgressStatus.IdentityVerified]: 4,
  [SelfServiceProgressStatus.AuthAccountNeedsDataCheck]: 5,
  [SelfServiceProgressStatus.ProviderAlreadyExists]: 6,
  [SelfServiceProgressStatus.TermsAccepted]: 7,
  [SelfServiceProgressStatus.PayrollValidated]: 8,
  [SelfServiceProgressStatus.PayrollConnectError]: 9,
  [SelfServiceProgressStatus.Complete]: 10,
};

export interface SelfServiceEvaluationParameters {
  authConfiguring: boolean;
  authenticated?: boolean;
  accountExists?: boolean;
  hasLocalProspect?: boolean;
  providerCreated?: boolean;
  prospectDataIndicators?: ProspectDataIndicators;
  transientOnboardingState?: TransientOnboardingState;
}

export const evaluateSelfServiceProgressStatus = ({
  authConfiguring,
  authenticated,
  accountExists,
  hasLocalProspect,
  prospectDataIndicators,
  transientOnboardingState,
  providerCreated,
}: SelfServiceEvaluationParameters) => {
  if (authConfiguring) {
    return SelfServiceProgressStatus.Undetermined;
  }

  if (providerCreated || prospectDataIndicators?.dailyPayAccountCreated) {
    return SelfServiceProgressStatus.Complete;
  }

  if (authenticated && !transientOnboardingState?.remoteProspectFetched) {
    return SelfServiceProgressStatus.Undetermined;
  }

  if (transientOnboardingState?.payrollValidated) {
    return SelfServiceProgressStatus.PayrollValidated;
  }

  if (authenticated && prospectDataIndicators?.providerAlreadyExists === true) {
    return SelfServiceProgressStatus.ProviderAlreadyExists;
  }

  if (
    authenticated &&
    prospectDataIndicators?.providerAlreadyExists === undefined
  ) {
    return SelfServiceProgressStatus.AuthAccountNeedsDataCheck;
  }

  if (prospectDataIndicators?.termsAccepted) {
    return SelfServiceProgressStatus.TermsAccepted;
  }

  if (authenticated) {
    return SelfServiceProgressStatus.IdentityVerified;
  }

  if (accountExists) {
    return SelfServiceProgressStatus.IdentityCreated;
  }

  if (hasLocalProspect) {
    return SelfServiceProgressStatus.ProspectUnsaved;
  }

  return SelfServiceProgressStatus.NotStarted;
};

const initialAuthState = {
  username: 'Demo User',
  isLoggedIn: false,
  selfServiceProgress: SelfServiceProgressStatus.Undetermined,
  identityAccountExists: false,
};

export { initialAuthState };
