import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import {
  getIntegrations,
  getProspectData,
  getProviders,
  stageProspect,
  verifyPayrollTMSIntegration,
} from '../../services/api-wrapper';
import { Typography } from '@mui/joy';
import { AppContext } from '../../state/appContext';
import {
  createProviderAction,
  verifyIntegrationAction,
} from '../../state/onboarding/onboardingActions';
import {
  sampleCreateProviderRequest,
  sampleVerifyIntegrationsRequest,
} from '../../sampleData/sampleIntegrationsEndpointsData';
import { captureException } from '../../services/sentry';

export default function APITester() {
  const { dispatch } = React.useContext(AppContext);

  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState<number | null>(0);

  // const [serverStatus, setServerStatus] = React.useState<ServerStatus>();
  // const [error, setError] = React.useState<boolean>(false);

  // Create a useCallback wrapepr around callStatusAPI async function.
  /* const checkAdminStatus = React.useCallback(async () => {
    // Call the API endpoint.
    try {
      setError(false);
      setServerStatus(undefined);
      const response = await callStatusAPI();
      setServerStatus(response);
    } catch (error) {
      setError(true);
      console.error(error);
    }
  }, []);*/

  // Create a useCallback wrapepr around callStatusAPI async function.
  const getStagedProspectData = React.useCallback(async () => {
    // Call the API endpoint.
    try {
      // setError(false);
      // setServerStatus(undefined);
      const response = await getIntegrations();
      alert(JSON.stringify(response));
    } catch (error) {
      //  setError(true);
      captureException(error, {
        extra: {
          message: 'Error in getIntegrations api call',
        },
      });
      alert(JSON.stringify(error));
    }
  }, []);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
      }}
    >
      <Button variant="outlined" color="neutral" onClick={() => setOpen(!open)}>
        {open ? 'Close' : 'Open'} API Tester
      </Button>

      {open && (
        <AccordionGroup sx={{ overflow: 'wrap', paddingTop: '1em' }}>
          <Accordion
            sx={{ overflow: 'wrap' }}
            expanded={index === 0}
            onChange={(event, expanded) => {
              setIndex(expanded ? 0 : null);
            }}
          >
            <AccordionSummary>Test Basic API</AccordionSummary>
            <AccordionDetails>
              <Button
                variant="outlined"
                color="neutral"
                onClick={getStagedProspectData}
              >
                Check Admin Status
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={() =>
                  stageProspect({
                    company_name: 'ABCD',
                    data: { owner: 'def' },
                  })
                }
              >
                Stage Prospect Admin Status
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={async () =>
                  alert(JSON.stringify(await getProspectData()))
                }
              >
                Get Prospect Status
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={async () =>
                  alert(
                    JSON.stringify(
                      await verifyPayrollTMSIntegration(
                        sampleVerifyIntegrationsRequest,
                      ),
                    ),
                  )
                }
              >
                Verify Payroll Integration
              </Button>

              <Button
                variant="outlined"
                color="neutral"
                onClick={async () => {
                  await verifyIntegrationAction(
                    dispatch,
                    sampleVerifyIntegrationsRequest,
                  );
                }}
              >
                Verify Payroll Integration Action
              </Button>

              <Button
                variant="outlined"
                color="neutral"
                onClick={async () => {
                  await createProviderAction(
                    dispatch,
                    sampleCreateProviderRequest,
                  );
                }}
              >
                Create Provider Action
              </Button>
              <Button
                variant="outlined"
                color="neutral"
                onClick={async () => {
                  await getProviders();
                }}
              >
                Get Provider Action
              </Button>
              <Typography sx={{ lineHeight: '2em' }}></Typography>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      )}
    </Box>
  );
}
