import {
  ListEmployeesResponse,
  ProviderResponse,
} from '@dailypay/self-service-ts-sdk';
import { GetCardWithButtonQuery } from '../../cms/contentful/generated/graphql';

export type AdminStateType = {
  username: string;
  employeeList: ListEmployeesResponse | undefined;
  providerList: ProviderResponse[] | undefined;
  mainProvider: ProviderResponse | undefined;
  cardWithButton: GetCardWithButtonQuery | undefined;
  providersFetched: boolean;
};

const initialAdminState = {
  username: 'Demo User',
  employeeList: undefined,
  providerList: undefined,
  cardWithButton: undefined,
  providersFetched: false,
  mainProvider: undefined,
};

export { initialAdminState };
