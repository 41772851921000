import React, { useEffect } from 'react';

import { Button, Grid, Link, Stack, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useNavigate } from 'react-router-dom';
import './signinForm.css';

import { useForm } from 'react-hook-form';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Auth from 'aws-amplify/auth';
import * as yup from 'yup';
import { AppContext } from '../../../../state/appContext';
import { ProspectDataIndicators } from '../../../../state/onboarding/onboardingState';
import { captureException } from '../../../../services/sentry';
import { useTranslation } from 'react-i18next';
import { dawnOrangeLight } from '../../../../theme/self-service-joy-theme';
import { TextCL } from '../../../../components/library/TextCL';
import { SignupPageVariants } from '../signinPage';
import {
  standardEmailYupValidation,
  standardPasswordYupValidation,
} from '../../../../utils/validation';
import { fetchProvidersAction } from '../../../../state/admin/adminActions';
import { fetchProspectAction } from '../../../../state/onboarding/onboardingActions';
import { resendSignUpCode } from 'aws-amplify/auth';
import { dispatchIdentityCreatedAction } from '../../../../state/auth/authReducer';

export type SigninFormData = {
  emailAddress: string;
  password: string;
};

const schema = yup
  .object({
    emailAddress: standardEmailYupValidation,
    password: standardPasswordYupValidation,
  })
  .required();

interface SignInFormProps {
  setPageVariant: (pageVariant: SignupPageVariants) => void;
}

const SignInForm: React.FC<SignInFormProps> = ({ setPageVariant }) => {
  const { state, dispatch } = React.useContext(AppContext);

  const [errorText, setErrorText] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { control, handleSubmit, reset, formState } = useForm<SigninFormData>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(state.onboarding.prospectData?.data as SigninFormData);
  }, [state.onboarding.prospectData?.data, reset]);

  const handleVerifySigninRequest = React.useCallback(async () => {
    Auth.signIn({
      username: control._formValues.emailAddress,
      password: control._formValues.password,
    })
      .then(async (resp) => {
        if (
          resp?.isSignedIn === false &&
          resp?.nextStep?.signInStep === 'CONFIRM_SIGN_UP'
        ) {
          dispatchIdentityCreatedAction(dispatch, true);

          const input: Auth.ResendSignUpCodeInput = {
            username: control._formValues.emailAddress,
          };
          resendSignUpCode(input).catch(() => {
            setErrorText(t('onboarding.resendVerification.errorDescription'));
            return;
          });

          navigate('/onboarding/checkYourEmail', {
            state: {
              email: control._formValues.emailAddress,
            },
          });
          return;
        }

        setErrorText(null);

        let okToRedirectToAdmin = false;

        try {
          const providers = await fetchProvidersAction(dispatch);
          const prospectData = await fetchProspectAction(dispatch);

          if (providers && providers?.length > 0) {
            okToRedirectToAdmin = true;
          } else {
            okToRedirectToAdmin =
              (prospectData?.data as ProspectDataIndicators)
                .dailyPayAccountCreated === true;
          }
        } catch (err) {
          captureException(err, {
            extra: {
              message: 'Error in getProspectData api call on signinForm',
            },
          });
        }

        if (okToRedirectToAdmin) {
          window.location.href = '/admin';
        } else {
          window.location.href = '/onboarding';
        }
      })
      .catch(() => {
        setErrorText('Invalid username or password');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    control._formValues.emailAddress,
    control._formValues.password,
  ]);

  return (
    <>
      <Stack
        spacing={4}
        flex={1}
        flexGrow={1}
        alignItems={'stretch'}
        justifyContent={'center'}
      >
        <InputBoxCL
          control={control}
          fieldName="emailAddress"
          label="EMAIL ADDRESS"
          type="email"
          replayEncrypt={true}
        />

        <InputBoxCL
          control={control}
          fieldName="password"
          label="PASSWORD"
          type="password"
          replayBlock={true}
        />

        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={handleSubmit(handleVerifySigninRequest)}
        >
          Log in
        </Button>

        {errorText && (
          <Grid
            xs={12}
            md={12}
            lg={12}
            sx={{
              padding: '10px 24px',
              marginBottom: '24px',
              backgroundColor: dawnOrangeLight,
            }}
          >
            <TextCL variant="body">
              {t('admin.login.errors.invalidUsernamePassword')}
            </TextCL>
          </Grid>
        )}

        {formState.errors.root && (
          <Box sx={{ paddingTop: '2rem' }}>
            <Typography level="body-sm" sx={{ paddingTop: '2em' }}>
              {JSON.stringify(formState.errors)}
            </Typography>
          </Box>
        )}

        <Link
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => setPageVariant(SignupPageVariants.FORGOT_PASSWORD)}
          underline="always"
        >
          {t('admin.login.forgotPassword')}
        </Link>

        <Link
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => {
            navigate('/onboarding');
          }}
          underline="always"
        >
          {t('admin.login.createAnAccount')}
        </Link>
      </Stack>
    </>
  );
};

export default SignInForm;
