import { Button } from '@mui/joy';
import { Moolah } from '../../../theme';
import { TEST_IDS } from '../../../test-utils/test-ids';
import React, { FC } from 'react';

// TODO - refactor upstream - doesn't make sense for these to be possible be
// undefined in this context.
interface Props {
  setShowEditInputs?: (showEditInputs: boolean) => void;
  onCancel?: () => void;
  onSave?: () => void;
}

const EditActionButtons: FC<Props> = ({
  setShowEditInputs,
  onSave,
  onCancel,
}) => {
  const handleCancelClick = () => {
    setShowEditInputs?.(false);
    onCancel?.();
  };

  const handleSaveClick = () => {
    onSave?.();
  };

  return (
    <div>
      <Button
        variant="solid"
        size="sm"
        sx={{
          borderRadius: '50px',
          backgroundColor: Moolah.white,
          color: Moolah.navy[60],
          marginRight: '8px',
          marginLeft: '1rem',
          '&:hover': {
            background: Moolah.navy[40],
          },
        }}
        onClick={handleCancelClick}
        data-testid={TEST_IDS.terms.nextButton}
      >
        Cancel
      </Button>
      <Button
        variant="solid"
        size="sm"
        sx={{
          borderRadius: '50px',
          backgroundColor: Moolah.orange[60],
          '&:hover': {
            background: Moolah.gold[60],
          },
        }}
        onClick={handleSaveClick}
        data-testid={TEST_IDS.terms.nextButton}
      >
        Save
      </Button>
    </div>
  );
};

export default EditActionButtons;
