import * as React from 'react';
import Button, { ButtonPropsVariantOverrides } from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { OverridableStringUnion } from '@mui/types';
import { VariantProp } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { Moolah } from '../../../../../theme';
import { TEST_IDS } from '../../../../../test-utils/test-ids';

interface AdminModalProps {
  variant?: OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>;
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  title: string;
  description: string;
  confirmButtonText: string;
  cancelButtonText: string;
}

const buttonStyles = {
  borderRadius: '50px',
  marginRight: '8px',
  marginLeft: '1rem',
};

export const AdminModal = ({
  variant = 'outlined',
  isOpen,
  onCancel,
  onConfirm,
  title,
  description,
  confirmButtonText,
  cancelButtonText,
}: AdminModalProps) => {
  return (
    <Modal open={isOpen}>
      <ModalDialog
        variant={variant}
        role="modal"
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          padding: '48px',
        }}
      >
        <DialogTitle>
          <TextCL variant="h2Bold" color="white">
            {title}
          </TextCL>
        </DialogTitle>
        <DialogContent
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <TextCL color={'white'} variant="h4">
            {description}
          </TextCL>
        </DialogContent>
        <DialogActions>
          <Button
            variant="solid"
            size="sm"
            sx={{ ...buttonStyles, backgroundColor: Moolah.orange[60] }}
            onClick={onConfirm}
            data-testid={TEST_IDS.modal.modalConfirmButton}
          >
            {confirmButtonText}
          </Button>
          <Button
            variant="solid"
            size="sm"
            sx={{
              ...buttonStyles,
              backgroundColor: Moolah.white,
              color: Moolah.navy[60],
              '&:hover': {
                background: Moolah.navy[40],
              },
            }}
            onClick={onCancel}
            data-testid={TEST_IDS.modal.modalCloseButton}
          >
            {cancelButtonText}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
