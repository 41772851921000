/* eslint-disable @typescript-eslint/no-explicit-any */
import { CircularProgress, Grid } from '@mui/joy';
import React, {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { TextCL } from '../../../../../components/library/TextCL';
import Table from '@mui/joy/Table';
import Sheet from '@mui/joy/Sheet';
import { useParams } from 'react-router-dom';
import { captureException } from '../../../../../services/sentry';
import { getEmployeePayPeriods } from '../../../../../services/api-wrapper';
import { AppContext } from '../../../../../state/appContext';
import EmployeeEarningsTableRow from './EmployeeEarningsTableRow';
import { DpTransfersTable, EarningsTable, PayTable } from './types';
import { InfoCardCL } from '../../../../../components/library/InfoCardCL';
import { formatDateToLongFormat } from '../../../../../formatters/date-formatters';
import { Moolah } from '../../../../../theme';
import { PayPeriod, PayPeriods } from '@dailypay/self-service-ts-sdk';
import { replaceHyphenWithSlash } from '../../../../../formatters/string-formatters';

export const createTableHeaders = (
  mainHeading: string,
  subHeading: string,
  subHeading2: string,
  subHeading3: string,
  subHeading4: string,
  subHeading5: string,
) => {
  return {
    mainHeading,
    subHeading,
    subHeading2,
    subHeading3,
    subHeading4,
    subHeading5,
  };
};

export interface EmployeeInfoProps {
  employmentUUID: string;
  selectedPayPeriodId: string;
  isCurrentPayPeriod: boolean;
}

const EmployeeEarnings: FC<EmployeeInfoProps> = ({
  employmentUUID,
  selectedPayPeriodId,
  isCurrentPayPeriod,
}) => {
  const { t } = useTranslation();
  const { employeeUUID } = useParams();
  const { state } = useContext(AppContext);
  const [providerID, setProviderID] = useState<string>('');
  const [employeePayPeriods, setEmployeePayPeriods] = useState<PayPeriods>();
  const [employeePayPeriod, setEmployeePayPeriod] = useState<PayPeriod>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state.admin.providerList && state.admin.providerList.length > 0) {
      setProviderID(state.admin.providerList[0].uuid);
    }
  }, [state.admin.providerList]);

  const fetchEmployeePayPeriods = useCallback(
    async (
      providerID: string,
      employeeUUID: string,
      employmentUUID: string,
    ) => {
      return getEmployeePayPeriods(providerID, employeeUUID, employmentUUID);
    },
    [],
  );

  useEffect(() => {
    if (providerID && employeeUUID && employmentUUID) {
      getEmployeePayPeriods(providerID, employeeUUID, employmentUUID)
        .then((data) => {
          setEmployeePayPeriods(data);
          setIsLoading(false);
        })
        .catch((error) => {
          captureException(error, { extra: { providerID, employeeUUID } });
          setIsLoading(false);
        });
    }
  }, [employeeUUID, employmentUUID, fetchEmployeePayPeriods, providerID]);

  useEffect(() => {
    if (employeePayPeriods && selectedPayPeriodId) {
      setEmployeePayPeriod(employeePayPeriods[selectedPayPeriodId]);
    }
  }, [employeePayPeriods, selectedPayPeriodId]);

  const tableHeaders = useMemo(() => {
    if (!employeePayPeriod) return [];

    return [
      createTableHeaders(
        EarningsTable.Earnings,
        EarningsTable.Shift,
        ' ',
        ' ',
        EarningsTable.ShiftStatus,
        EarningsTable.GrossPay,
      ),
      createTableHeaders(
        DpTransfersTable.DailyPayTransfers,
        DpTransfersTable.Date,
        DpTransfersTable.DestAcc,
        DpTransfersTable.TransferAmount,
        DpTransfersTable.Fees,
        DpTransfersTable.TotalAmount,
      ),
      createTableHeaders(
        PayTable.Pay,
        PayTable.Date,
        ' ',
        PayTable.Earnings,
        PayTable.DailyPayTransfers,
        PayTable.PaycheckAmount,
      ),
    ];
  }, [employeePayPeriod]);

  if (!employeePayPeriod) {
    return (
      <Grid xs={12} md={12} lg={12} display={'flex'} justifyContent={'center'}>
        <CircularProgress />
      </Grid>
    );
  }

  const totalTransferAndFeeAmount = employeePayPeriod.transfers.reduce(
    (total: number, transfer: any) => total + (transfer.amount + transfer.fee),
    0,
  );

  const totalTaxAndDeductions =
    employeePayPeriod?.gross_pay - employeePayPeriod?.net_pay;

  const payday = isCurrentPayPeriod
    ? `${t('admin.employees.earnings.nextPayday')} ${replaceHyphenWithSlash(
        employeePayPeriod?.pay_date,
      )}`
    : `${t('admin.employees.earnings.payDay')} ${replaceHyphenWithSlash(
        employeePayPeriod?.pay_date,
      )}`;

  return (
    <Grid
      container
      spacing={4}
      sx={{
        flexGrow: 1,
        borderRadius: '8px',
      }}
    >
      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formField"
        sx={{ padding: '24px 40px' }}
      >
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <InfoCardCL
            title={
              isCurrentPayPeriod
                ? 'Today'
                : formatDateToLongFormat(employeePayPeriod.pay_date)
            }
            l1Text={employeePayPeriod.gross_pay || 0}
            l2Text={
              isCurrentPayPeriod
                ? employeePayPeriod?.gross_earnings_advance_rate || 0
                : totalTaxAndDeductions || 0
            }
            l3Text={
              isCurrentPayPeriod
                ? employeePayPeriod?.available_balance
                : totalTransferAndFeeAmount
            }
            isCurrentPayPeriod={isCurrentPayPeriod}
          />
        )}
      </Grid>

      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        sx={{ padding: '0px 48px' }}
      >
        <TextCL
          variant="h4"
          fontWeight="600"
          color={Moolah.navy[60]}
          replayEncrypt={true}
        >
          {payday}
        </TextCL>
      </Grid>
      <Grid xs={12} md={12} lg={12} className="formSection">
        <Sheet>
          <Table aria-label="collapsible table" sx={{ textAlign: 'left' }}>
            <tbody>
              {employeePayPeriod &&
                tableHeaders.map((row, index) => (
                  <EmployeeEarningsTableRow
                    key={row.mainHeading}
                    row={row}
                    employeePayPeriod={employeePayPeriod}
                    initialOpen={index === 0}
                    index={index}
                  />
                ))}
            </tbody>
          </Table>
        </Sheet>
      </Grid>
    </Grid>
  );
};

export default EmployeeEarnings;
