import { Accordion, AccordionDetails, AccordionSummary, Grid } from '@mui/joy';
import React, { FC } from 'react';
import { Horizon, Moolah } from '../../../theme';
import { TextCL } from '../../../components/library/TextCL';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Document } from '@contentful/rich-text-types';

interface Props {
  title: string;
  content: Document;
}

const TermsInfoBox: FC<Props> = ({ title, content }) => {
  return (
    <Grid padding={0} minWidth={'100%'}>
      <Accordion expanded={true}>
        <AccordionSummary indicator={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '8px',
              paddingLeft: '26px',
              backgroundColor: Horizon.info[2],
              width: '120%',
            }}
          >
            <TextCL variant="h6" color={Moolah.white}>
              {title}
            </TextCL>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{
            backgroundColor: Horizon.info[5],
            color: Moolah.navy[60],
            fontSize: '16px',
            fontFamily: 'Graphik-Medium',
            lineHeight: '130%',
          }}
          slotProps={{
            root: {
              'data-qm-encrypt': true,
            },
          }}
        >
          {documentToReactComponents(content)}
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default TermsInfoBox;
