import React from 'react';
import { Outlet } from 'react-router-dom';
import './WizardLayout.css';
import {
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  ModalClose,
  Stack,
  useTheme,
} from '@mui/joy';
import WizardLayoutFoundations from './foundations/WizardLayoutFoundations';
import WizardSidebar from '../components/wizard/WizardSidebar';
import logo from '../assets/images/dailypay-logo.png';
import logoWhite from '../assets/images/dailypay-logo-white.png';
import useMediaQuery from '@mui/material/useMediaQuery';
import { iconLibrary } from '../assets/icons/icon-library';

const WizardLayout: React.FC = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <CssBaseline />
      {!matches && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.level3,
            display: 'flex',
            height: '62px',
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <IconButton
            onClick={() => setOpen(true)}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <img width={24} src={iconLibrary.common.menuWhite}></img>
          </IconButton>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '1rem',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <img src={logoWhite} width="120px" />
          </Box>
        </Box>
      )}
      <WizardLayoutFoundations.Root>
        {matches && (
          <WizardLayoutFoundations.LeftSider>
            <Stack spacing={4} sx={{ padding: '2.5em 3em 3em 3em' }}>
              <img src={logo} width="130px" />
            </Stack>
            <WizardSidebar />
          </WizardLayoutFoundations.LeftSider>
        )}
        {!matches && (
          <>
            <Drawer open={open} onClose={() => setOpen(false)}>
              <ModalClose />
              <WizardSidebar />
            </Drawer>
          </>
        )}

        <WizardLayoutFoundations.MainContent sx={{ flexGrow: 1 }}>
          <Outlet />
        </WizardLayoutFoundations.MainContent>
      </WizardLayoutFoundations.Root>
    </React.Fragment>
  );
};

export default WizardLayout;
