import React, { useCallback, useEffect, useMemo } from 'react';

import './employees.css';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { CircularProgress, Grid, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import EmployeeInfo from './components/employee-info/EmployeeInfo';
import { Helmet } from 'react-helmet';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { AppContext } from '../../../state/appContext';
import { getEmployeeIndividualData } from '../../../services/api-wrapper';
import { Employee } from '@dailypay/self-service-ts-sdk';
import { captureException } from '../../../services/sentry';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { iconLibrary } from '../../../assets/icons/icon-library';

export default function EmployeeDetails() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();
  const { employeeUUID } = useParams();

  const { state } = React.useContext(AppContext);
  const [providerID, setProviderID] = React.useState<string>('');
  const [getLatestEmployeeDetails, setGetLatestEmployeeDetails] =
    React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const [employeeDetails, setEmployeeDetails] = React.useState<Employee>();
  const [hasNoPayPeriods, setHasNoPayPeriods] = React.useState<boolean>(false);

  useEffect(() => {
    if (state.admin.providerList && state.admin.providerList.length > 0) {
      setProviderID(state.admin.providerList[0].uuid);
    }
  }, [state.admin.providerList]);

  const fetchEmployeeData = useCallback(
    async (providerID: string, employeeUUID: string) => {
      return getEmployeeIndividualData(providerID, employeeUUID);
    },
    [],
  );

  const fetchEmployeeDataMemo = useMemo(
    () => fetchEmployeeData,
    [fetchEmployeeData],
  );

  useEffect(() => {
    if (providerID && employeeUUID) {
      fetchEmployeeDataMemo(providerID, employeeUUID)
        .then((data) => {
          setLoading(false);
          if (data.employments && data.employments.length > 0) {
            setEmployeeDetails(data);
            setGetLatestEmployeeDetails(false);

            if (data.employments[0].pay_periods === null) {
              setHasNoPayPeriods(true);
            }
          }
        })
        .catch((error) => {
          setLoading(false);
          navigate(-1);
          captureException(error, { extra: { providerID, employeeUUID } });
        });
    }
  }, [
    providerID,
    employeeUUID,
    fetchEmployeeDataMemo,
    getLatestEmployeeDetails,
    navigate,
  ]);

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });

  if (loading) {
    return (
      <Grid
        xs={12}
        md={12}
        lg={12}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '1rem',
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Self Service - Employees</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '0em' : '1em' }}>
        <Grid container xs={12} md={12} lg={12}>
          <Grid xs={12} md={6} lg={6}>
            <PageHeader
              replayEncrypt={true}
              image={iconLibrary.heading.employees}
              imageAlt="admin.employees.title.icon"
              titleKey={`${t('admin.employees.title')}: `}
              name={`${
                employeeDetails?.first_name &&
                `${employeeDetails?.first_name} ${employeeDetails?.last_name}`
              }`}
            />
          </Grid>
          {isLargerScreen && (
            <Grid xs={12} md={6} lg={6}>
              <AdminUserLogout />
            </Grid>
          )}
        </Grid>

        {employeeDetails && (
          <EmployeeInfo
            employee={employeeDetails}
            providerId={providerID}
            refetchEmployeeInfo={() => {
              setGetLatestEmployeeDetails(true);
            }}
            hasNoPayPeriods={hasNoPayPeriods}
          />
        )}
      </Stack>
    </>
  );
}
