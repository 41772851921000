import React from 'react';

import { Typography } from '@mui/joy';
import { makeStyles } from '../../services/styles/index';
import { darkBlue, darkRed, midnightBlue } from '../../theme/theme';
import { Moolah } from '../../theme';

export declare type FontWeight =
  | '400'
  | 'normal'
  | '500'
  | 'partialbold'
  | '600'
  | 'semibold'
  | '700'
  | 'bold';

export interface TextProps {
  children?: React.ReactNode;
  fontWeight?: FontWeight;
  color?: string;
  fontStyle?: 'italic';
  textAlign?: 'left' | 'center' | 'right' | 'initial';
  component?: string;
  variant?:
    | 'error'
    | 'body'
    | 'overline'
    | 'label'
    | 'input'
    | 'caption'
    | 'link'
    | 'xs'
    | 'h1'
    | 'h1Bold'
    | 'h2'
    | 'h2Bold'
    | 'h3'
    | 'h3Bold'
    | 'h4'
    | 'h4Bold'
    | 'h5'
    | 'h6'
    | 'dpH1'
    | 'dpH2'
    | 'dpH3'
    | 'dpH4';
  replayBlock?: boolean;
  replayEncrypt?: boolean;
}

export const TextCL = (props: TextProps) => {
  const styles = useStyles();
  const {
    children,
    variant,
    color,
    fontStyle,
    fontWeight,
    replayBlock,
    replayEncrypt,
    ...otherProps
  } = props;

  function getFontWeight() {
    switch (fontWeight) {
      case '500':
      case 'partialbold':
        return styles.partialbold;
      case '600':
      case 'semibold':
        return styles.semibold;
      case '700':
      case 'bold':
        return styles.bold;
      default:
        return styles.normal;
    }
  }

  //TODO: Refactor to support cases with one or more font style
  function getFontStyle() {
    switch (fontStyle) {
      case 'italic':
        return styles.normalItalic;
      default:
        return;
    }
  }

  function getVariantStyle() {
    switch (variant) {
      case 'error':
        return styles.error;
      case 'body':
        return styles.body;
      case 'overline':
        return styles.overline;
      case 'label':
        return styles.label;
      case 'input':
        return styles.input;
      case 'caption':
        return styles.caption;
      case 'link':
        return styles.link;
      case 'xs':
        return styles.xs;
      case 'h1':
        return styles.h1;
      case 'h1Bold':
        return styles.h1Bold;
      case 'h2':
        return styles.h2;
      case 'h2Bold':
        return styles.h2Bold;
      case 'h3':
        return styles.h3;
      case 'h3Bold':
        return styles.h3Bold;
      case 'h4':
        return styles.h4;
      case 'h4Bold':
        return styles.h4Bold;
      case 'h5':
        return styles.h5;
      case 'h6':
        return styles.h6;
      case 'dpH1':
        return styles.dpH1;
      case 'dpH2':
        return styles.dpH2;
      case 'dpH3':
        return styles.dpH3;
      case 'dpH4':
        return styles.dpH4;
      default:
        return styles.body;
    }
  }

  const renderText = () => (
    <Typography
      {...otherProps}
      style={{
        ...styles.text,
        ...getFontWeight(),
        ...getFontStyle(),
        ...getVariantStyle(),
        color: color || Moolah.navy[60],
      }}
      slotProps={{
        root: {
          'data-qm-block': replayBlock,
          'data-qm-encrypt': replayEncrypt,
        },
      }}
    >
      {children}
    </Typography>
  );

  return <>{renderText()}</>;
};

const useStyles = makeStyles(() => {
  return {
    dpH1: {
      fontFamily: 'Horizon-H1',
      fontSize: '42px',
    },
    dpH2: {
      fontFamily: 'Horizon-H1',
      fontSize: '34px',
    },
    dpH3: {
      fontFamily: 'Horizon-H1',
      fontSize: '28px',
    },
    dpH4: {
      fontFamily: 'Horizon-H1',
      fontSize: '18px',
    },
    h1: {
      fontFamily: 'Graphik-Light',
      fontSize: '42px',
      lineHeight: '120%' /* 3.15rem */,
    },
    h1Bold: {
      fontFamily: 'Graphik',
      fontSize: '64px',
      fontWeight: '500',
      lineHeight: '75px' /* 3.15rem */,
    },
    h2: {
      fontFamily: 'Graphik',
      fontSize: '34px',
      lineHeight: '120%' /* 3.15rem */,
      letterSpacing: '-0.34px',
    },
    h2Bold: {
      fontFamily: 'Graphik-Semibold',
      fontSize: '34px',
      lineHeight: '120%' /* 3.15rem */,
      letterSpacing: '0.34px',
    },
    h3: {
      fontSize: '28px',
      fontFamily: 'Graphik',
      lineHeight: '120%' /* 3.15rem */,
    },
    h3Bold: {
      fontSize: '28px',
      fontFamily: 'Graphik-Medium',
      lineHeight: '120%' /* 3.15rem */,
    },
    h4: {
      fontSize: '20px',
      fontFamily: 'Graphik-Medium',
      lineHeight: '130%',
    },
    h4Bold: {
      fontSize: '20px',
      fontFamily: 'Graphik-Black',
      lineHeight: '130%',
      letterSpacing: '0.4px',
    },
    h5: {
      fontSize: '18px',
      fontFamily: 'Graphik',
      lineHeight: '130%',
    },
    h6: {
      fontSize: '16px',
      fontFamily: 'Graphik-Medium',
      lineHeight: '130%',
    },
    xs: {
      fontSize: '11px',
      fontFamily: 'Graphik',
    },
    normal: {
      fontFamily: 'Graphik-Regular',
    },
    partialbold: {
      fontFamily: 'Graphik-Medium',
    },
    semibold: {
      fontFamily: 'Graphik-Semibold',
      fontWeight: 600,
    },
    bold: {
      fontFamily: 'Graphik-Bold',
      fontWeight: '600',
    },
    normalItalic: {
      fontFamily: 'Graphik-RegularItalic',
    },
    text: {
      color: midnightBlue,
    },
    body: {
      fontSize: '14px',
      fontFamily: 'Graphik',
      lineHeight: '140%',
      letterSpacing: '0.07px',
    },
    overline: {
      fontSize: '10px',
      fontFamily: 'Graphik-Semibold',
      fontWeight: '600',
      lineHeight: '12px',
      textTransform: 'uppercase',
      letterSpacing: '0.6px',
    },
    label: {
      fontSize: '12px',
      fontFamily: 'Graphik',
      lineHeight: 'normal',
      textTransform: 'uppercase',
      color: '#383D4A',
    },
    input: {
      fontSize: '14px',
      fontFamily: 'Graphik',
      lineHeight: 'normal',
    },
    caption: {
      fontSize: '14px',
      fontFamily: 'Graphik-Medium',
      letterSpacing: '0.42px',
      textTransform: 'uppercase',
    },
    link: {
      fontSize: '14px',
      fontFamily: 'Graphik-Medium',
      color: darkBlue,
    },
    error: {
      fontSize: '12px',
      fontFamily: 'Graphik-Medium',
      letterSpacing: '0.11px',
      color: darkRed,
    },
  };
});
