import React from 'react';
import { Select, Option, FormLabel } from '@mui/joy';
import { EmployeeStatusChipCL } from './EmployeeStatusChipCL';
import { EmploymentStatus } from '@dailypay/self-service-ts-sdk';
import { TextCL } from './TextCL';

interface SelectCLProps {
  size?: 'sm' | 'md' | 'lg';
  fieldName: string;
  options: { id: string; label: string }[];
  label?: string;
  datatestid: string;
  value?: string | null;
  isStatusDropdown?: boolean;
  replayBlock?: boolean;
  replayEncrypt?: boolean;
  onChangeCallback?: (val: string) => void;
}

export const SelectCL = ({
  size,
  fieldName,
  options,
  label,
  datatestid,
  value,
  replayBlock,
  replayEncrypt,
  onChangeCallback,
}: SelectCLProps) => {
  return (
    <>
      {label && (
        <FormLabel sx={{ padding: '1em 0' }}>
          <TextCL variant="overline">{label}</TextCL>
        </FormLabel>
      )}
      <Select
        size={size}
        defaultValue={value}
        value={value}
        onChange={(e, v) => {
          if (!v) return;
          if (typeof onChangeCallback === 'function') {
            onChangeCallback(v);
          }
        }}
        placeholder={value}
        slotProps={{
          root: {
            'data-testid': datatestid || `${label}-dropdown`,
            'data-qm-block': replayBlock,
            'data-qm-encrypt': replayEncrypt,
          },
          button: { sx: { whiteSpace: 'nowrap' } },
        }}
      >
        {options.map((option, index) => (
          <Option
            value={option.id}
            key={index}
            slotProps={{
              root: {
                'data-testid': datatestid || `${label}-dropdown`,
                'data-qm-block': replayBlock,
                'data-qm-encrypt': replayEncrypt,
              },
            }}
          >
            {fieldName === 'employmentStatus' ? (
              <EmployeeStatusChipCL
                employeeStatus={option.id as EmploymentStatus}
              />
            ) : (
              <TextCL>{option.label}</TextCL>
            )}
          </Option>
        ))}
      </Select>
    </>
  );
};
