import React from 'react';

import { Button, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import { Helmet } from 'react-helmet';
import { iconLibrary } from '../../../assets/icons/icon-library';
import CheckboxCL from '../../../components/library/CheckboxCL';
import { TEST_IDS } from '../../../test-utils/test-ids';
import { useForm } from 'react-hook-form';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../services/analytic-events/SelfServiceAnalyticsTypes';
import ENV_VARS from '../../../environment/envvars';

type FormData = {
  letMeKnowChecked: boolean;
};

export default function UnsupportedPayrollProvider() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();

  const { control, handleSubmit, setValue } = useForm<FormData>({});

  const handleOkayClicked = React.useCallback(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderOKButton,
      payload: {
        notify: control._formValues.letMeKnowChecked,
      },
    });
    if (ENV_VARS.landingPageURL) {
      window.location.href = ENV_VARS.landingPageURL;
    }
  }, [control._formValues.letMeKnowChecked]);

  return (
    <>
      <Helmet>
        <title>Self Service - Unsupported Providers</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '5em' : '1em' }}>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <img width={45} src={iconLibrary.heading.register} alt="register" />
          <Typography level="h1" sx={{ paddingLeft: '12px' }}>
            {t('onboarding.registration.title')}
          </Typography>
        </Stack>
        <FormFoundations.FormWrapperBox>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gridColumn: '1/-1',
              paddingBottom: '103px',
              textAlign: 'center',
            }}
          >
            <Typography
              level="h2"
              sx={{
                display: 'flex',
                paddingBottom: '58px',
              }}
            >
              {t('onboarding.registration.notSupported.title')}
            </Typography>
            <Typography level="title-md" sx={{ paddingBottom: '23px' }}>
              {t('onboarding.registration.notSupported.description')}
            </Typography>
            <CheckboxCL
              control={control}
              fieldName="letMeKnowChecked"
              datatestid={TEST_IDS.register.letMeKnowCheckbox}
              label={t('onboarding.registration.notSupported.letMeKnow')}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setValue('letMeKnowChecked', e.target.checked);
                logAnalyticsEventForSelfService({
                  type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderCheckbox,
                  payload: {
                    notify: e.target.checked,
                  },
                });
              }}
            />
          </div>
        </FormFoundations.FormWrapperBox>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: '50px',
            }}
            onClick={handleSubmit(handleOkayClicked)}
          >
            {t('onboarding.registration.notSupported.buttonText')}
          </Button>
        </div>
      </Stack>
    </>
  );
}
