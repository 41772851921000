import React, { useCallback, useEffect, useMemo } from 'react';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { CircularProgress, Grid, Stack } from '@mui/joy';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { iconLibrary } from '../../../assets/icons/icon-library';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';
import { useNavigate, useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import AdminDetails from './components/adminDetails/AdminDetails';
import { Admin } from './components/adminDetails/type';
import { captureException } from '../../../services/sentry';
import { DailyPayStatus } from '@dailypay/self-service-ts-sdk';

const adminTestData: Admin = {
  admin_uuid: '123e4567-e89b-12d3-a456-426614174000',
  first_name: 'John',
  last_name: 'Doe',
  email: 'john.doe@example.com',
  phone_number: '1234567890',
  status: DailyPayStatus.Pending,
  invite_date: '2024-01-20T12:45:00Z',
};

const ManageAdminDetails = () => {
  const { isLargerScreen } = useScreenQuery();
  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });
  const { adminUUID } = useParams();
  const { t } = useTranslation();
  const [loading, setLoading] = React.useState<boolean>(true);
  const navigate = useNavigate();
  const [adminDetails, setAdminDetails] = React.useState<Admin>();

  const fetchAdminData = useCallback(
    async (providerID: string, adminUUID: string) => {
      // eslint-disable-next-line no-console
      console.log(providerID, adminUUID);
      // placeholder to get admin data
      // return getAdminIndividualData(providerID, adminUUID);
    },
    [],
  );

  const fetchAdminDataMemo = useMemo(() => fetchAdminData, [fetchAdminData]);

  useEffect(() => {
    if (adminUUID) {
      fetchAdminDataMemo('providerID', adminUUID)
        // .then((data) => {
        .then(() => {
          setLoading(false);
          setAdminDetails(adminTestData);
          // setAdminDetails(data);
        })
        .catch((error) => {
          setLoading(false);
          navigate(-1);
          captureException(error, { extra: { adminUUID } });
        });
    }
  }, [adminUUID, fetchAdminDataMemo, navigate]);

  if (loading) {
    return (
      <Grid
        xs={12}
        md={12}
        lg={12}
        display={'flex'}
        justifyContent={'center'}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingTop: '1rem',
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <Stack spacing={4} sx={{ padding: isLargerScreen ? '0em' : '1em' }}>
      <Grid container xs={12} md={12} lg={12}>
        <Grid xs={12} md={6} lg={6}>
          <PageHeader
            replayEncrypt={true}
            image={iconLibrary.heading.manageCompanyAccount}
            imageAlt="admin.manageCompanyAccount.title.icon"
            titleKey={`${t('admin.manageCompanyAccount.title')}: `}
            name={`${adminTestData.first_name} ${adminTestData.last_name}`}
          />
        </Grid>
        {isLargerScreen && (
          <Grid xs={12} md={6} lg={6}>
            <AdminUserLogout />
          </Grid>
        )}
      </Grid>
      {adminDetails && <AdminDetails admin={adminDetails} />}
    </Stack>
  );
};

export default ManageAdminDetails;
