import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import ENV_VARS from '../../../environment/envvars';
import './env-details.css';
import { Chip, IconButton } from '@mui/joy';
import CloseIcon from '@mui/icons-material/Close';

const EnvDetails = () => {
  const disableShowEnv = true;
  const [showEnvDetails, setShowEnvDetails] = React.useState<boolean>(
    ENV_VARS.env !== 'production',
  );

  if (!showEnvDetails || disableShowEnv) {
    return null;
  }

  return (
    <Box alignItems="flex-end" className="pushToBottom">
      <Stack
        className="padded"
        flexGrow={1}
        alignItems={'flex-end'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          flexGrow={1}
          sx={{ paddingBottom: '1rem' }}
        >
          <Chip>
            <Typography variant="body1">
              <b>App: </b>
              {ENV_VARS.appName}
            </Typography>
          </Chip>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          flexGrow={1}
        >
          <Chip>
            <Typography variant={'body1'}>
              <b>Env: </b>
              {ENV_VARS.env}
            </Typography>
          </Chip>
        </Box>
        <Box
          display={'flex'}
          justifyContent={'flex-end'}
          alignItems={'center'}
          flexGrow={1}
          sx={{ paddingTop: '1rem' }}
        >
          <IconButton onClick={() => setShowEnvDetails(false)}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Stack>
    </Box>
  );
};

export default EnvDetails;
