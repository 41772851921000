import React from 'react';

import ReactDOM from 'react-dom/client';
import './font.css';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { RouterProvider } from 'react-router-dom';
import { AppProvider } from './state/appContext';

import { CssVarsProvider } from '@mui/joy/styles';

import { router } from './routes/router';
import EnvDetails from './components/common/env-details/env-details';

import { selfServiceJoyTheme } from './theme/self-service-joy-theme';

import WebFont from 'webfontloader';

import './translations/i18n';

import { Helmet } from 'react-helmet';
import DevAssist from './components/devTools/DevAssist';

import { Amplify } from 'aws-amplify';
import ENV_VARS, { AWS_ENV } from './environment/envvars';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css'; // default theme
import ServerStatusViewer from './components/devTools/ServerStatusViewer';
import ReferenceDataLoader from './components/stateManagement/ReferenceDataLoader';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import * as Sentry from './services/sentry';
import * as Amplitude from '@amplitude/analytics-browser';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

const client = new ApolloClient({
  uri: ENV_VARS.contentfulURL,
  cache: new InMemoryCache(),
});

WebFont.load({
  google: {
    families: ['Inter'],
  },
});

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: AWS_ENV.USER_POOL_ID,
      userPoolClientId: AWS_ENV.USER_POOL_APP_CLIENT_ID,
    },
  },
});

Sentry.init();

if (ENV_VARS.amplitudeApiKey) {
  Amplitude.init(ENV_VARS.amplitudeApiKey, { defaultTracking: false });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const queryClient = new QueryClient();

root.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <ApolloProvider client={client}>
        <Helmet>
          <title>Self Service</title>
          <meta name="description" content="Self Service" />
        </Helmet>
        <CssVarsProvider theme={selfServiceJoyTheme}>
          <QueryClientProvider client={queryClient}>
            <AppProvider>
              <ReferenceDataLoader />
              <RouterProvider router={router} />
              {ENV_VARS.env !== 'production' && (
                <>
                  <EnvDetails />
                  <DevAssist />
                </>
              )}
              <ServerStatusViewer />
            </AppProvider>
          </QueryClientProvider>
        </CssVarsProvider>
      </ApolloProvider>
    </Authenticator.Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
