import React from 'react';

import './validatePayroll.css';
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/joy';

export default function EnterPayrollConfig() {
  const navigate = useNavigate();

  const handleAcceptValidatedPayroll = React.useCallback(() => {
    navigate('/onboarding/payrollConnectionSucccess');
  }, [navigate]);

  return (
    <>
      <h2>Validate Payroll</h2>

      <Button
        sx={{ borderRadius: '50px' }}
        onClick={handleAcceptValidatedPayroll}
      >
        Confirm Payroll
      </Button>
    </>
  );
}
