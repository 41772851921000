import * as React from 'react';
import Box, { BoxProps } from '@mui/joy/Box';

function Root(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        { display: 'flex' },
      ]}
    />
  );
}

function LeftSider(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          minHeight: '100vh',
          minWidth: '290px',
          width: '290px',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function MainContent(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[...(Array.isArray(props.sx) ? props.sx : [props.sx])]}
    />
  );
}

export default {
  Root,
  LeftSider,
  MainContent,
};
