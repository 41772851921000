import React from 'react';
import { Box, BoxProps, Grid } from '@mui/joy';
import { formInsetBackground } from '../../theme/self-service-joy-theme';

function FormWrapperGrid(props: BoxProps) {
  return (
    <Grid
      container
      spacing={4}
      sx={{
        flexGrow: 1,
        backgroundColor: formInsetBackground,
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 2px 4px rgba(15, 22, 57, 0.04)',
        ...(Array.isArray(props?.sx) ? props?.sx : [props?.sx]),
      }}
    >
      {props.children}
    </Grid>
  );
}

function FormWrapperBox(props: BoxProps) {
  return (
    <Box
      sx={{
        pt: 3,
        pb: 10,
        display: 'grid',
        gridTemplateColumns: {
          xs: '100%',
          sm: 'minmax(120px, 30%) 1fr',
          lg: '280px 1fr minmax(120px, 208px)',
        },
        columnGap: { xs: 2, sm: 3, md: 4 },
        rowGap: { xs: 2, sm: 2.5 },
        '& > hr': {
          gridColumn: '1/-1',
        },
        backgroundColor: formInsetBackground,
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 2px 4px rgba(15, 22, 57, 0.04)',
        ...(Array.isArray(props?.sx) ? props?.sx : [props?.sx]),
      }}
    >
      {props.children}
    </Box>
  );
}

function FormOutlineFlex(props: BoxProps) {
  return (
    <Box
      sx={{
        pt: 3,
        pb: 10,
        display: 'flex',
        backgroundColor: formInsetBackground,
        borderRadius: '8px',
        padding: '24px',
        boxShadow: '0px 2px 4px rgba(15, 22, 57, 0.04)',
        ...(Array.isArray(props?.sx) ? props?.sx : [props?.sx]),
      }}
    >
      {props.children}
    </Box>
  );
}

export default { FormWrapperBox, FormWrapperGrid, FormOutlineFlex };
