import { Link } from '@mui/joy';
import { TextCL } from '../../../../components/library/TextCL';
import React, { useEffect, useState } from 'react';
import { ResendSignUpCodeInput, resendSignUpCode } from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';

type ResendVerificationProps = {
  email: string;
  setErrorText: (errorText: string) => void;
};

const THROTTLE_TIME = 5000;

const ResendVerification = ({
  email,
  setErrorText,
}: ResendVerificationProps) => {
  const { t } = useTranslation();
  const [clicked, setClicked] = useState(false);

  const onResendClickHandler = () => {
    const input: ResendSignUpCodeInput = {
      username: email,
    };
    resendSignUpCode(input)
      .then(() => setClicked(true))
      .catch(() => {
        setErrorText(t('onboarding.resendVerification.errorDescription'));
      });
  };

  const ResendJsx = (
    <TextCL variant="h5">
      {t('onboarding.resendVerification.resendDescription')}{' '}
      <Link onClick={onResendClickHandler} underline={'always'}>
        {t('onboarding.resendVerification.resendCTA')}
      </Link>
    </TextCL>
  );

  const HasResentJsx = (
    <TextCL variant="h5">
      {t('onboarding.resendVerification.sentDescription')}
    </TextCL>
  );

  useEffect(() => {
    if (clicked) {
      setTimeout(() => {
        setClicked(false);
      }, THROTTLE_TIME);
    }
  }, [clicked]);

  return clicked ? HasResentJsx : ResendJsx;
};

export default ResendVerification;
