import React from 'react';

import { Card, CardOverflow, CardContent } from '@mui/joy';
import { TextCL } from './TextCL';
import { iconLibrary } from '../../assets/icons/icon-library';
import { useTranslation } from 'react-i18next';
import { Horizon, Moolah } from '../../theme/common';
import TooltipCL from './TooltipCL';
import { formatMoney } from '../../formatters/money-formatter';

interface InfoCardProps {
  title?: string;
  l1Text: number;
  l2Text: number;
  l3Text?: number;
  isCurrentPayPeriod?: boolean;
}

export const InfoCardCL = ({
  title,
  l1Text,
  l2Text,
  l3Text,
  isCurrentPayPeriod,
}: InfoCardProps) => {
  const { t } = useTranslation();

  const calculateTotal = () => {
    const l3 = l3Text || 0;
    const total = l1Text - l2Text - l3;
    return formatMoney({ amountCents: total > 0 ? total : 0 });
  };

  const subTitle1 = isCurrentPayPeriod
    ? t('admin.employees.earnings.currentPay.subTitle1')
    : t('admin.employees.earnings.previousPay.subTitle1');
  const subTitle2 = isCurrentPayPeriod
    ? t('admin.employees.earnings.currentPay.subTitle2')
    : t('admin.employees.earnings.previousPay.subTitle2');
  const subTitle3 = t('admin.employees.earnings.previousPay.subTitle3');
  const subTitleTotal = isCurrentPayPeriod
    ? t('admin.employees.earnings.currentPay.titleTotal')
    : `${t('admin.employees.earnings.previousPay.titleTotal')} (${title})`;
  const l2TextFormatted = isCurrentPayPeriod
    ? `${l2Text * 100}%`
    : `${formatMoney({ amountCents: l2Text })}`;

  return (
    <Card sx={{ padding: 0 }}>
      <CardOverflow
        variant="soft"
        sx={{
          paddingTop: '10px',
          paddingBottom: '10px',
          textAlign: 'center',
          backgroundColor: Moolah.navy[60],
        }}
      >
        <TextCL variant="overline" color={Moolah.white} replayEncrypt={true}>
          {title}
        </TextCL>
      </CardOverflow>
      <CardContent
        sx={{
          backgroundColor: isCurrentPayPeriod
            ? Horizon.info[2]
            : Horizon.info[3],
          marginTop: '-15px',
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'row',
            margin: '20px',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            {isCurrentPayPeriod ? (
              <TooltipCL
                text={subTitle1}
                tooltipTitle={t('terminology.tooltip.totalEarningsTitle')}
                tooltipText={t('terminology.tooltip.totalEarnings')}
                placement="top"
                showCloseIcon={true}
                iconColor={Moolah.white}
              />
            ) : (
              <TextCL
                variant="overline"
                color={isCurrentPayPeriod ? Moolah.white : Moolah.navy[60]}
                replayEncrypt={true}
              >
                {subTitle1}
              </TextCL>
            )}
            <TextCL
              variant="dpH1"
              color={isCurrentPayPeriod ? Moolah.white : Moolah.navy[60]}
              replayBlock={true}
            >
              {formatMoney({ amountCents: l1Text })}
            </TextCL>
          </div>
          {!isCurrentPayPeriod && (
            <img
              width={24}
              src={iconLibrary.common.circleSubtract}
              alt="circleSubtractEarnings"
            ></img>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              flexGrow: 1,
            }}
          >
            {isCurrentPayPeriod ? (
              <TooltipCL
                text={t('admin.employees.earnings.currentPay.subTitle2')}
                tooltipTitle={t('terminology.tooltip.advanceRateTitle')}
                tooltipText={t('terminology.tooltip.advanceRate')}
                placement="top"
                showCloseIcon={true}
                iconColor={Moolah.white}
              />
            ) : (
              <TextCL
                variant="overline"
                color={isCurrentPayPeriod ? Moolah.white : Moolah.navy[60]}
                replayEncrypt={true}
              >
                {subTitle2}
              </TextCL>
            )}
            <TextCL
              variant="dpH1"
              color={isCurrentPayPeriod ? Moolah.white : Moolah.navy[60]}
              replayBlock={true}
            >
              {l2TextFormatted}
            </TextCL>
          </div>
          {!isCurrentPayPeriod && (
            <>
              <img
                width={24}
                src={iconLibrary.common.circleSubtract}
                alt="circleSubtractEarnings"
              ></img>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flexGrow: 1,
                }}
              >
                <TextCL
                  variant="overline"
                  color={Moolah.navy[60]}
                  replayEncrypt={true}
                >
                  {subTitle3}
                </TextCL>
                <TextCL
                  variant="dpH1"
                  color={Moolah.navy[60]}
                  replayBlock={true}
                >
                  {formatMoney({ amountCents: l3Text })}
                </TextCL>
              </div>
            </>
          )}
          {!isCurrentPayPeriod && (
            <img
              width={24}
              src={iconLibrary.common.circleEquals}
              alt="circleEqualsEarnings"
            ></img>
          )}
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              backgroundColor: isCurrentPayPeriod
                ? Moolah.white
                : 'transparent',
              borderRadius: '5px',
              padding: '15px',
              margin: '0px 10px',
              flexGrow: 1,
            }}
          >
            <TextCL
              variant="overline"
              color={Moolah.navy[60]}
              replayEncrypt={true}
            >
              {subTitleTotal}
            </TextCL>
            <TextCL variant="dpH1" color={Moolah.navy[60]} replayBlock={true}>
              {isCurrentPayPeriod
                ? formatMoney({ amountCents: l3Text })
                : calculateTotal()}
            </TextCL>
          </div>
        </CardContent>
      </CardContent>
    </Card>
  );
};
