import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AppContext } from '../state/appContext';
import { SelfServiceProgressStatus } from '../state/auth/authState';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const OnlyUnauthedRoute: React.FC<ProtectedRouteProps> = (props) => {
  const navigate = useNavigate();

  const { route } = useAuthenticator((context) => [context.route]);

  const { state } = useContext(AppContext);

  if (route === 'authenticated') {
    if (state.auth.selfServiceProgress === SelfServiceProgressStatus.Complete) {
      navigate('/admin');
    } else {
      navigate('/onboarding');
    }

    return null;
  }

  return <React.Fragment>{props.children}</React.Fragment>;
};

export default OnlyUnauthedRoute;
