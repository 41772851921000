import React, { useEffect, useState } from 'react';

import { Button, Divider, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import './payForPlanForm.css';

import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';

import {
  Elements,
  // LinkAuthenticationElement,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { AppContext } from '../../../../state/appContext';
import {
  Appearance,
  loadStripe,
  Stripe,
  StripeElements,
} from '@stripe/stripe-js';
import { dispatchPaymentMadeAction } from '../../../../state/onboarding/onboardingReducer';

const PayForPlanForm: React.FC = () => {
  const { dispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const stripe = useStripe() as Stripe;
  const elements = useElements() as StripeElements;

  const confirmPayment = async () => {
    if (!stripe) {
      return;
    }
    console.log('Submitting payment...');

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: window.location.href,
      },
    });

    if (error) {
      console.error('Error submitting payment: ', error);
    } else {
      dispatchPaymentMadeAction(dispatch);
    }
  };

  return (
    <>
      <FormFoundations.FormWrapperBox>
        <Typography level="h3">
          {t('onboarding.registration.personalDetails.title')}
        </Typography>

        <Divider />
      </FormFoundations.FormWrapperBox>
      <Box
        sx={{
          flexDirection: 'column',
          gridColumn: '1/-1',
          justifySelf: 'flex-end',
          justifyContent: 'space-between',
          overflow: 'wrap',
          display: 'inline-flex',
          gap: 5,
        }}
      >
        {/*<LinkAuthenticationElement*/}
        {/*  // Access the email value like so:*/}
        {/*  // onChange={(event) => {*/}
        {/*  //  setEmail(event.value.email);*/}
        {/*  // }}*/}
        {/*  //*/}
        {/*  // Prefill the email field like so:*/}
        {/*  // options={{defaultValues: {email: 'foo@bar.com'}}}*/}
        {/*/>*/}
        <PaymentElement />
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px', maxWidth: '200px', alignSelf: 'center' }}
          onClick={confirmPayment}
        >
          Pay for my Plan
        </Button>
      </Box>
    </>
  );
};

const ElementsWrapped = () => {
  const { state } = React.useContext(AppContext);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const stripeKey = state.subscription.stripeConfig?.publishableKey;
  const clientSecret = state.subscription.stripeSubscription?.client_secret;
  const appearance: Appearance = {
    theme: 'stripe',
    variables: {
      colorPrimary: '#ed5f74',
      borderRadius: '20px',
      fontFamily: '-apple-system, BlinkMacSystemFont, sans-serif',
      colorBackground: '#fafafa',
    },
  };

  useEffect(() => {
    if (stripeKey) {
      loadStripe(stripeKey).then(setStripe);
    }
  }, [stripeKey]);

  return (
    <Elements stripe={stripe} options={{ appearance, clientSecret }}>
      <PayForPlanForm />
    </Elements>
  );
};

export default ElementsWrapped;
