import { AggregateActions } from '../appContext';
import {
  EmployeeListQueryParams,
  getEmployeeData,
  getProviders,
} from '../../services/api-wrapper';

import { AdminActionTypes } from './adminReducer';
import { GetCardWithButtonQuery } from '../../cms/contentful/generated/graphql';
import { captureException } from '../../services/sentry';
import { EmploymentStatus } from '@dailypay/self-service-ts-sdk';

export const fetchProvidersAction = async (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  let providerResponse = undefined;
  try {
    providerResponse = await getProviders();

    dispatch({
      type: AdminActionTypes.FetchProvidersSuccess,
      payload: { providerList: providerResponse },
    });
  } catch (error: unknown) {
    console.error(error);
    dispatch({
      type: AdminActionTypes.FetchProvidersFailure,
    });
  }
  return providerResponse;
};

export const fetchEmployeesAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  providerUUID: string,
  query: EmployeeListQueryParams,
) => {
  let success = true;
  try {
    if (query === undefined) {
      query = {
        page_size: 100,
        employment_status: EmploymentStatus.Employed,
      };
    }
    const fetchEmployeeResponse = await getEmployeeData(providerUUID, query);

    dispatch({
      type: AdminActionTypes.FetchEmployeesSuccess,
      payload: { employeeList: fetchEmployeeResponse },
    });
  } catch (error) {
    success = false;
    console.error(error);
    dispatch({
      type: AdminActionTypes.FetchEmployeesFailure,
    });
  }
  return success;
};

export const getCardWithButtonAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  cardData: GetCardWithButtonQuery | undefined,
) => {
  try {
    dispatch({
      type: AdminActionTypes.CardWithButtonFetched,
      payload: { cardData },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in getCardWithButtonAction',
      },
    });
  }
};
