import React from 'react';
import Table from '@mui/joy/Table';
import { useTranslation } from 'react-i18next';
import { Moolah } from '../../../../theme/common';
import TooltipCL from '../../../../components/library/TooltipCL';
import { Box, Link } from '@mui/joy';
import { TableHead } from '@mui/material';
import { TableRow } from '@aws-amplify/ui-react';
import ArrowIcon from '@mui/icons-material/ExpandMore';
import { Header } from './types';
import { useSortableHeaders } from './use-sortable-headers';
import StatusTooltip from './tooltips/status-tooltip';

const baseKeyPath = 'admin.manageCompanyAccount.adminTable';
const headingKeyPath = `${baseKeyPath}.headings`;
const tooltipsKeyPath = `${baseKeyPath}.tooltips`;
const HEADING_KEYS = {
  firstName: `${headingKeyPath}.firstName`,
  lastName: `${headingKeyPath}.lastName`,
  email: `${headingKeyPath}.email`,
  phone: `${headingKeyPath}.phone`,
  status: `${headingKeyPath}.status`,
};

const HEADERS: Header = {
  firstName: {
    tKey: HEADING_KEYS.firstName,
  },
  lastName: {
    tKey: HEADING_KEYS.lastName,
    sortable: true,
  },
  email: {
    tKey: HEADING_KEYS.email,
  },
  phone: {
    tKey: HEADING_KEYS.phone,
  },
  status: {
    tKey: HEADING_KEYS.status,
    tooltipKey: `${tooltipsKeyPath}.status`,
    sortable: true,
  },
};

const AdminTable = () => {
  const { onSortableHeadingClickHandler, sortableHeaders } =
    useSortableHeaders(HEADERS);
  const { t } = useTranslation();

  const handleSortableLinkClick = (tKey: string) => () => {
    onSortableHeadingClickHandler(tKey);
  };

  return (
    <Table
      stickyHeader
      noWrap
      sx={[
        {
          '--Table-headerUnderlineThickness': '1px',
          '--TableCell-height': '60px',
          '--TableCell-paddingX': '8px',
          '--TableCell-paddingY': '8px',
          '--TableCell-align': 'right',
          '& thead th': { height: '40px', paddingY: '16px' },
        },
      ]}
    >
      <TableHead>
        <TableRow padding={2}>
          {Object.values(HEADERS).map(({ tKey, sortable, tooltipKey }) => (
            <th key={tKey} style={{ alignItems: 'center' }}>
              <Box
                flexDirection="row"
                display="flex"
                justifyContent={'flex-start'}
                flexWrap="wrap"
              >
                {sortable ? (
                  <Link
                    underline="none"
                    color="primary"
                    component="button"
                    pr={1}
                    fontWeight="lg"
                    endDecorator={<ArrowIcon />}
                    onClick={handleSortableLinkClick(tKey)}
                    sx={
                      sortableHeaders[tKey]
                        ? {
                            '& svg': {
                              transition: '0.2s',
                              transform:
                                sortableHeaders[tKey].direction === 'asc'
                                  ? 'rotate(0deg)'
                                  : 'rotate(180deg)',
                            },
                            paddingRight: '1em',
                          }
                        : { paddingRight: '1em' }
                    }
                  >
                    {t(tKey)}
                  </Link>
                ) : (
                  t(tKey)
                )}
                {!!tooltipKey && (
                  <TooltipCL
                    tooltipText={<StatusTooltip />}
                    placement="top"
                    showCloseIcon={true}
                    iconColor={Moolah.navy[60]}
                  />
                )}
              </Box>
            </th>
          ))}
        </TableRow>
      </TableHead>
      <tbody></tbody>
    </Table>
  );
};

export default AdminTable;
