import * as React from 'react';
import Card from '@mui/joy/Card';
import { CardContent, CardOverflow, Grid } from '@mui/joy';
import { Horizon, Moolah } from '../../../../theme';
import { TextCL } from '../../../../components/library/TextCL';
import { useTranslation } from 'react-i18next';
import ProgramChart from './ProgramChart';

interface ProgramCardProps {
  title?: string | null | undefined;
  text?: string | null | undefined;
  employeeCount?: number | undefined;
  activeEmployeeCount?: number | undefined;
  pendingEmployeeCount?: number | undefined;
}

const ProgramCard: React.FC<ProgramCardProps> = ({
  title,
  employeeCount,
  activeEmployeeCount,
  pendingEmployeeCount,
}: ProgramCardProps) => {
  const { t } = useTranslation();

  return (
    <Card sx={{ minHeight: '240px' }}>
      <CardOverflow
        variant="soft"
        sx={{
          paddingTop: '5px',
          paddingBottom: '5px',
          textAlign: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
          backgroundColor: Moolah.white,
        }}
      >
        <TextCL variant="h4" color={Horizon.info[4]}>
          {title}
        </TextCL>
      </CardOverflow>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          rowSpacing={3}
          sx={{
            display: 'flex',
            justifyContent: 'space-evenly',
          }}
        >
          <Grid xs={12} md={12} lg={3} sx={{ margin: '24px' }}>
            <ProgramChart
              count={employeeCount}
              total={employeeCount}
              color="transparent"
            />
            <TextCL variant="h4">
              {t('admin.dashboard.program.subtitle1')}
            </TextCL>
            <TextCL>{t('admin.dashboard.program.text1')}</TextCL>
          </Grid>
          <Grid xs={12} md={6} lg={3} sx={{ margin: '24px' }}>
            <ProgramChart
              count={activeEmployeeCount}
              total={employeeCount}
              color={Horizon.green[6]}
            />
            <TextCL variant="h4">
              {t('admin.dashboard.program.subtitle2')}
            </TextCL>
            <TextCL>{t('admin.dashboard.program.text2')}</TextCL>
          </Grid>
          <Grid xs={12} md={6} lg={3} sx={{ margin: '24px' }}>
            <ProgramChart
              count={pendingEmployeeCount}
              total={employeeCount}
              color={Horizon.yellow}
            />
            <TextCL variant="h4">
              {t('admin.dashboard.program.subtitle3')}
            </TextCL>
            <TextCL>{t('admin.dashboard.program.text3')}</TextCL>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ProgramCard;
