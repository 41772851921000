import * as React from 'react';
import { Button, Grid } from '@mui/joy';
import { Employee } from '@dailypay/self-service-ts-sdk';
import { useTranslation } from 'react-i18next';
import { Horizon } from '../../../../../theme';
import { sendEmployeeOnboardLink } from '../../../../../services/api-wrapper';
import { captureException } from '../../../../../services/sentry';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { InfoModalCL } from '../../../../../components/library/InfoModalCL';
import EditFormHeader from '../../../../../components/common/form-header/EditFormHeader';

export interface DailyPayInvitationProps {
  employee: Employee;
  providerID: string | undefined;
}

export default function DailyPayInvitation({
  employee,
  providerID,
}: DailyPayInvitationProps) {
  const { t } = useTranslation();
  const [showModal, setShowModal] = React.useState(false);
  const [hasApiError, setHasApiError] = React.useState(false);

  const sendInvitation = () => {
    if (!providerID) {
      return;
    }
    sendEmployeeOnboardLink(
      providerID,
      employee.employee_uuid,
      employee.employments[0].employment_uuid,
    )
      .then(() => {
        setShowModal(true);
        setHasApiError(false);
      })
      .catch((error) => {
        setShowModal(true);
        setHasApiError(true);
        captureException(error, {
          extra: {
            message: 'Error trying to send onboarding invitation',
          },
        });
      })
      .finally(() => {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.SendOnboardInvitationSent,
          payload: {
            employeeUUID: employee.employee_uuid,
          },
        });
      });
  };
  return (
    <div style={{ marginBottom: '24px' }}>
      <InfoModalCL
        isOpen={showModal}
        title={
          hasApiError
            ? t('admin.employees.accountInfo.invite.inviteError')
            : t('admin.employees.accountInfo.invite.inviteSuccess')
        }
        text={
          hasApiError
            ? t('admin.employees.accountInfo.invite.inviteErrorDesc')
            : ''
        }
        showCloseButton={true}
        onClose={() => {
          setShowModal(false);
        }}
      />
      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        sx={{ paddingLeft: '40px' }}
      >
        <EditFormHeader
          titleKey={t('admin.employees.accountInfo.invite.title')}
          isEditing={false}
        />
      </Grid>
      <Grid container spacing={0} xs={12} md={12} lg={12} rowSpacing={3}>
        <Grid
          xs={12}
          md={12}
          lg={12}
          className="formField"
          sx={{ backgroundColor: Horizon.info[3] }}
        >
          <div
            style={{
              paddingLeft: '24px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              variant="solid"
              color="neutral"
              size="sm"
              sx={{ borderRadius: '50px' }}
              onClick={() => sendInvitation()}
            >
              {t('admin.employees.accountInfo.invite.sendButton')}
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
