import React from 'react';
import { Header } from './types';

type Sortable = {
  [key: string]: {
    sorted: boolean;
    direction: 'asc' | 'desc';
  };
};

const setUpSortable = (headers: Header) =>
  Object.values(headers).reduce((acc, curr) => {
    if (curr.sortable) {
      acc[curr.tKey] = {
        sorted: false,
        direction: 'asc',
      };
    }
    return acc;
  }, {} as Sortable);

export const useSortableHeaders = (headers: Header) => {
  const [sortableHeaders, setSortableHeaders] = React.useState(
    setUpSortable(headers),
  );

  const onSortableHeadingClickHandler = (key: string) => {
    const updatedSortable = { ...sortableHeaders };
    const currentSortable = updatedSortable[key];
    currentSortable.sorted = !currentSortable.sorted;
    currentSortable.direction =
      currentSortable.direction === 'asc' ? 'desc' : 'asc';

    setSortableHeaders(updatedSortable);
  };

  return { onSortableHeadingClickHandler, sortableHeaders };
};
