import React from 'react';

import { Button, Grid, Link, Stack, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import './signinForm.css';

import { useForm } from 'react-hook-form';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';

import { yupResolver } from '@hookform/resolvers/yup';
import * as Auth from 'aws-amplify/auth';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';
import { TextCL } from '../../../../components/library/TextCL';
import { dawnOrangeLight } from '../../../../theme/self-service-joy-theme';
import { SignupPageVariants } from '../signinPage';
import { standardEmailYupValidation } from '../../../../utils/validation';

export type ResetPasswordFormData = {
  emailAddress: string;
};

interface SignInFormProps {
  setPageVariant: (pageVariant: SignupPageVariants) => void;
  setCachedEmail: (email: string) => void;
}

const schema = yup
  .object({
    emailAddress: standardEmailYupValidation,
  })
  .required();

const ResetPasswordForm: React.FC<SignInFormProps> = ({
  setPageVariant,
  setCachedEmail,
}) => {
  const [errorText, setErrorText] = React.useState<string | null>(null);

  const { t } = useTranslation();

  const { control, handleSubmit, formState } = useForm<ResetPasswordFormData>({
    resolver: yupResolver(schema),
  });

  const handleResetPasswordInitiateRequest = React.useCallback(async () => {
    Auth.resetPassword({ username: control._formValues.emailAddress })
      .then(async () => {
        setErrorText(null);

        setCachedEmail(control._formValues.emailAddress);
        setPageVariant(SignupPageVariants.CHECK_EMAIL_FOR_LINK);
      })
      .catch(() => {
        setErrorText('Unable to reset password. Please try again.');
      });
  }, [control._formValues.emailAddress, setCachedEmail, setPageVariant]);

  return (
    <>
      <Stack
        spacing={4}
        flex={1}
        flexGrow={1}
        alignItems={'stretch'}
        justifyContent={'center'}
      >
        <InputBoxCL
          control={control}
          fieldName="emailAddress"
          label="EMAIL ADDRESS"
          type="email"
          replayEncrypt={true}
        />

        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={handleSubmit(handleResetPasswordInitiateRequest)}
        >
          Next
        </Button>

        {errorText && (
          <Grid
            xs={12}
            md={12}
            lg={12}
            sx={{
              padding: '10px 24px',
              marginBottom: '24px',
              backgroundColor: dawnOrangeLight,
            }}
          >
            <TextCL variant="body">
              {t('admin.forgotPassword.errors.limitExceeded')}
            </TextCL>
          </Grid>
        )}

        {formState.errors.root && (
          <Box sx={{ paddingTop: '2rem' }}>
            <Typography level="body-sm" sx={{ paddingTop: '2em' }}>
              {JSON.stringify(formState.errors)}
            </Typography>
          </Box>
        )}

        <Link
          sx={{ display: 'flex', justifyContent: 'center' }}
          onClick={() => setPageVariant(SignupPageVariants.SIGNIN)}
          underline="always"
        >
          {t('admin.forgotPassword.cancel')}
        </Link>
      </Stack>
    </>
  );
};

export default ResetPasswordForm;
