import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { AppContext } from '../../state/appContext';

export default function StateViewer() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState<number | null>(0);
  const { state } = React.useContext(AppContext);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
      }}
    >
      <Button variant="outlined" color="neutral" onClick={() => setOpen(!open)}>
        {open ? 'Close' : 'Open'} state viewer
      </Button>

      {open && (
        <AccordionGroup sx={{ paddingTop: '1em' }}>
          <Accordion
            expanded={index === 0}
            onChange={(event, expanded) => {
              setIndex(expanded ? 0 : null);
            }}
          >
            <AccordionSummary>Auth State</AccordionSummary>
            <AccordionDetails>
              {state.auth.isLoggedIn ? 'Logged in' : 'Logged out'}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={index === 1}
            onChange={(event, expanded) => {
              setIndex(expanded ? 1 : null);
            }}
          >
            <AccordionSummary>Admin State</AccordionSummary>
            <AccordionDetails sx={{ wordWrap: 'break-word' }}>
              {JSON.stringify(state.admin)}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={index === 2}
            onChange={(event, expanded) => {
              setIndex(expanded ? 2 : null);
            }}
          >
            <AccordionSummary>Transient</AccordionSummary>
            <AccordionDetails sx={{ wordWrap: 'break-word' }}>
              {JSON.stringify(state.onboarding.transientOnboardingState)}
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={index === 3}
            onChange={(event, expanded) => {
              setIndex(expanded ? 3 : null);
            }}
          >
            <AccordionSummary>Prospect Data Indicators</AccordionSummary>
            <AccordionDetails sx={{ overflow: 'wrap', wordWrap: 'break-word' }}>
              <ul>
                {Object.entries(state.onboarding.prospectData?.data ?? {}).map(
                  (key) => {
                    return (
                      <li key={key[0]}>
                        {key[0]}: {key[1].toString()}
                      </li>
                    );
                  },
                )}
              </ul>
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      )}
    </Box>
  );
}
