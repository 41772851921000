import React from 'react';

import './demo.css';
import { Box, Button, Divider, Stack, Typography } from '@mui/joy';
// import { InfoCardCL } from '../../components/library/InfoCardCL';
import CardWithActions from '../../components/foundational/CardWithActions';
import { TextCL } from '../../components/library/TextCL';

export default function Demo() {
  return (
    <>
      <Stack spacing={2} sx={{ padding: '3rem' }}>
        <Divider />
        <Typography level="h4">
          TypoGraphy Levels - with TypoGraphy element
        </Typography>
        <Divider />

        <TextCL variant={'h1'}>H1 Change pay for good.</TextCL>
        <TextCL variant={'h2'}>H2 Change pay for good.</TextCL>
        <TextCL variant={'h2Bold'}>H2 Change pay for good.</TextCL>
        <TextCL variant={'h3'}>H3 Change pay for good.</TextCL>
        <TextCL variant={'h4'}>H4 Change pay for good.</TextCL>
        <TextCL variant={'h4Bold'}>H4 Change pay for good.</TextCL>
        <TextCL variant={'h5'}>H5 Change pay for good.</TextCL>
        <TextCL variant={'h6'}>H6 Change pay for good.</TextCL>
        <TextCL variant={'body'}>Body Change pay for good.</TextCL>
        <TextCL variant={'caption'}>Caption Change pay for good.</TextCL>
        <TextCL variant={'link'}>Text Link Change pay for good.</TextCL>
        <TextCL variant={'error'}>Error enter a valid email address</TextCL>
        <TextCL variant={'xs'}>Disclaimer Change pay for good.</TextCL>
        <p></p>
        <Divider />
        <p></p>
        <TextCL variant={'dpH1'}>H1 Change pay for good.</TextCL>
        <TextCL variant={'dpH2'}>H2 Change pay for good.</TextCL>
        <TextCL variant={'dpH3'}>H3 Change pay for good.</TextCL>
        <Divider />

        <Typography level="h4">Joy Theme</Typography>

        <Divider />

        <Typography level="title-md">Title md</Typography>
        <Typography level="title-sm">Title sm</Typography>
        <Typography level="title-lg">Title lg</Typography>
        <Typography level="body-md">Body md</Typography>
        <Typography level="body-sm">Body sm</Typography>
        <Typography level="body-lg">Body lg</Typography>
        <Typography level="body-xs">Body xs</Typography>

        <Divider />
        <Typography level="h4">Buttons - with Joy UI Button</Typography>

        <Divider />
        <Box>
          <Button color="primary">Primary</Button>
        </Box>

        <Box>
          <Button color="success">Success</Button>
        </Box>

        <Box>
          <Button color="warning">Success</Button>
        </Box>

        <Box>
          <Button color="danger">Success</Button>
        </Box>

        <Box>
          <Button color="success">Success</Button>
        </Box>

        <Divider />
        <Typography level="h4">Colors</Typography>
        <Divider />

        <Typography level="h4" color="primary">
          Primary
        </Typography>
        <Typography level="h4" color="neutral">
          Neutral
        </Typography>
        <Typography level="h4" color="warning">
          Warning
        </Typography>
        <Typography level="h4" color="success">
          Success
        </Typography>
        <Typography level="h4" color="danger">
          Danger
        </Typography>

        <Divider />
        <Typography level="h4">Cards</Typography>
        <Divider />

        {/* <InfoCardCL l1Text="ADP" l2Text="ADP" l3Text="ADP" /> */}

        <CardWithActions linkHref="#" label="Supported" />
      </Stack>
    </>
  );
}
