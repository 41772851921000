import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../state/appContext';
import { SelfServiceProgressStatus } from '../state/auth/authState';

interface ProtectedRouteProps {
  children: React.ReactNode;
}

const OnlyUnauthedRoute: React.FC<ProtectedRouteProps> = ({ children }) => {
  const navigate = useNavigate();

  const { state } = useContext(AppContext);

  if (
    state.auth.selfServiceProgress !== SelfServiceProgressStatus.Undetermined &&
    state.auth.selfServiceProgress !== SelfServiceProgressStatus.Complete
  ) {
    navigate('/login');
  }
  if (
    state.auth.selfServiceProgress === SelfServiceProgressStatus.Undetermined
  ) {
    return null;
  }
  return <React.Fragment>{children}</React.Fragment>;
};

export default OnlyUnauthedRoute;
