import { Box } from '@mui/joy';
import React, { FC } from 'react';
import { ManageConnectPayrollView } from '../types';
import { useTranslation } from 'react-i18next';
import { getTextAfterError } from '../../../../../utils/string-helpers';
import { TextCL } from '../../../../../components/library/TextCL';
import { iconLibrary } from '../../../../../assets/icons/icon-library';
import { adpRun, adpWfn } from '../../../../../ss-constants/onboarding';

type ErrorType = 'title' | 'description' | `multipleAttempts${'1' | '2' | '3'}`;

interface Props {
  activeView: ManageConnectPayrollView;
  error: string;
  payrollProviderKey?: string;
}

const adpPayrollProviders = [adpRun, adpWfn];

const ValidationError: FC<Props> = ({
  activeView,
  error,
  payrollProviderKey,
}) => {
  const { t } = useTranslation();

  const getError = (errorType: ErrorType, key?: string) => {
    return t(
      `onboarding.connect.errors.${key ?? payrollProviderKey}.${errorType}`,
      {
        provider: t(`integration.names.${payrollProviderKey}`),
      },
    );
  };
  const isAdpError =
    payrollProviderKey && adpPayrollProviders.includes(payrollProviderKey);

  const getErrorTitle = isAdpError
    ? getError('title')
    : `Incorrect ${getTextAfterError(error)}`;

  const getErrorDescription = getError('description');

  const getMultipleAttemptsDescription1 = getError('multipleAttempts1');

  const getMultipleAttemptsDescription2 =
    getError('multipleAttempts2') +
    (isAdpError
      ? ''
      : `${getTextAfterError(error)} ${getError('multipleAttempts3', 'common')}`);

  return (
    <Box>
      {activeView === ManageConnectPayrollView.validationError && (
        <>
          <Box
            sx={{ display: 'flex', alignItems: 'center', paddingTop: '40px' }}
          >
            <img
              width={50}
              src={iconLibrary.common.alertCircle}
              alt="errorTerms"
            ></img>
            <div style={{ paddingLeft: '12px' }}>
              <TextCL variant="h2" replayEncrypt={true}>
                {getErrorTitle}
              </TextCL>
            </div>
          </Box>
          <div
            style={{
              paddingLeft: '12px',
              paddingBottom: '34px',
              paddingTop: '34px',
            }}
          >
            <TextCL variant="h3" replayEncrypt={true}>
              {getErrorDescription}
            </TextCL>
          </div>
        </>
      )}

      {activeView ===
        ManageConnectPayrollView.validationErrorMultipleAttempts && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img
              width={50}
              src={iconLibrary.common.alertCircle}
              alt="errorTerms"
            ></img>
            <div style={{ paddingLeft: '12px' }}>
              <TextCL variant="h2" replayEncrypt={true}>
                {getErrorTitle}
              </TextCL>
            </div>
          </Box>
          <div
            style={{
              paddingLeft: '12px',
              paddingBottom: '34px',
              paddingTop: '34px',
            }}
          >
            <TextCL variant="h3" replayEncrypt={true}>
              {getMultipleAttemptsDescription1}
            </TextCL>
          </div>
          <div
            style={{
              paddingLeft: '12px',
              paddingBottom: '34px',
            }}
          >
            <TextCL variant="h3" replayEncrypt={true}>
              {getMultipleAttemptsDescription2}
            </TextCL>
          </div>
        </>
      )}
    </Box>
  );
};

export default ValidationError;
