import { Moolah } from '../../../../theme';

type PasswordColors = {
  light: string;
  strong: string;
};

const alpha = 33;

const badColors: PasswordColors = {
  light: `${Moolah.alerts.error}${alpha}`,
  strong: Moolah.alerts.error,
};

const warningColors: PasswordColors = {
  light: `${Moolah.alerts.warning}${alpha}`,
  strong: Moolah.alerts.warning,
};

const strongColors: PasswordColors = {
  light: `${Moolah.alerts.success}${alpha}`,
  strong: Moolah.alerts.success,
};

const baseTPath = 'onboarding.createAccount.passwordStrengthMeter';
export const weakKey = `${baseTPath}.weak`;
export const mediumKey = `${baseTPath}.medium`;
export const strongKey = `${baseTPath}.strong`;
export const bgColorMap: {
  [key: number]: { colors: PasswordColors; tKey: string };
} = {
  0: {
    colors: badColors,
    tKey: weakKey,
  },
  1: {
    colors: badColors,
    tKey: weakKey,
  },
  2: {
    colors: warningColors,
    tKey: mediumKey,
  },
  3: {
    colors: warningColors,
    tKey: mediumKey,
  },
  4: {
    colors: strongColors,
    tKey: strongKey,
  },
};
