import * as Sentry from '@sentry/react';
import ENV_VARS from '../../environment/envvars';

export const init = () =>
  Sentry.init({
    dsn: 'https://6dc87d38313a50b9bd446301802f515b@o27158.ingest.sentry.io/4506099601178624',
    environment: ENV_VARS.env,
    release: process.env.REACT_APP_VERSION,
  });

export const captureException = Sentry.captureException;
