import React, { FC } from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { useTranslation } from 'react-i18next';
import { Moolah } from '../../../../../theme';
import useScreenQuery from '../../../../../layouts/hooks/useScreenQuery';
import BankInfoDesktop from './BankInfoDesktop';
import BankInfoMobile from './BankInfoMobile';
import { BankDetails } from './types';

const EmployeeBankDetails: FC<BankDetails> = ({ details }) => {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();

  return (
    <>
      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        sx={{
          paddingTop: '12px',
          paddingBottom: '12px',
        }}
      >
        <div
          style={{
            color: Moolah.navy[60],
            paddingLeft: '24px',
          }}
        >
          <TextCL variant="h4" fontWeight="bold">
            {t('admin.employees.accountInfo.bank.title')}
          </TextCL>
        </div>
      </Grid>
      <Grid container spacing={0} xs={12} md={12} lg={12} rowSpacing={3}>
        {details.length === 0 ? (
          <div style={{ paddingLeft: '24px' }}>
            <TextCL>{t('admin.employees.accountInfo.bank.emptyState')}</TextCL>
          </div>
        ) : (
          <>
            {isLargerScreen ? (
              <BankInfoDesktop details={details} />
            ) : (
              <BankInfoMobile details={details} />
            )}
          </>
        )}
      </Grid>
    </>
  );
};

export default EmployeeBankDetails;
