import React from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { maskAccountNumber } from '../../../../../formatters/card-formatter';
import BankInfoDesktopFields from './BankInfoDesktopFields';
import { BankDetails } from './types';

const BankInfoDesktop: React.FC<BankDetails> = ({ details }) => {
  return (
    <>
      <BankInfoDesktopFields
        labelKey="admin.employees.accountInfo.bank.type"
        paddingLeft="24px"
      />
      <BankInfoDesktopFields labelKey="admin.employees.accountInfo.bank.name" />
      <BankInfoDesktopFields
        labelKey="admin.employees.accountInfo.bank.accNumber"
        paddingLeft="24px"
      />
      <BankInfoDesktopFields labelKey="admin.employees.accountInfo.bank.routingNumber" />
      {details.map((bankInfo) => (
        <React.Fragment key={bankInfo.accountNumber}>
          <Grid xs={12} sm={6} md={3} lg={3} className="formField">
            <div
              style={{
                paddingLeft: '24px',
              }}
            >
              <TextCL variant="input" replayEncrypt={true}>
                {bankInfo.accountType}
              </TextCL>
            </div>
          </Grid>
          <Grid xs={6} md={3} lg={3}>
            <TextCL variant="input" replayBlock={true}>
              {bankInfo.bankName}
            </TextCL>
          </Grid>
          <Grid xs={6} md={3} lg={3}>
            <div
              style={{
                paddingLeft: '24px',
              }}
            >
              <TextCL variant="input" replayBlock={true}>
                {maskAccountNumber(bankInfo.accountNumber)}
              </TextCL>
            </div>
          </Grid>
          <Grid xs={6} md={3} lg={3}>
            <TextCL variant="input" replayEncrypt={true}>
              {bankInfo.routingNumber}
            </TextCL>
          </Grid>
        </React.Fragment>
      ))}
    </>
  );
};

export default BankInfoDesktop;
