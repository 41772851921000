import React from 'react';
import { AppContext } from '../../state/appContext';
import {
  getAvailableIntegrations,
  getHelpTopicsAction,
  getTermsAction,
} from '../../state/onboarding/onboardingActions';
import {
  useGetCardWithButtonQuery,
  useGetHelpTopicsQuery,
  useGetTermsAndCondtionsQuery,
} from '../../cms/contentful/generated/graphql';
import { getCardWithButtonAction } from '../../state/admin/adminActions';

enum FetchState {
  NotStarted,
  Loading,
  Loaded,
  Error,
}

const ReferenceDataLoader: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);

  const [integrationsFetchState, setIntegrationsFetchState] =
    React.useState<FetchState>(FetchState.NotStarted);

  const { data: helpTopicData } = useGetHelpTopicsQuery();
  const { data: termsData } = useGetTermsAndCondtionsQuery();
  const { data: cardWithButtonData } = useGetCardWithButtonQuery();

  React.useEffect(() => {
    const fetchIntegrationData = async () => {
      if (
        state.onboarding.integrations === undefined &&
        (integrationsFetchState === FetchState.NotStarted ||
          integrationsFetchState === FetchState.Error)
      ) {
        setIntegrationsFetchState(FetchState.Loading);

        try {
          await getAvailableIntegrations(dispatch);
        } catch (e) {
          setIntegrationsFetchState(FetchState.Error);
        }

        setIntegrationsFetchState(FetchState.Loaded);
      }
    };
    fetchIntegrationData();
  }, [dispatch, integrationsFetchState, state.onboarding.integrations]);

  React.useEffect(() => {
    getTermsAction(dispatch, termsData);
  }, [dispatch, termsData]);

  React.useEffect(() => {
    getHelpTopicsAction(dispatch, helpTopicData);
  }, [dispatch, helpTopicData]);

  React.useEffect(() => {
    getCardWithButtonAction(dispatch, cardWithButtonData);
  }, [dispatch, cardWithButtonData]);

  return null;
};

export default ReferenceDataLoader;
