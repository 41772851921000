import React from 'react';

import { Button, CircularProgress, Grid, Link, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import { Helmet } from 'react-helmet';
import { iconLibrary } from '../../../assets/icons/icon-library';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import { TextCL } from '../../../components/library/TextCL';
import { Moolah } from '../../../theme';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { AppContext } from '../../../state/appContext';
import { dispatchResetAppStateAction } from '../../../state/auth/authReducer';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { useNavigate } from 'react-router-dom';

export default function DuplicateCompany() {
  const { dispatch } = React.useContext(AppContext);
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();
  const navigate = useNavigate();
  const { signOut } = useAuthenticator((context) => [context.user]);
  const [showLoader, setShowLoader] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 800);
  }, []);

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Duplicate Company',
  });

  const performLogout = (hasForgotPassword: boolean) => {
    setTimeout(() => {
      try {
        signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }, 0);
    setTimeout(() => {
      dispatchResetAppStateAction(dispatch);
    }, 600);
    setTimeout(() => {
      if (hasForgotPassword) {
        navigate('/login', { state: { variant: 'forgotPassword' } });
      } else {
        navigate('/login');
      }
    }, 1000);
  };

  const handleForgotPassword = React.useCallback(() => {
    performLogout(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate, signOut]);

  const handleLogout = React.useCallback(() => {
    performLogout(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, signOut]);

  if (showLoader) {
    return (
      <Grid
        xs={12}
        md={12}
        lg={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Self Service - Duplicate Provider</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '2em' : '1em' }}>
        <PageHeader
          image={iconLibrary.heading.createAccount}
          imageAlt="onboarding.createAccount.title.icon"
          titleKey="onboarding.createAccount.title"
        />

        <FormFoundations.FormWrapperGrid>
          <Grid
            xs={12}
            md={12}
            lg={12}
            className="formSection"
            display={'flex'}
            flexDirection={'column'}
            padding={isLargerScreen ? '48px 64px' : '24px 0px'}
            textAlign={'center'}
          >
            <TextCL variant="h1Bold">
              {t('onboarding.registration.duplicateCompany.title')}
            </TextCL>
            <div
              style={{
                padding: '50px 0',
              }}
            >
              <Link
                underline="always"
                sx={{ paddingTop: '24px' }}
                onClick={() => {
                  window.open(
                    'https://adminsupport.dailypay.com/hc/en-us/requests/new',
                    '_blank',
                    'noopener',
                  );
                }}
              >
                <TextCL variant="h5">
                  {t('onboarding.registration.duplicateCompany.description')}
                </TextCL>
              </Link>
            </div>
          </Grid>
        </FormFoundations.FormWrapperGrid>
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: '50px',
            }}
            onClick={() => handleLogout()}
          >
            {t('onboarding.registration.duplicateCompany.loginButton')}
          </Button>
          <Link
            underline="always"
            sx={{ paddingTop: '24px' }}
            level="title-sm"
            onClick={() => {
              handleForgotPassword();
            }}
          >
            <TextCL variant="link" color={Moolah.navy[60]}>
              {t('onboarding.registration.duplicateCompany.forgotPassword')}
            </TextCL>
          </Link>
        </Grid>
      </Stack>
    </>
  );
}
