import React, { FC } from 'react';
import { CardWithButton } from '../../../cms/contentful/custom/CustomTypes';
import { Grid, Stack } from '@mui/joy';
import CardWithActions from '../../foundational/CardWithActions';
import { Horizon, Moolah } from '../../../theme';

interface Props {
  cardInfo: CardWithButton[];
  modifyCardUrl?: (card: CardWithButton) => void;
}

export const SidebarCards: FC<Props> = ({ cardInfo, modifyCardUrl }) => {
  return (
    <Stack spacing={3} sx={{ padding: '2em 1em 2em 2em' }}>
      {cardInfo
        .filter((card) => card.location === 'sidebar')
        .map(({ ...card }, index: number) => {
          modifyCardUrl?.(card);

          return (
            <Grid
              key={index}
              sx={{
                '&.MuiGrid-root': {
                  display: 'flex',
                  flexGrow: 1,
                },
              }}
            >
              <CardWithActions
                title={card.title}
                text={card.description}
                linkHref={card.buttonUrl}
                label={card.buttonText}
                headingColor={Horizon.info[4]}
                headingTextColor={Moolah.white}
              />
            </Grid>
          );
        })}
    </Stack>
  );
};
