import { extendTheme } from '@mui/joy/styles';
import { grey } from '@mui/material/colors';
import { Horizon, Moolah } from './common';

// See https://mui.com/joy-ui/customization/theme-builder/
const combinedPalette = {
  primary: {
    '50': '#eff6ff',
    '100': '#dbeafe',
    '200': '#bfdbfe',
    '300': '#93c5fd',
    '400': '#60a5fa',
    '500': '#676B75',
    '600': '#2563eb',
    '700': '#1d4ed8',
    '800': '#1e40af',
    '900': '#1e3a8a',
  },
  neutral: {
    '50': '#f8fafc',
    '100': '#f1f5f9',
    '200': '#e2e8f0',
    '300': '#cbd5e1',
    '400': '#94a3b8',
    '500': '#676B75',
    '600': '#676B75',
    '700': '#676B75',
    '800': '#1e293b',
    '900': '#0f172a',
  },
  danger: {
    '50': '#fef2f2',
    '100': '#fee2e2',
    '200': '#fecaca',
    '300': '#fca5a5',
    '400': '#f87171',
    '500': '#ef4444',
    '600': '#dc2626',
    '700': '#b91c1c',
    '800': '#991b1b',
    '900': '#7f1d1d',
  },
  success: {
    '50': '#ecfdf5',
    '100': '#d1fae5',
    '200': '#a7f3d0',
    '300': '#6ee7b7',
    '400': '#34d399',
    '500': '#10b981',
    '600': '#059669',
    '700': '#047857',
    '800': '#065f46',
    '900': '#064e3b',
  },
  warning: {
    '50': '#fff7ed',
    '100': '#ffedd5',
    '200': '#fed7aa',
    '300': '#fdba74',
    '400': '#fb923c',
    '500': '#f97316',
    '600': '#ea580c',
    '700': '#c2410c',
    '800': '#9a3412',
    '900': '#7c2d12',
  },
  grey: {
    '100': '#F4F4F5',
  },
};

export const mainBackground = '#F7F7F7';
export const formInsetBackground = '#FFFFFF';

export const primaryDark = '#091459';
export const primaryOrange = '#EC5A29';
export const lightGrey = '#DDE2EC';
export const lighterGrey = '#F0F2F5';
export const darkGrey = '#6B737B';
export const calloutYellow = '#FFFBE3';
export const dodgerBlue = '#418BF7';
export const darkGreen = '#2D7934';
export const darkRed = '#B81E20';
export const darkBlue = '#0B349D';
export const midnightBlue = '#04143A';
export const white = '#FFFFFF';
export const header = '#F0F8FA';
export const lightGreyCount = '#9799A0';
export const dawnOrangeLight = '#FF4C0033';

export const selfServiceJoyTheme = extendTheme({
  fontFamily: {
    display: 'Graphik',
    body: 'Graphik',
    code: 'Graphik',
    fallback: 'Graphik',
  },
  typography: {
    h4: {
      fontFamily: 'Graphik',
    },
    /*h3: {
      fontFamily: 'Graphik',
      fontSize: '1.75rem',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '120%',
    },*/
    h3: {
      color: '#18181B',
      fontSize: '24px',
      fontFamily: 'Graphik',
      fontWeight: '500',
      wordWrap: 'break-word',
    },
    h2: {
      fontFamily: 'Graphik',
      fontSize: '34px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '4.6875rem',
      letterSpacing: '-0.125rem',
    },
    h1: {
      fontFamily: 'Horizon',
      fontSize: '2.625rem',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: '120%' /* 3.15rem */,
    },
    'title-lg': {
      fontFamily: 'Graphik',
    },
    'title-sm': {
      fontFamily: 'Graphik',
    },
    'title-md': {
      fontFamily: 'Graphik',
      fontSize: 18,
      fontWeight: '400',
      wordWrap: 'break-word',
    },
    'body-lg': {
      fontFamily: 'Graphik',
    },
    'body-md': {
      fontFamily: 'Graphik',
    },
    'body-sm': {
      fontFamily: 'Graphik',
    },
    'body-xs': {
      fontFamily: 'Graphik',
    },
  },
  colorSchemes: {
    light: {
      palette: {
        primary: {
          ...combinedPalette.primary,
        },
        neutral: {
          ...combinedPalette.neutral,
        },
        danger: {
          ...combinedPalette.danger,
        },
        warning: {
          ...combinedPalette.warning,
        },
        success: {
          ...combinedPalette.success,
        },
        focusVisible: '#323130',
        background: {
          body: mainBackground,
          level3: primaryOrange,
        },
      },
    },
  },
  focus: {
    default: {
      outlineOffset: -1,
      outlineWidth: '1px',
    },
  },
  components: {
    JoyCard: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            boxShadow: 'none',
            border: '1px solid #E2E8F0',
            backgroundColor: '#fff',
          }),
        }),
      },
    },
    JoyDivider: {
      styleOverrides: {
        root: () => ({
          backgroundColor: grey[100],
        }),
      },
    },
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'plain' && {
            borderBottom: '2px solid #E2E8F0',
            borderRadius: '0px',
            outline: 'none',
          }),
          ...(ownerState.color === 'neutral' &&
            {
              // color: 'green',
            }),
        }),
      },
    },
    JoyButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          paddingLeft: 30,
          paddingRight: 30,
          '--Button-iconOffsetStep': 0,
          ...(ownerState.variant === 'solid' && {
            backgroundColor: '#04143A',
            background: '#04143A',
            '&.Mui-focusVisible, &:focus-visible': {
              outlineOffset: '-3px',
              outlineColor: '#fff',
              background: '#04143A',
            },
            '&:hover': {
              backgroundColor: Moolah.orange[60],
            },
          }),
          ...(ownerState.variant === 'outlined' && {
            '&.Mui-focusVisible, &:focus-visible': {
              outlineOffset: '-3px',
            },
            borderWidth: '1px',
            borderColor: '#04143A',
          }),
          ...(ownerState.size === 'md' && {
            '--Icon-fontSize': '20px',
            fontSize: '16px',
            fontWeight: 600,
            minHeight: '40px',
            borderRadius: '2px',
            paddingLeft: 85,
            paddingRight: 85,
          }),
          ...(ownerState.disabled && {
            backgroundColor: '#E2E8F0',
            color: '#676B75',
          }),
          fontSize: '12px',
        }),
      },
    },
    JoyTab: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.selected && {
            '&.Mui-selected': {
              backgroundColor: Moolah.navy[60],
            },
            color: white,
            ListPadding: '0px',
          }),
          border: '1px solid #E2E8F0',
          borderRadius: '5px',

          paddingHorizontal: '0px',
        }),
      },
    },
    JoyTabs: {
      styleOverrides: {
        root: () => ({}),
      },
    },
    JoyTabList: {
      styleOverrides: {
        root: () => ({
          ListPadding: '0px',
        }),
      },
    },
    JoyTable: {
      styleOverrides: {
        root: () => ({
          '--TableCell-headBackground': header,
          '& th': {
            backgroundColor: 'header',
            color: midnightBlue,
            fontFamily: 'Graphik',
            fontWeight: '600',
            fontSize: '10px',
            lineHeight: '12px',
          },
        }),
      },
    },
    JoySelect: {
      styleOverrides: {
        root: () => ({
          backgroundColor: header,
          fontFamily: 'Graphik',
          fontWeight: '400',
          fontSize: '14px',
        }),
      },
    },
    JoyChip: {
      styleOverrides: {
        root: () => ({
          backgroundColor: '#F0F8FA',
          color: Moolah.white,
        }),
      },
    },
    JoyModalDialog: {
      styleOverrides: {
        root: () => ({
          backgroundColor: Moolah.navy[60],
          color: Moolah.white,
        }),
      },
    },
    JoyAccordionSummary: {
      styleOverrides: {
        button: () => ({
          '& :hover': {
            backgroundColor: 'transparent',
          },
          padding: '0px',
        }),
      },
    },
    JoyTooltip: {
      styleOverrides: {
        arrow: ({ ownerState }) => ({
          ...(ownerState.variant === 'solid' && {
            '::before': {
              borderRightColor: Moolah.navy[60],
              borderTopColor: Moolah.navy[60],
            },
          }),
          ...(ownerState.variant === 'plain' && {
            '::before': {
              borderRightColor: Horizon.info[2],
              borderTopColor: Horizon.info[2],
            },
          }),
        }),
      },
    },
  },
});
