/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react';

import './acceptTerms.css';
import { useNavigate } from 'react-router-dom';
import { Box, Button, CircularProgress, Divider, Grid, Stack } from '@mui/joy';
import { ButtonCL } from '../../../components/library/ButtonCL';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import CheckboxCL from '../../../components/library/CheckboxCL';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { TEST_IDS } from '../../../test-utils/test-ids';
import { iconLibrary } from '../../../assets/icons/icon-library';
import {
  acceptTermsAction,
  scheduleSaveProspectAction,
} from '../../../state/onboarding/onboardingActions';
import { AppContext } from '../../../state/appContext';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { TermsAndConditions } from '../../../cms/contentful/custom/CustomTypes';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { TextCL } from '../../../components/library/TextCL';
import PageHeader from '../../../components/common/page-header/pageHeader';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../services/analytic-events/SelfServiceAnalyticsTypes';
import TermsInfoBox from './termsInfoBox';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';

type FormData = {
  termsAccepted: boolean;
};

export interface AcceptTermsProps {
  hasTermsBeenUpdated?: boolean;
}

export default function AcceptTerms({ hasTermsBeenUpdated }: AcceptTermsProps) {
  const { isLargerScreen } = useScreenQuery();
  const { state, dispatch } = React.useContext(AppContext);
  const [terms, setTerms] = React.useState<TermsAndConditions | undefined>(
    undefined,
  );
  const [showLoader, setShowLoader] = React.useState(true);
  React.useEffect(() => {
    setTimeout(() => {
      setShowLoader(false);
    }, 800);
  }, []);

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Accept Terms',
  });

  const { t } = useTranslation();
  const navigate = useNavigate();

  const schema = yup
    .object({
      termsAccepted: yup.boolean().required().oneOf([true], ''),
    })
    .required();

  const { control, handleSubmit, setValue, formState, reset } =
    useForm<FormData>({
      resolver: yupResolver(schema),
    });

  const handleClickBack = React.useCallback(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.TermsBack,
    });
    navigate('/onboarding');
  }, [navigate]);

  useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.TermsPageViewed,
    });
  }, []);

  useEffect(() => {
    const terms =
      state.onboarding.termsAndConditions?.termsOfUseCollection?.items.reduce(
        (highestTerms: any, currentTerms: any) => {
          if (
            currentTerms.status[0] === 'active' &&
            (!highestTerms || currentTerms.version > highestTerms.version)
          ) {
            return currentTerms;
          }
          return highestTerms;
        },
        null,
      ) as TermsAndConditions;

    setTerms(terms);
  }, [state.onboarding.termsAndConditions]);

  useEffect(() => {
    reset(state.onboarding.prospectData?.data);
  }, [state.onboarding.prospectData?.data, reset]);

  const handleClickAcceptTerms = React.useCallback(async () => {
    if (!terms?.versions) {
      return;
    }
    await acceptTermsAction(dispatch, true, terms?.versions);
    await scheduleSaveProspectAction(dispatch);

    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.TermsNext,
      payload: {
        accept: true,
        termsVersion: terms?.versions ? terms.versions : -1,
      },
    });

    navigate(hasTermsBeenUpdated ? '/admin' : '/onboarding/connectPayroll');
  }, [dispatch, hasTermsBeenUpdated, navigate, terms?.versions]);

  const getSubHeading = hasTermsBeenUpdated
    ? t('admin.dashboard.terms.subHeading')
    : t('onboarding.terms.subHeading');

  const getPadding = (
    isLargerScreen: boolean,
    hasTermsBeenUpdated: boolean | undefined,
  ) => {
    if (isLargerScreen) {
      return hasTermsBeenUpdated ? '0em' : '2em';
    } else {
      return '1em';
    }
  };

  if (showLoader) {
    return (
      <Grid
        xs={12}
        md={12}
        lg={12}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          minHeight: '100vh',
        }}
      >
        <CircularProgress />
      </Grid>
    );
  }

  return (
    <>
      <Helmet>
        <title>Self Service - Terms and Conditions</title>
      </Helmet>
      <Stack
        spacing={4}
        sx={{
          padding: getPadding(isLargerScreen, hasTermsBeenUpdated),
        }}
      >
        <Grid container xs={12} md={12} lg={12}>
          <Grid xs={12} md={6} lg={6}>
            <PageHeader
              replayEncrypt={true}
              image={iconLibrary.heading.acceptTerms}
              imageAlt="onboarding.createAccount.title.icon"
              titleKey="onboarding.terms.title"
            />
          </Grid>
          <Grid xs={12} md={6} lg={6}>
            <AdminUserLogout />
          </Grid>
        </Grid>
        <TextCL variant="h5">{getSubHeading}</TextCL>
        <FormFoundations.FormWrapperGrid>
          <Grid xs={12} md={12} lg={12} className="formSection">
            <TextCL variant="h4">{terms?.title}</TextCL>
            <Divider sx={{ marginTop: '20px' }} />
          </Grid>
          <Grid
            xs={12}
            md={12}
            lg={12}
            className="formField"
            style={{ height: '445px', overflowY: 'scroll' }}
          >
            <TextCL replayEncrypt={true}>
              {documentToReactComponents(terms?.terms?.json)}
            </TextCL>
          </Grid>

          <Grid xs={12} md={12} lg={12} className="formField">
            {hasTermsBeenUpdated ? (
              <Button
                variant="solid"
                color="neutral"
                size="sm"
                sx={{ borderRadius: '50px' }}
                onClick={() => {
                  handleClickAcceptTerms();
                }}
                data-testid={TEST_IDS.terms.nextButton}
              >
                {t('onboarding.terms.agreeButtonText')}
              </Button>
            ) : (
              <CheckboxCL
                control={control}
                fieldName="termsAccepted"
                datatestid={TEST_IDS.terms.termsCheckbox}
                label={t('onboarding.terms.agree')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  logAnalyticsEventForSelfService({
                    type: SelfServiceAnalyticsEventTypes.TermsCheckbox,
                    payload: {
                      accept: e.target.checked,
                      termsVersion: terms?.versions ? terms.versions : -1,
                    },
                  });

                  setValue('termsAccepted', e.target.checked);
                }}
              />
            )}
            {(hasTermsBeenUpdated || formState.errors.termsAccepted) && (
              <Grid xs={12} md={12} lg={12} className="formField">
                <div
                  style={{
                    color: 'red',
                    display: 'flex',
                    alignItems: 'center',
                    paddingTop: '2em',
                    width: '100%',
                  }}
                >
                  <img
                    width={18}
                    src={iconLibrary.common.alertCircle}
                    alt="errorTerms"
                  ></img>
                  <div style={{ paddingLeft: '4px', color: '#383D4A' }}>
                    <TextCL variant="error">
                      {t('onboarding.terms.error')}
                    </TextCL>
                  </div>
                </div>
              </Grid>
            )}
            <Grid
              container
              xs={12}
              md={12}
              lg={12}
              gap={2}
              sx={{ marginTop: '40px' }}
            >
              <TermsInfoBox
                title={t('onboarding.terms.infoTitle')}
                content={terms?.help?.json}
              />
            </Grid>
          </Grid>
        </FormFoundations.FormWrapperGrid>
        {!hasTermsBeenUpdated && (
          <Box
            sx={{
              gridColumn: '1/-1',
              justifySelf: 'flex-end',
              display: 'flex',
              gap: 1,
              paddingTop: '2em',
              justifyContent: 'space-between',
              alignContent: 'center',
            }}
          >
            <ButtonCL
              data-testid={TEST_IDS.terms.backButton}
              onClick={handleClickBack}
              text="Back"
              color="neutral"
            />
            <Button
              variant="solid"
              color="neutral"
              size="sm"
              sx={{ borderRadius: '50px' }}
              onClick={handleSubmit(handleClickAcceptTerms)}
              data-testid={TEST_IDS.terms.nextButton}
            >
              Next
            </Button>
          </Box>
        )}
      </Stack>
    </>
  );
}
