import { Box } from '@mui/joy';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { TextCL } from '../../../../../components/library/TextCL';
import { iconLibrary } from '../../../../../assets/icons/icon-library';

interface Props {
  payrollProviderKey?: string;
}

const ProviderError: FC<Props> = ({ payrollProviderKey }) => {
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={{ display: 'flex', alignItems: 'center', paddingTop: '40px' }}>
        <img
          width={50}
          src={iconLibrary.common.alertCircle}
          alt="errorTerms"
        ></img>
        <div style={{ paddingLeft: '12px' }}>
          <TextCL variant="h2" replayEncrypt={true}>
            {t('onboarding.connect.providerApiError.title')}
            {t(`integration.names.${payrollProviderKey}`)}
          </TextCL>
        </div>
      </Box>
      <div
        style={{
          paddingLeft: '12px',
          paddingBottom: '34px',
          paddingTop: '34px',
        }}
      >
        <TextCL variant="h3" replayEncrypt={true}>
          {t('onboarding.connect.providerApiError.description1')}
          {t(`integration.names.${payrollProviderKey}`)}
          {t('onboarding.connect.providerApiError.description2')}
          {t(`integration.names.${payrollProviderKey}`)}
          {t('onboarding.connect.providerApiError.description3')}
        </TextCL>
      </div>
    </Box>
  );
};

export default ProviderError;
