/* eslint-disable @typescript-eslint/no-explicit-any */
import { useAuthenticator } from '@aws-amplify/ui-react';
import React, {
  useCallback,
  Dispatch,
  createContext,
  useMemo,
  useReducer,
} from 'react';
import { AdminActions, adminReducer } from './admin/adminReducer';
import { AdminStateType, initialAdminState } from './admin/adminState';
import {
  AuthActions,
  authReducer,
  dispatchSelfServiceProgressAction,
} from './auth/authReducer';
import {
  AuthStateType,
  SelfServiceProgressStatus,
  evaluateSelfServiceProgressStatus,
  initialAuthState,
} from './auth/authState';
import {
  acceptTermsAction,
  checkProviderExistsAction,
  fetchProspectAction,
  saveProspectAction,
} from './onboarding/onboardingActions';
import {
  OnboardingActions,
  dispatchTriggerBusinessNameCheck,
  onboardingReducer,
} from './onboarding/onboardingReducer';
import {
  OnboardingStateType,
  ProspectDataIndicators,
  initialOnboardingState,
} from './onboarding/onboardingState';
import {
  SubscriptionActions,
  subscriptionReducer,
} from './subscription/subscriptionReducer';
import {
  SubscriptionStateType,
  initialSubscriptionState,
} from './subscription/subscriptionState';
import useDetectDependencyChange from './helpers/useDetectedDependencyChange';
import { fetchProvidersAction } from './admin/adminActions';
import * as Auth from 'aws-amplify/auth';
import { captureException } from '../services/sentry';
import {
  AnalyticsUserPropertyMap,
  logUserNameForSelfService,
  logUserPropertiesForSelfService,
} from '../services/analytic-events/SelfServiceAnalyticsTypes';
import { formatTimestamp } from '../formatters/date-formatters';
import { debounce } from 'lodash';
import useUtmParams from '../hooks/useUTMParams';

export type AggregateStateType = {
  onboarding: OnboardingStateType;
  admin: AdminStateType;
  auth: AuthStateType;
  subscription: SubscriptionStateType;
};

export type AggregateActions =
  | OnboardingActions
  | AdminActions
  | AuthActions
  | SubscriptionActions;

export const initialState: AggregateStateType = {
  onboarding: initialOnboardingState,
  admin: initialAdminState,
  auth: initialAuthState,
  subscription: initialSubscriptionState,
};

const AppContext = createContext<{
  state: AggregateStateType;
  dispatch: Dispatch<AggregateActions>;
}>({
  state: initialState,
  dispatch: () => null,
});

const mainReducer = (
  { onboarding, admin, auth, subscription }: AggregateStateType,
  action: AggregateActions,
) => ({
  onboarding: onboardingReducer(onboarding, action),
  admin: adminReducer(admin, action),
  auth: authReducer(auth, action),
  subscription: subscriptionReducer(subscription, action),
});

type AppProviderProps = {
  children: React.ReactNode;
};

const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(mainReducer, initialState);
  const { fetchAndStoreUtmParams } = useUtmParams();

  const { route, authStatus, user } = useAuthenticator((context) => [
    context.route,
    context.authStatus,
    context.user,
  ]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetSelfServiceProgressStatus = useCallback(
    debounce((progressStatus: SelfServiceProgressStatus) => {
      dispatchSelfServiceProgressAction(dispatch, progressStatus);
    }, 300),
    [dispatchSelfServiceProgressAction],
  );

  React.useEffect(() => {
    async function saveUserNameToAnalytics() {
      if (user?.username) {
        logUserNameForSelfService(user.username);
      }
    }

    async function saveUserPropertiesToAnalytics() {
      if (state.onboarding.prospectData?.data) {
        const prospectData = state.onboarding?.prospectData
          ?.data as ProspectDataIndicators;

        const identifyObj: AnalyticsUserPropertyMap = {};

        for (const key in prospectData) {
          if (prospectData[key] !== undefined) {
            identifyObj[key] = prospectData[key] as string;
          }
        }

        console.debug(
          `authStatus: ${authStatus} user: ${user?.username} state.auth.selfServiceProgress: ${state.auth.selfServiceProgress}`,
        );

        identifyObj['selfServiceProgress'] = state.auth.selfServiceProgress;

        if (user?.username && authStatus === 'authenticated') {
          try {
            const attrs = await Auth.fetchUserAttributes();
            if (attrs.email && user?.username) {
              identifyObj['cognito:email'] = attrs.email;
              identifyObj['cognito:username'] = user?.username;
            }

            if (prospectData.dailyPayAccountCreated && user?.username) {
              identifyObj['emailVerified'] = true;
              identifyObj['selfServiceProgress'] =
                state.auth.selfServiceProgress;
            } else {
              identifyObj['emailVerified'] = false;
              identifyObj['selfServiceProgress'] =
                state.auth.selfServiceProgress;
            }
          } catch (error) {
            captureException(error, {
              extra: {
                message: 'Error fetching user attributes',
              },
            });
            return;
          }
        }

        if (state.admin.mainProvider?.uuid) {
          identifyObj['coreProviderUUID'] = state.admin.mainProvider?.uuid;
          identifyObj['selfServiceProgress'] =
            SelfServiceProgressStatus.Complete;
          identifyObj['coreProviderCreatedDate'] = formatTimestamp(
            state.admin.mainProvider?.created_at,
          );
        }

        logUserPropertiesForSelfService(identifyObj);
      }
    }

    saveUserNameToAnalytics();
    saveUserPropertiesToAnalytics();
  }, [
    authStatus,
    user,
    route,
    state.onboarding.prospectData?.data,
    state.auth.selfServiceProgress,
    state.admin.mainProvider?.uuid,
    state.admin.mainProvider?.created_at,
  ]);

  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);

  useDetectDependencyChange(
    [
      dispatch,
      route,
      state.onboarding.prospectData,
      state.onboarding.transientOnboardingState.saveProspectRequired,
      state.auth.identityAccountExists,
      authStatus,
      state.auth.selfServiceProgress,
      state.admin.providerList,
      state.onboarding.transientOnboardingState.checkBusinessNameRequired,
    ],
    [
      'dispatch',
      'route',
      'state.onboarding.prospectData',
      'state.onboarding.transientOnboardingState.saveProspectRequired',
      'state.auth.identityAccountExists',
      'authStatus',
      'state.auth.selfServiceProgress',
      'state.admin.providerList',
      'state.onboarding.transientOnboardingState.checkBusinessNameRequired',
    ],
  );

  const syncProspectCallBack = React.useCallback(async () => {
    if (
      route === 'authenticated' ||
      state.onboarding.transientOnboardingState.userLoggedIn
    ) {
      await fetchProspectAction(dispatch);
    }
  }, [dispatch, route, state.onboarding.transientOnboardingState.userLoggedIn]);

  React.useEffect(() => {
    syncProspectCallBack();
  }, [syncProspectCallBack]);

  React.useEffect(() => {
    async function saveProspectData() {
      if (state.onboarding.prospectData) {
        await saveProspectAction(dispatch, state.onboarding.prospectData);
      }
    }

    if (state.onboarding.transientOnboardingState.saveProspectRequired) {
      saveProspectData();
    }
  }, [
    dispatch,
    state.onboarding.prospectData,
    state.onboarding.transientOnboardingState.saveProspectRequired,
  ]);

  React.useEffect(() => {
    if (state.onboarding.prospectData?.data) {
      const prospectData = state.onboarding?.prospectData
        ?.data as ProspectDataIndicators;

      const activeTerms =
        state.onboarding.termsAndConditions?.termsOfUseCollection?.items.filter(
          (currentTerms: any) => currentTerms.status[0] === 'active',
        );

      const highestActiveTermsVersion = activeTerms?.reduce(
        (highestVersion: any, currentTerms: any) => {
          return currentTerms.versions > highestVersion
            ? currentTerms.versions
            : highestVersion;
        },
        -1,
      );

      if (prospectData.termsVersion) {
        if (highestActiveTermsVersion > prospectData.termsVersion) {
          acceptTermsAction(dispatch, false, -1);
        }
      }
    }
  }, [
    state.onboarding.prospectData,
    state.onboarding.termsAndConditions?.termsOfUseCollection?.items,
  ]);

  React.useEffect(() => {
    const prospectIndicators = state.onboarding.prospectData
      ?.data as ProspectDataIndicators;

    const transientOnboardingState = state.onboarding.transientOnboardingState;

    const progressStatus = evaluateSelfServiceProgressStatus({
      authConfiguring: authStatus === 'configuring',
      authenticated:
        authStatus === 'authenticated' ||
        state.onboarding.transientOnboardingState.userLoggedIn,
      hasLocalProspect: !!state.onboarding.prospectData?.company_name,
      accountExists: state.auth.identityAccountExists,
      prospectDataIndicators: prospectIndicators,
      transientOnboardingState,
      providerCreated:
        state.admin.providersFetched &&
        state.admin.providerList &&
        state.admin.providerList.length > 0,
    });
    debouncedSetSelfServiceProgressStatus(progressStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.onboarding.prospectData,
    state.onboarding.transientOnboardingState,
    state.admin.providerList,
    authStatus,
    state.auth.identityAccountExists,
    state.admin.providersFetched,
    state.onboarding.transientOnboardingState.userLoggedIn,
  ]);

  const fetchProviderCallback = React.useCallback(async () => {
    const fetchProviders = async () => {
      try {
        const providersResponse = await fetchProvidersAction(dispatch);

        if (providersResponse && providersResponse.length > 0) {
          const providerUUID = providersResponse[0].uuid;

          console.debug(
            'fetchProviderAndEmployees, Got first provider: ' + providerUUID,
          );
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (
      route === 'authenticated' ||
      state.onboarding.transientOnboardingState.userLoggedIn
    ) {
      fetchProviders();
    }
  }, [dispatch, route, state.onboarding.transientOnboardingState.userLoggedIn]);

  React.useEffect(() => {
    fetchProviderCallback();
  }, [fetchProviderCallback]);

  const checkProviderExistsCallback = React.useCallback(async () => {
    const checkProviderExists = async () => {
      try {
        await checkProviderExistsAction(
          dispatch,
          state.onboarding.prospectData?.company_name as string,
        );
      } catch (error) {
        captureException(error, {
          extra: {
            message: 'Error in checkProviderExistsCallback',
          },
        });
      }
    };

    if (
      (state.onboarding.transientOnboardingState.userLoggedIn ||
        route === 'authenticated') &&
      state.admin.providersFetched &&
      !state.admin.mainProvider &&
      state.onboarding.prospectData?.company_name !== undefined &&
      state.onboarding.transientOnboardingState.checkBusinessNameRequired
    ) {
      checkProviderExists();
    }
  }, [
    state.onboarding.transientOnboardingState.userLoggedIn,
    route,
    state.admin.mainProvider,
    state.admin.providersFetched,
    state.onboarding.prospectData?.company_name,
    state.onboarding.transientOnboardingState.checkBusinessNameRequired,
  ]);

  React.useEffect(() => {
    checkProviderExistsCallback();
  }, [checkProviderExistsCallback]);

  React.useEffect(() => {
    dispatchTriggerBusinessNameCheck(dispatch);
  }, [state.onboarding.prospectData?.company_name]);

  const getUtmProps = React.useCallback(async () => {
    if (
      state.auth.selfServiceProgress === SelfServiceProgressStatus.NotStarted ||
      state.auth.selfServiceProgress ===
        SelfServiceProgressStatus.ProspectUnsaved
    ) {
      fetchAndStoreUtmParams();
    }
  }, [fetchAndStoreUtmParams, state.auth.selfServiceProgress]);

  React.useEffect(() => {
    getUtmProps();
  }, [getUtmProps]);

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};

export { AppContext, AppProvider };
