// TODO - add tests
export function normalizeSnakeCaseString(input: string): string {
  // Remove double quotes from the input string
  const cleanedInput = input.replace(/"/g, '');

  const words = cleanedInput.split('_');
  const formattedWords = words
    .filter((word) => word.length > 0) // Filter out empty words
    .map((word) => {
      const firstLetter = word[0].toUpperCase();
      const restOfWord = word.slice(1).toLowerCase();
      return firstLetter + restOfWord;
    });
  return formattedWords.join(' ');
}

export function isNotEmptyString(input: string | undefined): boolean {
  return !!input && input.length > 0;
}

export function getTextAfterError(input: string): string {
  const indexOfError = input.indexOf('Error');
  if (indexOfError !== -1) {
    return input.slice(indexOfError + 5); // +5 to skip the word "Error"
  }
  return '';
}
