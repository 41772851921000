import React from 'react';

import './support.css';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { Grid, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';

export default function Dashboard() {
  const { t } = useTranslation();

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });

  return (
    <>
      <Grid container xs={12} md={12} lg={12}>
        <Grid xs={12} md={6} lg={6} sx={{ paddingBottom: '24px' }}>
          <Typography level="h1" sx={{ paddingLeft: '12px' }}>
            {t('admin.support.title')}
          </Typography>
        </Grid>

        <Grid xs={12} md={6} lg={6} sx={{ paddingBottom: '37px' }}>
          <AdminUserLogout />
        </Grid>
      </Grid>
    </>
  );
}
