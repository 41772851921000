export enum payrollConfigTypes {
  resourcedomain = 'resource_domain',
  username = 'username',
  password = 'password',
  companyShortName = 'company_short_name',
  apiKey = 'api_key',
}

export enum ManageConnectPayrollView {
  steps = 'steps',
  validationSuccess = 'validationSuccess',
  validationError = 'validationError',
  validationErrorMultipleAttempts = 'validationErrorMultipleAttempts',
  providerError = 'providerError',
}
