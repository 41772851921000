import React, { useCallback, useEffect, useState } from 'react';

import './subscriptionPage.css';

import SelectPlanForm from './forms/selectPlanForm';

import { Stack, Typography } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import PayForPlanForm from './forms/payForPlanForm';
import { AppContext } from '../../../state/appContext';
import { ProspectDataIndicators } from '../../../state/onboarding/onboardingState';
import SubscriptionConfirmedForm from './forms/subscriptionConfirmedForm';
import { useSearchParams } from 'react-router-dom';
import {
  dispatchSubcriptionConfirmedAction,
  dispatchSubcriptionFailedAction,
  Types,
} from '../../../state/subscription/subscriptionReducer';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { getStripeConfiguration } from '../../../state/subscription/subscriptionActions';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';

function SubscriptionPage() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();

  const { state, dispatch } = React.useContext(AppContext);
  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [stripeMsg, setStripeMsg] = useState<string | null>('');
  const [searchParams] = useSearchParams();
  const clientSecret =
    state.subscription.stripeSubscription?.client_secret ??
    (searchParams.get('payment_intent_client_secret') as string);
  const stripeKey = state.subscription.stripeConfig?.publishableKey;
  const prospectData = state.onboarding?.prospectData
    ?.data as ProspectDataIndicators;
  const isCallback = searchParams.get('payment_intent_client_secret') != null;

  useEffect(() => {
    getStripeConfiguration(dispatch).then();
  }, [dispatch]);

  useEffect(() => {
    if (stripeKey) {
      loadStripe(stripeKey).then(setStripe);
    }
  }, [stripeKey]);

  // Hack so our interim state is shown, since we do not currently persist state before redirecting to Stripe
  if (!prospectData.paymentMade && isCallback) {
    dispatch({ type: Types.StripePaymentMade, payload: { paymentMade: true } });
  }

  const succeeded = useCallback(() => {
    // Hack so the selectPlanForm doesn't show again, since we do not currently persist state before redirecting to Stripe
    dispatch({
      type: Types.SubcriptionTypeSelected,
      payload: { priceId: 'foo' },
    });
    dispatchSubcriptionConfirmedAction(dispatch);
  }, [dispatch]);
  const failed = useCallback(
    (reason: string) => dispatchSubcriptionFailedAction(dispatch, reason),
    [dispatch],
  );

  useEffect(() => {
    if (!stripe || !clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(({ paymentIntent }) => {
        setStripeMsg(paymentIntent?.description + ' ' + paymentIntent?.status);
        // https://stripe.com/docs/payments/payment-methods#payment-notification
        switch (paymentIntent?.status) {
          case 'succeeded':
            console.log('Success! Payment received.');
            succeeded();
            break;

          case 'processing':
            console.log(
              'Payment processing. We will update you when payment is received.',
            );
            break;

          case 'requires_payment_method':
            failed('Payment failed. Please try another payment method.');
            // Redirect user back to your payment page to attempt collecting payment again
            break;

          default:
            failed('Something went wrong.');
            break;
        }
      })
      .catch((error) => {
        failed('Something went wrong: ' + error);
      });
  }, [clientSecret, stripe, succeeded, failed]);

  return (
    <>
      <Helmet>
        <title>Self Service - Create Account</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '5em' : '1em' }}>
        <Typography level="h1">
          {t('onboarding.createAccount.title')}
        </Typography>

        <Typography level="title-md">
          {t('onboarding.createAccount.description')}
        </Typography>

        {!isCallback && !prospectData?.paymentPlanSelected && (
          <SelectPlanForm />
        )}

        {!isCallback &&
          clientSecret &&
          prospectData?.paymentPlanSelected &&
          !prospectData?.paymentMade &&
          !prospectData?.subscriptionCreated && <PayForPlanForm />}

        {isCallback &&
          clientSecret &&
          prospectData?.paymentMade &&
          !prospectData?.subscriptionCreated && (
            <Typography level="title-md">Processing...</Typography>
          )}

        {isCallback && prospectData?.subscriptionCreated && (
          <>
            <SubscriptionConfirmedForm />
            <Typography level="title-md">{stripeMsg}</Typography>
          </>
        )}
      </Stack>
    </>
  );
}

export default SubscriptionPage;
