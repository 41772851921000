import React from 'react';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { Grid, Stack } from '@mui/joy';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { iconLibrary } from '../../../assets/icons/icon-library';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import AdminTable from './components/admin-table';

const ManageCompanyAccount = () => {
  const { isLargerScreen } = useScreenQuery();
  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });

  return (
    <Grid container xs={12} md={12} lg={12}>
      <Grid xs={12} md={6} lg={6} sx={{ paddingBottom: '24px' }}>
        <PageHeader
          image={iconLibrary.heading.manageCompanyAccount}
          imageAlt="admin.manageCompanyAccount.title.icon"
          titleKey={'admin.manageCompanyAccount.title'}
          excludeBackButton={true}
        />
      </Grid>
      {isLargerScreen && (
        <Grid xs={12} md={6} lg={6}>
          <AdminUserLogout />
        </Grid>
      )}
      <FormFoundations.FormOutlineFlex sx={{ maxWidth: '100hw' }}>
        <Stack>
          <AdminTable />
        </Stack>
      </FormFoundations.FormOutlineFlex>
    </Grid>
  );
};

export default ManageCompanyAccount;
