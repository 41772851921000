import React from 'react';
import { DropdownOption } from '../forms/registrationForm';
import { AvailableIntegrations } from '@dailypay/self-service-ts-sdk';
import { AutocompleteCL } from '../../../../components/library/AutocompleteCL';
import { TEST_IDS } from '../../../../test-utils/test-ids';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

type PayrollProviderDropdownProps = {
  integrations: AvailableIntegrations | undefined;
  setShowOtherPayroll: (val: boolean) => void;
  setHasSelectedOther: (val: boolean) => void;
};

const otherDropdownOption = {
  id: 'other',
  label: 'Other',
};

const PayrollProviderDropdown = ({
  integrations,
  setShowOtherPayroll,
  setHasSelectedOther,
}: PayrollProviderDropdownProps) => {
  const { t } = useTranslation();
  const { control } = useFormContext();
  const [payrollValues, setPayrollValues] = React.useState<DropdownOption[]>(
    [],
  );

  React.useEffect(() => {
    if (integrations) {
      setPayrollValues(
        Object.entries(integrations)
          .filter(([, value]) => value.integration_type.includes('payroll'))
          .map(([key, value]) => {
            return {
              id: key,
              label: t(`integration.names.${value.display_name}`),
            };
          })
          .concat(otherDropdownOption),
      );
    }
  }, [integrations, t]);

  const handleOnChange = (newValue: string) => {
    if (newValue !== 'other') {
      setHasSelectedOther(false);
    }
  };


  return (
    <>
      {payrollValues.length > 0 && (
        <AutocompleteCL
          control={control}
          fieldName={'payrollProviderKey'}
          label={t(
            'onboarding.registration.serviceProviders.payrollProvider',
          ).toUpperCase()}
          datatestid={TEST_IDS.register.payrollProviderDropdown}
          options={payrollValues}
          hasSelectedOther={(val: boolean) => {
            setShowOtherPayroll(val);
          }}
          replayEncrypt={true}
          onChange={handleOnChange}
        />
      )}
    </>
  );
};

export default PayrollProviderDropdown;
