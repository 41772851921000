import React from 'react';
import { Box, Button, Sheet, Tooltip } from '@mui/joy';
import { Horizon, Moolah } from '../../theme';
import { TextCL } from './TextCL';
import { iconLibrary } from '../../assets/icons/icon-library';
import useScreenQuery from '../../layouts/hooks/useScreenQuery';
import { ClickAwayListener } from '@mui/material';

interface TooltipCLProps {
  text?: string;
  tooltipText?: string | React.ReactNode;
  tooltipTitle?: string;
  placement?:
    | 'bottom'
    | 'left'
    | 'right'
    | 'top'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start';
  infoBackgroundColor?: boolean;
  showCloseIcon?: boolean;
  iconColor?: string;
}

export default function TooltipCL({
  text,
  tooltipText,
  tooltipTitle,
  placement,
  infoBackgroundColor,
  showCloseIcon,
  iconColor,
}: Readonly<TooltipCLProps>) {
  const { isLargerScreen } = useScreenQuery();

  const [showTooltip, setShowTooltip] = React.useState<boolean>(
    isLargerScreen && infoBackgroundColor ? true : false,
  );

  const getIcon = () => {
    if (iconColor === Moolah.navy[60]) {
      return iconLibrary.common.circleInfoNavy;
    } else if (iconColor === Moolah.white) {
      return iconLibrary.common.circleInfo;
    } else if (iconColor === Horizon.info[2]) {
      return iconLibrary.common.circleInfoBlue;
    } else {
      return iconLibrary.common.circleInfo;
    }
  };
  const onClose = () => {
    if (showTooltip) {
      setShowTooltip(false);
    }
  };
  return (
    <Sheet
      sx={{
        backgroundColor: 'transparent',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        maxHeight: '14px',
      }}
    >
      {text && (
        <TextCL variant="overline" color={Moolah.white}>
          {text}
        </TextCL>
      )}
      <ClickAwayListener onClickAway={onClose}>
        <Tooltip
          variant={infoBackgroundColor ? 'plain' : 'solid'}
          sx={{
            backgroundColor: infoBackgroundColor
              ? Horizon.info[2]
              : Moolah.navy[60],
            color: infoBackgroundColor ? Horizon.info[2] : Moolah.navy[60],
          }}
          title={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 280,
                justifyContent: 'center',
                paddingLeft: '26px',
                paddingRight: '8px',
                paddingBottom: '24px',
                backgroundColor: infoBackgroundColor
                  ? Horizon.info[2]
                  : Moolah.navy[60],
                outlineColor: infoBackgroundColor
                  ? Horizon.info[2]
                  : Moolah.navy[60],
              }}
            >
              {showCloseIcon && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    variant="plain"
                    sx={{
                      '&:hover': {
                        background: 'transparent',
                      },
                      padding: '0',
                    }}
                    onClick={onClose}
                  >
                    <img
                      width={12}
                      src={iconLibrary.common.cross}
                      alt="tooltipClose"
                    ></img>
                  </Button>
                </div>
              )}
              {tooltipTitle && (
                <div
                  style={{
                    paddingRight: '26px',
                  }}
                >
                  <TextCL variant="h6" color={Moolah.white}>
                    {tooltipTitle}
                  </TextCL>
                </div>
              )}
              {tooltipText && (
                <Box sx={{ display: 'flex', gap: 1, width: '100%', mt: 1 }}>
                  <div
                    style={{
                      paddingRight: '26px',
                    }}
                  >
                    {typeof tooltipText === 'string' ? (
                      <TextCL
                        variant={infoBackgroundColor ? 'h6' : 'body'}
                        color={Moolah.white}
                      >
                        {tooltipText}
                      </TextCL>
                    ) : (
                      tooltipText
                    )}
                  </div>
                </Box>
              )}
            </Box>
          }
          arrow
          open={showTooltip}
          placement={placement}
        >
          <Button
            variant="plain"
            sx={{
              '&:hover': {
                background: 'transparent',
              },
              padding: '0',
            }}
            onClick={() => setShowTooltip(!showTooltip)}
          >
            <img
              width={infoBackgroundColor ? 24 : 18}
              src={getIcon()}
              alt="tooltipIcon"
            ></img>
          </Button>
        </Tooltip>
      </ClickAwayListener>
    </Sheet>
  );
}
