import * as React from 'react';
import { Box, Link } from '@mui/joy';
import { WizardIcon } from './WizardIcon';
import { WizardIconType } from '../../../assets/icons/icon-library';
import { Moolah } from '../../../theme';

export interface WizardNavLinkProps {
  title: string;
  iconDetails: WizardIconType;
  linkFunction: () => void;
  disabled: boolean;
}

export const WizardNavLink = ({
  title,
  iconDetails,
  linkFunction,
  disabled,
}: WizardNavLinkProps) => {
  return (
    <>
      <Link
        underline="none"
        sx={{ display: 'flex', width: '100%' }}
        level="title-sm"
        onClick={() => linkFunction()}
        disabled={disabled}
      >
        <Box
          sx={{
            display: 'flex',
            width: '100%',
            flexGrow: 'true',
            alignItems: 'center',
            justifyContent: 'flex-start',
            color: Moolah.navy[60],
          }}
        >
          <WizardIcon {...iconDetails} />
          {title}
        </Box>
        {iconDetails.icon !== 'register' && (
          <div
            style={{
              position: 'absolute',
              top: -32,
              left: '50%',
              height: '80%',
              width: '2px',
              background: Moolah.navy[60],
              transform: 'translateX(-3800%)',
            }}
          />
        )}
      </Link>
    </>
  );
};
