export enum SortKeys {
  FirstName = 'first_name',
  FirstNameDesc = '-first_name',
  LastName = 'last_name',
  LastNameDesc = '-last_name',
  Email = 'email',
  EmailDesc = '-email',
  DpStatus = 'dp_status',
  DpStatusDesc = '-dp_status',
  EmploymentStatus = 'employment_status',
  EmploymentStatusDesc = '-employment_status',
}

export const invertSortKey = (sortKey: SortKeys) => {
  switch (sortKey) {
    case SortKeys.FirstName:
      return SortKeys.FirstNameDesc;
    case SortKeys.FirstNameDesc:
      return SortKeys.FirstName;
    case SortKeys.LastName:
      return SortKeys.LastNameDesc;
    case SortKeys.LastNameDesc:
      return SortKeys.LastName;
    case SortKeys.Email:
      return SortKeys.EmailDesc;
    case SortKeys.EmailDesc:
      return SortKeys.Email;
    case SortKeys.DpStatus:
      return SortKeys.DpStatusDesc;
    case SortKeys.DpStatusDesc:
      return SortKeys.DpStatus;
    case SortKeys.EmploymentStatus:
      return SortKeys.EmploymentStatusDesc;
    case SortKeys.EmploymentStatusDesc:
      return SortKeys.EmploymentStatus;
    default:
      return SortKeys.Email;
  }
};

export const sortKeyMap = {
  'admin.employeeTable.headings.firstName': SortKeys.FirstName,
  'admin.employeeTable.headings.lastName': SortKeys.LastName,
  'admin.employeeTable.headings.email': SortKeys.Email,
  'admin.employeeTable.headings.phone': undefined,
  'admin.employeeTable.headings.status': SortKeys.DpStatus,
  'admin.employeeTable.headings.action': undefined,
};

export const sortKeyVisibilityMap = (headingId: string) => {
  return [
    'admin.employeeTable.headings.firstName',
    'admin.employeeTable.headings.lastName',
    'admin.employeeTable.headings.action',
  ].includes(headingId);
};

interface ToolTipMap {
  [key: string]: undefined | string;
}

export const tooltipMap: ToolTipMap = {
  'admin.employeeTable.headings.firstName': undefined,
  'admin.employeeTable.headings.lastName': undefined,
  'admin.employeeTable.headings.email': undefined,
  'admin.employeeTable.headings.phone': undefined,
  'admin.employeeTable.headings.status': 'terminology.tooltip.dailypayStatus',
  'admin.employeeTable.headings.action': undefined,
};
