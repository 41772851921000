import React from 'react';

import { Grid, IconButton, Stack } from '@mui/joy';

import { useTranslation } from 'react-i18next';
import { iconLibrary } from '../../../assets/icons/icon-library';

import useScreenQuery from '../../../layouts/hooks/useScreenQuery';

import { useNavigate } from 'react-router-dom';
import { TextCL } from '../../library/TextCL';

interface PageHeaderProps {
  image?: string;
  imageAlt?: string;
  excludeBackButton?: boolean;
  titleKey: string;
  name?: string;
  replayEncrypt?: boolean;
}

function PageHeader({
  image,
  imageAlt,
  titleKey,
  name,
  excludeBackButton,
  replayEncrypt,
}: PageHeaderProps) {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();

  const navigate = useNavigate();

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}
    >
      {!isLargerScreen && !excludeBackButton && (
        <IconButton
          onClick={() => navigate(-1)}
          variant="plain"
          color="neutral"
          size="sm"
          sx={{ marginRight: '1rem' }}
        >
          <img
            width={24}
            src={iconLibrary.common.back}
            alt="backButtonHeading"
          ></img>
        </IconButton>
      )}
      {image && (
        <img
          width={45}
          src={image}
          alt={imageAlt}
          style={{ marginRight: '12px' }}
        />
      )}
      {isLargerScreen ? (
        <TextCL variant="dpH3" replayEncrypt={replayEncrypt}>
          {t(titleKey)} {name && t(name)}
        </TextCL>
      ) : (
        <Grid>
          <TextCL variant="dpH3" replayEncrypt={replayEncrypt}>
            {t(titleKey)}
          </TextCL>
          {name && (
            <TextCL variant="dpH4" replayEncrypt={replayEncrypt}>
              {t(name)}
            </TextCL>
          )}
        </Grid>
      )}
    </Stack>
  );
}

export default PageHeader;
