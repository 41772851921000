/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import './dashboard.css';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { Grid } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';
import { iconLibrary } from '../../../assets/icons/icon-library';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { formInsetBackground } from '../../../theme/self-service-joy-theme';
import ThingsToDo from './components/ThingsToDo';
import { AppContext } from '../../../state/appContext';
import CardWithActions from '../../../components/foundational/CardWithActions';
import { CardWithButton } from '../../../cms/contentful/custom/CustomTypes';
import ProgramCard from './components/ProgramCard';
import { Horizon, Moolah } from '../../../theme';
import {
  EmployeeListQueryParams,
  getEmployeeData,
} from '../../../services/api-wrapper';
import {
  DailyPayStatus,
  EmploymentStatus,
} from '@dailypay/self-service-ts-sdk';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../services/analytic-events/SelfServiceAnalyticsTypes';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import { InfoModalCL } from '../../../components/library/InfoModalCL';
import { useNavigate } from 'react-router-dom';
import {
  addDays,
  getDateInTimestamp,
  getToday,
  isDateAfter,
} from '../../../formatters/date-formatters';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from '../../../utils/local-storage-utils';
import { LocalStorageKeys } from '../../../ss-constants/local-storage';
import { isToday } from 'date-fns';

export default function Dashboard() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();
  const { state } = React.useContext(AppContext);
  const [cardInfo, setCardInfo] = React.useState<CardWithButton[] | undefined>(
    undefined,
  );
  const [totalEmployeeCount, setTotalEmployeeCount] = useState<
    number | undefined
  >(0);
  const [activeEmployeeCount, setActiveEmployeeCount] = useState<
    number | undefined
  >(0);
  const [pendingEmployeeCount, setPendingEmployeeCount] = useState<
    number | undefined
  >(0);
  const [showUpdateTerms, setShowUpdateTerms] = React.useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (getLocalStorageItem(LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE)) {
      const nextTermsPromptTimestamp = Number(
        getLocalStorageItem(LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE),
      );
      const getTodaysDate = getToday('end');

      if (
        isDateAfter(getTodaysDate, new Date(nextTermsPromptTimestamp)) ||
        isToday(nextTermsPromptTimestamp)
      ) {
        setShowUpdateTerms(true);
      }
    }
  }, []);

  const [providerUUID, setProviderUUID] = React.useState<string | undefined>();
  const [payrollProviderContentfulId, setPayrollProviderContentfulId] =
    React.useState<string | undefined>('');

  useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.PortalDashboardPageViewed,
    });
  }, []);

  useEffect(() => {
    const providerUUID = state.admin.mainProvider?.uuid;

    setProviderUUID(providerUUID);
    setPayrollProviderContentfulId(state.admin.mainProvider?.contentful_id);
  }, [state.admin.mainProvider]);

  useEffect(() => {
    const fetchAndSetEmployeeCount = async (
      employmentStatus: EmployeeListQueryParams['employment_status'],
      dpStatus: EmployeeListQueryParams['dp_status'],
      setCountState: Dispatch<SetStateAction<number | undefined>>,
    ) => {
      if (providerUUID) {
        const query: EmployeeListQueryParams = {
          employment_status: employmentStatus,
          dp_status: dpStatus,
          page_size: 1,
          page: 1,
        };

        const response = await getEmployeeData(providerUUID, query);
        setCountState(() => response?.employee_count);
      }
    };

    fetchAndSetEmployeeCount(
      EmploymentStatus.Employed,
      undefined,
      setTotalEmployeeCount,
    );
    fetchAndSetEmployeeCount(
      EmploymentStatus.Employed,
      DailyPayStatus.Active,
      setActiveEmployeeCount,
    );
    fetchAndSetEmployeeCount(
      EmploymentStatus.Employed,
      DailyPayStatus.Pending,
      setPendingEmployeeCount,
    );
  }, [providerUUID]);

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });

  React.useEffect(() => {
    const info = state.admin.cardWithButton?.cardWithButtonCollection
      ?.items as CardWithButton[];
    setCardInfo(info);
  }, [state.admin.cardWithButton?.cardWithButtonCollection?.items]);

  const renderCardContent = (cardInfo: CardWithButton) => {
    let buttonUrl = cardInfo.buttonUrl;

    if (cardInfo.buttonText?.includes('Success Hub')) {
      buttonUrl = `${cardInfo.buttonUrl}${payrollProviderContentfulId}`;
    } else if (cardInfo.buttonText?.includes('launch')) {
      buttonUrl = `${cardInfo.buttonUrl}${payrollProviderContentfulId}/launch`;
    } else if (cardInfo.buttonText?.includes('training')) {
      buttonUrl = `${cardInfo.buttonUrl}${payrollProviderContentfulId}/training`;
    }

    return (
      <CardWithActions
        title={cardInfo.title}
        text={cardInfo.description}
        linkHref={buttonUrl}
        label={cardInfo.buttonText}
        headingColor={Moolah.white}
        headingTextColor={Horizon.info[4]}
      />
    );
  };

  const onCloseModal = async () => {
    const getTodaysDate = getToday('end');
    const nextTermsPromptDate = addDays(getTodaysDate, { days: 3 });
    const nextTermsPromptTimestamp = getDateInTimestamp(nextTermsPromptDate);
    setLocalStorageItem(
      LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE,
      nextTermsPromptTimestamp.toString(),
    );

    setShowUpdateTerms(false);
  };

  const onClickModal = () => {
    setShowUpdateTerms(false);
    navigate('/admin/acceptTerms');
  };

  return (
    <>
      <InfoModalCL
        isOpen={showUpdateTerms}
        title={t('admin.dashboard.terms.title')}
        text={t('admin.dashboard.terms.text')}
        buttonText={t('admin.dashboard.terms.button')}
        onClose={() => onCloseModal()}
        onActionClicked={() => onClickModal()}
      />
      <Grid container xs={12} md={12} lg={12} maxWidth="100%">
        <Grid xs={12} sm={6} md={6} lg={6} sx={{ paddingBottom: '24px' }}>
          <PageHeader
            image={iconLibrary.heading.dashboard}
            imageAlt="admin.dashboard.title.icon"
            titleKey={t('admin.dashboard.title')}
            excludeBackButton={true}
          />
        </Grid>

        {isLargerScreen && (
          <Grid
            container
            alignItems={'center'}
            xs={12}
            sm={6}
            md={6}
            lg={6}
            sx={{ paddingBottom: '37px' }}
          >
            <Grid xs={12} md={6} lg={6}></Grid>

            <Grid xs={12} md={6} lg={6}>
              <AdminUserLogout />
            </Grid>
          </Grid>
        )}
      </Grid>

      {providerUUID && (
        <Grid
          container
          spacing={4}
          sx={{
            maxWidth: '100hw',
            flexGrow: 1,
            backgroundColor: formInsetBackground,
            borderRadius: '8px',
            padding: '0px  0px 24px 0px',
            boxShadow: '0px 2px 4px rgba(15, 22, 57, 0.04)',
          }}
        >
          <Grid container xs={12} md={12} lg={12} rowSpacing={3}>
            <ThingsToDo />
          </Grid>

          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            rowSpacing={3}
            sx={{
              display: 'flex',
              justifyContent: 'space-evenly',
            }}
          >
            {cardInfo?.map((item: CardWithButton, index: number) => {
              return (
                item.location === 'dashboard' && (
                  <Grid
                    xs={12}
                    md={6}
                    lg={3}
                    key={index}
                    sx={{
                      margin: '24px',
                      '&.MuiGrid-root': {
                        display: 'flex',
                        flexGrow: 1,
                      },
                    }}
                  >
                    {renderCardContent(item)}
                  </Grid>
                )
              );
            })}
          </Grid>
          <Grid xs={12} md={12} lg={12}>
            <ProgramCard
              title={t('admin.dashboard.program.title')}
              text={'test'}
              employeeCount={totalEmployeeCount}
              activeEmployeeCount={activeEmployeeCount}
              pendingEmployeeCount={pendingEmployeeCount}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
}
