import {
  AvailableIntegrations,
  ProspectData,
} from '@dailypay/self-service-ts-sdk';
import { ActionMap } from '../actionMap';
import { AggregateActions } from '../appContext';
import {
  OnboardingStateType,
  ProspectDataIndicators,
  initialOnboardingState,
} from './onboardingState';
import { GlobalActionTypes } from '../auth/authReducer';
import { produce } from 'immer';
import {
  GetCardWithButtonQuery,
  GetHelpTopicsQuery,
  GetTermsAndCondtionsQuery,
} from '../../cms/contentful/generated/graphql';

const logChangesToConsole = false;

export enum Types {
  ChoosePayroll = 'CHOOSE_PAYROLL',
  ProviderAlreadyExists = 'PROVIDER_ALREADY_EXISTS',
  AcceptTerms = 'ACCEPT_TERMS',
  FetchSavedProspect = 'FETCH_SAVED_PROSPECT',
  SubcriptionTypeSelected = 'SUBSCRIPTION_TYPE_SELECTED',
  SubcriptionConfirmed = 'SUBSCRIPTION_CONFIRMED',
  PayrollConnectionValidated = 'PAYROLL_CONNECTION_VALIDATED',
  PayrollConnectionFailed = 'PAYROLL_CONNECTION_FAILED',
  DailyPayProviderCreationSuccess = 'DAILY_PAY_PROVIDER_CREATION_SUCCESS',
  DailyPayProviderCreationFailure = 'DAILY_PAY_PROVIDER_CREATION_FAILURE',
  DailyPayProviderCreationSubmitting = 'DAILY_PAY_PROVIDER_CREATION_SUBMITTING',
  AvailableIntegrationsFetched = 'AVAILABLE_INTEGRATIONS_FETCHED',
  HelpTopicsFetched = 'HELP_TOPICS_FETCHED',
  CardWithButtonFetched = 'CARD_WITH_BUTTON_FETCHED',
  ShouldDisplaySidebarCardsChanged = 'SHOULD_DISPLAY_SIDEBAR_CARDS_CHANGED',
  TermsFetched = 'TERMS_FETCHED',
  StripePaymentMade = 'STRIPE_PAYMENT_MADE',
  QueueSaveProspect = 'QUEUE_SAVE_PROSPECT',
  AckSavedProspect = 'ACK_SAVED_PROSPECT',
  RegistrationDataUpdated = 'RegistrationDataUpdated',
  NoRemoteProspectFound = 'NoRemoteProspectFound',
  ThingsToDoCheckEmployeesPage = 'CHECK_EMPLOYEES_PAGE',
  ThingsToDoCheckInvites = 'CHECK_INVITES',
  CheckBusinessNameRequired = 'CHECK_BUSINESS_NAME_REQUIRED',
  SignInVerified = 'SIGN_IN_VERIFIED',
}

type OnboardingPayload = {
  [Types.ChoosePayroll]: {
    providerName: string;
  };
  [Types.ProviderAlreadyExists]: {
    providerAlreadyExists: boolean | undefined;
  };
  [Types.AcceptTerms]: {
    termsAccepted: boolean;
    version: number;
  };
  [Types.TermsFetched]: {
    termsAndConditons: GetTermsAndCondtionsQuery | undefined;
  };
  [Types.FetchSavedProspect]: {
    prospectData: ProspectData;
  };
  [Types.SubcriptionTypeSelected]: {
    priceId: string;
  };
  [Types.SubcriptionConfirmed]: {
    subscriptionConfirmed: boolean;
  };
  [Types.PayrollConnectionValidated]: {
    payrollConnectionValidated: boolean;
  };
  [Types.PayrollConnectionFailed]: {
    name: string;
  };
  [Types.DailyPayProviderCreationSuccess]: {
    payrollProviderContentfulId: string | undefined;
    coreProviderUUID: string | undefined;
  };
  [Types.DailyPayProviderCreationFailure]: {
    error: unknown;
  };
  [Types.DailyPayProviderCreationSubmitting]: undefined;
  [Types.AvailableIntegrationsFetched]: {
    availableIntegrations: AvailableIntegrations;
  };
  [Types.HelpTopicsFetched]: {
    helpTopics: GetHelpTopicsQuery | undefined;
  };
  [Types.CardWithButtonFetched]: {
    cardData: GetCardWithButtonQuery | undefined;
  };
  [Types.ShouldDisplaySidebarCardsChanged]: {
    shouldDisplaySidebarCards: boolean;
  };
  [GlobalActionTypes.ResetAppState]: undefined;
  [Types.StripePaymentMade]: {
    paymentMade: boolean;
  };
  [Types.QueueSaveProspect]: undefined;
  [Types.AckSavedProspect]: undefined;
  [Types.RegistrationDataUpdated]: {
    prospectData: ProspectData;
  };
  [Types.ThingsToDoCheckEmployeesPage]: {
    checkedEmployeesPage: boolean;
  };
  [Types.ThingsToDoCheckInvites]: {
    checkedInvites: boolean;
  };
  [Types.NoRemoteProspectFound]: undefined;
  [GlobalActionTypes.ResetAppState]: undefined;
  [Types.CheckBusinessNameRequired]: undefined;
  [Types.SignInVerified]: undefined;
};

export type OnboardingActions =
  ActionMap<OnboardingPayload>[keyof ActionMap<OnboardingPayload>];

export const onboardingReducer = (
  state: OnboardingStateType,
  action: AggregateActions,
) => {
  switch (action.type) {
    case Types.ChoosePayroll:
      return {
        ...state,
        providerName: action.payload.providerName,
      };
    case Types.ProviderAlreadyExists:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (
            draft.prospectData.data as ProspectDataIndicators
          ).providerAlreadyExists = action.payload.providerAlreadyExists;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
        draft.transientOnboardingState.checkBusinessNameRequired = false;
      });
    case Types.FetchSavedProspect:
      return {
        ...state,
        prospectData: action.payload.prospectData,
        transientOnboardingState: {
          ...state.transientOnboardingState,
          remoteProspectFetched: true,
        },
      };
    case Types.RegistrationDataUpdated:
      return produce(state, (draft) => {
        draft.prospectData = action.payload.prospectData;
        draft.transientOnboardingState.saveProspectRequired = true;
      });
    case Types.SubcriptionTypeSelected:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (
            draft.prospectData.data as ProspectDataIndicators
          ).paymentPlanSelected = action.payload.priceId;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });

    case Types.SubcriptionConfirmed:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (
            draft.prospectData.data as ProspectDataIndicators
          ).subscriptionCreated = action.payload.subscriptionConfirmed;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });

    case Types.PayrollConnectionValidated:
      return produce(state, (draft) => {
        draft.transientOnboardingState.payrollValidated = true;
      });
    case Types.AcceptTerms:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (draft.prospectData.data as ProspectDataIndicators).termsAccepted =
            action.payload.termsAccepted;
          (draft.prospectData.data as ProspectDataIndicators).termsVersion =
            action.payload.version;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });
    case Types.PayrollConnectionFailed:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          const fieldName = action.payload.name;
          const prospectData = draft.prospectData
            .data as ProspectDataIndicators;

          if (!prospectData[fieldName]) {
            prospectData[fieldName] = 1;
          } else {
            const currentCount = prospectData[fieldName] as number;
            prospectData[fieldName] = currentCount + 1;
          }
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });

    case Types.DailyPayProviderCreationSubmitting:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.dailyPayAccountConnectionSubmitting =
            true;
        }
      });

    case Types.DailyPayProviderCreationFailure:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.dailyPayAccountConnectionSubmitting =
            false;
          draft.transientOnboardingState.dailyPayAccountConnectionError =
            'Failed to create DailyPay account';
        }
      });

    case Types.DailyPayProviderCreationSuccess:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          const prospectDataIndicators = draft.prospectData
            .data as ProspectDataIndicators;
          prospectDataIndicators.dailyPayAccountCreated = true;
          prospectDataIndicators.payrollProviderContentfulId =
            action.payload.payrollProviderContentfulId;
          prospectDataIndicators.coreProviderUUID =
            action.payload.coreProviderUUID;
        }
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.dailyPayAccountConnectionSubmitting =
            false;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });

    case Types.AvailableIntegrationsFetched:
      return {
        ...state,
        integrations: action.payload.availableIntegrations,
      };
    case Types.QueueSaveProspect:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.saveProspectRequired = true;
        }
      });

    case Types.CheckBusinessNameRequired:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.checkBusinessNameRequired = true;
        }
      });
    case Types.SignInVerified:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.userLoggedIn = true;
        }
      });
    case Types.AckSavedProspect:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.saveProspectRequired = false;
          draft.transientOnboardingState.remoteProspectFetched = true;
        }
      });
    case Types.NoRemoteProspectFound:
      return produce(state, (draft) => {
        if (draft.transientOnboardingState) {
          draft.transientOnboardingState.remoteProspectFetched = true;
        }
      });
    case GlobalActionTypes.ResetAppState:
      return {
        ...initialOnboardingState,
      };
    case Types.HelpTopicsFetched:
      return {
        ...state,
        helpTopics: action.payload.helpTopics,
      };
    case Types.CardWithButtonFetched:
      return {
        ...state,
        cardWithButton: action.payload.cardData,
      };
    case Types.ShouldDisplaySidebarCardsChanged:
      return {
        ...state,
        shouldDisplaySidebarCards: action.payload.shouldDisplaySidebarCards,
      };
    case Types.TermsFetched:
      return {
        ...state,
        termsAndConditions: action.payload.termsAndConditons,
      };
    case Types.ThingsToDoCheckEmployeesPage:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (
            draft.prospectData.data as ProspectDataIndicators
          ).checkedEmployeesPage = action.payload.checkedEmployeesPage;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });
    case Types.ThingsToDoCheckInvites:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (draft.prospectData.data as ProspectDataIndicators).checkedInvites =
            action.payload.checkedInvites;
        }
        draft.transientOnboardingState.saveProspectRequired = true;
      });
    case Types.StripePaymentMade:
      return produce(state, (draft) => {
        if (draft.prospectData?.data) {
          (draft.prospectData.data as ProspectDataIndicators).paymentMade =
            action.payload.paymentMade;
        }
      });
    default:
      return state;
  }
};

export const dispatchFetchSavedProspectAction = (
  dispatch: React.Dispatch<AggregateActions>,
  prospectData: ProspectData,
) => {
  if (logChangesToConsole) {
    console.log('dispatchFetchSavedProspectAction');
  }
  dispatch({
    type: Types.FetchSavedProspect,
    payload: {
      prospectData,
    },
  });
};

export const dispatchRegistrationDataUpdatedAction = (
  dispatch: React.Dispatch<AggregateActions>,
  prospectData: ProspectData,
) => {
  if (logChangesToConsole) {
    console.log('dispatchRegistrationDataUpdatedAction');
  }
  dispatch({
    type: Types.RegistrationDataUpdated,
    payload: {
      prospectData,
    },
  });
};

export const dispatchSubcriptionTypeSelectedAction = (
  dispatch: React.Dispatch<AggregateActions>,
  priceId: string,
) => {
  if (logChangesToConsole) {
    console.log('dispatchSubcriptionTypeSelected');
  }
  dispatch({
    type: Types.SubcriptionTypeSelected,
    payload: {
      priceId,
    },
  });
};

export const dispatchSubcriptionConfirmedSelectedAction = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  if (logChangesToConsole) {
    console.log('dispatchSubcriptionConfirmedSelected');
  }
  dispatch({
    type: Types.SubcriptionConfirmed,
    payload: {
      subscriptionConfirmed: true,
    },
  });
};

export const dispatchPayrollConnectionValidatedAction = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  if (logChangesToConsole) {
    console.log('dispatchSubcriptionConfirmedSelected');
  }
  dispatch({
    type: Types.PayrollConnectionValidated,
    payload: {
      payrollConnectionValidated: true,
    },
  });
};

export const incrementPayrollConnectionFailedAction = (
  dispatch: React.Dispatch<AggregateActions>,
  name: string,
) => {
  if (logChangesToConsole) {
    console.log('dispatchSubcriptionConfirmedSelected');
  }
  dispatch({
    type: Types.PayrollConnectionFailed,
    payload: {
      name: `payrollErrorCount-${name}`,
    },
  });
};

export const dispatchPaymentMadeAction = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  dispatch({
    type: Types.StripePaymentMade,
    payload: {
      paymentMade: true,
    },
  });
};

export const dispatchTriggerBusinessNameCheck = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  dispatch({
    type: Types.CheckBusinessNameRequired,
  });
};

export const dispatchSignInVerified = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  dispatch({
    type: Types.SignInVerified,
  });
};

export const dispatchDisplaySidebarCards = (
  dispatch: React.Dispatch<AggregateActions>,
  value: boolean,
) => {
  dispatch({
    type: Types.ShouldDisplaySidebarCardsChanged,
    payload: {
      shouldDisplaySidebarCards: value,
    },
  });
};
