import React from 'react';

import { Button, Divider, Typography } from '@mui/joy';
import Box from '@mui/joy/Box';
import './selectPlanForm.css';

import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';
import { useNavigate } from 'react-router-dom';

const SubscriptionConfirmedForm: React.FC = () => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  return (
    <>
      <FormFoundations.FormWrapperBox>
        <Typography level="h3">
          {t('onboarding.registration.personalDetails.title')}
        </Typography>

        <Divider />
      </FormFoundations.FormWrapperBox>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'flex-end',
          justifyContent: 'space-between',
          overflow: 'wrap',
          display: 'flex',
          gap: 1,
        }}
      >
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={() => {
            navigate('/onboarding/connectPayroll');
          }}
        >
          Setup your payroll now
        </Button>
      </Box>
    </>
  );
};

export default SubscriptionConfirmedForm;
