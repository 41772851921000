import React, { useEffect } from 'react';

import { Button, Divider, Grid } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import './registrationForm.css';

import { FormProvider, useForm, useWatch } from 'react-hook-form';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ButtonCL } from '../../../../components/library/ButtonCL';
import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';
import { AutocompleteCL } from '../../../../components/library/AutocompleteCL';
import { TEST_IDS } from '../../../../test-utils/test-ids';
import { AppContext } from '../../../../state/appContext';
import { dispatchRegistrationDataUpdatedAction } from '../../../../state/onboarding/onboardingReducer';
import {
  acrisureSupportedCompanyCountRange,
  supportedCompanyCountRange,
} from '../types';
import { RegistrationFormData } from '../../../../formData/formDataTypes';
import { SelfServiceProgressStatus } from '../../../../state/auth/authState';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import ENV_VARS from '../../../../environment/envvars';
import { TextCL } from '../../../../components/library/TextCL';
import PayrollProviderDropdown from '../payrollProviderDropdown/payrollProviderDropdown';
import {
  acrisureReferral,
  adpRun,
  adpWfn,
  otherProvider,
  ukgReady,
} from '../../../../ss-constants/onboarding';
import { Moolah } from '../../../../theme';

export type DropdownOption = {
  id: string;
  label: string;
};

const otherDropdownOption = {
  id: otherProvider,
  label: 'Other',
};

const schema = yup
  .object()
  .shape({
    firstName: yup.string().required('First Name is required'),
    lastName: yup.string().required('Last Name is required'),
    emailAddress: yup
      .string()
      .email('Email Address must be a valid email')
      .required('Email Address is required'),
    companyName: yup
      .string()
      .required('Company Name is required')
      .min(3, 'Company Name must be at least 3 characters'),
    companyRole: yup.string().required('Company Role is required'),
    payrollProviderKey: yup.string().required('Payroll Provider is required'),
    timeManagementProviderKey: yup
      .string()
      .required('Time Management Provider is required'),
    payrollProviderNameOther: yup.string(),
    timeManagementProviderNameOther: yup.string(),
    numberOfEmployees: yup.string().required('Number of Employees is required'),
  })
  .required();

const getCompanyCountOptions = (referrer: string) => {
  if (referrer === acrisureReferral) {
    return acrisureSupportedCompanyCountRange;
  } else {
    return supportedCompanyCountRange;
  }
};

export const payrollToTmMapping: Record<string, string> = {
  'adp_run': 'other',
  'adp_wfn': 'adp_wfn',
  'team': 'team',
  'ukg_ready': 'ukg_ready',
  'other': 'other',
}

const LOGIN_PAGE_URL = '/login';
const RegistrationForm: React.FC = () => {
  // Use the app context to get state
  const { state, dispatch } = React.useContext(AppContext);

  const methods = useForm<RegistrationFormData>({
    resolver: yupResolver(schema),
  });
  const { control, handleSubmit, reset } = methods;

  const [nextPath, setNextPath] = React.useState<string>(
    '/onboarding/createAccount',
  );
  const selectedPayrollProvider = useWatch({
    name: 'payrollProviderKey',
    control,
  });
  const [tmsValues, setTMValues] = React.useState<DropdownOption[]>([]);
  const [showOtherPayroll, setShowOtherPayroll] =
    React.useState<boolean>(false);
  const [showOtherTM, setShowOtherTM] = React.useState<boolean>(false);
  const [disableFieldsForExistingUser, setDisableFieldsForExistingUser] =
    React.useState<boolean>(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const utmProps = JSON.parse(localStorage.getItem('utmProps') || '{}');
  const referrer = utmProps['referrer'];
  const companyCountOptions = getCompanyCountOptions(referrer);

  useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.RegistrationPageViewed,
    });
  }, []);

  const getFormValue = <T extends keyof RegistrationFormData>(
    fieldName: T,
  ): RegistrationFormData[T] => {
    return control._formValues[fieldName];
  };

  useEffect(() => {
    reset(state.onboarding.prospectData?.data);
  }, [state.onboarding.prospectData?.data, reset]);

  useEffect(() => {
    if (
      state.auth.selfServiceProgress === SelfServiceProgressStatus.NotStarted ||
      state.auth.selfServiceProgress ===
        SelfServiceProgressStatus.ProspectUnsaved ||
      control._formValues.emailAddress === ''
    ) {
      setDisableFieldsForExistingUser(false);
      setNextPath('/onboarding/createAccount');
    } else if (
      state.auth.selfServiceProgress ===
      SelfServiceProgressStatus.ProviderAlreadyExists
    ) {
      setNextPath('/onboarding/duplicateCompany');
    } else if (
      state.auth.selfServiceProgress ===
      SelfServiceProgressStatus.IdentityCreated
    ) {
      setNextPath('/onboarding/verifyAccount');
    } else {
      setNextPath('/onboarding/acceptTerms');
    }
  }, [control._formValues.emailAddress, state.auth.selfServiceProgress]);

  const handleConnectPayroll = React.useCallback(async () => {
    const dataToSave = {
      company_name: getFormValue('companyName'),
      data: control._formValues,
    };
    dispatchRegistrationDataUpdatedAction(dispatch, dataToSave);
    const isAcrisureReferral = referrer === acrisureReferral;

    const numberOfEmployees = control._formValues.numberOfEmployees;

    // Logic for determining enterprise company size, if acrisure referral then it is considered an enterprise company
    // when over 300+ employees is selected, otherwise when 500+ employees is selected. This is a none exhaustive check.
    const hasEnterpriseNumberOfEmployees = isAcrisureReferral
      ? numberOfEmployees === '300+'
      : numberOfEmployees === '500+';


    const payrollProviderKey = getFormValue('payrollProviderKey');
    const timeManagementProviderKey = getFormValue('timeManagementProviderKey');

    const invalidAdpWfnCombo =
      payrollProviderKey === adpWfn && timeManagementProviderKey !== adpWfn;
    const invalidUkgReadyCombo =
      payrollProviderKey === ukgReady && timeManagementProviderKey !== ukgReady;

    if (hasEnterpriseNumberOfEmployees) {
      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizePageViewed,
        payload: {
          registrationForm: control._formValues,
        },
      });
      navigate('/onboarding/enterpriseProgram');
    } else if (
      payrollProviderKey === otherProvider ||
      (payrollProviderKey !== adpRun &&
        timeManagementProviderKey === otherProvider) ||
      invalidAdpWfnCombo ||
      invalidUkgReadyCombo
    ) {
      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderPageViewed,
        payload: {
          registrationForm: control._formValues,
        },
      });
      navigate('/onboarding/unsupportedPayrollProvider');
    } else {
      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.RegistrationFormValid,
        payload: {
          registrationForm: control._formValues,
        },
      });
      navigate(nextPath);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  const handleNextButtonInvalid = React.useCallback(async () => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.RegistrationFormNextButtonInvalid,
      payload: {
        registrationForm: control._formValues,
      },
    });
  }, [control._formValues]);

  useEffect(() => {
    if (state.onboarding.integrations) {
      const tmProvider = payrollToTmMapping[selectedPayrollProvider];
      setTMValues(
        Object.entries(state.onboarding.integrations)
          .filter(([, value]) =>
            value.integration_type.includes('time_and_attendance') && value.display_name === tmProvider
          )
          .map(([key, value]) => {
            return {
              id: key,
              label: t(`integration.names.${value.display_name}`),
            };
          })
          .concat(otherDropdownOption),
      );
      methods.setValue('timeManagementProviderKey', tmProvider);
      if (selectedPayrollProvider === otherProvider) {
        setShowOtherPayroll(true);
      }
      if (getFormValue('timeManagementProviderKey') === otherProvider) {
        setShowOtherTM(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.onboarding.integrations, selectedPayrollProvider, control._formValues.payrollProviderKey]);

  const handleHasSelectedOther = (val: boolean) => {
      setShowOtherPayroll(val);
      setShowOtherTM(false);
    };

  const handleBackButtonClick = (referrer: string) => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.RegistrationFormBackButton,
      payload: {
        registrationForm: control._formValues,
      },
    });
    const referrerURLs: { [key: string]: string } = {
      [acrisureReferral]: ENV_VARS.landingPageURL || LOGIN_PAGE_URL,
      default: LOGIN_PAGE_URL,
    }
    window.location.href = referrerURLs[referrer] || referrerURLs.default;
  }

  return (
    <FormProvider {...methods}>
      <FormFoundations.FormWrapperGrid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h3">
            {t('onboarding.registration.personalDetails.title')}
          </TextCL>
        </Grid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h5">
            {t('onboarding.registration.description')}
          </TextCL>
          <Divider sx={{ marginTop: '16px' }} />
        </Grid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h6">
            {t(
              'onboarding.registration.personalDetails.subHeading',
            ).toUpperCase()}
          </TextCL>
        </Grid>

        <Grid xs={12} md={6} lg={6} className="formField">
          <InputBoxCL
            control={control}
            fieldName="firstName"
            label={t(
              'onboarding.registration.personalDetails.firstName',
            ).toUpperCase()}
            datatestid={TEST_IDS.register.firstNameInput}
            addDivider
            replayEncrypt={true}
          />
        </Grid>

        <Grid xs={12} md={6} lg={6}>
          <InputBoxCL
            control={control}
            fieldName="lastName"
            label={t(
              'onboarding.registration.personalDetails.lastName',
            ).toUpperCase()}
            datatestid={TEST_IDS.register.lastNameInput}
            addDivider
            replayEncrypt={true}
          />
        </Grid>

        <Grid xs={12} md={6} lg={6}>
          <InputBoxCL
            disabled={disableFieldsForExistingUser}
            control={control}
            value={location.state?.email}
            fieldName="emailAddress"
            label={t(
              'onboarding.registration.personalDetails.emailAddress',
            ).toUpperCase()}
            type="email"
            datatestid={TEST_IDS.register.emailAddressInput}
            replayEncrypt={true}
          />
        </Grid>

        <Grid xs={12} md={12} lg={12}>
          <TextCL variant="h6">
            {t('onboarding.registration.company.title').toUpperCase()}
          </TextCL>
        </Grid>

        <Grid xs={12} md={6} lg={6}>
          <InputBoxCL
            control={control}
            fieldName="companyName"
            label={t('onboarding.registration.company.name').toUpperCase()}
            datatestid={TEST_IDS.register.companyNameInput}
            replayEncrypt={true}
          />
        </Grid>

        <Grid xs={12} md={6} lg={6}>
          <InputBoxCL
            control={control}
            fieldName="companyRole"
            label={t('onboarding.registration.company.role').toUpperCase()}
            datatestid={TEST_IDS.register.companyRoleInput}
          />
        </Grid>

        <Grid xs={12} md={6} lg={6}>
          <AutocompleteCL
            fieldName={'numberOfEmployees'}
            control={control}
            label={t(
              'onboarding.registration.company.numberOfEmployees',
            ).toUpperCase()}
            datatestid={TEST_IDS.register.companyCountDropdown}
            options={companyCountOptions}
            replayEncrypt={true}
          />
        </Grid>

        <Grid xs={12} md={12} lg={12}>
          <TextCL variant="h6">
            {t('onboarding.registration.serviceProviders.title').toUpperCase()}
          </TextCL>
        </Grid>

        <Grid xs={12} md={12} lg={6}>
          <PayrollProviderDropdown
            integrations={state.onboarding.integrations}
            setShowOtherPayroll={setShowOtherPayroll}
            setHasSelectedOther={handleHasSelectedOther}
          />
        </Grid>

        <Grid xs={12} md={12} lg={6}>
          {tmsValues.length > 0 && (
            <AutocompleteCL
              fieldName={'timeManagementProviderKey'}
              control={control}
              label={t(
                'onboarding.registration.serviceProviders.timeManagementProvider',
              ).toUpperCase()}
              datatestid={TEST_IDS.register.timeManagementProviderDropdown}
              options={tmsValues}
              hasSelectedOther={(val: boolean) => {
                setShowOtherTM(val);
              }}
              replayEncrypt={true}
              onChange={(newValue: string) => {
                if (newValue !== 'other') {
                  setShowOtherTM(false);
                }
              }}
            />
          )}
        </Grid>

        <Grid xs={12} md={12} lg={6}>
          {showOtherPayroll && (
            <InputBoxCL
              control={control}
              fieldName="payrollProviderNameOther"
              label={t(
                'onboarding.registration.serviceProviders.otherProvider',
              ).toUpperCase()}
            />
          )}
        </Grid>

        <Grid xs={12} md={12} lg={6}>
          {showOtherTM && (
            <InputBoxCL
              control={control}
              fieldName="timeManagementProviderNameOther"
              label={t(
                'onboarding.registration.serviceProviders.otherTM',
              ).toUpperCase()}
              datatestid={TEST_IDS.register.timeManagementProviderDropdown}
            />
          )}
        </Grid>
      </FormFoundations.FormWrapperGrid>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'flex-end',
          justifyContent: 'space-between',
          overflow: 'wrap',
          display: 'flex',
          gap: 1,
        }}
      >
        <ButtonCL
          color="neutral"
          sx={{
            color: Moolah.navy[60],
            width: 300,
            height: 48,
            fontSize: 15,
          }}
          onClick={() => handleBackButtonClick(referrer)}
          datatestid={TEST_IDS.register.backButton}
          text="Back"
        />

        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px', width: 300, height: 48, fontSize: 15 }}
          onClick={handleSubmit(handleConnectPayroll, handleNextButtonInvalid)}
          data-testid={TEST_IDS.register.nextButton}
        >
          Next
        </Button>
      </Box>
    </FormProvider>
  );
};

export default RegistrationForm;
