import * as React from 'react';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary, {
  accordionSummaryClasses,
} from '@mui/joy/AccordionSummary';
import { Button, Link } from '@mui/joy';
import { Horizon, Moolah } from '../../../../theme';
import { TextCL } from '../../../../components/library/TextCL';
import { RegistrationFormData } from '../../../../formData/formDataTypes';
import { AppContext } from '../../../../state/appContext';
import CheckboxCL from '../../../../components/library/CheckboxCL';
import { TEST_IDS } from '../../../../test-utils/test-ids';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  checkedEmployeesPageAction,
  checkedInvitesAction,
} from '../../../../state/onboarding/onboardingActions';
import { ProspectDataIndicators } from '../../../../state/onboarding/onboardingState';
import { iconLibrary } from '../../../../assets/icons/icon-library';
import useScreenQuery from '../../../../layouts/hooks/useScreenQuery';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../services/analytic-events/SelfServiceAnalyticsTypes';

type FormData = {
  employeesChecked: boolean;
  invitesChecked: boolean;
};

export default function ThingsToDo() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { state, dispatch } = React.useContext(AppContext);
  const prospect = state.onboarding.prospectData?.data as RegistrationFormData;
  const { control, setValue } = useForm<FormData>({});
  const [showTodo, setShowTodo] = React.useState<boolean>(true);
  const { isLargerScreen } = useScreenQuery();

  const prospectData = state.onboarding.prospectData
    ?.data as ProspectDataIndicators;

  React.useEffect(() => {
    if (prospectData.checkedEmployeesPage && prospectData.checkedInvites) {
      setShowTodo(false);
    }
  }, [prospectData.checkedEmployeesPage, prospectData.checkedInvites]);

  const handleChange = (isExpanded: boolean) => {
    setShowTodo(!isExpanded);
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.ThingsToDoShowHide,
      payload: {
        show: isExpanded,
      },
    });
  };

  return (
    <AccordionGroup
      transition="0.2s"
      sx={{
        paddingTop: '24px',
        maxWidth: '100%',
        flexGrow: 1,
        bgcolor: Horizon.info[2],
        color: Moolah.white,
        borderTopLeftRadius: '8px',
        borderTopRightRadius: '8px',
        [`& .${accordionSummaryClasses.button}`]: {
          borderRadius: '0px',
          color: Moolah.white,
          bgcolor: Moolah.navy[60],
        },
      }}
    >
      <Accordion expanded={showTodo} onChange={() => handleChange(showTodo)}>
        <div style={{ paddingLeft: '24px', paddingBottom: '8px' }}>
          <TextCL variant="h2Bold" color={Moolah.white}>
            {t('admin.dashboard.thingsToDo.title')}
          </TextCL>
        </div>

        <AccordionSummary indicator={false}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '8px',
              backgroundColor: Moolah.navy[60],
              width: '120%',
            }}
          >
            <div
              style={{
                paddingLeft: '16px',
              }}
            >
              <TextCL variant="caption" color={Moolah.white}>
                {t('admin.dashboard.thingsToDo.subtitle')}
              </TextCL>
            </div>
            <img
              width={20}
              src={
                showTodo
                  ? iconLibrary.common.chevronUpCircle
                  : iconLibrary.common.chevronDownCircle
              }
              alt="chevronInfoBox"
            ></img>
          </div>
        </AccordionSummary>
        <AccordionDetails variant="soft" sx={{ padding: '0px 24px 0px 24px' }}>
          <div
            style={{
              paddingTop: '12px ',
            }}
          >
            <TextCL variant="h5" replayEncrypt={true}>
              {t('admin.dashboard.thingsToDo.hello')}
              {`${prospect.firstName}`}
              {t('admin.dashboard.thingsToDo.welcome')}
            </TextCL>
          </div>
          <div
            style={{
              display: 'flex',
              padding: '12px 0px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                paddingRight: '8px',
              }}
            >
              <CheckboxCL
                control={control}
                fieldName="employeesChecked"
                value={prospectData.checkedEmployeesPage}
                datatestid={TEST_IDS.admin.thingsToDo.employeesChecked}
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('employeesChecked', e.target.checked);
                  await checkedEmployeesPageAction(dispatch, e.target.checked);
                }}
              />
            </div>
            {isLargerScreen && (
              <>
                <TextCL variant="h5">
                  {t('admin.dashboard.thingsToDo.employeesChecked')}
                </TextCL>
                <Button
                  variant="solid"
                  size="sm"
                  sx={{
                    padding: '0px',
                    backgroundColor: 'transparent',
                    '&:hover': {
                      backgroundColor: 'transparent',
                    },
                  }}
                  onClick={() => navigate('/admin/employees')}
                >
                  <Link sx={{ margin: '0px 4px' }} underline="always">
                    <TextCL variant="h5">
                      {t('admin.dashboard.thingsToDo.employeesCheckedLink')}
                    </TextCL>
                  </Link>
                </Button>
                <TextCL variant="h5">
                  {t('admin.dashboard.thingsToDo.employeesChecked2')}
                </TextCL>
              </>
            )}

            {!isLargerScreen && (
              <>
                <TextCL variant="h5">
                  {t('admin.dashboard.thingsToDo.employeesChecked')}{' '}
                  {t('admin.dashboard.thingsToDo.employeesCheckedLink')}{' '}
                  {t('admin.dashboard.thingsToDo.employeesChecked2')}
                </TextCL>
              </>
            )}
          </div>
          <div
            style={{
              display: 'flex',
              padding: '12px 0px',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                paddingRight: '8px',
              }}
            >
              <CheckboxCL
                control={control}
                fieldName="invitesChecked"
                value={prospectData.checkedInvites}
                datatestid={TEST_IDS.admin.thingsToDo.letMeKnowCheckbox}
                onChange={async (e: React.ChangeEvent<HTMLInputElement>) => {
                  setValue('invitesChecked', e.target.checked);
                  await checkedInvitesAction(dispatch, e.target.checked);
                }}
              />
            </div>
            <TextCL variant="h5">
              {t('admin.dashboard.thingsToDo.invitesChecked')}
            </TextCL>
          </div>
        </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  );
}
