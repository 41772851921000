import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never;
    };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: any; output: any };
  Dimension: { input: any; output: any };
  HexColor: { input: any; output: any };
  JSON: { input: any; output: any };
  Quality: { input: any; output: any };
  _Any: { input: any; output: any };
  _FieldSet: { input: any; output: any };
};

/** Represents a binary file in a space. An asset can be any file type. */
export type Asset = {
  __typename?: 'Asset';
  contentType?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  fileName?: Maybe<Scalars['String']['output']>;
  height?: Maybe<Scalars['Int']['output']>;
  linkedFrom?: Maybe<AssetLinkingCollections>;
  size?: Maybe<Scalars['Int']['output']>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
  width?: Maybe<Scalars['Int']['output']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetContentTypeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetFileNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetHeightArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetSizeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  transform?: InputMaybe<ImageTransformOptions>;
};

/** Represents a binary file in a space. An asset can be any file type. */
export type AssetWidthArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type AssetCollection = {
  __typename?: 'AssetCollection';
  items: Array<Maybe<Asset>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type AssetFilter = {
  AND?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<AssetFilter>>>;
  contentType?: InputMaybe<Scalars['String']['input']>;
  contentType_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_exists?: InputMaybe<Scalars['Boolean']['input']>;
  contentType_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentType_not?: InputMaybe<Scalars['String']['input']>;
  contentType_not_contains?: InputMaybe<Scalars['String']['input']>;
  contentType_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  fileName?: InputMaybe<Scalars['String']['input']>;
  fileName_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  fileName_not?: InputMaybe<Scalars['String']['input']>;
  fileName_not_contains?: InputMaybe<Scalars['String']['input']>;
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  height?: InputMaybe<Scalars['Int']['input']>;
  height_exists?: InputMaybe<Scalars['Boolean']['input']>;
  height_gt?: InputMaybe<Scalars['Int']['input']>;
  height_gte?: InputMaybe<Scalars['Int']['input']>;
  height_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  height_lt?: InputMaybe<Scalars['Int']['input']>;
  height_lte?: InputMaybe<Scalars['Int']['input']>;
  height_not?: InputMaybe<Scalars['Int']['input']>;
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size?: InputMaybe<Scalars['Int']['input']>;
  size_exists?: InputMaybe<Scalars['Boolean']['input']>;
  size_gt?: InputMaybe<Scalars['Int']['input']>;
  size_gte?: InputMaybe<Scalars['Int']['input']>;
  size_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  size_lt?: InputMaybe<Scalars['Int']['input']>;
  size_lte?: InputMaybe<Scalars['Int']['input']>;
  size_not?: InputMaybe<Scalars['Int']['input']>;
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url?: InputMaybe<Scalars['String']['input']>;
  url_contains?: InputMaybe<Scalars['String']['input']>;
  url_exists?: InputMaybe<Scalars['Boolean']['input']>;
  url_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  url_not?: InputMaybe<Scalars['String']['input']>;
  url_not_contains?: InputMaybe<Scalars['String']['input']>;
  url_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  width?: InputMaybe<Scalars['Int']['input']>;
  width_exists?: InputMaybe<Scalars['Boolean']['input']>;
  width_gt?: InputMaybe<Scalars['Int']['input']>;
  width_gte?: InputMaybe<Scalars['Int']['input']>;
  width_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  width_lt?: InputMaybe<Scalars['Int']['input']>;
  width_lte?: InputMaybe<Scalars['Int']['input']>;
  width_not?: InputMaybe<Scalars['Int']['input']>;
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type AssetLinkingCollections = {
  __typename?: 'AssetLinkingCollections';
  benefitCollection?: Maybe<BenefitCollection>;
  entryCollection?: Maybe<EntryCollection>;
  helpDocumentCollection?: Maybe<HelpDocumentCollection>;
};

export type AssetLinkingCollectionsBenefitCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export type AssetLinkingCollectionsHelpDocumentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum AssetOrder {
  ContentTypeAsc = 'contentType_ASC',
  ContentTypeDesc = 'contentType_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  UrlAsc = 'url_ASC',
  UrlDesc = 'url_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
}

/** DailyPay benefit [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/benefit) */
export type Benefit = Entry & {
  __typename?: 'Benefit';
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<BenefitLinkingCollections>;
  mainImage?: Maybe<Asset>;
  paragraphText?: Maybe<Scalars['String']['output']>;
  summaryText?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  title?: Maybe<BenefitTitle>;
};

/** DailyPay benefit [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/benefit) */
export type BenefitLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** DailyPay benefit [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/benefit) */
export type BenefitMainImageArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** DailyPay benefit [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/benefit) */
export type BenefitParagraphTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** DailyPay benefit [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/benefit) */
export type BenefitSummaryTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** DailyPay benefit [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/benefit) */
export type BenefitTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type BenefitCollection = {
  __typename?: 'BenefitCollection';
  items: Array<Maybe<Benefit>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type BenefitFilter = {
  AND?: InputMaybe<Array<InputMaybe<BenefitFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BenefitFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  mainImage_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraphText?: InputMaybe<Scalars['String']['input']>;
  paragraphText_contains?: InputMaybe<Scalars['String']['input']>;
  paragraphText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  paragraphText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  paragraphText_not?: InputMaybe<Scalars['String']['input']>;
  paragraphText_not_contains?: InputMaybe<Scalars['String']['input']>;
  paragraphText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  summaryText?: InputMaybe<Scalars['String']['input']>;
  summaryText_contains?: InputMaybe<Scalars['String']['input']>;
  summaryText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  summaryText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  summaryText_not?: InputMaybe<Scalars['String']['input']>;
  summaryText_not_contains?: InputMaybe<Scalars['String']['input']>;
  summaryText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  sys?: InputMaybe<SysFilter>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
};

export type BenefitLinkingCollections = {
  __typename?: 'BenefitLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type BenefitLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum BenefitOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

export type BenefitTitle = {
  __typename?: 'BenefitTitle';
  json: Scalars['JSON']['output'];
  links: BenefitTitleLinks;
};

export type BenefitTitleAssets = {
  __typename?: 'BenefitTitleAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type BenefitTitleEntries = {
  __typename?: 'BenefitTitleEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type BenefitTitleLinks = {
  __typename?: 'BenefitTitleLinks';
  assets: BenefitTitleAssets;
  entries: BenefitTitleEntries;
  resources: BenefitTitleResources;
};

export type BenefitTitleResources = {
  __typename?: 'BenefitTitleResources';
  block: Array<BenefitTitleResourcesBlock>;
  hyperlink: Array<BenefitTitleResourcesHyperlink>;
  inline: Array<BenefitTitleResourcesInline>;
};

export type BenefitTitleResourcesBlock = ResourceLink & {
  __typename?: 'BenefitTitleResourcesBlock';
  sys: ResourceSys;
};

export type BenefitTitleResourcesHyperlink = ResourceLink & {
  __typename?: 'BenefitTitleResourcesHyperlink';
  sys: ResourceSys;
};

export type BenefitTitleResourcesInline = ResourceLink & {
  __typename?: 'BenefitTitleResourcesInline';
  sys: ResourceSys;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButton = Entry & {
  __typename?: 'CardWithButton';
  buttonText?: Maybe<Scalars['String']['output']>;
  buttonUrl?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  description?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<CardWithButtonLinkingCollections>;
  location?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButtonButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButtonButtonUrlArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButtonDescriptionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButtonLocationArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Card component that displays a title, description and a button to link to somewhere [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/cardWithButton) */
export type CardWithButtonTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type CardWithButtonCollection = {
  __typename?: 'CardWithButtonCollection';
  items: Array<Maybe<CardWithButton>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type CardWithButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<CardWithButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CardWithButtonFilter>>>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  buttonText_contains?: InputMaybe<Scalars['String']['input']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonText_not?: InputMaybe<Scalars['String']['input']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonUrl?: InputMaybe<Scalars['String']['input']>;
  buttonUrl_contains?: InputMaybe<Scalars['String']['input']>;
  buttonUrl_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonUrl_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonUrl_not?: InputMaybe<Scalars['String']['input']>;
  buttonUrl_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  description?: InputMaybe<Scalars['String']['input']>;
  description_contains?: InputMaybe<Scalars['String']['input']>;
  description_exists?: InputMaybe<Scalars['Boolean']['input']>;
  description_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  description_not?: InputMaybe<Scalars['String']['input']>;
  description_not_contains?: InputMaybe<Scalars['String']['input']>;
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  location?: InputMaybe<Scalars['String']['input']>;
  location_contains?: InputMaybe<Scalars['String']['input']>;
  location_exists?: InputMaybe<Scalars['Boolean']['input']>;
  location_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  location_not?: InputMaybe<Scalars['String']['input']>;
  location_not_contains?: InputMaybe<Scalars['String']['input']>;
  location_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type CardWithButtonLinkingCollections = {
  __typename?: 'CardWithButtonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type CardWithButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum CardWithButtonOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ButtonUrlAsc = 'buttonUrl_ASC',
  ButtonUrlDesc = 'buttonUrl_DESC',
  LocationAsc = 'location_ASC',
  LocationDesc = 'location_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type ContentfulMetadata = {
  __typename?: 'ContentfulMetadata';
  tags: Array<Maybe<ContentfulTag>>;
};

export type ContentfulMetadataFilter = {
  tags?: InputMaybe<ContentfulMetadataTagsFilter>;
  tags_exists?: InputMaybe<Scalars['Boolean']['input']>;
};

export type ContentfulMetadataTagsFilter = {
  id_contains_all?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_none?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_contains_some?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/**
 * Represents a tag entity for finding and organizing content easily.
 *     Find out more here: https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/content-tags
 */
export type ContentfulTag = {
  __typename?: 'ContentfulTag';
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type Entry = {
  contentfulMetadata: ContentfulMetadata;
  sys: Sys;
};

export type EntryCollection = {
  __typename?: 'EntryCollection';
  items: Array<Maybe<Entry>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type EntryFilter = {
  AND?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<EntryFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
};

export enum EntryOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
}

/** Accordion help box [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpAccordion) */
export type HelpAccordion = Entry & {
  __typename?: 'HelpAccordion';
  contentfulMetadata: ContentfulMetadata;
  helpBody?: Maybe<HelpAccordionHelpBody>;
  linkedFrom?: Maybe<HelpAccordionLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
};

/** Accordion help box [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpAccordion) */
export type HelpAccordionHelpBodyArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** Accordion help box [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpAccordion) */
export type HelpAccordionLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Accordion help box [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpAccordion) */
export type HelpAccordionTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HelpAccordionCollection = {
  __typename?: 'HelpAccordionCollection';
  items: Array<Maybe<HelpAccordion>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HelpAccordionFilter = {
  AND?: InputMaybe<Array<InputMaybe<HelpAccordionFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HelpAccordionFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  helpBody_contains?: InputMaybe<Scalars['String']['input']>;
  helpBody_exists?: InputMaybe<Scalars['Boolean']['input']>;
  helpBody_not_contains?: InputMaybe<Scalars['String']['input']>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HelpAccordionHelpBody = {
  __typename?: 'HelpAccordionHelpBody';
  json: Scalars['JSON']['output'];
  links: HelpAccordionHelpBodyLinks;
};

export type HelpAccordionHelpBodyAssets = {
  __typename?: 'HelpAccordionHelpBodyAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type HelpAccordionHelpBodyEntries = {
  __typename?: 'HelpAccordionHelpBodyEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type HelpAccordionHelpBodyLinks = {
  __typename?: 'HelpAccordionHelpBodyLinks';
  assets: HelpAccordionHelpBodyAssets;
  entries: HelpAccordionHelpBodyEntries;
  resources: HelpAccordionHelpBodyResources;
};

export type HelpAccordionHelpBodyResources = {
  __typename?: 'HelpAccordionHelpBodyResources';
  block: Array<HelpAccordionHelpBodyResourcesBlock>;
  hyperlink: Array<HelpAccordionHelpBodyResourcesHyperlink>;
  inline: Array<HelpAccordionHelpBodyResourcesInline>;
};

export type HelpAccordionHelpBodyResourcesBlock = ResourceLink & {
  __typename?: 'HelpAccordionHelpBodyResourcesBlock';
  sys: ResourceSys;
};

export type HelpAccordionHelpBodyResourcesHyperlink = ResourceLink & {
  __typename?: 'HelpAccordionHelpBodyResourcesHyperlink';
  sys: ResourceSys;
};

export type HelpAccordionHelpBodyResourcesInline = ResourceLink & {
  __typename?: 'HelpAccordionHelpBodyResourcesInline';
  sys: ResourceSys;
};

export type HelpAccordionLinkingCollections = {
  __typename?: 'HelpAccordionLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HelpAccordionLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HelpAccordionOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpDocument) */
export type HelpDocument = Entry & {
  __typename?: 'HelpDocument';
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<HelpDocumentLinkingCollections>;
  pdfFile?: Maybe<Asset>;
  provider?: Maybe<Scalars['String']['output']>;
  purpose?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  version?: Maybe<Scalars['Int']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpDocument) */
export type HelpDocumentLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpDocument) */
export type HelpDocumentPdfFileArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpDocument) */
export type HelpDocumentProviderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpDocument) */
export type HelpDocumentPurposeArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpDocument) */
export type HelpDocumentVersionArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HelpDocumentCollection = {
  __typename?: 'HelpDocumentCollection';
  items: Array<Maybe<HelpDocument>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HelpDocumentFilter = {
  AND?: InputMaybe<Array<InputMaybe<HelpDocumentFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HelpDocumentFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  pdfFile_exists?: InputMaybe<Scalars['Boolean']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_exists?: InputMaybe<Scalars['Boolean']['input']>;
  provider_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  purpose?: InputMaybe<Scalars['String']['input']>;
  purpose_contains?: InputMaybe<Scalars['String']['input']>;
  purpose_exists?: InputMaybe<Scalars['Boolean']['input']>;
  purpose_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  purpose_not?: InputMaybe<Scalars['String']['input']>;
  purpose_not_contains?: InputMaybe<Scalars['String']['input']>;
  purpose_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  version?: InputMaybe<Scalars['Int']['input']>;
  version_exists?: InputMaybe<Scalars['Boolean']['input']>;
  version_gt?: InputMaybe<Scalars['Int']['input']>;
  version_gte?: InputMaybe<Scalars['Int']['input']>;
  version_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  version_lt?: InputMaybe<Scalars['Int']['input']>;
  version_lte?: InputMaybe<Scalars['Int']['input']>;
  version_not?: InputMaybe<Scalars['Int']['input']>;
  version_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type HelpDocumentLinkingCollections = {
  __typename?: 'HelpDocumentLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HelpDocumentLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HelpDocumentOrder {
  ProviderAsc = 'provider_ASC',
  ProviderDesc = 'provider_DESC',
  PurposeAsc = 'purpose_ASC',
  PurposeDesc = 'purpose_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  VersionAsc = 'version_ASC',
  VersionDesc = 'version_DESC',
}

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopic = Entry & {
  __typename?: 'HelpTopic';
  contentfulMetadata: ContentfulMetadata;
  help?: Maybe<HelpTopicHelp>;
  internalName?: Maybe<Scalars['String']['output']>;
  linkedFrom?: Maybe<HelpTopicLinkingCollections>;
  provider?: Maybe<Scalars['String']['output']>;
  subject?: Maybe<Scalars['String']['output']>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopicHelpArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopicInternalNameArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopicLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopicProviderArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopicSubjectArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/helpTopic) */
export type HelpTopicTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type HelpTopicCollection = {
  __typename?: 'HelpTopicCollection';
  items: Array<Maybe<HelpTopic>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type HelpTopicFilter = {
  AND?: InputMaybe<Array<InputMaybe<HelpTopicFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<HelpTopicFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  help_contains?: InputMaybe<Scalars['String']['input']>;
  help_exists?: InputMaybe<Scalars['Boolean']['input']>;
  help_not_contains?: InputMaybe<Scalars['String']['input']>;
  internalName?: InputMaybe<Scalars['String']['input']>;
  internalName_contains?: InputMaybe<Scalars['String']['input']>;
  internalName_exists?: InputMaybe<Scalars['Boolean']['input']>;
  internalName_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  internalName_not?: InputMaybe<Scalars['String']['input']>;
  internalName_not_contains?: InputMaybe<Scalars['String']['input']>;
  internalName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_contains?: InputMaybe<Scalars['String']['input']>;
  provider_exists?: InputMaybe<Scalars['Boolean']['input']>;
  provider_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  provider_not?: InputMaybe<Scalars['String']['input']>;
  provider_not_contains?: InputMaybe<Scalars['String']['input']>;
  provider_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subject?: InputMaybe<Scalars['String']['input']>;
  subject_contains?: InputMaybe<Scalars['String']['input']>;
  subject_exists?: InputMaybe<Scalars['Boolean']['input']>;
  subject_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  subject_not?: InputMaybe<Scalars['String']['input']>;
  subject_not_contains?: InputMaybe<Scalars['String']['input']>;
  subject_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type HelpTopicHelp = {
  __typename?: 'HelpTopicHelp';
  json: Scalars['JSON']['output'];
  links: HelpTopicHelpLinks;
};

export type HelpTopicHelpAssets = {
  __typename?: 'HelpTopicHelpAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type HelpTopicHelpEntries = {
  __typename?: 'HelpTopicHelpEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type HelpTopicHelpLinks = {
  __typename?: 'HelpTopicHelpLinks';
  assets: HelpTopicHelpAssets;
  entries: HelpTopicHelpEntries;
  resources: HelpTopicHelpResources;
};

export type HelpTopicHelpResources = {
  __typename?: 'HelpTopicHelpResources';
  block: Array<HelpTopicHelpResourcesBlock>;
  hyperlink: Array<HelpTopicHelpResourcesHyperlink>;
  inline: Array<HelpTopicHelpResourcesInline>;
};

export type HelpTopicHelpResourcesBlock = ResourceLink & {
  __typename?: 'HelpTopicHelpResourcesBlock';
  sys: ResourceSys;
};

export type HelpTopicHelpResourcesHyperlink = ResourceLink & {
  __typename?: 'HelpTopicHelpResourcesHyperlink';
  sys: ResourceSys;
};

export type HelpTopicHelpResourcesInline = ResourceLink & {
  __typename?: 'HelpTopicHelpResourcesInline';
  sys: ResourceSys;
};

export type HelpTopicLinkingCollections = {
  __typename?: 'HelpTopicLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type HelpTopicLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum HelpTopicOrder {
  InternalNameAsc = 'internalName_ASC',
  InternalNameDesc = 'internalName_DESC',
  ProviderAsc = 'provider_ASC',
  ProviderDesc = 'provider_DESC',
  SubjectAsc = 'subject_ASC',
  SubjectDesc = 'subject_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export enum ImageFormat {
  Avif = 'AVIF',
  /** JPG image format. */
  Jpg = 'JPG',
  /**
   * Progressive JPG format stores multiple passes of an image in progressively higher detail.
   *         When a progressive image is loading, the viewer will first see a lower quality pixelated version which
   *         will gradually improve in detail, until the image is fully downloaded. This is to display an image as
   *         early as possible to make the layout look as designed.
   */
  JpgProgressive = 'JPG_PROGRESSIVE',
  /** PNG image format */
  Png = 'PNG',
  /**
   * 8-bit PNG images support up to 256 colors and weigh less than the standard 24-bit PNG equivalent.
   *         The 8-bit PNG format is mostly used for simple images, such as icons or logos.
   */
  Png8 = 'PNG8',
  /** WebP image format. */
  Webp = 'WEBP',
}

export enum ImageResizeFocus {
  /** Focus the resizing on the bottom. */
  Bottom = 'BOTTOM',
  /** Focus the resizing on the bottom left. */
  BottomLeft = 'BOTTOM_LEFT',
  /** Focus the resizing on the bottom right. */
  BottomRight = 'BOTTOM_RIGHT',
  /** Focus the resizing on the center. */
  Center = 'CENTER',
  /** Focus the resizing on the largest face. */
  Face = 'FACE',
  /** Focus the resizing on the area containing all the faces. */
  Faces = 'FACES',
  /** Focus the resizing on the left. */
  Left = 'LEFT',
  /** Focus the resizing on the right. */
  Right = 'RIGHT',
  /** Focus the resizing on the top. */
  Top = 'TOP',
  /** Focus the resizing on the top left. */
  TopLeft = 'TOP_LEFT',
  /** Focus the resizing on the top right. */
  TopRight = 'TOP_RIGHT',
}

export enum ImageResizeStrategy {
  /** Crops a part of the original image to fit into the specified dimensions. */
  Crop = 'CROP',
  /** Resizes the image to the specified dimensions, cropping the image if needed. */
  Fill = 'FILL',
  /** Resizes the image to fit into the specified dimensions. */
  Fit = 'FIT',
  /**
   * Resizes the image to the specified dimensions, padding the image if needed.
   *         Uses desired background color as padding color.
   */
  Pad = 'PAD',
  /** Resizes the image to the specified dimensions, changing the original aspect ratio if needed. */
  Scale = 'SCALE',
  /** Creates a thumbnail from the image. */
  Thumb = 'THUMB',
}

export type ImageTransformOptions = {
  /**
   * Desired background color, used with corner radius or `PAD` resize strategy.
   *         Defaults to transparent (for `PNG`, `PNG8` and `WEBP`) or white (for `JPG` and `JPG_PROGRESSIVE`).
   */
  backgroundColor?: InputMaybe<Scalars['HexColor']['input']>;
  /**
   * Desired corner radius in pixels.
   *         Results in an image with rounded corners (pass `-1` for a full circle/ellipse).
   *         Defaults to `0`. Uses desired background color as padding color,
   *         unless the format is `JPG` or `JPG_PROGRESSIVE` and resize strategy is `PAD`, then defaults to white.
   */
  cornerRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Desired image format. Defaults to the original image format. */
  format?: InputMaybe<ImageFormat>;
  /** Desired height in pixels. Defaults to the original image height. */
  height?: InputMaybe<Scalars['Dimension']['input']>;
  /**
   * Desired quality of the image in percents.
   *         Used for `PNG8`, `JPG`, `JPG_PROGRESSIVE` and `WEBP` formats.
   */
  quality?: InputMaybe<Scalars['Quality']['input']>;
  /** Desired resize focus area. Defaults to `CENTER`. */
  resizeFocus?: InputMaybe<ImageResizeFocus>;
  /** Desired resize strategy. Defaults to `FIT`. */
  resizeStrategy?: InputMaybe<ImageResizeStrategy>;
  /** Desired width in pixels. Defaults to the original image width. */
  width?: InputMaybe<Scalars['Dimension']['input']>;
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _node?: Maybe<_Node>;
  _service: _Service;
  asset?: Maybe<Asset>;
  assetCollection?: Maybe<AssetCollection>;
  benefit?: Maybe<Benefit>;
  benefitCollection?: Maybe<BenefitCollection>;
  cardWithButton?: Maybe<CardWithButton>;
  cardWithButtonCollection?: Maybe<CardWithButtonCollection>;
  entryCollection?: Maybe<EntryCollection>;
  helpAccordion?: Maybe<HelpAccordion>;
  helpAccordionCollection?: Maybe<HelpAccordionCollection>;
  helpDocument?: Maybe<HelpDocument>;
  helpDocumentCollection?: Maybe<HelpDocumentCollection>;
  helpTopic?: Maybe<HelpTopic>;
  helpTopicCollection?: Maybe<HelpTopicCollection>;
  termsOfUse?: Maybe<TermsOfUse>;
  termsOfUseCollection?: Maybe<TermsOfUseCollection>;
  textFieldWithButton?: Maybe<TextFieldWithButton>;
  textFieldWithButtonCollection?: Maybe<TextFieldWithButtonCollection>;
};

export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']['input']>;
};

export type Query_NodeArgs = {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssetArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryAssetCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<AssetOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<AssetFilter>;
};

export type QueryBenefitArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryBenefitCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<BenefitOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<BenefitFilter>;
};

export type QueryCardWithButtonArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryCardWithButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<CardWithButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<CardWithButtonFilter>;
};

export type QueryEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<EntryOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<EntryFilter>;
};

export type QueryHelpAccordionArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHelpAccordionCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HelpAccordionOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HelpAccordionFilter>;
};

export type QueryHelpDocumentArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHelpDocumentCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HelpDocumentOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HelpDocumentFilter>;
};

export type QueryHelpTopicArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryHelpTopicCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<HelpTopicOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<HelpTopicFilter>;
};

export type QueryTermsOfUseArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTermsOfUseCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TermsOfUseOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TermsOfUseFilter>;
};

export type QueryTextFieldWithButtonArgs = {
  id: Scalars['String']['input'];
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
};

export type QueryTextFieldWithButtonCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  order?: InputMaybe<Array<InputMaybe<TextFieldWithButtonOrder>>>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<TextFieldWithButtonFilter>;
};

export type ResourceLink = {
  sys: ResourceSys;
};

export type ResourceSys = {
  __typename?: 'ResourceSys';
  linkType: Scalars['String']['output'];
  urn: Scalars['String']['output'];
};

export type Sys = {
  __typename?: 'Sys';
  environmentId: Scalars['String']['output'];
  firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  /** The locale that was requested - mainly used for Apollo Federation. */
  locale?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  publishedVersion?: Maybe<Scalars['Int']['output']>;
  spaceId: Scalars['String']['output'];
};

export type SysFilter = {
  firstPublishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  firstPublishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  firstPublishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  firstPublishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  id?: InputMaybe<Scalars['String']['input']>;
  id_contains?: InputMaybe<Scalars['String']['input']>;
  id_exists?: InputMaybe<Scalars['Boolean']['input']>;
  id_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id_not?: InputMaybe<Scalars['String']['input']>;
  id_not_contains?: InputMaybe<Scalars['String']['input']>;
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt_gt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_gte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  publishedAt_lt?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_lte?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not?: InputMaybe<Scalars['DateTime']['input']>;
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['DateTime']['input']>>
  >;
  publishedVersion?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_exists?: InputMaybe<Scalars['Boolean']['input']>;
  publishedVersion_gt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_gte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
  publishedVersion_lt?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_lte?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not?: InputMaybe<Scalars['Float']['input']>;
  publishedVersion_not_in?: InputMaybe<
    Array<InputMaybe<Scalars['Float']['input']>>
  >;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUse = Entry & {
  __typename?: 'TermsOfUse';
  contentfulMetadata: ContentfulMetadata;
  help?: Maybe<TermsOfUseHelp>;
  linkedFrom?: Maybe<TermsOfUseLinkingCollections>;
  status?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  sys: Sys;
  terms?: Maybe<TermsOfUseTerms>;
  title?: Maybe<Scalars['String']['output']>;
  versions?: Maybe<Scalars['Int']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUseHelpArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUseLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUseStatusArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUseTermsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUseTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/termsOfUse) */
export type TermsOfUseVersionsArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TermsOfUseCollection = {
  __typename?: 'TermsOfUseCollection';
  items: Array<Maybe<TermsOfUse>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TermsOfUseFilter = {
  AND?: InputMaybe<Array<InputMaybe<TermsOfUseFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TermsOfUseFilter>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  help_contains?: InputMaybe<Scalars['String']['input']>;
  help_exists?: InputMaybe<Scalars['Boolean']['input']>;
  help_not_contains?: InputMaybe<Scalars['String']['input']>;
  status_contains_all?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  status_contains_none?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  status_contains_some?: InputMaybe<
    Array<InputMaybe<Scalars['String']['input']>>
  >;
  status_exists?: InputMaybe<Scalars['Boolean']['input']>;
  sys?: InputMaybe<SysFilter>;
  terms_contains?: InputMaybe<Scalars['String']['input']>;
  terms_exists?: InputMaybe<Scalars['Boolean']['input']>;
  terms_not_contains?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  versions?: InputMaybe<Scalars['Int']['input']>;
  versions_exists?: InputMaybe<Scalars['Boolean']['input']>;
  versions_gt?: InputMaybe<Scalars['Int']['input']>;
  versions_gte?: InputMaybe<Scalars['Int']['input']>;
  versions_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  versions_lt?: InputMaybe<Scalars['Int']['input']>;
  versions_lte?: InputMaybe<Scalars['Int']['input']>;
  versions_not?: InputMaybe<Scalars['Int']['input']>;
  versions_not_in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
};

export type TermsOfUseHelp = {
  __typename?: 'TermsOfUseHelp';
  json: Scalars['JSON']['output'];
  links: TermsOfUseHelpLinks;
};

export type TermsOfUseHelpAssets = {
  __typename?: 'TermsOfUseHelpAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TermsOfUseHelpEntries = {
  __typename?: 'TermsOfUseHelpEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TermsOfUseHelpLinks = {
  __typename?: 'TermsOfUseHelpLinks';
  assets: TermsOfUseHelpAssets;
  entries: TermsOfUseHelpEntries;
  resources: TermsOfUseHelpResources;
};

export type TermsOfUseHelpResources = {
  __typename?: 'TermsOfUseHelpResources';
  block: Array<TermsOfUseHelpResourcesBlock>;
  hyperlink: Array<TermsOfUseHelpResourcesHyperlink>;
  inline: Array<TermsOfUseHelpResourcesInline>;
};

export type TermsOfUseHelpResourcesBlock = ResourceLink & {
  __typename?: 'TermsOfUseHelpResourcesBlock';
  sys: ResourceSys;
};

export type TermsOfUseHelpResourcesHyperlink = ResourceLink & {
  __typename?: 'TermsOfUseHelpResourcesHyperlink';
  sys: ResourceSys;
};

export type TermsOfUseHelpResourcesInline = ResourceLink & {
  __typename?: 'TermsOfUseHelpResourcesInline';
  sys: ResourceSys;
};

export type TermsOfUseLinkingCollections = {
  __typename?: 'TermsOfUseLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TermsOfUseLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TermsOfUseOrder {
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  VersionsAsc = 'versions_ASC',
  VersionsDesc = 'versions_DESC',
}

export type TermsOfUseTerms = {
  __typename?: 'TermsOfUseTerms';
  json: Scalars['JSON']['output'];
  links: TermsOfUseTermsLinks;
};

export type TermsOfUseTermsAssets = {
  __typename?: 'TermsOfUseTermsAssets';
  block: Array<Maybe<Asset>>;
  hyperlink: Array<Maybe<Asset>>;
};

export type TermsOfUseTermsEntries = {
  __typename?: 'TermsOfUseTermsEntries';
  block: Array<Maybe<Entry>>;
  hyperlink: Array<Maybe<Entry>>;
  inline: Array<Maybe<Entry>>;
};

export type TermsOfUseTermsLinks = {
  __typename?: 'TermsOfUseTermsLinks';
  assets: TermsOfUseTermsAssets;
  entries: TermsOfUseTermsEntries;
  resources: TermsOfUseTermsResources;
};

export type TermsOfUseTermsResources = {
  __typename?: 'TermsOfUseTermsResources';
  block: Array<TermsOfUseTermsResourcesBlock>;
  hyperlink: Array<TermsOfUseTermsResourcesHyperlink>;
  inline: Array<TermsOfUseTermsResourcesInline>;
};

export type TermsOfUseTermsResourcesBlock = ResourceLink & {
  __typename?: 'TermsOfUseTermsResourcesBlock';
  sys: ResourceSys;
};

export type TermsOfUseTermsResourcesHyperlink = ResourceLink & {
  __typename?: 'TermsOfUseTermsResourcesHyperlink';
  sys: ResourceSys;
};

export type TermsOfUseTermsResourcesInline = ResourceLink & {
  __typename?: 'TermsOfUseTermsResourcesInline';
  sys: ResourceSys;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/textFieldWithButton) */
export type TextFieldWithButton = Entry & {
  __typename?: 'TextFieldWithButton';
  buttonText?: Maybe<Scalars['String']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  contentfulMetadata: ContentfulMetadata;
  linkedFrom?: Maybe<TextFieldWithButtonLinkingCollections>;
  sys: Sys;
  title?: Maybe<Scalars['String']['output']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/textFieldWithButton) */
export type TextFieldWithButtonButtonTextArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/textFieldWithButton) */
export type TextFieldWithButtonContentArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/textFieldWithButton) */
export type TextFieldWithButtonLinkedFromArgs = {
  allowedLocales?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** [See type definition](https://app.contentful.com/spaces/az32j799teoy/content_types/textFieldWithButton) */
export type TextFieldWithButtonTitleArgs = {
  locale?: InputMaybe<Scalars['String']['input']>;
};

export type TextFieldWithButtonCollection = {
  __typename?: 'TextFieldWithButtonCollection';
  items: Array<Maybe<TextFieldWithButton>>;
  limit: Scalars['Int']['output'];
  skip: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

export type TextFieldWithButtonFilter = {
  AND?: InputMaybe<Array<InputMaybe<TextFieldWithButtonFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TextFieldWithButtonFilter>>>;
  buttonText?: InputMaybe<Scalars['String']['input']>;
  buttonText_contains?: InputMaybe<Scalars['String']['input']>;
  buttonText_exists?: InputMaybe<Scalars['Boolean']['input']>;
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  buttonText_not?: InputMaybe<Scalars['String']['input']>;
  buttonText_not_contains?: InputMaybe<Scalars['String']['input']>;
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content?: InputMaybe<Scalars['String']['input']>;
  content_contains?: InputMaybe<Scalars['String']['input']>;
  content_exists?: InputMaybe<Scalars['Boolean']['input']>;
  content_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  content_not?: InputMaybe<Scalars['String']['input']>;
  content_not_contains?: InputMaybe<Scalars['String']['input']>;
  content_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contentfulMetadata?: InputMaybe<ContentfulMetadataFilter>;
  sys?: InputMaybe<SysFilter>;
  title?: InputMaybe<Scalars['String']['input']>;
  title_contains?: InputMaybe<Scalars['String']['input']>;
  title_exists?: InputMaybe<Scalars['Boolean']['input']>;
  title_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  title_not?: InputMaybe<Scalars['String']['input']>;
  title_not_contains?: InputMaybe<Scalars['String']['input']>;
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type TextFieldWithButtonLinkingCollections = {
  __typename?: 'TextFieldWithButtonLinkingCollections';
  entryCollection?: Maybe<EntryCollection>;
};

export type TextFieldWithButtonLinkingCollectionsEntryCollectionArgs = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  preview?: InputMaybe<Scalars['Boolean']['input']>;
  skip?: InputMaybe<Scalars['Int']['input']>;
};

export enum TextFieldWithButtonOrder {
  ButtonTextAsc = 'buttonText_ASC',
  ButtonTextDesc = 'buttonText_DESC',
  ContentAsc = 'content_ASC',
  ContentDesc = 'content_DESC',
  SysFirstPublishedAtAsc = 'sys_firstPublishedAt_ASC',
  SysFirstPublishedAtDesc = 'sys_firstPublishedAt_DESC',
  SysIdAsc = 'sys_id_ASC',
  SysIdDesc = 'sys_id_DESC',
  SysPublishedAtAsc = 'sys_publishedAt_ASC',
  SysPublishedAtDesc = 'sys_publishedAt_DESC',
  SysPublishedVersionAsc = 'sys_publishedVersion_ASC',
  SysPublishedVersionDesc = 'sys_publishedVersion_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type _Entity =
  | Benefit
  | CardWithButton
  | HelpAccordion
  | HelpDocument
  | HelpTopic
  | TermsOfUse
  | TextFieldWithButton;

export type _Node = {
  _id: Scalars['ID']['output'];
};

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};

export type GetCardWithButtonQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetCardWithButtonQuery = {
  __typename?: 'Query';
  cardWithButtonCollection?: {
    __typename?: 'CardWithButtonCollection';
    items: Array<{
      __typename?: 'CardWithButton';
      title?: string | null;
      description?: string | null;
      buttonText?: string | null;
      buttonUrl?: string | null;
      location?: string | null;
    } | null>;
  } | null;
};

export type GetHelpTopicsQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetHelpTopicsQuery = {
  __typename?: 'Query';
  helpTopicCollection?: {
    __typename?: 'HelpTopicCollection';
    items: Array<{
      __typename?: 'HelpTopic';
      provider?: string | null;
      subject?: string | null;
      title?: string | null;
      help?: {
        __typename?: 'HelpTopicHelp';
        json: any;
        links: {
          __typename?: 'HelpTopicHelpLinks';
          entries: {
            __typename?: 'HelpTopicHelpEntries';
            block: Array<
              | {
                  __typename?: 'Benefit';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'CardWithButton';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HelpAccordion';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HelpDocument';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'HelpTopic';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TermsOfUse';
                  sys: { __typename?: 'Sys'; id: string };
                }
              | {
                  __typename?: 'TextFieldWithButton';
                  title?: string | null;
                  buttonText?: string | null;
                  content?: string | null;
                  sys: { __typename?: 'Sys'; id: string };
                }
              | null
            >;
          };
        };
      } | null;
    } | null>;
  } | null;
};

export type GetTermsAndCondtionsQueryVariables = Exact<{
  preview?: InputMaybe<Scalars['Boolean']['input']>;
}>;

export type GetTermsAndCondtionsQuery = {
  __typename?: 'Query';
  termsOfUseCollection?: {
    __typename?: 'TermsOfUseCollection';
    items: Array<{
      __typename?: 'TermsOfUse';
      title?: string | null;
      versions?: number | null;
      status?: Array<string | null> | null;
      terms?: { __typename?: 'TermsOfUseTerms'; json: any } | null;
      help?: { __typename?: 'TermsOfUseHelp'; json: any } | null;
    } | null>;
  } | null;
};

export const GetCardWithButtonDocument = gql`
  query GetCardWithButton($preview: Boolean) {
    cardWithButtonCollection(preview: $preview) {
      items {
        title
        description
        buttonText
        buttonUrl
        location
      }
    }
  }
`;

/**
 * __useGetCardWithButtonQuery__
 *
 * To run a query within a React component, call `useGetCardWithButtonQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardWithButtonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardWithButtonQuery({
 *   variables: {
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useGetCardWithButtonQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCardWithButtonQuery,
    GetCardWithButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCardWithButtonQuery,
    GetCardWithButtonQueryVariables
  >(GetCardWithButtonDocument, options);
}
export function useGetCardWithButtonLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCardWithButtonQuery,
    GetCardWithButtonQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCardWithButtonQuery,
    GetCardWithButtonQueryVariables
  >(GetCardWithButtonDocument, options);
}
export type GetCardWithButtonQueryHookResult = ReturnType<
  typeof useGetCardWithButtonQuery
>;
export type GetCardWithButtonLazyQueryHookResult = ReturnType<
  typeof useGetCardWithButtonLazyQuery
>;
export type GetCardWithButtonQueryResult = Apollo.QueryResult<
  GetCardWithButtonQuery,
  GetCardWithButtonQueryVariables
>;
export const GetHelpTopicsDocument = gql`
  query GetHelpTopics($preview: Boolean) {
    helpTopicCollection(preview: $preview) {
      items {
        provider
        subject
        title
        help {
          json
          links {
            entries {
              block {
                ... on TextFieldWithButton {
                  title
                  buttonText
                  content
                }
                sys {
                  id
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetHelpTopicsQuery__
 *
 * To run a query within a React component, call `useGetHelpTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHelpTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHelpTopicsQuery({
 *   variables: {
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useGetHelpTopicsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetHelpTopicsQuery,
    GetHelpTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHelpTopicsQuery, GetHelpTopicsQueryVariables>(
    GetHelpTopicsDocument,
    options,
  );
}
export function useGetHelpTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHelpTopicsQuery,
    GetHelpTopicsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHelpTopicsQuery, GetHelpTopicsQueryVariables>(
    GetHelpTopicsDocument,
    options,
  );
}
export type GetHelpTopicsQueryHookResult = ReturnType<
  typeof useGetHelpTopicsQuery
>;
export type GetHelpTopicsLazyQueryHookResult = ReturnType<
  typeof useGetHelpTopicsLazyQuery
>;
export type GetHelpTopicsQueryResult = Apollo.QueryResult<
  GetHelpTopicsQuery,
  GetHelpTopicsQueryVariables
>;
export const GetTermsAndCondtionsDocument = gql`
  query GetTermsAndCondtions($preview: Boolean) {
    termsOfUseCollection(preview: $preview) {
      items {
        title
        terms {
          json
        }
        versions
        status
        help {
          json
        }
      }
    }
  }
`;

/**
 * __useGetTermsAndCondtionsQuery__
 *
 * To run a query within a React component, call `useGetTermsAndCondtionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTermsAndCondtionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTermsAndCondtionsQuery({
 *   variables: {
 *      preview: // value for 'preview'
 *   },
 * });
 */
export function useGetTermsAndCondtionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetTermsAndCondtionsQuery,
    GetTermsAndCondtionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTermsAndCondtionsQuery,
    GetTermsAndCondtionsQueryVariables
  >(GetTermsAndCondtionsDocument, options);
}
export function useGetTermsAndCondtionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTermsAndCondtionsQuery,
    GetTermsAndCondtionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTermsAndCondtionsQuery,
    GetTermsAndCondtionsQueryVariables
  >(GetTermsAndCondtionsDocument, options);
}
export type GetTermsAndCondtionsQueryHookResult = ReturnType<
  typeof useGetTermsAndCondtionsQuery
>;
export type GetTermsAndCondtionsLazyQueryHookResult = ReturnType<
  typeof useGetTermsAndCondtionsLazyQuery
>;
export type GetTermsAndCondtionsQueryResult = Apollo.QueryResult<
  GetTermsAndCondtionsQuery,
  GetTermsAndCondtionsQueryVariables
>;
