export function lowerCaseWithUnderscoresToTitleCase(str: string) {
  return str
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

export function formatPhoneNumber(phone: string) {
  let formattedPhone = phone.replace(/\D/g, '').substring(0, 10);
  const match = formattedPhone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);

  if (match) {
    formattedPhone = '(';
    formattedPhone += match[1] + (match[2] ? ') ' : '');
    formattedPhone += match[2] + (match[3] ? '-' : '');
    formattedPhone += match[3];
  }

  return formattedPhone;
}

export function capitalizeFirstLetter(word: string) {
  // Check if the word is not an empty string
  if (word.length === 0) {
    return word;
  }

  // Capitalize the first letter and concatenate it with the rest of the word
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export const replaceHyphenWithSlash = (inputString: string): string => {
  return inputString.replace(/-/g, '/');
};

export const formatResourceDomain = (value: string) => {
  if (!value.startsWith('http://') && !value.startsWith('https://')) {
    value = 'https://' + value;
  }
  const url = new URL(value);
  return url.hostname;
};
