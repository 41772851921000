import React from 'react';

import './registrationPage.css';

import RegistrationForm from './forms/registrationForm';
import { Stack } from '@mui/joy';

import { Helmet } from 'react-helmet';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { iconLibrary } from '../../../assets/icons/icon-library';

function RegistrationPage() {
  const { isLargerScreen } = useScreenQuery();
  return (
    <>
      <Helmet>
        <title>Self Service - Registration</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '2em' : '1em' }}>
        <PageHeader
          image={iconLibrary.heading.register}
          imageAlt="onboarding.registration.title.icon"
          titleKey="onboarding.registration.title"
        />
        <RegistrationForm />
      </Stack>
    </>
  );
}

export default RegistrationPage;
