import { useEffect, useDebugValue, useRef } from 'react';

const useDetectDependencyChange = (
  dependencies: unknown[],
  labels: string[],
) => {
  const previousDependencies = useRef(dependencies);

  useEffect(() => {
    const changedDependencyIndex = dependencies.findIndex(
      (dependency, index) => dependency !== previousDependencies.current[index],
    );

    if (changedDependencyIndex !== -1) {
      console.debug(
        `** [${Date.now()} Dependency '${
          labels[changedDependencyIndex]
        }' changed to ${JSON.stringify(dependencies[changedDependencyIndex])}]`,
      );
    }

    previousDependencies.current = dependencies;
  }, [dependencies, labels]);

  useDebugValue(dependencies);
};
export default useDetectDependencyChange;
