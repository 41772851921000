import { Config, Customer, Subscription } from '@dailypay/self-service-ts-sdk';
import { ActionMap } from '../actionMap';
import { AggregateActions } from '../appContext';
import { SubscriptionStateType } from './subscriptionState';

const logChangesToConsole = false;

export enum Types {
  SubcriptionTypeSelected = 'SUBSCRIPTION_TYPE_SELECTED',
  SubcriptionConfirmed = 'SUBSCRIPTION_CONFIRMED',
  StripeConfigFetched = 'STRIPE_CONFIG_FETCHED',
  StripeCustomerCreated = 'STRIPE_CUSTOMER_CREATED',
  stripeSubscriptionCreated = 'STRIPE_SUBSCRIPTION_CREATED',
  stripeSubscriptionFailed = 'STRIPE_SUBSCRIPTION_FAILED',
  StripePaymentMade = 'STRIPE_PAYMENT_MADE',
}

type SubscriptionPayload = {
  [Types.SubcriptionTypeSelected]: {
    priceId: string;
  };
  [Types.SubcriptionConfirmed]: {
    subscriptionConfirmed: boolean;
  };
  [Types.StripeConfigFetched]: {
    stripeConfig: Config;
  };
  [Types.StripeCustomerCreated]: {
    stripeCustomer: Customer;
  };
  [Types.stripeSubscriptionCreated]: {
    stripeSubscription: Subscription;
  };
  [Types.stripeSubscriptionFailed]: {
    error: string;
  };
  [Types.StripePaymentMade]: {
    paymentMade: boolean;
  };
};

export type SubscriptionActions =
  ActionMap<SubscriptionPayload>[keyof ActionMap<SubscriptionPayload>];

export const subscriptionReducer = (
  state: SubscriptionStateType,
  action: AggregateActions,
) => {
  switch (action.type) {
    case Types.StripeConfigFetched:
      return {
        ...state,
        stripeConfig: action.payload.stripeConfig,
      };
    case Types.StripeCustomerCreated:
      return {
        ...state,
        stripeCustomer: action.payload.stripeCustomer,
      };
    case Types.stripeSubscriptionCreated:
      return {
        ...state,
        stripeSubscription: action.payload.stripeSubscription,
      };
    case Types.stripeSubscriptionFailed:
      return {
        ...state,
        stripeSubscriptionError: action.payload.error,
      };
    default:
      return state;
  }
};

export const dispatchSubcriptionFailedAction = (
  dispatch: React.Dispatch<AggregateActions>,
  error: string,
) => {
  dispatch({
    type: Types.stripeSubscriptionFailed,
    payload: {
      error,
    },
  });
};

export const dispatchSubcriptionConfirmedAction = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  if (logChangesToConsole) {
    console.log('dispatchSubcriptionConfirmed');
  }
  dispatch({
    type: Types.SubcriptionConfirmed,
    payload: {
      subscriptionConfirmed: true,
    },
  });
};
