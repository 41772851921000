import React from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { Horizon } from '../../../../../theme';
import { useTranslation } from 'react-i18next';

interface GridItemProps {
  labelKey: string;
  paddingLeft?: string;
}

const BankInfoDesktopFields: React.FC<GridItemProps> = ({
  labelKey,
  paddingLeft = '0px',
}) => {
  const { t } = useTranslation();

  return (
    <Grid xs={12} sm={6} md={3} lg={3} className="formField">
      <div
        style={{
          backgroundColor: Horizon.info[3],
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: paddingLeft,
        }}
      >
        <TextCL variant="overline">{t(labelKey)}</TextCL>
      </div>
    </Grid>
  );
};

export default BankInfoDesktopFields;
