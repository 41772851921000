import { PayGroup } from './payGroups';

export function formatSemiMonthlyPayGroup(payGroup: PayGroup) {
  return {
    payFrequency: payGroup.payFrequency,
    startDate: payGroup.startDate2,
    endDate: payGroup.endDate2,
    processDate: payGroup.processDate2,
    payDate: payGroup.payDate2,
    startDate2: payGroup.startDate,
    endDate2: payGroup.endDate,
    processDate2: payGroup.processDate,
    payDate2: payGroup.payDate,
  };
}
