/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import EmployeeBankDetails from './EmployeeBankDetails';
import {
  DailyPayStatus,
  Employee,
  EmploymentStatus,
  PatchRequest,
} from '@dailypay/self-service-ts-sdk';
import ContactInfo from './ContactInfo';
import EmploymentInfo from './EmploymentInfo';
import { updateEmployee } from '../../../../../services/api-wrapper';
import { captureException } from '../../../../../services/sentry';
import { Grid } from '@mui/joy';
import DailyPayInfo from './DailypayInfo';
import DailyPayInvitation from './DailyPayInvitation';

export interface EmployeeAccountInfoProps {
  employee: Employee;
  providerId: string | undefined;
  refetchEmployeeInfo: (
    employmentStatus?: EmploymentStatus | undefined,
  ) => void;
  employmentStatus?: EmploymentStatus;
}

export default function EmployeeAccountInfo({
  employee,
  providerId,
  refetchEmployeeInfo,
  employmentStatus,
}: EmployeeAccountInfoProps) {
  const [confirmTerminationOpen, setConfirmTerminationOpen] =
    React.useState<boolean>(true);
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isEditingContact, setIsEditingContact] = React.useState(false);
  const [isEditingEmployment, setIsEditingEmployment] = React.useState(false);
  const [onSuccess, setOnSuccess] = React.useState<boolean>(false);

  if (!employee) {
    return <div>Loading...</div>;
  }

  const employment = employee.employments[0];

  const bankDetails = employee.bank_accounts.map((acc) => ({
    bankName: acc.account_bank_name,
    accountNumber: acc.account_number,
    routingNumber: acc.account_routing_number,
    accountType: acc.account_type,
  }));

  const handleUpdateEmployee = async (body: PatchRequest) => {
    setIsLoading(true);

    if (body.email || body.phone_number) {
      setIsEditingContact(true);
    }
    if (body.employment_status) {
      setIsEditingEmployment(true);
    }

    if (providerId) {
      try {
        await updateEmployee(
          providerId,
          employee.employee_uuid,
          employment.employment_uuid,
          body,
        );
        setHasError(false);
        if (typeof refetchEmployeeInfo === 'function') {
          refetchEmployeeInfo(
            body.employment_status ? body.employment_status : employmentStatus,
          );
        }
        setOnSuccess(true);
        setIsEditingContact(false);
        setIsEditingEmployment(false);
      } catch (err: any) {
        captureException(err, {
          extra: {
            message: 'Error in updateEmployee api call on EmployeeAccountInfo',
          },
        });
        setOnSuccess(false);
        setHasError(true);
      } finally {
        setConfirmTerminationOpen(false);
        setIsLoading(false);
      }
    }
  };

  return (
    <Grid container xs={12} md={12} lg={12}>
      <div
        style={{
          minWidth: '100%',
        }}
      >
        {employee.dp_status === DailyPayStatus.Pending &&
          employee.employments[0].status === EmploymentStatus.Employed && (
            <DailyPayInvitation employee={employee} providerID={providerId} />
          )}
        <ContactInfo
          employee={employee}
          updateEmployee={(body: object) => {
            handleUpdateEmployee(body);
          }}
          hasError={hasError && isEditingContact}
          setHasError={setHasError}
        />
        <DailyPayInfo employee={employee} />
        <EmploymentInfo
          employee={employee}
          updateEmployee={(body: object) => {
            handleUpdateEmployee(body);
          }}
          confirmTermination={confirmTerminationOpen}
          hasError={hasError && isEditingEmployment}
          isLoading={isLoading}
          employmentStatus={employmentStatus}
          setHasError={setHasError}
          onSuccess={onSuccess}
        />
        {bankDetails && <EmployeeBankDetails details={bankDetails} />}
      </div>
    </Grid>
  );
}
