import React from 'react';

import { Button } from '@mui/joy';
import Box from '@mui/joy/Box';
import { useNavigate } from 'react-router-dom';
import './provideCompanyDetailsForm.css';

import { useForm } from 'react-hook-form';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ButtonCL } from '../../../../components/library/ButtonCL';

type FormData = {
  username: string;
  password: string;
  emailAddress: string;
};

const schema = yup
  .object({
    username: yup.string().required(),
    password: yup.string().required(),
    emailAddress: yup.string().email().required(),
  })
  .required();

export default function PayrollConfigADPRun() {
  const navigate = useNavigate();
  const { control, handleSubmit, reset, formState } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const handleConnectPayroll = React.useCallback(() => {
    navigate('/onboarding/validatePayroll');
  }, [navigate]);

  return (
    <>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'stretch',
          display: 'flex',
          gap: 4,
          paddingBottom: '2rem',
        }}
      >
        <InputBoxCL
          control={control}
          fieldName="username"
          addDivider
          replayBlock={true}
        />
        <InputBoxCL
          control={control}
          fieldName="password"
          addDivider
          replayBlock={true}
        />
      </Box>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'stretch',
          display: 'flex',
          gap: 4,
          paddingBottom: '2rem',
        }}
      >
        <InputBoxCL
          control={control}
          fieldName="emailAddress"
          type="email"
          replayEncrypt={true}
        />
      </Box>

      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'flex-end',
          display: 'flex',
          gap: 1,
        }}
      >
        <Button
          variant="outlined"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={() => reset()}
        >
          Simulate All Good
        </Button>
        <ButtonCL color="neutral" onClick={() => reset()} text="Reset" />
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={handleSubmit(handleConnectPayroll)}
          disabled={!formState.isDirty}
        >
          Submit
        </Button>
      </Box>
    </>
  );
}
