import React, { Grid } from '@mui/joy';
import { FC } from 'react';
import { ButtonCL } from '../../../../../../../components/library/ButtonCL';
import { useTranslation } from 'react-i18next';

interface Props {
  isConnecting: boolean;
  onButtonPressed: () => void;
}

export const TeamVendorRequirement: FC<Props> = ({
  isConnecting,
  onButtonPressed,
}) => {
  const { t } = useTranslation();

  return (
    <Grid container mt={7} mb={5} height={40} justifyContent="center">
      <ButtonCL
        variant="solid"
        color="neutral"
        text={t('onboarding.connect.iHaveMyToken')}
        sx={{ width: 420, height: 48, fontSize: 15 }}
        disabled={isConnecting}
        onClick={onButtonPressed}
      />
    </Grid>
  );
};
