import { Grid } from '@mui/joy';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { iconLibrary } from '../../../../../assets/icons/icon-library';
import { TextCL } from '../../../../../components/library/TextCL';

interface Props {
  payrollProviderKey?: string;
}

const ValidationSuccess: FC<Props> = ({ payrollProviderKey }) => {
  const { t } = useTranslation();

  const getDescription =
    payrollProviderKey === 'adp_run'
      ? t('onboarding.connect.successAdp.description1')
      : t('onboarding.connect.success.description');
  return (
    <>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        flexDirection="column"
        sx={{ paddingBottom: '34px' }}
      >
        <TextCL variant="h1Bold">
          {t('onboarding.connect.success.title1')}
        </TextCL>
        <TextCL variant="h1Bold">
          {t('onboarding.connect.success.title2')}
        </TextCL>
      </Grid>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        flexDirection="column"
        sx={{ paddingBottom: '34px' }}
      >
        <TextCL variant="h5">{getDescription}</TextCL>
      </Grid>
      {payrollProviderKey === 'adp_run' && (
        <Grid container xs={12} md={12} lg={12} flexDirection="column" gap={4}>
          <TextCL variant="h5">
            {t('onboarding.connect.successAdp.description2')}
          </TextCL>
        </Grid>
      )}
      {payrollProviderKey === 'ukg_ready' && (
        <Grid container xs={12} md={12} lg={12} flexDirection="column">
          <TextCL variant="h2Bold">
            {t('onboarding.connect.success.thingsToDo')}
          </TextCL>
          <Grid container xs={12} md={12} lg={12} sx={{ padding: '13px 0px' }}>
            <img
              width={30}
              height={30}
              src={iconLibrary.common.rectangle}
              alt="register"
            />
            <div
              style={{
                paddingLeft: '12px',
              }}
            >
              <TextCL variant="h4">
                {t('onboarding.connect.success.thingsToDo1')}
              </TextCL>
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ValidationSuccess;
