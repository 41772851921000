import React from 'react';
import Button from '@mui/joy/Button';

import './section.css';
import { Stack } from '@mui/joy';

import { useNavigate } from 'react-router-dom';

export default function Benefits() {
  const navigate = useNavigate();
  // Create a click function that won't get recreated every render
  const handleClickGetStarted = React.useCallback(() => {
    navigate('/onboarding');
  }, [navigate]);

  return (
    <>
      <h2>Benefits</h2>
      <Stack spacing={2}>
        <Button sx={{ borderRadius: '50px' }} onClick={handleClickGetStarted}>
          Get Started
        </Button>
      </Stack>
    </>
  );
}
