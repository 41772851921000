import { useTheme } from '@mui/joy';
import useMediaQuery from '@mui/material/useMediaQuery';

function useScreenQuery() {
  const theme = useTheme();

  const isLargerScreen = useMediaQuery(theme.breakpoints.up('md'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.up('sm'));
  return { isLargerScreen, isMediumScreen };
}

export default useScreenQuery;
