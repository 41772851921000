import React from 'react';

import './enterPayrollConfig.css';

import { Stack } from '@mui/joy';
import PayrollConfigForm from './payrollForms/payrollConfigForm';
import { iconLibrary } from '../../../assets/icons/icon-library';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import PageHeader from '../../../components/common/page-header/pageHeader';

export default function EnterPayrollConfig() {
  const { isLargerScreen } = useScreenQuery();

  return (
    <Stack
      spacing={4}
      sx={{ padding: isLargerScreen ? '2em' : '1em' }}
      flex={1}
    >
      <PageHeader
        image={iconLibrary.heading.connectPayroll}
        imageAlt="onboarding.connect.title.icon"
        titleKey="onboarding.connect.title"
      />
      <PayrollConfigForm />
    </Stack>
  );
}
