/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Grid, Link, Tab, TabList, Tabs } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { TextCL } from '../../../../../components/library/TextCL';
import {
  Employee,
  EmploymentStatus,
  PatchRequest,
  PayPeriodSummary,
} from '@dailypay/self-service-ts-sdk';
import EmployeeEarnings from './EmployeeEarnings';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../../../test-utils/test-ids';
import { supportedEmploymentStatus } from './types';
import { Moolah } from '../../../../../theme';
import { ModalCL } from '../../../../../components/library/ModalCL';
import { updateEmployee } from '../../../../../services/api-wrapper';
import { captureException } from '../../../../../services/sentry';
import EmployeeAccountInfo from './EmployeeAccountInfo';
import { formInsetBackground } from '../../../../../theme/self-service-joy-theme';
import { SelectCL } from '../../../../../components/library/SelectCL';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import useScreenQuery from '../../../../../layouts/hooks/useScreenQuery';

export interface EmployeeInfoProps {
  employee: Employee;
  providerId: string;
  refetchEmployeeInfo: () => void;
  hasNoPayPeriods?: boolean;
}

export default function EmployeeInfo({
  employee,
  providerId,
  refetchEmployeeInfo,
  hasNoPayPeriods,
}: EmployeeInfoProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPayPeriod, setSelectedPayPeriod] = React.useState('');
  const [selectedPayPeriodId, setSelectedPayPeriodId] = React.useState('');
  const [providerID, setProviderID] = React.useState<string>();
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [payPeriods, setPayPeriods] = React.useState<PayPeriodSummary[]>([]);
  const [open, setOpen] = React.useState<boolean>(false);
  const employment = employee.employments[0];
  const [status, setStatus] = React.useState<EmploymentStatus>(
    employment.status,
  );
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isCurrentPayPeriod, setIsCurrentPayPeriod] =
    React.useState<boolean>(false);
  const { isLargerScreen } = useScreenQuery();

  React.useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.PortalEmployeePageViewed,
      payload: {
        tab: selectedTab === 0 ? 'details' : 'earnings',
        employeeUUID: employee.employee_uuid,
        providerUUID: providerId,
      },
    });
  }, [selectedTab, employee, providerId]);

  React.useEffect(() => {
    if (!hasNoPayPeriods && selectedPayPeriod) {
      const today = new Date();
      const [startDateString, endDateString] = selectedPayPeriod.split(' - ');
      const startDate = new Date(startDateString);
      const endDate = new Date(endDateString);
      setIsCurrentPayPeriod(today >= startDate && today <= endDate);
    }
  }, [hasNoPayPeriods, selectedPayPeriod]);

  const handleUpdateEmployee = React.useCallback(async () => {
    setIsLoading(true);
    if (providerId) {
      try {
        const body: PatchRequest = {
          employment_status: status,
        } as object;
        await updateEmployee(
          providerId,
          employee.employee_uuid,
          employment.employment_uuid,
          body,
        );
        refetchEmployeeInfo();
      } catch (err: any) {
        setHasError(true);
        setStatus(employment.status);
        captureException(err, {
          extra: {
            message: 'Error in updateEmployee api call on EmployeeAccountInfo',
          },
        });
      } finally {
        setIsLoading(false);
        setOpen(false);
      }
    }
  }, [
    employee.employee_uuid,
    employment.employment_uuid,
    employment.status,
    providerId,
    refetchEmployeeInfo,
    status,
  ]);

  React.useEffect(() => {
    if (providerId) {
      setProviderID(providerId);
    }
  }, [providerId]);

  React.useEffect(() => {
    if (hasError) {
      setStatus(employment.status);
    }
  }, [employment.status, hasError]);

  React.useEffect(() => {
    if (employment.pay_periods) {
      setPayPeriods(employment.pay_periods);
      setSelectedPayPeriodId(employment.pay_periods[0].id);
      setSelectedPayPeriod(
        `${employment.pay_periods[0].start_date} - ${employment.pay_periods[0].end_date}`,
      );
    }
  }, [employment.pay_periods]);

  if (!employee) {
    return <div>Loading...</div>;
  }

  const handlePayPeriodChange = (selectedValue: any) => {
    const selectedOption = payPeriods.find(
      (option) => option.id.toString() === selectedValue,
    );

    const payPeriodDates = `${selectedOption?.start_date} - ${selectedOption?.end_date}`;
    setSelectedPayPeriod(payPeriodDates);
    setSelectedPayPeriodId(selectedValue);

    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.EmployeePayPeriodChanged,
      payload: {
        employeeUUID: employee.employee_uuid,
        payPeriodID: selectedValue,
        dates: payPeriodDates,
      },
    });
  };

  const payPeriodLabel = isCurrentPayPeriod
    ? `CURRENT ${t('admin.employees.earnings.label').toUpperCase()}`
    : `${t('admin.employees.earnings.label').toUpperCase()}`;

  const handleTerminateEmployeeStatus = () => {
    setOpen(true);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          flexGrow: 1,
          backgroundColor: formInsetBackground,
          borderRadius: '8px',
          paddingBottom: '24px',
          boxShadow: '0px 2px 4px rgba(15, 22, 57, 0.04)',
        }}
      >
        <ModalCL
          isOpen={open}
          onCancel={() => {
            setStatus(employment.status);
            setOpen(false);
          }}
          onConfirm={() => {
            setOpen(false);
            handleUpdateEmployee();
          }}
          terminateEmployee={status === 'terminated'}
          isLoading={isLoading}
        />
        <Grid
          container
          justifyContent="space-between"
          alignItems={'center'}
          xs={12}
          md={12}
          lg={12}
          className="formSection"
          sx={{
            padding: '24px',
          }}
        >
          <Grid xs={12} md={6} lg={6} className="formSection">
            {isLargerScreen && (
              <Link
                underline="always"
                sx={{ display: 'flex', width: '100%', paddingBottom: '24px' }}
                level="title-sm"
                onClick={() => navigate('/admin/employees')}
              >
                <TextCL variant="link" color={Moolah.navy[60]}>
                  {'<'} {t('admin.employees.backButton')}
                </TextCL>
              </Link>
            )}
            <Tabs
              aria-label="tabs"
              sx={{ bgcolor: 'transparent' }}
              onChange={(_, val: any) => {
                setSelectedTab(val);
              }}
            >
              <TabList disableUnderline>
                <Tab disableIndicator>{t('admin.employees.detailsTab')}</Tab>
                <Tab disableIndicator>{t('admin.employees.earningsTab')}</Tab>
              </TabList>
            </Tabs>
          </Grid>
          <Grid xs={12} md={6} lg={4}>
            {selectedTab === 1 ? (
              <Grid xs={12} md={6} lg={6}>
                {!hasNoPayPeriods && (
                  <SelectCL
                    label={payPeriodLabel}
                    size="sm"
                    fieldName="PayPeriod"
                    options={payPeriods.map((period) => ({
                      id: `${period.id}`,
                      label: `${period.start_date} - ${period.end_date}`,
                    }))}
                    value={selectedPayPeriod}
                    replayEncrypt={true}
                    datatestid={
                      TEST_IDS.admin.employees.employmentStatusDropdown
                    }
                    onChangeCallback={(val) => {
                      handlePayPeriodChange(val);
                    }}
                  />
                )}
              </Grid>
            ) : (
              <Grid xs={12} md={6} lg={6}>
                <SelectCL
                  size="sm"
                  fieldName="employmentStatus"
                  label={t('admin.employees.accountInfo.employment.status')}
                  options={supportedEmploymentStatus}
                  value={status}
                  replayEncrypt={true}
                  datatestid={TEST_IDS.admin.employees.employmentStatusDropdown}
                  onChangeCallback={(val) => {
                    setStatus(val as EmploymentStatus);
                    handleTerminateEmployeeStatus();
                  }}
                />
              </Grid>
            )}
          </Grid>
        </Grid>

        {selectedTab === 0 && (
          <EmployeeAccountInfo
            employee={employee}
            providerId={providerID}
            refetchEmployeeInfo={(v) => {
              setStatus(v as EmploymentStatus);
              if (typeof refetchEmployeeInfo === 'function') {
                refetchEmployeeInfo();
              }
            }}
            employmentStatus={status}
          />
        )}

        {selectedTab === 1 && (
          <>
            {hasNoPayPeriods ? (
              <Grid
                container
                justifyContent="space-between"
                alignItems={'center'}
                xs={12}
                md={12}
                lg={12}
                className="formSection"
                sx={{
                  padding: '24px',
                }}
              >
                <TextCL variant="h4" fontWeight="600" color={Moolah.navy[60]}>
                  {t('admin.employees.earnings.emptyState')}
                </TextCL>
              </Grid>
            ) : (
              <EmployeeEarnings
                employmentUUID={employment.employment_uuid}
                selectedPayPeriodId={selectedPayPeriodId.toString()}
                isCurrentPayPeriod={isCurrentPayPeriod}
              />
            )}
          </>
        )}
      </Grid>
    </>
  );
}
