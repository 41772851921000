import React from 'react';

import { Chip } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import {
  DailyPayStatus,
  EmploymentStatus,
} from '@dailypay/self-service-ts-sdk';

interface EmployeeStatusChipCLProps {
  employeeStatus?: EmploymentStatus | undefined;
  dpStatus?: DailyPayStatus | 'expired' | undefined;
  replayBlock?: boolean;
  replayEncrypt?: boolean;
}

const backgroundColors = {
  active: '#F0FFF5',
  employed: '#F0FFF5',
  banned: '#FAE6E6',
  deactivated: '#FAE6E6',
  disabled: '#FAE6E6',
  terminated: '#FAE6E6',
  canceled: '#EDEDED',
  pending: '#FAF4E6',
  expired: '#FAF4E6',
};
const textColors = {
  active: '#2C6124',
  banned: '#C31B1B',
  deactivated: '#C31B1B',
  disabled: '#C31B1B',
  terminated: '#C31B1B',
  canceled: '#676B75',
  pending: '#956200',
  expired: '#956200',
  employed: '#2C6124',
};

export const EmployeeStatusChipCL = ({
  employeeStatus,
  dpStatus,
  replayBlock,
  replayEncrypt,
}: EmployeeStatusChipCLProps) => {
  const { t } = useTranslation();

  const status = employeeStatus || dpStatus;
  if (!status) return null;

  return (
    <Chip
      size="sm"
      variant="solid"
      sx={{
        color: textColors[status],
        background: backgroundColors[status],
        fontWeight: '600',
        font: 'Graphik',
        fontSize: '10px',
        lineHeight: '12px',
        borderRadius: '0px',
        paddingX: '1em',
      }}
      slotProps={{
        root: {
          'data-qm-block': replayBlock,
          'data-qm-encrypt': replayEncrypt,
        },
      }}
    >
      {t(`employmentStatus.${status}`)}
    </Chip>
  );
};
