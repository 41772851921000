import React from 'react';

import { Button, Grid, Link, Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import { Helmet } from 'react-helmet';
import { iconLibrary } from '../../../assets/icons/icon-library';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { TextCL } from '../../../components/library/TextCL';
import { Moolah } from '../../../theme';
import { useNavigate } from 'react-router-dom';
import PageHeader from '../../../components/common/page-header/pageHeader';

export default function EnterpriseProgram() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();
  const navigate = useNavigate();

  const handleJoinEnterpise = React.useCallback(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizeOKButton,
    });

    window.open('https://www.dailypay.com/dailypay-solution/', '_blank');
  }, []);

  return (
    <>
      <Helmet>
        <title>Self Service - Enterprise</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '2em' : '1em' }}>
        <PageHeader
          image={iconLibrary.heading.register}
          imageAlt="onboarding.registration.title.icon"
          titleKey="onboarding.registration.title"
        />

        <FormFoundations.FormWrapperGrid>
          <Grid
            xs={12}
            md={12}
            lg={12}
            className="formSection"
            display={'flex'}
            flexDirection={'column'}
            padding={isLargerScreen ? '48px 64px' : '24px 0px'}
            textAlign={'center'}
          >
            <TextCL variant="h1Bold">
              {t('onboarding.registration.enterprise.title')}
            </TextCL>
            <div
              style={{
                padding: '50px 0',
              }}
            >
              <TextCL variant="h5">
                {t('onboarding.registration.enterprise.description')}
              </TextCL>
            </div>
          </Grid>
        </FormFoundations.FormWrapperGrid>
        <Grid
          container
          xs={12}
          md={12}
          lg={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Button
            sx={{
              borderRadius: '50px',
            }}
            onClick={handleJoinEnterpise}
          >
            {t('onboarding.registration.enterprise.buttonText')}
          </Button>
          <Link
            underline="always"
            sx={{ paddingTop: '24px' }}
            level="title-sm"
            onClick={() => navigate('/onboarding')}
          >
            <TextCL variant="link" color={Moolah.navy[60]}>
              {t('onboarding.registration.enterprise.backButton')}
            </TextCL>
          </Link>
        </Grid>
      </Stack>
    </>
  );
}
