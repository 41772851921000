export enum PageTypes {
  credentials = 'credentials',
  payGroups = 'payGroups',
  vendorRequirement = 'vendorRequirement',
}

export interface PayrollSetupStepConfiguration {
  providerName: string;
  totalSteps: number;
  pages: {
    pageTranslationKeys: {
      title: string;
      subtitle: string;
    };
    fields: string[];
    pageType?: `${PageTypes}`;
    helpRef: string;
    contentfulRef: string;
  }[];
}

const pageTranslationKey = (rest: string): string => {
  return `onboarding.connect.payrollSetup.${rest}`;
};

const commonADPSetupPages = {
  totalSteps: 2,
  pages: [
    {
      pageTranslationKeys: {
        title: pageTranslationKey('adp.step_1.title'),
        subtitle: pageTranslationKey('adp.step_1.subtitle'),
      },
      fields: [],
      helpRef: 'adp_run_step_1',
      contentfulRef: 'adp_run_step_1',
    },
    {
      pageTranslationKeys: {
        title: pageTranslationKey('adp.step_2.title'),
        subtitle: pageTranslationKey('adp.step_2.subtitle'),
      },
      fields: ['subscriber_email'],
      helpRef: 'adp_run_step_2',
      contentfulRef: 'adp_run_step_2',
    },
  ],
};

const payrollSetupStepConfiguration: PayrollSetupStepConfiguration[] = [
  {
    providerName: 'ukg_ready',
    totalSteps: 3,
    pages: [
      {
        pageTranslationKeys: {
          title: pageTranslationKey('ukg_ready.step_1.title'),
          subtitle: pageTranslationKey('ukg_ready.step_1.subtitle'),
        },
        fields: ['resource_domain', 'username', 'password'],
        helpRef: 'ukg_ready_step_1',
        contentfulRef: 'ukg_ready_step_1',
      },
      {
        pageTranslationKeys: {
          title: pageTranslationKey('ukg_ready.step_2.title'),
          subtitle: pageTranslationKey('ukg_ready.step_2.subtitle'),
        },
        fields: ['company_short_name'],
        helpRef: 'ukg_ready_step_2',
        contentfulRef: 'ukg_ready_step_2',
      },
      {
        pageTranslationKeys: {
          title: pageTranslationKey('ukg_ready.step_3.title'),
          subtitle: pageTranslationKey('ukg_ready.step_3.subtitle'),
        },
        fields: ['api_key'],
        helpRef: 'ukg_ready_step_3',
        contentfulRef: 'ukg_ready_step_3',
      },
    ],
  },
  {
    providerName: 'adp_run',
    ...commonADPSetupPages,
  },
  {
    providerName: 'adp_wfn',
    ...commonADPSetupPages,
  },
  {
    providerName: 'team',
    totalSteps: 2,
    pages: [
      {
        pageTranslationKeys: {
          title: pageTranslationKey('team.step_1.title'),
          subtitle: pageTranslationKey('team.step_1.subtitle'),
        },
        fields: [],
        helpRef: 'team_vendor_requirement',
        contentfulRef: 'team_step_1',
        pageType: PageTypes.vendorRequirement,
      },
      {
        pageTranslationKeys: {
          title: pageTranslationKey('team.step_2.title'),
          subtitle: pageTranslationKey('team.step_2.subtitle'),
        },
        fields: ['tenant_id'],
        helpRef: 'team_step_1',
        contentfulRef: 'team_step_2',
        pageType: PageTypes.credentials,
      },
      {
        pageTranslationKeys: {
          title: pageTranslationKey('team.step_3.title'),
          subtitle: pageTranslationKey('team.step_3.subtitle'),
        },
        fields: [],
        helpRef: 'team_step_2',
        contentfulRef: 'team_step_3',
        pageType: PageTypes.payGroups,
      },
    ],
  },
];

export default payrollSetupStepConfiguration;
