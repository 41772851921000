export const takeLastPartOfString = (
  sourceString: string,
  numberOfChars: number,
) => sourceString.substring(sourceString.length - numberOfChars);

export const maskAccountNumber = (sourceString: string | undefined) => {
  if (!sourceString) {
    return '';
  }
  if (sourceString.length > 3) {
    return (
      '*'.repeat(sourceString.length - 4) +
      takeLastPartOfString(sourceString, 4)
    );
  } else {
    return '*'.repeat(sourceString.length);
  }
};
