/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import { TextCL } from '../../../../../components/library/TextCL';
import Table from '@mui/joy/Table';
import { createTableHeaders } from './EmployeeEarnings';
import { useTranslation } from 'react-i18next';
import { Horizon, Moolah } from '../../../../../theme';
import {
  capitalizeFirstLetter,
  replaceHyphenWithSlash,
} from '../../../../../formatters/string-formatters';
import { formatMoney } from '../../../../../formatters/money-formatter';
import { ProspectDataIndicators } from '../../../../../state/onboarding/onboardingState';
import { AppContext } from '../../../../../state/appContext';

const EmployeeEarningsTableRow = (props: {
  row: ReturnType<typeof createTableHeaders>;
  employeePayPeriod?: any;
  initialOpen?: boolean;
  index: number;
}) => {
  const { row, employeePayPeriod } = props;
  const { state } = React.useContext(AppContext);
  const { t } = useTranslation();
  if (!employeePayPeriod) {
    return <div>Loading...</div>;
  }

  const totalTransferAmountCents = employeePayPeriod.transfers.reduce(
    (total: number, transfer: any) => total + transfer.amount,
    0,
  );

  const totalTransferAmount = formatMoney({
    amountCents: totalTransferAmountCents,
  });

  const totalTransferAndFeeAmountCents = employeePayPeriod.transfers.reduce(
    (total: number, transfer: any) => total + (transfer.amount + transfer.fee),
    0,
  );

  const totalTransferFeesCents = employeePayPeriod.transfers.reduce(
    (total: number, transfer: any) => total + transfer.fee,
    0,
  );

  const totalGrossAmountCents = employeePayPeriod.shifts.reduce(
    (total: number, shifts: any) => total + shifts.amount,
    0,
  );

  return (
    <>
      <tr>
        <th
          style={{ backgroundColor: Horizon.info[2], paddingLeft: '24px' }}
          scope="row"
        >
          <TextCL variant="h4" color={Moolah.white}>
            {row.mainHeading}
          </TextCL>
        </th>
      </tr>
      <tr>
        <td style={{ padding: '0px' }}>
          <Table size="sm" aria-label="earnings" sx={{ textAlign: 'left' }}>
            <thead>
              <tr
                style={{
                  backgroundColor: Horizon.info[3],
                }}
              >
                <td style={{ paddingLeft: '24px' }}>
                  <TextCL variant="overline">{row.subHeading}</TextCL>
                </td>
                <td>
                  <TextCL variant="overline">{row.subHeading2}</TextCL>
                </td>
                <td>
                  <TextCL variant="overline">{row.subHeading3}</TextCL>
                </td>
                <td>
                  <TextCL variant="overline">{row.subHeading4}</TextCL>
                </td>
                <td>
                  <TextCL variant="overline">{row.subHeading5}</TextCL>
                </td>
              </tr>
            </thead>
            <tbody>
              {props.index === 0 && employeePayPeriod ? (
                employeePayPeriod.shifts.length > 0 ? (
                  employeePayPeriod.shifts.map((shift: any, index: number) => (
                    <tr key={index} style={{ backgroundColor: Moolah.white }}>
                      <td style={{ paddingLeft: '24px' }}>
                        <TextCL variant="input" replayBlock={true}>
                          {replaceHyphenWithSlash(shift.date)}
                        </TextCL>
                      </td>
                      <td>{''}</td>
                      <td>{''}</td>
                      <td>
                        <TextCL variant="input" replayBlock={true}>
                          {capitalizeFirstLetter(shift.status)}
                        </TextCL>
                      </td>
                      <td>
                        <TextCL variant="input" replayBlock={true}>
                          {formatMoney({
                            amountCents: shift.amount,
                          })}
                        </TextCL>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr style={{ backgroundColor: Moolah.white }}>
                    <td colSpan={5} style={{ paddingLeft: '24px' }}>
                      <TextCL variant="input">
                        {(
                          state.onboarding.prospectData
                            ?.data as ProspectDataIndicators
                        ).payrollProviderKey === 'adp_run'
                          ? t('admin.employees.earnings.emptyStateAdp')
                          : t('admin.employees.earnings.earningsEmptyState')}
                      </TextCL>
                    </td>
                  </tr>
                )
              ) : null}

              {props.index === 0 && employeePayPeriod?.shifts.length > 0 && (
                <tr style={{ backgroundColor: Moolah.white }}>
                  <td style={{ paddingLeft: '24px' }}>
                    <TextCL variant="input" fontWeight="600">
                      {t('admin.employees.total')}
                    </TextCL>
                  </td>
                  <td>{''}</td>
                  <td>{''}</td>
                  <td>{''}</td>
                  <td>
                    <div
                      style={{
                        display: 'inline-block',
                        backgroundColor: Horizon.info[3],
                        borderRadius: '8px',
                        padding: '4px',
                        color: Moolah.navy[60],
                        fontWeight: '600',
                      }}
                    >
                      <TextCL
                        variant="input"
                        fontWeight="600"
                        replayBlock={true}
                      >
                        {formatMoney({ amountCents: totalGrossAmountCents })}
                      </TextCL>
                    </div>
                  </td>
                </tr>
              )}

              {props.index === 1 && employeePayPeriod ? (
                employeePayPeriod.transfers.length > 0 ? (
                  employeePayPeriod?.transfers.map(
                    (transfer: any, index: number) => (
                      <tr key={index} style={{ backgroundColor: Moolah.white }}>
                        <td style={{ paddingLeft: '24px' }}>
                          <TextCL variant="input" replayBlock={true}>
                            {replaceHyphenWithSlash(transfer.date)}
                          </TextCL>
                        </td>
                        <td>
                          <TextCL variant="input" replayBlock={true}>
                            {transfer.destination_account}
                          </TextCL>
                        </td>
                        <td>
                          <TextCL variant="input" replayBlock={true}>
                            {formatMoney({ amountCents: transfer.amount })}
                          </TextCL>
                        </td>
                        <td>
                          <TextCL variant="input" replayBlock={true}>
                            {formatMoney({ amountCents: transfer.fee })}
                          </TextCL>
                        </td>
                        <td>
                          <TextCL variant="input" replayBlock={true}>
                            {formatMoney({
                              amountCents: transfer.amount + transfer.fee,
                            })}
                          </TextCL>
                        </td>
                      </tr>
                    ),
                  )
                ) : (
                  <tr style={{ backgroundColor: Moolah.white }}>
                    <td colSpan={5} style={{ paddingLeft: '24px' }}>
                      <TextCL variant="input">
                        {t('admin.employees.earnings.transfersEmptyState')}
                      </TextCL>
                    </td>
                  </tr>
                )
              ) : null}

              {props.index === 1 && employeePayPeriod?.transfers.length > 0 && (
                <tr style={{ backgroundColor: Moolah.white }}>
                  <td style={{ paddingLeft: '24px' }}>
                    <TextCL variant="input" fontWeight="600">
                      {t('admin.employees.total')}
                    </TextCL>
                  </td>
                  <td>{''}</td>
                  <td>
                    <div
                      style={{
                        display: 'inline-block',
                        color: Moolah.navy[60],
                      }}
                    >
                      <TextCL
                        variant="input"
                        fontWeight="600"
                        replayBlock={true}
                      >
                        {totalTransferAmount}
                      </TextCL>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'inline-block',
                        color: Moolah.navy[60],
                      }}
                    >
                      <TextCL
                        variant="input"
                        fontWeight="600"
                        replayBlock={true}
                      >
                        {formatMoney({
                          amountCents: totalTransferFeesCents,
                        })}
                      </TextCL>
                    </div>
                  </td>
                  <td>
                    <div
                      style={{
                        display: 'inline-block',
                        backgroundColor: Horizon.info[3],
                        borderRadius: '8px',
                        padding: '4px',
                        color: Moolah.navy[60],
                      }}
                    >
                      <TextCL
                        variant="input"
                        fontWeight="600"
                        replayBlock={true}
                      >
                        {formatMoney({
                          amountCents: totalTransferAndFeeAmountCents,
                        })}
                      </TextCL>
                    </div>
                  </td>
                </tr>
              )}

              {props.index === 2 && employeePayPeriod && (
                <>
                  <tr
                    key={employeePayPeriod.pay_date}
                    style={{ backgroundColor: Moolah.white }}
                  >
                    <td style={{ paddingLeft: '24px' }}>
                      <TextCL variant="input" replayBlock={true}>
                        {replaceHyphenWithSlash(employeePayPeriod.pay_date)}
                      </TextCL>
                    </td>
                    <td>{''}</td>
                    <td>
                      <TextCL variant="input" replayBlock={true}>
                        {formatMoney({
                          amountCents: employeePayPeriod.net_pay,
                        })}
                      </TextCL>
                    </td>
                    <td>
                      <TextCL variant="input" replayBlock={true}>
                        {formatMoney({
                          amountCents: totalTransferAndFeeAmountCents,
                        })}
                      </TextCL>
                    </td>
                    <td>
                      <div
                        style={{
                          display: 'inline-block',
                          backgroundColor: Horizon.info[3],
                          borderRadius: '8px',
                          padding: '4px',
                          color: Moolah.navy[60],
                        }}
                      >
                        <TextCL
                          variant="input"
                          fontWeight="600"
                          replayBlock={true}
                        >
                          {formatMoney({
                            amountCents: employeePayPeriod.remainder_pay,
                          })}
                        </TextCL>
                      </div>
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </Table>
        </td>
      </tr>
    </>
  );
};

export default EmployeeEarningsTableRow;
