import * as React from 'react';
import {
  WizardIconType,
  iconLibrary,
} from '../../../assets/icons/icon-library';

export const WizardIcon = ({ width, icon, state }: WizardIconType) => {
  return (
    <img
      width={width}
      style={{ marginRight: '1em' }}
      src={iconLibrary.onboardingNav[state ?? 'inactive'][icon]}
    ></img>
  );
};
