import { ActionMap } from '../actionMap';
import {
  AuthStateType,
  SelfServiceProgressStatus,
  initialAuthState,
} from './authState';
import { AggregateActions } from '../appContext';
import {
  removeQMCookie,
  signoutOfAmplitude,
} from '../../services/analytic-events/SelfServiceAnalyticsTypes';

const logChangesToConsole = true;

export enum GlobalActionTypes {
  ResetAppState = 'RESET_APP_STATE',
}

export enum AuthActionTypes {
  UpdateSelfServiceProgress = 'UPDATE_SELF_SERVICE_PROGRESS',
  IdentityCreated = 'IDENTITY_CREATED',
  ResetAppState = 'RESET_APP_STATE',
}

type AuthPayload = {
  [AuthActionTypes.IdentityCreated]: {
    identityCreated: boolean;
  };
  [AuthActionTypes.UpdateSelfServiceProgress]: {
    selfServiceProgress: SelfServiceProgressStatus;
  };
  [GlobalActionTypes.ResetAppState]: undefined;
};

export type AuthActions = ActionMap<AuthPayload>[keyof ActionMap<AuthPayload>];

export const authReducer = (
  state: AuthStateType,
  action: AggregateActions,
): AuthStateType => {
  switch (action.type) {
    case AuthActionTypes.UpdateSelfServiceProgress:
      return {
        ...state,
        selfServiceProgress: action.payload.selfServiceProgress,
      };
    case AuthActionTypes.IdentityCreated:
      return {
        ...state,
        identityAccountExists: action.payload.identityCreated,
      };
    case GlobalActionTypes.ResetAppState:
      return {
        ...initialAuthState,
      };
    default:
      return state;
  }
};

export const dispatchSelfServiceProgressAction = (
  dispatch: React.Dispatch<AggregateActions>,
  selfServiceProgress: SelfServiceProgressStatus,
) => {
  if (logChangesToConsole) {
    console.debug('dispatchSelfServiceProgressAction: ' + selfServiceProgress);
  }
  dispatch({
    type: AuthActionTypes.UpdateSelfServiceProgress,
    payload: {
      selfServiceProgress,
    },
  });
};

export const dispatchIdentityCreatedAction = (
  dispatch: React.Dispatch<AggregateActions>,
  identityCreated: boolean,
) => {
  if (logChangesToConsole) {
    console.log('dispatchIdentityCreatedAction: ' + identityCreated);
  }
  dispatch({
    type: AuthActionTypes.IdentityCreated,
    payload: {
      identityCreated,
    },
  });
};

export const dispatchResetAppStateAction = (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  if (logChangesToConsole) {
    console.log('dispatchResetAppStateAction');
  }

  dispatch({
    type: GlobalActionTypes.ResetAppState,
  });

  // Pass a callback to removeQMCookie to ensure it's called after the cookie is deleted
  removeQMCookie(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.QuantumMetricAPI?.newSession();
  });

  signoutOfAmplitude();
};
