import React from 'react';
import './inviteExpired.css';
import { Box, Grid, useTheme } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import PageHeader from '../../../components/common/page-header/pageHeader';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import logo from '../../../assets/images/dailypay-logo.svg';
import { useLocation } from 'react-router';

function InviteExpiredPage() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();
  const theme = useTheme();

  // TODO: https://dailypay.atlassian.net/browse/SELF-366
  // TODO: https://dailypay.atlassian.net/browse/SELF-349
  // The user will be redirected to this page if the invite link is expired
  // so from the createAdminAccount page, we need to add a check for the invite link
  // and redirect to this page if the link is expired
  // when redirecting, we need to pass the name of the user who sent the invite
  // i.e. navigate('/expired', { state: { name: 'Kate McA' } });
  // then we can use the name in the title of this page
  const location = useLocation();

  return (
    <Box
      sx={{ height: '100vh', backgroundColor: theme.palette.background.body }}
    >
      <Helmet>
        <title>{t('admin.login.title')}</title>
      </Helmet>

      <Grid
        flex={1}
        flexGrow={1}
        flexDirection={'column'}
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          backgroundColor: theme.palette.background.body,
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          display={'flex'}
          xs={12}
          md={6}
          lg={6}
          flex={1}
          justifyContent="center"
          alignItems="center"
          sx={{ minHeight: '10em', padding: isLargerScreen ? '2em' : '1em' }}
        >
          <img src={logo} width={isLargerScreen ? '360px' : '240px'} />
        </Grid>
        <Grid
          xs={12}
          md={6}
          lg={6}
          flex={1}
          flexGrow={1}
          padding={isLargerScreen ? '2em' : '1em'}
        >
          <FormFoundations.FormOutlineFlex
            sx={{ flexDirection: 'column', flex: 1 }}
          >
            <PageHeader
              excludeBackButton={true}
              titleKey={t('auth.inviteExpired.title', {
                name:
                  location.state && location.state.name
                    ? location.state.name
                    : 'an admin of your company',
              })}
            />
          </FormFoundations.FormOutlineFlex>
        </Grid>
      </Grid>
    </Box>
  );
}

export default InviteExpiredPage;
