import * as React from 'react';
import { Link, Stack, styled } from '@mui/joy';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import {
  WizardStepList,
  WizardStepListType,
  getCurrentNavigationSteps,
  isStepClickable,
} from './wizardEnablementLogic';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../state/appContext';
import { dispatchResetAppStateAction } from '../../state/auth/authReducer';
import { WizardNavLink } from './components/WizardNavLink';
import { SidebarCards } from '../common/card/sidebar-cards';
import { CardWithButton } from '../../cms/contentful/custom/CustomTypes';
import { TEST_IDS } from '../../test-utils/test-ids';
import ENV_VARS from '../../environment/envvars';
import { useMemo } from 'react';

const StyledParagraph = styled('p')({
  fontWeight: 50,
  color: '#00000025',
});

const defaultWizardConstraints = {
  emailProvided: false,
  authenticated: false,
  emailVerified: false,
  stripeConnected: false,
  fullySetup: false,
};

function WizardSidebar() {
  const { state, dispatch } = React.useContext(AppContext);

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [currentConstraints, setCurrentConstraints] = React.useState(
    defaultWizardConstraints,
  );

  const location = useLocation();

  const { route, authStatus, signOut } = useAuthenticator((context) => [
    context.route,
    context.authStatus,
  ]);

  const [wizardStepList, setWizardStepList] =
    React.useState<WizardStepListType>(WizardStepList);

  const cardInfo = useMemo(
    () => state.onboarding.cardWithButton?.cardWithButtonCollection?.items,
    [state.onboarding.cardWithButton?.cardWithButtonCollection?.items],
  );
  const shouldDisplayCards =
    state.onboarding.shouldDisplaySidebarCards && cardInfo;

  React.useEffect(() => {
    setCurrentConstraints({
      ...defaultWizardConstraints,
      authenticated: route === 'authenticated',
      emailVerified: route === 'authenticated',
      stripeConnected: false,
      fullySetup: false,
      emailProvided: false,
    });
  }, [route]);

  React.useEffect(() => {
    setWizardStepList(
      getCurrentNavigationSteps(
        currentConstraints,
        WizardStepList,
        location.pathname,
        { progressStatus: state.auth.selfServiceProgress },
      ),
    );
  }, [
    route,
    location.pathname,
    currentConstraints,
    state.auth.selfServiceProgress,
  ]);

  return (
    <>
      <Stack spacing={4} sx={{ padding: '0em 3em 3em 3em' }}>
        {wizardStepList.map((step) => {
          return (
            <WizardNavLink
              key={step.key}
              title={t(`onboarding.navigation.${step.navIconSet}`)}
              iconDetails={{
                width: 40,
                icon: step.navIconSet,
                state: step.navState,
              }}
              linkFunction={() => navigate(step.navPath)}
              disabled={
                !isStepClickable(
                  state.auth.selfServiceProgress,
                  step.lowestAllowedClickableStatus,
                )
              }
            />
          );
        })}

        {authStatus === 'authenticated' && (
          <Link
            underline="none"
            level="title-sm"
            color="danger"
            onClick={() => {
              setTimeout(() => {
                try {
                  signOut();
                } catch (error) {
                  console.log('error signing out: ', error);
                }
              }, 0);
              setTimeout(() => {
                dispatchResetAppStateAction(dispatch);
              }, 600);
              setTimeout(() => {
                window.location.href = '/login';
              }, 1000);
            }}
            data-testid={TEST_IDS.sideBar.signOutLink}
          >
            Signout
          </Link>
        )}
        {ENV_VARS.env !== 'production' && (
          <StyledParagraph>{state.auth.selfServiceProgress}</StyledParagraph>
        )}
      </Stack>

      {shouldDisplayCards && (
        <SidebarCards cardInfo={cardInfo as CardWithButton[]} />
      )}
    </>
  );
}

export default WizardSidebar;
