import * as React from 'react';
import Button, { ButtonPropsVariantOverrides } from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';
import { DialogActions, ModalClose, VariantProp } from '@mui/joy';
import { TextCL } from './TextCL';
import { Moolah } from '../../theme';
import { TEST_IDS } from '../../test-utils/test-ids';

interface InfoModalCLProps {
  color?: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
  title?: string;
  text?: string;
  buttonText?: string;
  variant?: OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>;
  sx?: SxProps;
  isOpen: boolean;
  showCloseButton?: boolean;
  children?: React.ReactNode;
  onClose?: () => void;
  onActionClicked?: () => void;
}
export const InfoModalCL = ({
  variant = 'outlined',
  isOpen,
  title,
  text,
  showCloseButton,
  sx,
  children,
  onClose,
  buttonText,
  onActionClicked,
}: InfoModalCLProps) => {
  const onCloseHandler = () => {
    if (onClose) {
      onClose();
    }
  };
  return (
    <Modal open={isOpen} onClose={onCloseHandler}>
      <ModalDialog
        variant={variant}
        role="modal"
        sx={{
          flexDirection: 'column',
          display: 'flex',
          alignItems: 'center',
          textAlign: 'center',
          padding: '48px',
          ...sx,
        }}
      >
        {showCloseButton && <ModalClose />}
        {title && (
          <DialogTitle>
            <TextCL variant="h2Bold" color="white">
              {title}
            </TextCL>
          </DialogTitle>
        )}
        {text && (
          <DialogContent sx={{ paddingTop: '24px' }}>
            <TextCL color={'white'} variant="h5">
              {text}
            </TextCL>
          </DialogContent>
        )}
        {buttonText && (
          <DialogActions>
            <Button
              variant="solid"
              size="sm"
              sx={{
                borderRadius: '50px',
                backgroundColor: Moolah.orange[60],
              }}
              onClick={() => {
                if (typeof onActionClicked === 'function') {
                  onActionClicked();
                }
              }}
              data-testid={TEST_IDS.modal.modalViewTermsButton}
            >
              {buttonText}
            </Button>
          </DialogActions>
        )}
        {children}
      </ModalDialog>
    </Modal>
  );
};
