import { useCallback, useContext, useEffect } from 'react';
import { AppContext } from '../state/appContext';
import { SelfServiceProgressStatus } from '../state/auth/authState';
import { useLocation, useNavigate } from 'react-router-dom';

export const defaultRedirectMap: RedirectRules[] = [
  {
    isStatus: [SelfServiceProgressStatus.Complete],
    redirectTo: '/admin',
  },
  {
    isStatus: [SelfServiceProgressStatus.ProspectUnsaved],
    redirectTo: '/onboarding/createAccount',
  },
  {
    isStatus: [SelfServiceProgressStatus.IdentityCreated],
    redirectTo: '/onboarding/verifyAccount',
  },
  {
    isStatus: [SelfServiceProgressStatus.ProviderAlreadyExists],
    redirectTo: '/onboarding/duplicateCompany',
  },
  {
    isStatus: [SelfServiceProgressStatus.IdentityVerified],
    redirectTo: '/onboarding/acceptTerms',
  },
  {
    isStatus: [SelfServiceProgressStatus.TermsAccepted],
    redirectTo: '/onboarding/connectPayroll',
  },
];

// Allow login screen to be accessed but redirect status is Complete.
export const loginPageRedirectMap: RedirectRules[] = [
  {
    isStatus: [SelfServiceProgressStatus.Complete],
    redirectTo: '/admin',
  },
];

interface RedirectRules {
  isStatus: SelfServiceProgressStatus[];
  redirectTo: string;
}

interface UseRouteProtectionOptions {
  redirectRules: RedirectRules[];
  context: string;
  disable?: boolean;
}

/**
 * This is intended to be used from within a page component.
 * If supplied with the default redirect map, it will redirect to the appropriate page based on the current self service progress status.
 * There are expected to be scenarios where the default redirect map is not sufficient, in which case you can supply your own redirect map.
 * For example: if we know the user has got as far as accepting terms it may make sense to redirect from the Accept Terms page to the Connect Payroll page.
 * Proposed redirect when status is Complete doesn't work for the
 */
const useRouteProtection = ({
  redirectRules,
  context,
  disable,
}: UseRouteProtectionOptions) => {
  const { state } = useContext(AppContext);
  const navigate = useNavigate();
  // get current window location
  const location = useLocation();

  const getRedirectRequired = useCallback(() => {
    const status = state.auth.selfServiceProgress;

    if (!status || status === SelfServiceProgressStatus.Undetermined) {
      return undefined;
    }

    const isStatus = redirectRules.find((item) =>
      item.isStatus.includes(status),
    );

    if (isStatus) {
      if (location.pathname.startsWith(isStatus.redirectTo)) {
        return undefined;
      }
      return isStatus.redirectTo;
    }
    return undefined;
  }, [location.pathname, redirectRules, state.auth.selfServiceProgress]);

  useEffect(() => {
    if (!disable) {
      const redirect = getRedirectRequired();

      if (redirect) {
        console.debug(`~~Redirecting to ${redirect} status ${
          state.auth.selfServiceProgress
        } current route is ${location.pathname}
        ~~~ status ${context} | current time ${Date.now()} |
        `);
        navigate(redirect);
      }
    }
  }, [
    context,
    navigate,
    getRedirectRequired,
    state.auth.selfServiceProgress,
    location.pathname,
    disable,
  ]);
};

export default useRouteProtection;
