import React from 'react';

import {
  Box,
  Button,
  ButtonPropsVariantOverrides,
  VariantProp,
} from '@mui/joy';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';

interface ButtonCLProps {
  color: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
  text?: string;
  datatestid?: string;
  onClick?: () => void;
  variant?:
    | OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>
    | undefined;
  sx?: SxProps | undefined;
  disabled?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const ButtonCL = ({
  color,
  text,
  onClick,
  datatestid,
  variant = 'outlined',
  sx,
  disabled,
  size = 'md',
}: ButtonCLProps) => (
  <Box>
    <Button
      sx={{ paddingX: '4rem', borderRadius: '50px', ...sx }}
      color={color}
      variant={variant}
      size={size}
      disabled={disabled}
      onClick={onClick}
      data-testid={datatestid}
    >
      {text}
    </Button>
  </Box>
);
