import React from 'react';
import { Box } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { TextCL } from '../../../../../components/library/TextCL';
import { Moolah } from '../../../../../theme';

const keysBasePath = 'admin.manageCompanyAccount.adminTable.statusTooltip';
const statusToolTipKeys = [
  `${keysBasePath}.active`,
  `${keysBasePath}.pending`,
  `${keysBasePath}.expired`,
];

const StatusTooltip = () => {
  const { t } = useTranslation();

  return (
    <Box color={Moolah.white}>
      <ul>
        {statusToolTipKeys.map((tKey) => (
          <li key={tKey}>
            <TextCL color={Moolah.white}>{t(tKey)}</TextCL>
          </li>
        ))}
      </ul>
    </Box>
  );
};

export default StatusTooltip;
