import React from 'react';

import './section.css';
import { Stack } from '@mui/joy';

export default function LearnMore() {
  return (
    <>
      <h2>On demand pay built for employers</h2>
      <Stack spacing={2}></Stack>
    </>
  );
}
