import { ActionMap } from '../actionMap';
import { AdminStateType, initialAdminState } from './adminState';
import { AggregateActions } from '../appContext';
import { GlobalActionTypes } from '../auth/authReducer';
import {
  ListEmployeesResponse,
  ProviderResponse,
} from '@dailypay/self-service-ts-sdk';
import { GetCardWithButtonQuery } from '../../cms/contentful/generated/graphql';

export enum AdminActionTypes {
  UpdateUsername = 'UPDATE_USERNAME',
  AcceptTerms = 'ACCEPT_TERMS',
  FetchProvidersSuccess = 'FETCH_PROVIDERS_SUCCESS',
  FetchProvidersFailure = 'FETCH_PROVIDERS_FAILURE',
  FetchEmployeesSuccess = 'FETCH_EMPLOYEES_SUCCESS',
  FetchEmployeesFailure = 'FETCH_EMPLOYEES_FAILURE',
  CardWithButtonFetched = 'CARD_WITH_BUTTON_FETCHED',
}

type AdminPayload = {
  [AdminActionTypes.UpdateUsername]: {
    username: string;
  };
  [AdminActionTypes.AcceptTerms]: {
    username: string;
  };
  [AdminActionTypes.FetchProvidersSuccess]: {
    providerList: ProviderResponse[];
  };
  [AdminActionTypes.FetchProvidersFailure]: undefined;
  [AdminActionTypes.FetchEmployeesSuccess]: {
    employeeList: ListEmployeesResponse;
  };
  [AdminActionTypes.FetchEmployeesFailure]: undefined;
  [AdminActionTypes.CardWithButtonFetched]: {
    cardData: GetCardWithButtonQuery | undefined;
  };
  [GlobalActionTypes.ResetAppState]: undefined;
};

export type AdminActions =
  ActionMap<AdminPayload>[keyof ActionMap<AdminPayload>];

export const adminReducer = (
  state: AdminStateType,
  action: AggregateActions,
): AdminStateType => {
  switch (action.type) {
    case AdminActionTypes.UpdateUsername:
      return {
        ...state,
        username: action.payload.username,
      };
    case AdminActionTypes.AcceptTerms:
      return {
        ...state,
        username: action.payload.username,
      };
    case AdminActionTypes.FetchProvidersSuccess:
      return {
        ...state,
        providerList: action.payload.providerList,
        mainProvider:
          action.payload.providerList?.length > 0
            ? action.payload.providerList[0]
            : undefined,
        providersFetched: true,
      };
    case AdminActionTypes.FetchProvidersFailure:
      return {
        ...state,
        providerList: undefined,
        mainProvider: undefined,
        providersFetched: true,
      };
    case AdminActionTypes.FetchEmployeesSuccess:
      return {
        ...state,
        employeeList: action.payload.employeeList,
      };

    case AdminActionTypes.FetchEmployeesFailure:
      return {
        ...state,
        employeeList: undefined,
      };
    case AdminActionTypes.CardWithButtonFetched:
      return {
        ...state,
        cardWithButton: action.payload.cardData,
      };
    case GlobalActionTypes.ResetAppState:
      return {
        ...initialAdminState,
      };
    default:
      return state;
  }
};
