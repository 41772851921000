import * as React from 'react';
import { Alert, Link } from '@mui/joy';
import { Moolah } from '../../theme';
import { TextCL } from './TextCL';

interface BannerCLrops {
  title?: string;
  onClick?: () => void;
  buttonText?: string;
  icon?: string;
}
export const BannerCL = ({
  title,
  buttonText,
  onClick,
  icon,
}: BannerCLrops) => {
  const onClickHandler = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <>
      <Alert
        sx={{
          backgroundColor: Moolah.navy[60],
          borderRadius: 0,
          paddingLeft: '3em',
        }}
      >
        <img width={24} src={icon} alt="bannerWarning" />
        <TextCL color="white">{title}</TextCL>
        {buttonText && (
          <Link underline="always" onClick={onClickHandler}>
            <TextCL color="white">{buttonText}</TextCL>
          </Link>
        )}
      </Alert>
    </>
  );
};
