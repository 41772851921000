import * as amplitude from '@amplitude/analytics-browser';
import { SelfServiceProgressStatus } from '../../state/auth/authState';
import { v4 as uuid } from 'uuid';

interface SelfServiceAnalyticsEventOutline {
  type: SelfServiceAnalyticsEventTypes;
  payload?: unknown;
}

/** Enum of event names as would be sent to amplitude */
export enum SelfServiceAnalyticsEventTypes {
  RegistrationFormNextButton = 'Registration-NextButton', // N/A - inferred from invalid or valid
  RegistrationFormNextButtonInvalid = 'Registration-NextButton-Invalid', // Done
  RegistrationFormBackButton = 'Registration-BackButton', // Done
  RegistrationFormValid = 'Registration-Valid', // Done
  RegistrationPageViewed = 'Registration-PageViewed', // Done
  RegistrationUnsupportedProviderPageViewed = 'RegistrationUnsupportedProvider-PageViewed', // Done
  RegistrationUnsupportedProviderCheckbox = 'RegistrationUnsupportedProvider-Checkbox', // Done
  RegistrationUnsupportedProviderOKButton = 'RegistrationUnsupportedProvider-OKButton', // Done
  RegistrationUnsupportedCompanySizePageViewed = 'RegistrationUnsupportedCompanySize-PageViewed', // Done
  RegistrationUnsupportedCompanySizeOKButton = 'RegistrationUnsupportedCompanySize-OKButton', // Done
  CreateAccountPageViewed = 'CreateAccount-PageViewed', // Done
  CreateAccountNext = 'CreateAccount-Next', // N/A - inferred from other types.
  CreateAccountNextInvalid = 'CreateAccount-Next-Invalid', // Done
  CreateAccountSuccess = 'CreateAccount-Success', // Done
  CreateAccountFailure = 'CreateAccount-Failure', // Done
  CreateAccountBack = 'CreateAccount-Back', // Done
  CreateAccountCheckEmailPageViewed = 'CreateAccountCheckEmail-PageViewed', // Done
  CreateAccountCheckEmailOKButton = 'CreateAccountCheckEmail-OKButton', // Done
  VerifyAccountPageViewed = 'VerifyAccount-PageViewed', // Done
  VerifyAccountSuccess = 'VerifyAccount-Success', // Done
  VerifyAccountFailure = 'VerifyAccount-Failure', // Done
  TermsPageViewed = 'Terms-PageViewed', // Done
  TermsBack = 'Terms-Back', // Done
  TermsCheckbox = 'Terms-Checkbox', // Done
  TermsNext = 'Terms-Next', // Done
  ConnectProviderNextButton = 'ConnectProvider-NextButton', // Done
  ConnectProviderNextButtonError = 'ConnectProvider-NextButton-Error', // Done
  ConnectProviderBackButton = 'ConnectProvider-BackButton', // Done
  ConnectProviderCollapseInstructions = 'ConnectProvider-CollapseInstructions', // Done
  ConnectProviderOpenInstructions = 'ConnectProvider-OpenInstructions', // Done
  ConnectProviderPageViewed = 'ConnectProvider-PageViewed', // Done
  ProviderConnectSuccessNextButton = 'ProviderConnectSuccess-NextButton', // Done
  ProviderConnectSuccessOKButton = 'ProviderConnectSuccess-OKButton', // Done
  ProviderConnectSuccessTryAgainButton = 'ProviderConnectSuccess-TryAgainButton', // Done
  ProviderConnectSuccessPageViewed = 'ProviderConnectSuccess-PageViewed', // Done
  EmployeeListPageViewed = 'EmployeesList-PageViewed', // Done
  APIRequestError = 'APIRequest-Error', // Done
  APIRequestSuccess = 'APIRequest-Success', // Done
  PortalDashboardPageViewed = 'PortalDashboard-PageViewed', // Done
  PortalEmployeePageViewed = 'PortalEmployee-PageViewed', // Done
  ThingsToDoShowHide = 'ThingsToDo-ShowHide', // Done
  DashboardClickHelpBox = 'Dashboard-ClickHelpBox', // Done
  EmployeePayPeriodChanged = 'Employee-PayPeriodChanged', // Done
  EditEmployeeContact = 'EditEmployee-Contact', // Done
  EditEmployeeContactSubmit = 'EditEmployee-ContactSubmit', // Done
  EmploymentStatusChanged = 'EmploymentStatus-Changed', // Done
  TerminateEmployeePanelViewed = 'TerminateEmployee-PanelViewed', // Done
  TerminateEmployeeCancelled = 'TerminateEmployee-Cancelled', // Done
  TerminateEmployeeConfirmed = 'TerminateEmployee-Confirmed', // Done
  RehireEmployeePanelViewed = 'RehireEmployee-PanelViewed', // Cant do yet
  RehireEmployeeCancelled = 'RehireEmployee-Cancelled', // Cant do yet
  RehireEmployeeConfirmed = 'RehireEmployee-Confirmed', // Cant do yet
  SendOnboardInvitationSent = 'SendOnboardInvitation-Sent', // Done
  PortalLogoutClicked = 'Portal-LogoutClicked', // Done
  EditAdminContact = 'EditAdmin-Contact', // Done
  EditAdminContactSubmit = 'EditAdmin-ContactSubmit', // Done
  DeleteAdminPanelViewed = 'DeleteAdmin-PanelViewed', // Done
  DeleteAdminCancelled = 'DeleteAdmin-Cancelled', // Done
  DeleteAdminConfirmed = 'DeleteAdmin-Confirmed', // Done
  ResendAdminPanelViewed = 'ResendAdmin-PanelViewed', // Done
  ResendAdminCancelled = 'ResendAdmin-Cancelled', // Done
  ResendAdminConfirmed = 'ResendAdmin-Confirmed', // Done
}

// Create a mapping of each event type to a page name
const selfServiceAnalyticsPageNameLookup: Record<
  SelfServiceAnalyticsEventTypes,
  string
> = {
  [SelfServiceAnalyticsEventTypes.RegistrationFormNextButton]: 'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationFormNextButtonInvalid]:
    'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationFormBackButton]: 'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationFormValid]: 'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationPageViewed]: 'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderPageViewed]:
    'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderCheckbox]:
    'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderOKButton]:
    'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizePageViewed]:
    'Registration',
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizeOKButton]:
    'Registration',
  [SelfServiceAnalyticsEventTypes.CreateAccountPageViewed]: 'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountNext]: 'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountNextInvalid]: 'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountSuccess]: 'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountFailure]: 'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountBack]: 'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailPageViewed]:
    'CreateAccount',
  [SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailOKButton]:
    'CreateAccount',
  [SelfServiceAnalyticsEventTypes.VerifyAccountPageViewed]: 'VerifyAccount',
  [SelfServiceAnalyticsEventTypes.VerifyAccountSuccess]: 'VerifyAccount',
  [SelfServiceAnalyticsEventTypes.VerifyAccountFailure]: 'VerifyAccount',
  [SelfServiceAnalyticsEventTypes.TermsPageViewed]: 'Terms',
  [SelfServiceAnalyticsEventTypes.TermsBack]: 'Terms',
  [SelfServiceAnalyticsEventTypes.TermsCheckbox]: 'Terms',
  [SelfServiceAnalyticsEventTypes.TermsNext]: 'Terms',
  [SelfServiceAnalyticsEventTypes.ConnectProviderNextButton]: 'ConnectProvider',
  [SelfServiceAnalyticsEventTypes.ConnectProviderNextButtonError]:
    'ConnectProvider',
  [SelfServiceAnalyticsEventTypes.ConnectProviderBackButton]: 'ConnectProvider',
  [SelfServiceAnalyticsEventTypes.ConnectProviderCollapseInstructions]:
    'ConnectProvider',
  [SelfServiceAnalyticsEventTypes.ConnectProviderOpenInstructions]:
    'ConnectProvider',
  [SelfServiceAnalyticsEventTypes.ConnectProviderPageViewed]: 'ConnectProvider',
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessNextButton]:
    'ProviderConnectSuccess',
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessOKButton]:
    'ProviderConnectSuccess',
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessTryAgainButton]:
    'ProviderConnectSuccess',
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessPageViewed]:
    'ProviderConnectSuccess',
  [SelfServiceAnalyticsEventTypes.EmployeeListPageViewed]: 'EmployeeList',
  [SelfServiceAnalyticsEventTypes.APIRequestError]: 'APIRequest',
  [SelfServiceAnalyticsEventTypes.APIRequestSuccess]: 'APIRequest',
  [SelfServiceAnalyticsEventTypes.PortalDashboardPageViewed]: 'PortalDashboard',
  [SelfServiceAnalyticsEventTypes.PortalEmployeePageViewed]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.ThingsToDoShowHide]: 'PortalDashboard',
  [SelfServiceAnalyticsEventTypes.DashboardClickHelpBox]: 'PortalDashboard',
  [SelfServiceAnalyticsEventTypes.EmployeePayPeriodChanged]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.EditEmployeeContact]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.EditEmployeeContactSubmit]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.EmploymentStatusChanged]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.TerminateEmployeePanelViewed]:
    'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.TerminateEmployeeCancelled]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.TerminateEmployeeConfirmed]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.RehireEmployeePanelViewed]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.RehireEmployeeCancelled]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.RehireEmployeeConfirmed]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.SendOnboardInvitationSent]: 'PortalEmployee',
  [SelfServiceAnalyticsEventTypes.PortalLogoutClicked]: 'Portal',
  [SelfServiceAnalyticsEventTypes.EditAdminContact]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.EditAdminContactSubmit]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.DeleteAdminPanelViewed]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.DeleteAdminCancelled]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.DeleteAdminConfirmed]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.ResendAdminPanelViewed]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.ResendAdminCancelled]: 'PortalAdmin',
  [SelfServiceAnalyticsEventTypes.ResendAdminConfirmed]: 'PortalAdmin',
};

export const getPageNameForSelfService = (
  eventType: SelfServiceAnalyticsEventTypes,
) => {
  return selfServiceAnalyticsPageNameLookup[eventType];
};

/** Enum of event names as would be sent to Quantum Metric */
export enum SelfServiceAnalyticsQMUserAttributeTypes {
  UserID = 'UserID', // not sure what this is
  analyticsVersion = 'analyticsVersion',
  cognitoEmail = 'cognito:email',
  cognitoUsername = 'cognito:username',
  companyName = 'companyName',
  companyRole = 'companyRole',
  coreProviderUUID = 'coreProviderUUID',
  dailyPayAccountCreated = 'dailyPayAccountCreated',
  emailAddress = 'emailAddress',
  emailVerified = 'emailVerified',
  firstName = 'firstName',
  lastName = 'lastName',
  numberOfEmployees = 'numberOfEmployees',
  payrollProviderContentfulId = 'payrollProviderContentfulId',
  payrollProviderKey = 'payrollProviderKey',
  selfServiceProgress = 'selfServiceProgress',
  termsAccepted = 'termsAccepted',
  termsVersion = 'termsVersion',
  timeManagementProviderKey = 'timeManagementProviderKey',
  referrer = 'referrer',
}

type QMEventFlags = 0 | 256;
type QMUserAttributionFlags = 0 | 2 | 4 | 8 | 16 | 256;

type SelfServiceAnalyticsQMUserAttributionLookup = {
  [key in SelfServiceAnalyticsQMUserAttributeTypes]:
    | undefined
    | { id: number | undefined; flag: QMUserAttributionFlags | undefined };
};

const selfServiceAnalyticUserAttributionQMLookup: SelfServiceAnalyticsQMUserAttributionLookup =
  {
    [SelfServiceAnalyticsQMUserAttributeTypes.UserID]: {
      id: 1963,
      flag: 256,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.analyticsVersion]: {
      id: 1964,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.cognitoEmail]: {
      id: 1965,
      flag: 4,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.cognitoUsername]: {
      id: 1966,
      flag: 256,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.companyName]: {
      id: 1967,
      flag: 256,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.companyRole]: {
      id: 1968,
      flag: 256,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.coreProviderUUID]: {
      id: 1969,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.dailyPayAccountCreated]: {
      id: 1970,
      flag: 256,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.emailAddress]: {
      id: 1971,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.emailVerified]: {
      id: 1972,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.firstName]: {
      id: 1973,
      flag: 8,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.lastName]: {
      id: 1974,
      flag: 16,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.numberOfEmployees]: {
      id: 1975,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.payrollProviderContentfulId]: {
      id: 1976,
      flag: 256,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.payrollProviderKey]: {
      id: 1977,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.selfServiceProgress]: {
      id: 1978,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.termsAccepted]: {
      id: 1979,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.termsVersion]: {
      id: 1980,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.timeManagementProviderKey]: {
      id: 1981,
      flag: 0,
    },
    [SelfServiceAnalyticsQMUserAttributeTypes.referrer]: {
      id: 2028,
      flag: 0,
    },
  };

// Type for the Quantum Metrics code lookup. This is a mapping of event types to QM event IDs.
type SelfServiceAnalyticsEventQMLookup = {
  [key in SelfServiceAnalyticsEventTypes]:
    | undefined
    | { id: number | undefined; flag: QMEventFlags | undefined };
};

// QM ID Lookup
const selfServiceAnalyticsEventQMLookup: SelfServiceAnalyticsEventQMLookup = {
  [SelfServiceAnalyticsEventTypes.RegistrationPageViewed]: {
    id: 1923,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationFormNextButton]: {
    id: 1924,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationFormNextButtonInvalid]: {
    id: 1925,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationFormBackButton]: {
    id: 1926,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationFormValid]: { id: 1927, flag: 0 },
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderPageViewed]: {
    id: 1928,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderCheckbox]: {
    id: 1929,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderOKButton]: {
    id: 1930,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizePageViewed]:
    { id: 1931, flag: 0 },
  [SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizeOKButton]: {
    id: 1932,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.CreateAccountPageViewed]: {
    id: 1933,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.CreateAccountNext]: { id: 1934, flag: 256 },
  [SelfServiceAnalyticsEventTypes.CreateAccountNextInvalid]: {
    id: 1935,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.CreateAccountSuccess]: {
    id: 1936,
    flag: 256,
  },
  [SelfServiceAnalyticsEventTypes.CreateAccountFailure]: {
    id: 1937,
    flag: 256,
  },
  [SelfServiceAnalyticsEventTypes.CreateAccountBack]: { id: 1938, flag: 256 },
  [SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailPageViewed]: {
    id: 1939,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailOKButton]: {
    id: 1940,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.VerifyAccountPageViewed]: {
    id: 1941,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.VerifyAccountSuccess]: { id: 1942, flag: 0 },
  [SelfServiceAnalyticsEventTypes.VerifyAccountFailure]: {
    id: 1943,
    flag: 256,
  },
  [SelfServiceAnalyticsEventTypes.TermsPageViewed]: { id: 1944, flag: 0 },
  [SelfServiceAnalyticsEventTypes.TermsBack]: { id: 1945, flag: 0 },
  [SelfServiceAnalyticsEventTypes.TermsCheckbox]: { id: 1946, flag: 0 },
  [SelfServiceAnalyticsEventTypes.TermsNext]: { id: 1947, flag: 0 },
  [SelfServiceAnalyticsEventTypes.ConnectProviderNextButton]: {
    id: 1948,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ConnectProviderNextButtonError]: {
    id: 1949,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ConnectProviderBackButton]: {
    id: 1950,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ConnectProviderCollapseInstructions]: {
    id: 1951,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ConnectProviderOpenInstructions]: {
    id: 1952,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ConnectProviderPageViewed]: {
    id: 1953,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessNextButton]: {
    id: 1954,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessOKButton]: {
    id: 1955,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessTryAgainButton]: {
    id: 1956,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ProviderConnectSuccessPageViewed]: {
    id: 1957,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EmployeeListPageViewed]: {
    id: 1958,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.APIRequestSuccess]: { id: 1959, flag: 0 },
  [SelfServiceAnalyticsEventTypes.APIRequestError]: { id: 1960, flag: 0 },
  [SelfServiceAnalyticsEventTypes.PortalDashboardPageViewed]: {
    id: 1961,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.PortalEmployeePageViewed]: {
    id: 1962,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ThingsToDoShowHide]: {
    id: 2005,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.DashboardClickHelpBox]: {
    id: 2006,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EmployeePayPeriodChanged]: {
    id: 2007,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EditEmployeeContact]: {
    id: 2008,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EditEmployeeContactSubmit]: {
    id: 2009,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EmploymentStatusChanged]: {
    id: 2010,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.TerminateEmployeePanelViewed]: {
    id: 2011,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.TerminateEmployeeCancelled]: {
    id: 2012,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.TerminateEmployeeConfirmed]: {
    id: 2013,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RehireEmployeePanelViewed]: {
    id: 2014,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RehireEmployeeCancelled]: {
    id: 2015,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.RehireEmployeeConfirmed]: {
    id: 2016,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.SendOnboardInvitationSent]: {
    id: 2017,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.PortalLogoutClicked]: {
    id: 2018,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EditAdminContact]: {
    id: 2039,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.EditAdminContactSubmit]: {
    id: 2040,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.DeleteAdminPanelViewed]: {
    id: 2041,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.DeleteAdminCancelled]: {
    id: 2042,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.DeleteAdminConfirmed]: {
    id: 2043,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ResendAdminPanelViewed]: {
    id: 2044,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ResendAdminCancelled]: {
    id: 2045,
    flag: 0,
  },
  [SelfServiceAnalyticsEventTypes.ResendAdminConfirmed]: {
    id: 2046,
    flag: 0,
  },
};

type SelfServiceAnalyticsEventRegistrationPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationPageViewed;
  };

type SelfServiceAnalyticsEventRegistrationNextButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationFormNextButton;
    payload: {
      registrationForm: unknown;
    };
  };

type SelfServiceAnalyticsEventRegistrationNextButtonInvalid =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationFormNextButtonInvalid;
    payload: {
      registrationForm: unknown;
    };
  };

type SelfServiceAnalyticsEventRegistrationBackButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationFormBackButton;
    payload: {
      registrationForm: unknown;
    };
  };

type SelfServiceAnalyticsEventRegistrationValid =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationFormValid;
    payload: {
      registrationForm: unknown;
    };
  };

type SelfServiceAnalyticsEventRegistrationUnsupportedProviderPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderPageViewed;
  };

type SelfServiceAnalyticsEventRegistrationUnsupportedProviderCheckbox =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderCheckbox;
    payload: {
      notify: boolean;
    };
  };

type SelfServiceAnalyticsEventRegistrationUnsupportedProviderOKButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedProviderOKButton;
    payload: {
      notify: boolean;
    };
  };

type SelfServiceAnalyticsEventRegistrationUnsupportedCompanySizePageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizePageViewed;
  };

type SelfServiceAnalyticsEventRegistrationUnsupportedCompanySizeOKButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RegistrationUnsupportedCompanySizeOKButton;
  };

type SelfServiceAnalyticsEventCreateAccountPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountPageViewed;
  };

type SelfServiceAnalyticsEventCreateAccountNext =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountNext;
    payload: {
      email: string;
    };
  };

type SelfServiceAnalyticsEventCreateAccountNextInvalid =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountNextInvalid;
  };

type SelfServiceAnalyticsEventCreateAccountSuccess =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountSuccess;
    payload: {
      email: string;
    };
  };

type SelfServiceAnalyticsEventCreateAccountFailure =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountFailure;
    payload: {
      email: string;
      reason: string;
    };
  };

type SelfServiceAnalyticsEventCreateAccountBack =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountBack;
    payload: {
      email: string;
    };
  };

type SelfServiceAnalyticsEventCreateAccountCheckEmailPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailPageViewed;
  };

type SelfServiceAnalyticsEventCreateAccountCheckEmailOKButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.CreateAccountCheckEmailOKButton;
  };

type SelfServiceAnalyticsEventVerifyAccountPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.VerifyAccountPageViewed;
  };

type SelfServiceAnalyticsEventVerifyAccountSuccess =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.VerifyAccountSuccess;
  };

type SelfServiceAnalyticsEventVerifyAccountFailure =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.VerifyAccountFailure;
    payload: {
      email: string;
      reason: string;
    };
  };

type SelfServiceAnalyticsEventTermsPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.TermsPageViewed;
  };

type SelfServiceAnalyticsEventTermsBack = SelfServiceAnalyticsEventOutline & {
  type: SelfServiceAnalyticsEventTypes.TermsBack;
};

type SelfServiceAnalyticsEventTermsCheckbox =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.TermsCheckbox;
    payload: {
      accept: boolean;
      termsVersion: number;
    };
  };

type SelfServiceAnalyticsEventTermsNext = SelfServiceAnalyticsEventOutline & {
  type: SelfServiceAnalyticsEventTypes.TermsNext;
  payload: {
    accept: boolean;
    termsVersion: number;
  };
};

type SelfServiceAnalyticsEventConnectProviderNextButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ConnectProviderNextButton;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventConnectProviderNextButtonError =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ConnectProviderNextButtonError;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
      error: string;
    };
  };

type SelfServiceAnalyticsEventConnectProviderBackButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ConnectProviderBackButton;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventConnectProviderCollapseInstructions =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ConnectProviderCollapseInstructions;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventConnectProviderOpenInstructions =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ConnectProviderOpenInstructions;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventConnectProviderPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ConnectProviderPageViewed;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventProviderConnectSuccessNextButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessNextButton;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventProviderConnectSuccessOKButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessOKButton;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventProviderConnectSuccessTryAgainButton =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessTryAgainButton;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventProviderConnectSuccessPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessPageViewed;
    payload: {
      providerType: 'payroll' | 'timeattendance';
      providerName: string;
      pageNumber: number;
    };
  };

type SelfServiceAnalyticsEventEmployeeListPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EmployeeListPageViewed;
    payload: {
      providerUUID: string | undefined;
      dpStatusFilter: unknown;
      employeeStatusFilter: unknown;
    };
  };

type SelfServiceAnalyticsEventApiFetchSuccess =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.APIRequestSuccess;
    payload: {
      path: string | undefined;
      correlationID: string | undefined;
    };
  };

type SelfServiceAnalyticsEventApiFetchError =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.APIRequestError;
    payload: {
      path: string | undefined;
      correlationID: string | undefined;
      error?: string;
      errorCode?: string;
    };
  };

type SelfServiceAnalyticsEventPortalDashboardPageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.PortalDashboardPageViewed;
  };

type SelfServiceAnalyticsEventPortalEmployeePageViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.PortalEmployeePageViewed;
    payload: {
      tab: string;
      employeeUUID: string;
      providerUUID: string;
    };
  };

type SelfServiceAnalyticsEventThingsToDoShowHide =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ThingsToDoShowHide;
    payload: {
      show: boolean;
    };
  };

type SelfServiceAnalyticsEventDashboardClickHelpBox =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.DashboardClickHelpBox;
    payload: {
      helpBox: string;
    };
  };

type SelfServiceAnalyticsEventEmployeePayPeriodChanged =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EmployeePayPeriodChanged;
    payload: {
      employeeUUID: string;
      payPeriodID: string;
      dates: string;
    };
  };

type SelfServiceAnalyticsEventEditEmployeeContact =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EditEmployeeContact;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventEditEmployeeContactSubmit =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EditEmployeeContactSubmit;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventEmploymentStatusChanged =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EmploymentStatusChanged;
    payload: {
      employeeUUID: string;
      employmentStatus: string;
    };
  };

type SelfServiceAnalyticsEventTerminateEmployeePanelViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.TerminateEmployeePanelViewed;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventTerminateEmployeeCancelled =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.TerminateEmployeeCancelled;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventTerminateEmployeeConfirmed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.TerminateEmployeeConfirmed;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventRehireEmployeePanelViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RehireEmployeePanelViewed;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventRehireEmployeeCancelled =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RehireEmployeeCancelled;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventRehireEmployeeConfirmed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.RehireEmployeeConfirmed;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventSendOnboardInvitationSent =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.SendOnboardInvitationSent;
    payload: {
      employeeUUID: string;
    };
  };

type SelfServiceAnalyticsEventPortalLogoutClicked =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.PortalLogoutClicked;
  };

type SelfServiceAnalyticsEventEditAdminContact =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EditAdminContact;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventEditAdminContactSubmit =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.EditAdminContactSubmit;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventDeleteAdminPanelViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.DeleteAdminPanelViewed;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventDeleteAdminCancelled =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.DeleteAdminCancelled;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventDeleteAdminConfirmed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.DeleteAdminConfirmed;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventResendAdminPanelViewed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ResendAdminPanelViewed;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventResendAdminCancelled =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ResendAdminCancelled;
    payload: {
      adminUUID: string;
    };
  };

type SelfServiceAnalyticsEventResendAdminConfirmed =
  SelfServiceAnalyticsEventOutline & {
    type: SelfServiceAnalyticsEventTypes.ResendAdminConfirmed;
    payload: {
      adminUUID: string;
    };
  };

export type SelfServiceAnalyticsEvent =
  | SelfServiceAnalyticsEventRegistrationPageViewed
  | SelfServiceAnalyticsEventRegistrationNextButton
  | SelfServiceAnalyticsEventRegistrationNextButtonInvalid
  | SelfServiceAnalyticsEventRegistrationBackButton
  | SelfServiceAnalyticsEventRegistrationValid
  | SelfServiceAnalyticsEventRegistrationUnsupportedProviderPageViewed
  | SelfServiceAnalyticsEventRegistrationUnsupportedProviderCheckbox
  | SelfServiceAnalyticsEventRegistrationUnsupportedProviderOKButton
  | SelfServiceAnalyticsEventRegistrationUnsupportedCompanySizePageViewed
  | SelfServiceAnalyticsEventRegistrationUnsupportedCompanySizeOKButton
  | SelfServiceAnalyticsEventCreateAccountPageViewed
  | SelfServiceAnalyticsEventCreateAccountNext
  | SelfServiceAnalyticsEventCreateAccountNextInvalid
  | SelfServiceAnalyticsEventCreateAccountSuccess
  | SelfServiceAnalyticsEventCreateAccountFailure
  | SelfServiceAnalyticsEventCreateAccountBack
  | SelfServiceAnalyticsEventCreateAccountCheckEmailPageViewed
  | SelfServiceAnalyticsEventCreateAccountCheckEmailOKButton
  | SelfServiceAnalyticsEventVerifyAccountPageViewed
  | SelfServiceAnalyticsEventVerifyAccountSuccess
  | SelfServiceAnalyticsEventVerifyAccountFailure
  | SelfServiceAnalyticsEventTermsPageViewed
  | SelfServiceAnalyticsEventTermsBack
  | SelfServiceAnalyticsEventTermsCheckbox
  | SelfServiceAnalyticsEventTermsNext
  | SelfServiceAnalyticsEventConnectProviderNextButton
  | SelfServiceAnalyticsEventConnectProviderNextButtonError
  | SelfServiceAnalyticsEventConnectProviderBackButton
  | SelfServiceAnalyticsEventConnectProviderCollapseInstructions
  | SelfServiceAnalyticsEventConnectProviderOpenInstructions
  | SelfServiceAnalyticsEventConnectProviderPageViewed
  | SelfServiceAnalyticsEventProviderConnectSuccessNextButton
  | SelfServiceAnalyticsEventProviderConnectSuccessOKButton
  | SelfServiceAnalyticsEventProviderConnectSuccessTryAgainButton
  | SelfServiceAnalyticsEventProviderConnectSuccessPageViewed
  | SelfServiceAnalyticsEventEmployeeListPageViewed
  | SelfServiceAnalyticsEventApiFetchSuccess
  | SelfServiceAnalyticsEventApiFetchError
  | SelfServiceAnalyticsEventPortalDashboardPageViewed
  | SelfServiceAnalyticsEventPortalEmployeePageViewed
  | SelfServiceAnalyticsEventThingsToDoShowHide
  | SelfServiceAnalyticsEventDashboardClickHelpBox
  | SelfServiceAnalyticsEventEmployeePayPeriodChanged
  | SelfServiceAnalyticsEventEditEmployeeContact
  | SelfServiceAnalyticsEventEditEmployeeContactSubmit
  | SelfServiceAnalyticsEventEmploymentStatusChanged
  | SelfServiceAnalyticsEventTerminateEmployeePanelViewed
  | SelfServiceAnalyticsEventTerminateEmployeeCancelled
  | SelfServiceAnalyticsEventTerminateEmployeeConfirmed
  | SelfServiceAnalyticsEventRehireEmployeePanelViewed
  | SelfServiceAnalyticsEventRehireEmployeeCancelled
  | SelfServiceAnalyticsEventRehireEmployeeConfirmed
  | SelfServiceAnalyticsEventSendOnboardInvitationSent
  | SelfServiceAnalyticsEventPortalLogoutClicked
  | SelfServiceAnalyticsEventEditAdminContact
  | SelfServiceAnalyticsEventEditAdminContactSubmit
  | SelfServiceAnalyticsEventDeleteAdminPanelViewed
  | SelfServiceAnalyticsEventDeleteAdminCancelled
  | SelfServiceAnalyticsEventDeleteAdminConfirmed
  | SelfServiceAnalyticsEventResendAdminPanelViewed
  | SelfServiceAnalyticsEventResendAdminCancelled
  | SelfServiceAnalyticsEventResendAdminConfirmed;

const ssEventPrefix = 'SelfSrv-';

export const logAnalyticsEventForSelfService = (
  generic: SelfServiceAnalyticsEvent,
) => {
  const QMEventId = selfServiceAnalyticsEventQMLookup[generic.type];

  let eventProperties: Record<string, unknown> = {};

  if (QMEventId) {
    eventProperties.QMEventId = QMEventId.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.QuantumMetricAPI?.sendEvent(
      QMEventId.id,
      QMEventId.flag,
      JSON.stringify(generic.payload),
    );
  }

  if (generic.payload) {
    eventProperties = {
      ...eventProperties,
      ...generic.payload,
    };
  } else {
    eventProperties = {
      ...eventProperties,
    };
  }
  amplitude.track(ssEventPrefix + generic.type, eventProperties);
};

export type AnalyticsUserPropertyMap = Record<
  string,
  string | number | boolean
>;

export const AnalyticsVersion = '1.0.6';

export const logUserPropertiesForSelfService = (
  userProperties: AnalyticsUserPropertyMap,
) => {
  const identifyObj = new amplitude.Identify();
  // eslint-disable-next-line no-console
  console.log('--- ', userProperties);
  Object.keys(userProperties).forEach((key) => {
    if (
      key !== 'selfServiceProgress' ||
      userProperties[key] !== SelfServiceProgressStatus.Undetermined
    ) {
      identifyObj.set(key, userProperties[key]);
      const QMEventId =
        selfServiceAnalyticUserAttributionQMLookup[
          key as keyof typeof selfServiceAnalyticUserAttributionQMLookup
        ];
      // eslint-disable-next-line no-console
      console.log(QMEventId, key, userProperties[key]);
      if (QMEventId) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        window.QuantumMetricAPI?.sendEvent(
          QMEventId.id,
          QMEventId.flag,
          userProperties[key],
        );
      }
    }
  });

  identifyObj.set('AnalyticsVersion', AnalyticsVersion);

  const analyticsVersionQmEventId =
    selfServiceAnalyticUserAttributionQMLookup.analyticsVersion;
  if (analyticsVersionQmEventId) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.QuantumMetricAPI?.sendEvent(
      analyticsVersionQmEventId.id,
      analyticsVersionQmEventId.flag,
      AnalyticsVersion,
    );
  }

  amplitude.identify(identifyObj);

  console.debug(
    'Analytics User Properties:  ',
    JSON.stringify(identifyObj.getUserProperties()),
  );
};

export const logUserNameForSelfService = (userId: string | undefined) => {
  console.debug(Date.now() + 'Analytics User ID:  ', userId);
  amplitude.setUserId(userId);
};

export const signoutOfAmplitude = () => {
  amplitude.setUserId(undefined);
  amplitude.setDeviceId(uuid());
  amplitude.reset();
};

// Cookie code here is related to deleting the Quantum Metric User cookie on logout
// This is needed because Quantum Metric will not delete the cookie on logout, .newSession() only resets the session ID
// This is a workaround until Quantum Metric fixes this issue
const getCookie = (name: string) => {
  return document.cookie.split(';').some((c) => {
    return c.trim().startsWith(name + '=');
  });
};

const deleteCookie = (
  name: string,
  path: string,
  domain: string,
  newSession: () => void,
) => {
  if (getCookie(name)) {
    document.cookie =
      name +
      '=' +
      (path ? ';path=' + path : '') +
      (domain ? ';domain=' + domain : '') +
      ';expires=Thu, 01 Jan 1970 00:00:01 GMT';

    newSession();
  }
};

export const removeQMCookie = (callback: () => void) => {
  deleteCookie('QuantumMetricUserID', '/', 'dailypay.com', callback);
};

const logPagesToConsole = false;
const logPageMappingToConsole = () => {
  // Create a tabular format for Object.entries(selfServiceAnalyticsPageNameLookup)
  // eslint-disable-next-line no-console
  console.table(
    Object.entries(selfServiceAnalyticsPageNameLookup).map(([key, value]) => ({
      event: `${ssEventPrefix}${key}`,
      page: value,
    })),
  );
};

export const analyticsTableValues = Object.entries(
  selfServiceAnalyticsPageNameLookup,
).map(([key, value]) => ({
  event: `${ssEventPrefix}${key}`,
  page: value,
}));

if (logPagesToConsole) {
  logPageMappingToConsole();
}
