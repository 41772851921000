import * as React from 'react';
import { Button } from '@mui/joy';
import { TextCL } from '../../library/TextCL';
import { Moolah } from '../../../theme';

export interface AdminNavLinkProps {
  title: string;
  iconDetails: string;
  linkFunction: () => void;
  currentPath: string;
  navPath: string;
  dataTestId?: string;
}

export const AdminNavLink = ({
  title,
  iconDetails,
  linkFunction,
  currentPath,
  navPath,
  dataTestId,
}: AdminNavLinkProps) => {
  const isCurrent = currentPath === navPath;
  const autoFocus = isCurrent;

  return (
    <div>
      <Button
        sx={{
          display: 'flex',
          flexGrow: 'true',
          justifyContent: 'flex-start',
          paddingLeft: '16px',
          width: '100%',
          borderRadius: '50px',
          backgroundColor: 'transparent',
          '&:focus': {
            backgroundColor: Moolah.white,
          },
          '&:hover': {
            backgroundColor: Moolah.white,
          },
        }}
        onClick={() => linkFunction()}
        autoFocus={autoFocus}
        data-testid={dataTestId}
      >
        <img width={24} style={{ marginRight: '1em' }} src={iconDetails}></img>
        <TextCL textAlign={'left'}>{title}</TextCL>
      </Button>
    </div>
  );
};
