import { createTheme } from '@mui/material/styles';

export const primaryDark = '#091459';
export const primaryOrange = '#EC5A29';
export const lightGrey = '#DDE2EC';
export const lighterGrey = '#F0F2F5';
export const darkGrey = '#6B737B';
export const calloutYellow = '#FFFBE3';
export const dodgerBlue = '#418BF7';
export const darkGreen = '#2D7934';
export const darkRed = '#B81E20';
export const darkBlue = '#0B349D';
export const midnightBlue = '#04143A';
export const customBoxShadow =
  '0px 1px 4px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.02), 0px 1px 12px rgba(10, 10, 52, 0.1);';
export const wasColor = {
  green: '#30C262',
  darkGreen: '#2C6124',
  orange: '#F28C0F',
  red: '#C82B2D',
  blue: '#337FF2',
};

export const theme = createTheme({
  typography: {
    fontFamily: [
      'Inter, "Playfair Display", "Work Sans", Horizon_Variable, Arial, "Plus Jakarta Sans"',
    ].join(','),
    body2: {
      fontSize: 12,
      fontWeight: 400,
      color: darkGrey,
    },
    h6: {
      fontFamily: 'Horizon_Variable',
    },
    h5: {
      fontFamily: 'Horizon_Variable',
    },
    h4: {
      fontFamily: 'Horizon_Variable',
    },
    h3: {
      fontFamily: 'Horizon_Variable',
    },
    h2: {
      fontFamily: 'Horizon_Variable',
    },
  },
  palette: {
    success: {
      main: '#00A671',
      light: '#00D28F',
      contrastText: '#E6FAF4',
    },
    error: {
      main: '#D32F2F',
      contrastText: '#FCF2F2',
      light: '#AE2443',
    },
    warning: {
      main: '#B58800',
      light: '#FFF9EB',
      dark: '#F1C21B',
    },
    grey: {
      '500': '#A8AAB2',
    },
    primary: {
      main: '#1B1B1E', // black
      light: '#343D4A', // grey
      dark: '#EC5A29', // orange
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFFFFF',
      dark: primaryDark,
      light: '#EEF1F4',
      contrastText: '#6B737B',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        label: {
          fontWeight: 'bold',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 32,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 42,
          fontFamily: 'Inter',
          fontWeight: 600,
          textTransform: 'none',
        },
        contained: {
          background: primaryDark,
        },
        outlined: {
          border: '2px solid #091459',
          '&:hover': {
            borderWidth: '2px',
          },
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        underlineAlways: {
          fontFamily: 'Inter',
          color: dodgerBlue,
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          ':nth-of-type(even)': {
            backgroundColor: '#FAFBFC',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 14,
          lineHeight: '16px',
          paddingTop: 12,
          paddingBottom: 12,
          borderBottom: 'none',
          ':first-of-type': {
            paddingLeft: 32,
          },
          ':last-of-type': {
            paddingRight: 32,
          },
        },
        head: {
          fontSize: 10,
          lineHeight: '12px',
          fontWeight: 700,
          color: midnightBlue,
          textTransform: 'uppercase',
          paddingBottom: 8,
          background: '#FFFFFF',
          borderBottom: `1px solid ${lightGrey}`,
          letterSpacing: '0.6px',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: primaryOrange,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 10,
          lineHeight: '12px',
          fontWeight: 700,
          minWidth: 180,
          '&.Mui-selected': {
            color: primaryOrange,
          },
        },
      },
    },
  },
});
