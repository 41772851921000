import React from 'react';

import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { Grid } from '@mui/joy';
import AcceptTerms from '../../onboarding/acceptTerms/acceptTerms';

export default function AcceptUpdatedTerms() {
  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });

  return (
    <Grid container xs={12} md={12} lg={12}>
      <AcceptTerms hasTermsBeenUpdated={true} />
    </Grid>
  );
}
