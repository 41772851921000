import React, { FC } from 'react';

import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Moolah } from '../../../theme';
import { TextCL } from '../../library/TextCL';
import EditButton from './EditButton';
import EditActionButtons from './EditActionButtons';

interface Props {
  titleKey: string;
  isEditing: boolean;
  setShowEditInputs?: (val: boolean) => void;
  onSave?: () => void;
  onCancel?: () => void;
}

const EditFormHeader: FC<Props> = ({
  titleKey,
  isEditing,
  setShowEditInputs,
  onSave,
  onCancel,
}) => {
  const { t } = useTranslation();

  const isEditable = [
    t('admin.employees.accountInfo.contact.title'),
    t('admin.employees.accountInfo.employment.title'),
    t('admin.employees.accountInfo.bank.title'),
  ].includes(titleKey);

  return (
    <Stack
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingTop: '12px',
        paddingBottom: '12px',
        backgroundColor: isEditing ? Moolah.navy[60] : 'transparent',
        marginLeft: '-40px',
      }}
    >
      <div
        style={{
          paddingLeft: '24px',
        }}
      >
        <TextCL
          variant="h4"
          fontWeight="bold"
          color={isEditing ? Moolah.white : Moolah.navy[60]}
        >
          {t(titleKey)}
        </TextCL>
      </div>
      {isEditable && (
        <>
          {isEditing ? (
            <EditActionButtons
              setShowEditInputs={setShowEditInputs}
              onSave={onSave}
              onCancel={onCancel}
            />
          ) : (
            <EditButton setShowEditInputs={setShowEditInputs} />
          )}
        </>
      )}
    </Stack>
  );
};
export default EditFormHeader;
