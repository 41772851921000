/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';

type Props = { [key: string]: string | number | boolean | undefined };

type NamedStyles<T> = {
  [P in keyof T]: React.CSSProperties;
};

type ThemeHook<T extends NamedStyles<T>> = (props?: Props) => NamedStyles<T>;
type ThemeFunc<T extends NamedStyles<T>> = (props?: Props) => T;

export const makeStyles =
  <T extends NamedStyles<T> | NamedStyles<any>>(
    f: ThemeFunc<T>,
  ): ThemeHook<T> =>
  (props) => {
    return useMemo(() => f(props), [props]);
  };
