import React from 'react';

import './employees.css';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../useRouteProtection';
import { Grid, Input, Stack } from '@mui/joy';
import FormFoundations from '../../../layouts/foundations/FormFoundations';
import EmployeesTable from './components/EmployeesTable';
import AdminUserLogout from '../../../components/admin/AdminUserLogout';
import PageHeader from '../../../components/common/page-header/pageHeader';
import { iconLibrary } from '../../../assets/icons/icon-library';
import SearchIcon from '@mui/icons-material/Search';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';
import { debounce } from 'lodash';
import { validateSearchInput } from '../../../utils/validation';
import { TextCL } from '../../../components/library/TextCL';

export default function Employees() {
  const [searchBoxValue, setSearchBoxValue] = React.useState<string>('');
  const [query, setQuery] = React.useState<string>('');
  const [errorMessage, setErrorMessage] = React.useState('');
  const { isLargerScreen } = useScreenQuery();

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Admin Dashboard',
  });

  const validateInput = debounce((input, callback) => {
    setQuery(input);
    const validationMessage = validateSearchInput(input);
    setErrorMessage(validationMessage);
    callback(validationMessage);
  }, 50);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    validateInput(inputValue, (error: string) => {
      if (!error) {
        setSearchBoxValue(inputValue);
      }
    });
  };

  return (
    <>
      <Grid container xs={12} md={12} lg={12}>
        <Grid xs={12} md={6} lg={6} sx={{ paddingBottom: '24px' }}>
          <PageHeader
            image={iconLibrary.heading.employees}
            imageAlt="admin.employees.title.icon"
            titleKey={'admin.employees.title'}
            excludeBackButton={true}
          />
        </Grid>

        <Grid
          container
          alignItems={'center'}
          xs={12}
          md={6}
          lg={6}
          sx={{ paddingBottom: '37px' }}
        >
          <Grid xs={12} md={6} lg={6} sx={{ position: 'relative' }}>
            <Input
              size="sm"
              data-qm-encrypt={true}
              placeholder="Search by name or email"
              startDecorator={<SearchIcon />}
              sx={{
                flexGrow: 1,
                borderRadius: 50,
                boxShadow: '0px 4px 4px rgba(15, 22, 57, 0.04)',
              }}
              value={query}
              onChange={handleInputChange}
            />
            {errorMessage && (
              <div
                style={{
                  position: 'absolute',
                  bottom: '-20px',
                  left: '0',
                }}
              >
                <TextCL variant="error">{errorMessage}</TextCL>
              </div>
            )}
          </Grid>

          {isLargerScreen && (
            <Grid xs={12} md={6} lg={6}>
              <AdminUserLogout />
            </Grid>
          )}
        </Grid>
      </Grid>

      <FormFoundations.FormOutlineFlex sx={{ maxWidth: '100hw' }}>
        <Stack sx={{ display: 'flex' }}>
          <EmployeesTable searchBoxValue={searchBoxValue} />
        </Stack>
      </FormFoundations.FormOutlineFlex>
    </>
  );
}
