import React from 'react';
import { maskAccountNumber } from '../../../../../formatters/card-formatter';
import { useTranslation } from 'react-i18next';
import BankInfoFields from './BankInfoFields';
import { BankDetails } from './types';

const BankInfoMobile: React.FC<BankDetails> = ({ details }) => {
  const { t } = useTranslation();

  return (
    <>
      {details.map((bankInfo) => (
        <React.Fragment key={bankInfo.accountNumber}>
          <BankInfoFields
            label={t('admin.employees.accountInfo.bank.type')}
            value={bankInfo.accountType}
            replayEncrypt={true}
          />
          <BankInfoFields
            label={t('admin.employees.accountInfo.bank.name')}
            value={bankInfo.bankName}
            replayBlock={true}
          />
          <BankInfoFields
            label={t('admin.employees.accountInfo.bank.accNumber')}
            value={maskAccountNumber(bankInfo.accountNumber)}
            replayBlock={true}
          />
          <BankInfoFields
            label={t('admin.employees.accountInfo.bank.routingNumber')}
            value={bankInfo.routingNumber}
            replayEncrypt={true}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default BankInfoMobile;
