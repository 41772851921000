/* eslint-disable no-console */
import { Api, HttpResponse } from '@dailypay/self-service-ts-sdk';
import * as Auth from 'aws-amplify/auth';
import ENV_VARS from '../environment/envvars';
import { captureException } from './sentry';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from './analytic-events/SelfServiceAnalyticsTypes';

const apiURL = ENV_VARS.apiBaseURL || '';

const constructApi = (
  baseUrl: string,
  transactionId: string,
  accessToken?: string,
  idToken?: string,
) => {
  const securityWorkerHeaders =
    accessToken && idToken && transactionId
      ? {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Id-Token': idToken,
          },
        }
      : {};

  const securityWorker = () => securityWorkerHeaders;

  return new Api({
    baseUrl,
    baseApiParams: {
      format: 'json',
      logLevel: 'none',
      // secure: true,
      headers: {
        'accept-encoding': 'gzip',
        'x-datadog-trace-id': transactionId,
        'x-datadog-parent-id': transactionId,
      },
    },
    securityWorker,
  });
};

export const getApiInstance = async (transactionId: string) => {
  try {
    const session = await Auth.fetchAuthSession();

    if (session) {
      return constructApi(
        apiURL,
        transactionId,
        session?.tokens?.accessToken?.toString(),
        session?.tokens?.idToken?.toString(),
      );
    }
  } catch (error) {
    captureException(error, {
      extra: {
        message: 'Error in getApiInstance call',
      },
    });
  }
  return constructApi(apiURL, transactionId, undefined, undefined);
};

export function getTrackingID() {
  const hexString = Array(16)
    .fill(0)
    .map(() => Math.round(Math.random() * 0xf).toString(16))
    .join('');

  return BigInt(`0x${hexString}`).toString();
}

export function callApiAndResolve() {
  const resolve = <T>(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    apiCall: (api: Api<any>) => Promise<HttpResponse<T, any>>,
    transactionId: string = getTrackingID(),
    trackAnalytics = false,
  ): Promise<T> =>
    getApiInstance(transactionId)
      .then(apiCall)
      .then((response) => {
        if (trackAnalytics) {
          if (response.ok) {
            logAnalyticsEventForSelfService({
              type: SelfServiceAnalyticsEventTypes.APIRequestSuccess,
              payload: {
                path: response.url,
                correlationID: transactionId,
              },
            });
          } else {
            logAnalyticsEventForSelfService({
              type: SelfServiceAnalyticsEventTypes.APIRequestError,
              payload: {
                path: response.url,
                correlationID: transactionId,
                error: response.error,
              },
            });
          }
        }

        return response.ok ? response.data : Promise.reject(response.error);
      });

  return {
    resolve,
  };
}

/**
 * Gets username from Access Token associated with the
 * logged in user.
 *
 * @returns {string} username from access token
 */
export const getUsernameUID = async () => {
  return Auth.getCurrentUser()
    .then((u) => u.username)
    .catch(() => null);
};
