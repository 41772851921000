import React, { FC } from 'react';
import { CredentialField } from '@dailypay/self-service-ts-sdk';
import { Grid } from '@mui/joy';
import { useFormContext } from 'react-hook-form';
import InputBoxCL from '../../../../../../components/library/forms/InputBoxCL';
import { ConnectFormData } from '../../payrollConfigForm';
import { useTranslation } from 'react-i18next';

interface Props {
  credentials: CredentialField[];
  fieldNamesToInclude: string[];
}

export const filterCredentialsByFieldNames = (
  credentials: CredentialField[],
  fieldNamesToInclude: string[],
) => {
  return credentials.filter((key) => fieldNamesToInclude.includes(key.name));
};

const CredentialsContainer: FC<Props> = ({
  credentials,
  fieldNamesToInclude,
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext<ConnectFormData>();

  const filteredCredentials = filterCredentialsByFieldNames(
    credentials,
    fieldNamesToInclude,
  );

  return filteredCredentials.map(
    ({ name, field_type, maxLength, display_name }, index) => {
      const isCredential = field_type === 'credential';

      return (
        <Grid
          xs={12}
          md={6}
          lg={6}
          pl={0}
          pb={3}
          key={name}
          {...(index > 0 && { pt: 3 })}
        >
          <InputBoxCL
            key={name}
            control={control}
            fieldName={name}
            label={
              name === 'subscriber_email'
                ? t('onboarding.connect.payrollSetup.common.email')
                : display_name.toLocaleUpperCase()
            }
            type={isCredential ? 'password' : field_type}
            maxLength={maxLength}
            replayEncrypt={!isCredential}
            replayBlock={isCredential}
          />
        </Grid>
      );
    },
  );
};

export default CredentialsContainer;
