import * as React from 'react';
import Card from '@mui/joy/Card';
import { TextCL } from '../library/TextCL';
import { Button, CardContent, CardOverflow } from '@mui/joy';
import { Moolah } from '../../theme';
import { TEST_IDS } from '../../test-utils/test-ids';
import { useNavigate } from 'react-router-dom';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../services/analytic-events/SelfServiceAnalyticsTypes';

interface CardWithActionsProps {
  label?: string | null | undefined;
  linkHref?: string | null | undefined;
  title?: string | null | undefined;
  textLarge?: string;
  subTitle?: string;
  text?: string | null | undefined;
  headingColor?: string;
  headingTextColor?: string;
}

const CardWithActions: React.FC<CardWithActionsProps> = ({
  label,
  linkHref,
  title,
  text,
  headingColor,
  headingTextColor,
}: CardWithActionsProps) => {
  const navigate = useNavigate();

  return (
    <Card sx={{ minHeight: '240px', flexGrow: 1 }}>
      <CardOverflow
        variant="soft"
        sx={{
          paddingTop: '5px',
          paddingBottom: '5px',
          textAlign: 'center',
          borderBottom: '1px solid',
          borderColor: 'divider',
          backgroundColor: headingColor,
        }}
      >
        <TextCL variant="h4" color={headingTextColor}>
          {title}
        </TextCL>
      </CardOverflow>
      <CardContent
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          textAlign: 'center',
        }}
      >
        <div style={{ paddingTop: '8px' }}>
          <TextCL>{text}</TextCL>
        </div>
        {linkHref && (
          <Button
            variant="solid"
            size="sm"
            sx={{
              borderRadius: '50px',
              backgroundColor: Moolah.navy[60],
              color: Moolah.white,
              marginTop: '8px',
              paddingTop: '8px',
              paddingBottom: '8px',
            }}
            data-testid={`${TEST_IDS.admin.card.button}`}
            onClick={() => {
              linkHref.includes('employees')
                ? navigate(linkHref)
                : window.open(linkHref, '_blank');
              logAnalyticsEventForSelfService({
                type: SelfServiceAnalyticsEventTypes.DashboardClickHelpBox,
                payload: {
                  helpBox: title || '',
                },
              });
            }}
          >
            <TextCL color={Moolah.white}>{label}</TextCL>
          </Button>
        )}
      </CardContent>
    </Card>
  );
};

export default CardWithActions;
