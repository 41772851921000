export const Moolah = {
  white: '#FFFFFF',
  gray: {
    10: '#F5F5F5',
    20: '#E1E4E5',
    40: '#CACACA',
    50: '#9B9B9B',
    80: '#676B75',
    100: '#383D4A',
    500: '#71717A',
  },
  navy: {
    10: '#EEF2F9',
    40: '#CDD0D8',
    60: '#04143A',
  },
  blue: {
    20: '#F1F6FE',
    30: '#F1F8FA',
    40: '#D0E2FC',
    60: '#146CF0',
  },
  orange: {
    20: '#FFF4F0',
    40: '#FFDBCC',
    60: '#FF4C00',
  },
  green: {
    20: '#383D4A',
    40: '#D1E7DA',
    60: '#38863E',
  },
  red: {
    20: '#FEF0F0',
    40: '#FBCDCD',
    60: '#E90606',
  },
  gold: {
    40: '#FDEBD2',
    60: '#F39C1E',
  },
  brown: {
    40: '#BFC0C2',
    60: '#231F20',
  },
  alerts: {
    error: '#DB1919',
    warning: '#C58544',
    success: '#2FAA53',
  },
};

export const Horizon = {
  dawnOrange: '#FF4C00',
  midnightBlue: {
    1: '#04143A',
    2: '#07246B',
    3: '#0B349D',
    4: '#0E44CE',
    5: '#337FF2',
  },
  gray: {
    1: '#383D4A',
    2: '#676B75',
    3: '#9799A0',
    4: '#C6C7CA',
    5: '#F5F5F5',
  },
  green: {
    1: '#2C6124',
    2: '#2D7934',
    3: '#2E9243',
    4: '#2E9243',
    5: '#30C262',
    6: '#2FAA53',
  },
  red: {
    1: '#A71013',
    2: '#B81E20',
    3: '#C82B2D',
    4: '#D93939',
    5: '#F64C4C',
  },
  yellow: '#C58544',
  violet: '#7B1771',
  orange: '#DB5C2A',
  white: '#FFFFFF',
  info: {
    1: '#9ad2e3',
    2: '#6AB6CD',
    3: '#F0F8FA',
    4: '#25ABC1',
    5: '#6AB6CD40',
  },
};
