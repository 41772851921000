import React from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { Horizon } from '../../../../../theme';

interface BankInfoFieldProps {
  label: string;
  value: string | undefined;
  replayEncrypt?: boolean;
  replayBlock?: boolean;
}

const BankInfoFields: React.FC<BankInfoFieldProps> = ({
  label,
  value,
  replayEncrypt = false,
  replayBlock = false,
}) => {
  return (
    <Grid xs={12} sm={6} md={3} lg={3} className="formField">
      <div
        style={{
          backgroundColor: Horizon.info[3],
          paddingTop: '10px',
          paddingBottom: '10px',
          paddingLeft: '24px',
        }}
      >
        <TextCL variant="overline">{label}</TextCL>
      </div>
      <div
        style={{
          paddingTop: '14px',
          paddingLeft: '24px',
        }}
      >
        <TextCL
          variant="input"
          replayEncrypt={replayEncrypt}
          replayBlock={replayBlock}
        >
          {value}
        </TextCL>
      </div>
    </Grid>
  );
};

export default BankInfoFields;
