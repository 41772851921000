import { Duration, add, endOfDay, isAfter, startOfDay } from 'date-fns';
import { formatToTimeZone } from 'date-fns-timezone';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

/**
 * Takes a date range string and formats it into a more readable format.
 * If the date range represents today's date, it returns 'Today'.
 * Otherwise, it returns the date in the format 'Month Day, Year'.
 *
 * @param dateRange - A string representing a date range in the format 'YYYY-MM-DD - YYYY-MM-DD'.
 * @returns A string representing the formatted date.
 *
 * @example
 * Input: '2022-12-01 - 2022-12-02'
 * Output: 'December 1, 2022'
 *
 * Input: '2022-12-01 - 2022-12-02' (if today's date is 2022-12-01)
 * Output: 'Today'
 */
export function formatDateRangeToReadableFormat(dateRange: string): string {
  const today = new Date();
  const dateParts = dateRange.split(' - ');
  if (dateParts.length !== 2) {
    return 'Invalid date range';
  }

  const firstDate = new Date(dateParts[0]);

  if (
    today.getDate() === firstDate.getDate() &&
    today.getMonth() === firstDate.getMonth() &&
    today.getFullYear() === firstDate.getFullYear()
  ) {
    return 'Today';
  } else {
    const formattedDate = `${
      months[firstDate.getMonth()]
    } ${firstDate.getDate()}, ${firstDate.getFullYear()}`;
    return formattedDate;
  }
}

/**
 * Takes a date string and formats it into a long format (Month Day, Year).
 *
 * @param date - A string representing a date in the format 'YYYY-MM-DD'.
 * @returns A string representing the formatted date.
 *
 * @example
 * Input: '2022-12-01'
 * Output: 'December 1, 2022'
 */
export const formatDateToLongFormat = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  const initialDate = new Date(date);
  const formattedDate = `${
    months[initialDate.getMonth()]
  } ${initialDate.getDate()}, ${initialDate.getFullYear()}`;

  return formattedDate;
};

/**
 * Converts a date string into a more readable format that includes the time in 12-hour format (AM/PM), the month, the day, and the year.
 *
 * @param date - A string representing a date in the format 'YYYY-MM-DD'.
 * @returns A string representing the formatted date.
 *
 * @example
 * Input: '2024-01-20T12:45:00Z'
 * Output: '12:45pm December 1, 2022'
 */
export const formatTimeAndDateToLongFormat = (date: string | undefined) => {
  if (!date) {
    return '';
  }
  const initialDate = new Date(date);
  const formattedDate = `${initialDate
    .toLocaleString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
    .toLocaleLowerCase()} ${
    months[initialDate.getMonth()]
  } ${initialDate.getDate()}, ${initialDate.getFullYear()}`;

  return formattedDate;
};

/**
 * @param  {Date} date
 * @param  {Duration} duration
 * @returns {Date} Returns the passed in date plus the duration
 */
export const addDays = (date: Date, duration: Duration): Date => {
  return add(date, duration);
};

/**
 * @param  {'beginning'|'end'} time
 * @returns {Date} Returns today with timestamp set to the beginning or end of the day
 */
export const getToday = (time: 'beginning' | 'end'): Date => {
  return time === 'beginning' ? startOfDay(new Date()) : endOfDay(new Date());
};

/**
 * @param  {Date} date
 * @param  {string} timezone
 * @returns {number} Returns the provided date with timestamp in a specific timezone
 */
export const getDateInTimestamp = (
  date: Date,
  timezone = 'America/New_York',
): number => {
  const format = 'YYYY-MM-DDTHH:mm:ss.SSS[Z]';
  const dateInTimezone = formatToTimeZone(date, format, { timeZone: timezone });

  return Date.parse(dateInTimezone);
};
/**
 * Calculates the number of days between two timestamps.
 *
 * @param {number} targetTimestamp - The target timestamp in seconds.
 * @param {number} currentTimestamp - The current timestamp in seconds.
 * @returns {number} - The number of days between the two timestamps.
 *
 * @example
 * Input: calculateDaysLeft(1641283200, 1640995200)
 * Output: 3 // January 6, 2022 - January 1, 2022
 */
export const calculateDaysLeft = (
  targetTimestamp: number,
  currentTimestamp: number,
): number => {
  const secondsInDay = 24 * 60 * 60;
  return Math.floor((targetTimestamp - currentTimestamp) / secondsInDay);
};

/**
 * Formats a timestamp into a readable date format.
 *
 * @param {number} timestamp - The timestamp in seconds.
 * @returns {string} - The formatted date string.
 *
 * @example
 * Input: formatTimestamp(1640995200)
 * Output: '2022-01-01 00:00'
 */
export const formatTimestamp = (timestamp: number) => {
  const date = new Date(timestamp * 1000);
  const formattedDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(
    date.getHours(),
  ).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
  return formattedDate;
};

/**
 * Returns the current timestamp in seconds.
 *
 * This function creates a new Date object for the current date and time,
 * gets the timestamp in milliseconds using the getTime method, and then
 * divides it by 1000 to convert it to seconds. The result is rounded down
 * to the nearest whole number using Math.floor.
 *
 * @returns {number} The current timestamp in seconds.
 */
export const getTodaysTimestamp = (): number => {
  const today = new Date();
  return Math.floor(today.getTime() / 1000);
};

/**
 * Checks if a given timestamp is within two weeks from the timestamp passed in.
 *
 * This function creates a new Date object for the current date and time, adds two weeks to it,
 * and then compares it with the given timestamp. If the given timestamp is within
 * the two-week period, it returns false. Otherwise, it returns true.
 *
 * @param {number} timestamp - The timestamp to check.
 * @returns {boolean} False if the timestamp is within two weeks from now, true otherwise.
 */
export const isTimestampWithinTwoWeeksFromStartDate = (
  timestamp: number,
): boolean => {
  if (timestamp < 0) {
    throw new Error('Invalid timestamp. Must be greater than 0.');
  }

  const twoWeeksFromTimestamp = new Date();
  twoWeeksFromTimestamp.setDate(twoWeeksFromTimestamp.getDate() + 14);

  const timestampDate = new Date(timestamp * 1000);

  return timestampDate.getTime() < twoWeeksFromTimestamp.getTime();
};

/**
 * @param  {Date} date
 * @param  {Date} dateToCompare
 * @returns {boolean} Returns true if date is after dateToCompare
 */
export const isDateAfter = (date: Date, dateToCompare: Date) => {
  return isAfter(date, dateToCompare);
};

/**
 * Checks if a given date exceeds 24 hours.
 *
 * @param {Date} date - The date to check.
 * @returns {boolean} - Returns true if the given date is more than 24 hours from now, false otherwise.
 */
export const checkDateExceeds24Hours = (date: Date) => {
  const today = new Date();
  const dateIn24Hours = addDays(date, { days: 1 });
  return isAfter(today, dateIn24Hours);
};
