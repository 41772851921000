import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  styled,
} from '@mui/joy';
import React, { FC, useEffect } from 'react';
import { HelpBox } from '../../../../../../cms/contentful/custom/CustomTypes';
import { AppContext } from '../../../../../../state/appContext';
import { Horizon, Moolah } from '../../../../../../theme';
import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS, Block, Inline, Text } from '@contentful/rich-text-types';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { TextCL } from '../../../../../../components/library/TextCL';
import { iconLibrary } from '../../../../../../assets/icons/icon-library';
import { adpRun, adpWfn } from '../../../../../../ss-constants/onboarding';
import {
  HelpTopicHelpLinks,
  Maybe,
  TextFieldWithButton,
} from '../../../../../../cms/contentful/generated/graphql';
import { TextFieldWithButtonWrapper } from '../../../../../../components/library/TextFieldWithButtonWrapper';
import { TEST_IDS } from '../../../../../../test-utils/test-ids';

const AccordionSummaryContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: 16,
  backgroundColor: Horizon.info[2],
  width: '120%',
  '&:hover': {
    backgroundColor: Horizon.info[2],
  },
});

export interface InfoBoxProps {
  index: number;
  payrollProviderKey: string;
}

type RichHyperlinkWithValue = ((Block | Inline | Text)[] | (Inline | Text)) & {
  value: string;
};

function contentfulRendererOptions(
  links: HelpTopicHelpLinks | null | undefined,
): Options {
  return {
    renderNode: {
      hyperlink: (node) => {
        const input: RichHyperlinkWithValue = node
          .content[0] as RichHyperlinkWithValue;

        if (node.nodeType !== 'hyperlink' || !node.data.uri || !input.value)
          return null;

        return (
          <a
            href={node.data.uri}
            target="_blank"
            rel="noopener noreferrer"
            data-testid={TEST_IDS.connectProvider.linkWrapper}
          >
            {input.value}
          </a>
        );
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node) => {
        const entry: Maybe<TextFieldWithButton> | undefined =
          links?.entries.block.find(
            (i) => i?.sys.id === node.data.target.sys.id,
          );
        if (entry && entry.__typename === 'TextFieldWithButton') {
          const { title, buttonText, content } = entry;

          return (
            <TextFieldWithButtonWrapper
              content={content}
              buttonText={buttonText}
              {...(title === 'teamTextWithCopyBtn' && {
                onClick: async () =>
                  await navigator.clipboard.writeText(content as string),
              })}
            />
          );
        }
      },
    },
  };
}

const InfoBox: FC<InfoBoxProps> = ({ index, payrollProviderKey }) => {
  const { state } = React.useContext(AppContext);
  const [expanded, setExpanded] = React.useState<boolean>(true);

  const handleChange = (isExpanded: boolean) => {
    logAnalyticsEventForSelfService({
      type: isExpanded
        ? SelfServiceAnalyticsEventTypes.ConnectProviderCollapseInstructions
        : SelfServiceAnalyticsEventTypes.ConnectProviderOpenInstructions,
      payload: {
        providerType: 'payroll',
        providerName: payrollProviderKey,
        pageNumber: index + 1,
      },
    });
    setExpanded(!isExpanded);
  };

  const [helpItem, setHelpItem] = React.useState<HelpBox | undefined>(
    undefined,
  );

  useEffect(() => {
    // if the providerKey is 'adp_wfn' then return 'adp_run' helpTopics
    const help = state.onboarding.helpTopics?.helpTopicCollection?.items?.find(
      (item) => {
        if (payrollProviderKey === adpWfn) {
          return (
            item?.provider === adpRun &&
            item?.subject === `connectPayrollStep${index + 1}`
          );
        } else {
          return (
            item?.provider === payrollProviderKey &&
            item?.subject === `connectPayrollStep${index + 1}`
          );
        }
      },
    ) as HelpBox;
    setHelpItem(help);
  }, [
    index,
    payrollProviderKey,
    state.onboarding.helpTopics?.helpTopicCollection?.items,
  ]);

  return (
    <Grid padding={0} mt={4} mb={2} minWidth={'100%'}>
      <Accordion expanded={expanded} onChange={() => handleChange(expanded)}>
        <AccordionSummary indicator={false}>
          <AccordionSummaryContainer>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <img
                width={24}
                src={iconLibrary.common.circleInfo}
                alt="infoIcon"
              ></img>
              <div
                style={{
                  marginLeft: '8px',
                }}
              >
                <TextCL variant="h6" color={Moolah.white}>
                  {helpItem?.title}
                </TextCL>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
              }}
            >
              <img
                width={24}
                src={
                  expanded
                    ? iconLibrary.common.chevronUp
                    : iconLibrary.common.chevronDown
                }
                alt="chevronInfoBox"
              ></img>
            </div>
          </AccordionSummaryContainer>
        </AccordionSummary>

        <AccordionDetails sx={{ backgroundColor: Moolah.gray[10] }}>
          <Box
            sx={{
              padding: '18px',
              pt: 0,
              '& ol': { px: 3 },
            }}
          >
            <TextCL component="div" variant="h5">
              {documentToReactComponents(
                helpItem?.help?.json,
                contentfulRendererOptions(helpItem?.help?.links),
              )}
            </TextCL>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Grid>
  );
};

export default InfoBox;
