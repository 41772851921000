import React from 'react';

import { Button, Stack } from '@mui/joy';
import './signinForm.css';

import { useTranslation } from 'react-i18next';
import { SignupPageVariants } from '../signinPage';

interface CheckEmailForCodeFormProps {
  setPageVariant: (pageVariant: SignupPageVariants) => void;
}

const CheckEmailForCodeForm: React.FC<CheckEmailForCodeFormProps> = ({
  setPageVariant,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Stack
        spacing={4}
        flex={1}
        flexGrow={1}
        alignItems={'stretch'}
        justifyContent={'center'}
      >
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={() =>
            setPageVariant(SignupPageVariants.CONFIRM_RESET_PASSWORD)
          }
        >
          {t('admin.checkEmailForCode.okay')}
        </Button>
      </Stack>
    </>
  );
};

export default CheckEmailForCodeForm;
