/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Grid } from '@mui/joy';
import { Chart as ChartJS, ArcElement, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

interface ProgramChartProps {
  count?: number;
  total?: number;
  color?: string;
}

const ProgramChart: React.FC<ProgramChartProps> = ({
  count,
  total,
  color,
}: ProgramChartProps) => {
  ChartJS.register(ArcElement, Legend);

  if (count === undefined || total === undefined) {
    return null;
  }
  const remaining = total - count;

  const data = {
    labels: ['Count', 'Remaining'],
    datasets: [
      {
        data: [count, remaining],
        backgroundColor: [color, 'transparent'],
        hoverBackgroundColor: [color, 'transparent'],
      },
    ],
  };

  const drawCenterText = (chartInstance: any) => {
    const ctx = chartInstance.chart.ctx;

    ctx.restore();
    const width = chartInstance.chart.width;
    const height = chartInstance.chart.height;

    const fontSize = (height / 60).toFixed(2);
    ctx.font = fontSize + 'em graphik';
    ctx.textBaseline = 'middle';

    const text = count;
    const textX = Math.round((width - ctx.measureText(text).width) / 2);
    const textY = height / 2;

    // Draw larger circle with border and box shadow
    ctx.beginPath();
    ctx.arc(width / 2, height / 2, 50, 0, 2 * Math.PI); // Increase the radius to 50
    ctx.fillStyle = 'white'; // Set the inner circle color to white
    ctx.fill(); // Fill the inner circle
    ctx.strokeStyle = '#F5F5F5'; // Border color
    ctx.lineWidth = 8; // Border width
    ctx.shadowBlur = 5; // Box shadow blur
    ctx.shadowOffsetX = 0;
    ctx.shadowOffsetY = 0;
    ctx.shadowColor = 'rgba(0, 0, 0, 0.1)';
    ctx.stroke();

    ctx.fillStyle = '#454459'; // Set the text color
    ctx.fillText(text, textX, textY);

    ctx.save();
  };

  return (
    <Grid container justifyContent="center">
      <Doughnut
        data={data}
        options={{
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              enabled: false,
            },
          },
          maintainAspectRatio: false,
          responsive: true,
          animation: {
            onComplete: (chart: any) => {
              drawCenterText(chart);
            },
            onProgress: (chart: any) => {
              drawCenterText(chart);
            },
          },
          elements: {
            arc: {
              borderWidth: 0,
            },
          },
        }}
      />
    </Grid>
  );
};

export default ProgramChart;
