import React, { useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import LayoutFoundations from './foundations/AdminLayoutFoundations';
import { Box, Drawer, IconButton, ModalClose, Stack, useTheme } from '@mui/joy';
import AdminSidebar from '../components/admin/AdminSidebar';
import logo from '../assets/images/dailypay-logo.png';
import { AppContext } from '../state/appContext';
import { iconLibrary } from '../assets/icons/icon-library';
import logoWhite from '../assets/images/dailypay-logo-white.png';
import './AdminLayout.css';
import AdminUserLogout from '../components/admin/AdminUserLogout';
import useScreenQuery from './hooks/useScreenQuery';
import { useTranslation } from 'react-i18next';
import { ProspectDataIndicators } from '../state/onboarding/onboardingState';
import { BannerCL } from '../components/library/BannerCL';

const AdminLayout: React.FC = () => {
  const navigate = useNavigate();
  const { state } = useContext(AppContext);
  const theme = useTheme();
  const { isLargerScreen } = useScreenQuery();
  const isMobile = !isLargerScreen;
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();
  const [showUpdateTermsBanner, setShowUpdateTermsBanner] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (state.onboarding.prospectData?.data) {
      const prospectData = state.onboarding?.prospectData
        ?.data as ProspectDataIndicators;
      setShowUpdateTermsBanner(prospectData.termsVersion === -1);
    }
  }, [state.onboarding.prospectData?.data]);

  if (!state.admin.providerList?.[0]?.created_at) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <CssBaseline />
      {showUpdateTermsBanner && (
        <BannerCL
          title={t('admin.dashboard.terms.bannerText')}
          buttonText={t('admin.dashboard.terms.bannerButton')}
          onClick={() => navigate('/admin/acceptTerms')}
          icon={iconLibrary.common.warning}
        />
      )}
      {isMobile && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.level3,
            display: 'flex',
            height: '62px',
            maxWidth: '100hw',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '1rem',
          }}
        >
          <IconButton
            onClick={() => setOpen(true)}
            variant="plain"
            color="neutral"
            size="sm"
          >
            <img width={24} src={iconLibrary.common.menuWhite}></img>
          </IconButton>
          <Box
            sx={{
              marginLeft: 'auto',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              padding: '1rem',
              flexGrow: 1,
              justifyContent: 'center',
            }}
          >
            <img src={logoWhite} width="120px" />
          </Box>
        </Box>
      )}
      <LayoutFoundations.Root sx={{ width: '100hw' }}>
        {isLargerScreen && (
          <LayoutFoundations.LeftSider>
            <Stack spacing={4} sx={{ padding: '2.5em 3em 2em 3em' }}>
              <img src={logo} width="130px" alt="logo" />
            </Stack>
            <AdminSidebar />
          </LayoutFoundations.LeftSider>
        )}
        {!isLargerScreen && (
          <Drawer open={open} onClose={() => setOpen(false)}>
            <ModalClose />
            <Stack spacing={4} sx={{ padding: '2.5em 3em 2em 3em' }}>
              <img src={logo} width="130px" alt="logo" />
            </Stack>
            <AdminUserLogout forMobile={true} />
            <AdminSidebar />
          </Drawer>
        )}
        <LayoutFoundations.MainContent sx={{ width: '100%' }}>
          <div className={isLargerScreen ? 'padded' : 'paddedMobile'}>
            <Outlet />
          </div>
        </LayoutFoundations.MainContent>
      </LayoutFoundations.Root>
    </>
  );
};

export default AdminLayout;
