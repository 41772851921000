import * as React from 'react';
import Button, { ButtonPropsVariantOverrides } from '@mui/joy/Button';
import DialogTitle from '@mui/joy/DialogTitle';
import DialogContent from '@mui/joy/DialogContent';
import DialogActions from '@mui/joy/DialogActions';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import { OverridableStringUnion } from '@mui/types';
import { SxProps } from '@mui/system';
import { VariantProp } from '@mui/joy';
import { Moolah } from '../../theme';
import { TEST_IDS } from '../../test-utils/test-ids';
import { TextCL } from './TextCL';
import { useTranslation } from 'react-i18next';

interface ModalCLProps {
  color?: 'primary' | 'success' | 'warning' | 'danger' | 'neutral';
  text?: string;
  onClick?: () => void;
  variant?:
    | OverridableStringUnion<VariantProp, ButtonPropsVariantOverrides>
    | undefined;
  sx?: SxProps | undefined;
  isOpen: boolean;
  onCancel?: () => void;
  onConfirm?: () => void;
  terminateEmployee?: boolean;
  isLoading?: boolean;
}
export const ModalCL = ({
  variant = 'outlined',
  isOpen,
  onCancel,
  onConfirm,
  terminateEmployee,
  isLoading,
}: ModalCLProps) => {
  const { t } = useTranslation();

  const status = terminateEmployee ? 'terminate' : 'rehire';

  return (
    <>
      <Modal open={isOpen}>
        <ModalDialog
          variant={variant}
          role="modal"
          sx={{
            flexDirection: 'column',
            display: 'flex',
            alignItems: 'center',
            padding: '48px',
          }}
        >
          <DialogTitle>
            <TextCL variant="h2Bold" color="white">
              {t(`admin.employees.${status}.title`)}
            </TextCL>
          </DialogTitle>
          <DialogContent
            sx={{
              flexDirection: 'column',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {terminateEmployee && (
              <TextCL color={'white'} fontWeight="600">
                {t('admin.employees.terminate.message')}
              </TextCL>
            )}
            <TextCL color={'white'} fontWeight="600">
              {t(`admin.employees.${status}.message2`)}
            </TextCL>
            <TextCL color={'white'}>
              • {t(`admin.employees.${status}.message3`)}
            </TextCL>
            <TextCL color={'white'}>
              • {t(`admin.employees.${status}.message4`)}
            </TextCL>
          </DialogContent>
          <DialogActions>
            <Button
              variant="solid"
              size="sm"
              sx={{
                borderRadius: '50px',
                backgroundColor: Moolah.orange[60],
              }}
              onClick={() => {
                if (typeof onConfirm === 'function') {
                  onConfirm();
                }
              }}
              loading={isLoading}
              data-testid={TEST_IDS.modal.modalConfirmButton}
            >
              {t(`admin.employees.${status}.confirm`)}
            </Button>
            <Button
              variant="solid"
              size="sm"
              sx={{
                borderRadius: '50px',
                backgroundColor: Moolah.white,
                color: Moolah.navy[60],
                marginRight: '8px',
                marginLeft: '1rem',
                '&:hover': {
                  background: Moolah.navy[40],
                },
              }}
              onClick={() => {
                if (typeof onCancel === 'function') {
                  onCancel();
                }
              }}
              disabled={isLoading}
              data-testid={TEST_IDS.modal.modalCloseButton}
            >
              {t(`admin.employees.${status}.cancel`)}
            </Button>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
};
