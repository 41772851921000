import React from 'react';

import './landingPage.css';
import { Stack } from '@mui/joy';
import LearnMore from './sections/learnMore/learnMore';
import Benefits from './sections/benefits/benefits';
import Requirements from './sections/requirements/requirements';

export default function LandingPage() {
  return (
    <>
      <Stack
        spacing={2}
        sx={{ padding: '2em', paddingTop: '5em', display: 'flex' }}
      >
        <LearnMore />
        <Benefits />
        <Requirements />
      </Stack>
    </>
  );
}
