import { LocalStorageKeys } from '../ss-constants/local-storage';

export const getLocalStorageItem = (key: LocalStorageKeys) => {
  try {
    const localStorageItem = localStorage.getItem(key);
    return localStorageItem;
  } catch (err) {
    return `Error getting ${key} in localStorage`;
  }
};

export const setLocalStorageItem = (key: LocalStorageKeys, value: string) => {
  if (!value) {
    return new Error(`Error setting ${key} in localStorage, no value passed`);
  }

  try {
    localStorage.setItem(key, value);
  } catch (err) {
    return new Error(`Error setting ${key} in localStorage`);
  }
};

export const removeLocalStorageItem = (key: LocalStorageKeys) => {
  try {
    localStorage.removeItem(key);
  } catch (err) {
    return `Error removing ${key} in localStorage`;
  }
};
