import React, { FC } from 'react';
import {
  Button,
  DialogActions,
  Divider,
  Grid,
  Typography,
  styled,
} from '@mui/joy';
import { MAX_PAYGROUPS_LENGTH, getFieldLabel } from './constants';
import { Moolah } from '../../../../../../theme';
import { AutocompleteCL } from '../../../../../../components/library/AutocompleteCL';
import InputBoxCL from '../../../../../../components/library/forms/InputBoxCL';
import { iconLibrary } from '../../../../../../assets/icons/icon-library';
import { useFormContext } from 'react-hook-form';
import { ConnectFormData } from '../../payrollConfigForm';
import { useTranslation } from 'react-i18next';
import { TEST_IDS } from '../../../../../../test-utils/test-ids';
import { PayFrequency, PayGroup } from './payGroups';
import { InfoModalCL } from '../../../../../../components/library/InfoModalCL';
import { ButtonCL } from '../../../../../../components/library/ButtonCL';

const AddOrRemoveButton = styled(Button)(({ theme }) => ({
  width: 'inherit',
  height: 30,
  color: Moolah.navy[60],
  display: 'block',
  backgroundColor: Moolah.blue[30],
  paddingLeft: theme.spacing(3),
  paddingRight: theme.spacing(3),
  '&:hover': {
    color: Moolah.white,
  },
  '& div': {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}));

const PayFrequencyGrid = styled(Grid)(({ theme }) => ({
  '& .MuiFormControl-root': {
    maxWidth: '50%',
    paddingRight: theme.spacing(2),
  },
  '& .MuiAutocomplete-root': {
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    height: 30,
    width: 25,
  },
  '& .MuiAutocomplete-popupIndicator': {
    marginInlineEnd: 'unset',
    maxWidth: 18,
    minWidth: 0,
  },
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  height: 2,
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(1),
}));

const dialogBtnCommonSxProps = {
  px: 6,
  borderRadius: 50,
  height: 50,
  fontSize: 'medium',
  fontWeight: 600,
};

interface PayGroupContainerProps {
  payGroup: PayGroup;
  payGroupIdx: number;
  isLastElement: boolean;
  payFrequencyOpts: PayFrequency[];
  onAddPayGroup: () => void;
  onRemovePayGroup: (index: number) => void;
  onPayFrequencyChange: (index: number, newValue: PayFrequency) => void;
}

export const PayGroupContainer: FC<PayGroupContainerProps> = ({
  payGroup,
  payGroupIdx,
  isLastElement,
  payFrequencyOpts,
  onAddPayGroup,
  onRemovePayGroup,
  onPayFrequencyChange,
}) => {
  const { t } = useTranslation();
  const { control, trigger } = useFormContext<ConnectFormData>();

  const [isDeleting, setIsDeleting] = React.useState(false);

  const shouldRenderAddOrRemoveBtn = payGroupIdx + 1 < MAX_PAYGROUPS_LENGTH;
  const commonKey = 'onboarding.connect.payrollSetup.common';

  function onDelete() {
    onRemovePayGroup(payGroupIdx + 1);
    setIsDeleting(false);
  }

  function onCancel() {
    setIsDeleting(false);
  }

  return (
    <>
      {Object.entries(payGroup).map(([key, value], index) => {
        const shouldRenderDivider = index > 0 && index % 5 == 0;
        const fieldNamePrefix = `payGroups.${payGroupIdx}.${key}` as const;
        const fieldLabel = getFieldLabel(key);

        return (
          <React.Fragment key={fieldNamePrefix}>
            {shouldRenderDivider && (
              <Grid xs={12}>
                <StyledDivider />
              </Grid>
            )}
            {key == 'payFrequency' ? (
              <PayFrequencyGrid key={key} xs={12}>
                <AutocompleteCL
                  key={key}
                  fieldName={fieldNamePrefix}
                  control={control}
                  onBeforeChange={(newValue) =>
                    onPayFrequencyChange(payGroupIdx, newValue as PayFrequency)
                  }
                  label={fieldLabel}
                  datatestid={fieldNamePrefix}
                  options={payFrequencyOpts.map((option) => ({
                    id: option,
                    label: t(
                      `${commonKey}.payFrequencyOpts.${option}`,
                    ).toLocaleUpperCase(),
                  }))}
                />
              </PayFrequencyGrid>
            ) : (
              <Grid key={key} xs={6} pt={6}>
                <InputBoxCL
                  key={key}
                  control={control}
                  fieldName={fieldNamePrefix}
                  validateOnChange={() => trigger(fieldNamePrefix)}
                  label={fieldLabel}
                  type="date"
                  value={value}
                />
              </Grid>
            )}
          </React.Fragment>
        );
      })}
      {shouldRenderAddOrRemoveBtn && (
        <Grid xs={12} pt={4}>
          <AddOrRemoveButton
            data-testid={`${TEST_IDS.connectProvider.addOrRemovePayGroupButton}.${payGroupIdx}`}
            onClick={() =>
              isLastElement ? onAddPayGroup() : setIsDeleting(true)
            }
          >
            <div>
              <Typography textColor="unset" fontSize="small" alignSelf="center">
                {t(
                  `${commonKey}.actions.payGroups.${isLastElement ? 'add' : 'remove'}`,
                )}{' '}
                {t(`${commonKey}.payrollFrequency`)}
              </Typography>
              <img
                src={iconLibrary.common[`${isLastElement ? 'add' : 'remove'}`]}
              />
            </div>
          </AddOrRemoveButton>
          <InfoModalCL
            isOpen={isDeleting}
            onClose={onCancel}
            title={t(`${commonKey}.actions.confirmDialog.title`)}
            sx={{
              maxWidth: 590,
              height: 380,
              padding: 6,
              borderRadius: '8%',
              justifyContent: 'center',
              '& .MuiDialogContent-root': {
                '& p': {
                  fontSize: '16px !important',
                },
              },
              '& .MuiDialogTitle-root': {
                mt: 8,
              },
              '& .MuiBox-root': {
                mt: 6,
              }
            }}
          >
            <DialogActions>
              <ButtonCL
                onClick={onDelete}
                variant="solid"
                color="neutral"
                size="lg"
                text={t(`${commonKey}.actions.confirmDialog.confirm`)}
                sx={{
                  backgroundColor: Moolah.orange[60],
                  ...dialogBtnCommonSxProps,
                }}
              />
              <ButtonCL
                onClick={onCancel}
                variant="solid"
                color="neutral"
                size="lg"
                text={t(`${commonKey}.actions.confirmDialog.cancel`)}
                sx={{
                  backgroundColor: Moolah.white,
                  color: Moolah.navy[60],
                  mr: 2,
                  '&:hover': {
                    backgroundColor: Moolah.white,
                  },
                  ...dialogBtnCommonSxProps,
                }}
              />
            </DialogActions>
          </InfoModalCL>
        </Grid>
      )}
    </>
  );
};
