import * as React from 'react';
import { FC, useContext, useEffect, useState } from 'react';
import { Button, Grid, Link } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { captureException } from '../../../../../services/sentry';
import AdminForm from './AdminForm';
import { Admin } from './type';
import { formInsetBackground } from '../../../../../theme/self-service-joy-theme';
import { TextCL } from '../../../../../components/library/TextCL';
import { Moolah } from '../../../../../theme';
import useScreenQuery from '../../../../../layouts/hooks/useScreenQuery';
import { useNavigate } from 'react-router';
import { TEST_IDS } from '../../../../../test-utils/test-ids';
import { RegistrationFormData } from '../../../../../formData/formDataTypes';
import { AppContext } from '../../../../../state/appContext';
import {
  logAnalyticsEventForSelfService,
  SelfServiceAnalyticsEvent,
  SelfServiceAnalyticsEventTypes,
} from '../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { DailyPayStatus } from '@dailypay/self-service-ts-sdk';
import { AdminModal } from './AdminModal';
import useUpdateAdmin from '../../../../../queries/useUpdateAdmin';

interface Props {
  admin: Admin;
}

const adminModalTranslationKey =
  'admin.manageCompanyAccount.adminDetails.deleteModal';

const AdminDetails: FC<Props> = ({ admin }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isLargerScreen } = useScreenQuery();

  const { state } = useContext(AppContext);
  const { mutateAsync } = useUpdateAdmin();

  const [open, setOpen] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');

  const payload = { adminUUID: admin.admin_uuid };

  useEffect(() => {
    const prospect: RegistrationFormData = state.onboarding.prospectData
      ?.data as RegistrationFormData;
    if (prospect?.emailAddress) {
      setEmail(prospect.emailAddress);
    }
  }, [state.onboarding.prospectData?.data]);

  const handleCancelClick = () => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.DeleteAdminCancelled,
      payload,
    });
    setOpen(false);
  };

  const handleConfirmClick = async () => {
    await updateAdminHandler(
      { dp_status: DailyPayStatus.Deactivated },
      { type: SelfServiceAnalyticsEventTypes.DeleteAdminConfirmed, payload },
    );
  };

  const updateAdminHandler = async (
    payload: unknown,
    event: SelfServiceAnalyticsEvent,
  ) => {
    try {
      await mutateAsync({ payload, event });
    } catch (err) {
      captureException(err, {
        extra: {
          message: 'Error in updateEmployee api call on EmployeeAccountInfo',
        },
      });
      setHasError(true);
    }

    setOpen(false);
  };

  return (
    <Grid
      container
      spacing={0}
      sx={{
        flexGrow: 1,
        backgroundColor: formInsetBackground,
        borderRadius: '8px',
        paddingBottom: '24px',
        boxShadow: '0px 2px 4px rgba(15, 22, 57, 0.04)',
      }}
    >
      <AdminModal
        title={t(`${adminModalTranslationKey}.title`)}
        description={t(`${adminModalTranslationKey}.description`)}
        confirmButtonText={t(`${adminModalTranslationKey}.confirm`)}
        cancelButtonText={t(`${adminModalTranslationKey}.cancel`)}
        isOpen={open}
        onCancel={handleCancelClick}
        onConfirm={handleConfirmClick}
      />
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        display={'flex'}
        flexDirection={'column'}
      >
        <Grid
          xs={12}
          md={12}
          lg={12}
          className="formSection"
          display={'flex'}
          flexDirection={'row'}
          padding={'24px'}
        >
          {isLargerScreen && (
            <Link
              underline="always"
              sx={{ display: 'flex', width: '100%' }}
              level="title-sm"
              onClick={() => navigate('/admin/manageCompanyAccount')}
            >
              <TextCL variant="link" color={Moolah.navy[60]}>
                {'<'} {t('admin.manageCompanyAccount.adminDetails.backButton')}
              </TextCL>
            </Link>
          )}
          {admin.email !== email && (
            <Button
              variant="solid"
              size="sm"
              sx={{
                borderRadius: '50px',
                width: '30%',
                padding: '0px',
              }}
              onClick={() => setOpen(true)}
              data-testid={TEST_IDS.admin.adminDetails.deleteButton}
            >
              Delete admin
            </Button>
          )}
        </Grid>

        <Grid xs={12} md={12} lg={12} className="formSection">
          <AdminForm
            admin={admin}
            // TODO form value body needs typed.
            updateAdmin={(body: object) =>
              updateAdminHandler(body, {
                type: SelfServiceAnalyticsEventTypes.EditAdminContactSubmit,
                payload,
              })
            }
            hasError={hasError}
            setHasError={setHasError}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AdminDetails;
