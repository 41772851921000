import React, { FC, useEffect } from 'react';
import { AppContext } from '../../../../../../state/appContext';
import { Grid, Divider, styled } from '@mui/joy';
import { Moolah } from '../../../../../../theme';
import InfoBox from '../infoBox/infoBox';
import { TextCL } from '../../../../../../components/library/TextCL';
import { useTranslation } from 'react-i18next';
import { dispatchDisplaySidebarCards } from '../../../../../../state/onboarding/onboardingReducer';
import { TEST_IDS } from '../../../../../../test-utils/test-ids';

const CurrentStepCountDiv = styled('div')({
  backgroundColor: Moolah.navy[60],
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '4px 12px',
  borderRadius: 5,
});

interface PageTranslationKeys {
  title: string;
  subtitle: string;
}

export interface CurrentStepContainerProps {
  index: number;
  currentPage: PageTranslationKeys;
  payrollProviderKey: string;
  isVendorRequirement: boolean;
  children: React.ReactNode;
  step?: number;
  totalSteps?: number;
}

const CurrentStepContainer: FC<CurrentStepContainerProps> = ({
  index,
  currentPage,
  payrollProviderKey,
  isVendorRequirement,
  step,
  totalSteps,
  children,
}) => {
  const { t } = useTranslation();
  const { dispatch } = React.useContext(AppContext);

  useEffect(() => {
    dispatchDisplaySidebarCards(dispatch, !isVendorRequirement);
    return () => {
      dispatchDisplaySidebarCards(dispatch, false);
    };
  }, [isVendorRequirement, dispatch]);

  return (
    <>
      <Grid container xs={12} md={12} lg={12} gap={2}>
        <Grid container xs={12} md={12} lg={12} gap={2}>
          {!isVendorRequirement && (
            <CurrentStepCountDiv
              data-testid={TEST_IDS.connectProvider.stepsContainer}
            >
              <TextCL variant="h3Bold" color={Moolah.white} fontWeight="500">
                {`Step ${step} of ${totalSteps}`}
              </TextCL>
            </CurrentStepCountDiv>
          )}
          <Grid>
            <TextCL variant="h3Bold">{t(currentPage.title)}</TextCL>
          </Grid>
        </Grid>
        <Grid container xs={12} md={12} lg={12}>
          <TextCL variant="h5">{t(currentPage.subtitle)}</TextCL>

          <Divider />
        </Grid>
      </Grid>
      <Divider sx={{ height: 2, mt: 5, mb: 3 }} />
      {children}
      <Grid container xs={12} md={12} lg={12} gap={2}>
        <InfoBox payrollProviderKey={payrollProviderKey} index={index} />
      </Grid>
    </>
  );
};

export default CurrentStepContainer;
