import React, { useEffect, useMemo } from 'react';

import {
  CredentialField,
  CreateProviderRequest,
} from '@dailypay/self-service-ts-sdk';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/joy';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { ButtonCL } from '../../../../components/library/ButtonCL';
import { RegistrationFormData } from '../../../../formData/formDataTypes';
import payrollSetupStepConfiguration, {
  PageTypes,
} from '../../../../formData/payrollSetupStepConfiguration';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';
import { verifyPayrollTMSIntegration } from '../../../../services/api-wrapper';
import { AppContext } from '../../../../state/appContext';
import { createProviderAction } from '../../../../state/onboarding/onboardingActions';
import {
  dispatchPayrollConnectionValidatedAction,
  incrementPayrollConnectionFailedAction,
} from '../../../../state/onboarding/onboardingReducer';
import { ProspectDataIndicators } from '../../../../state/onboarding/onboardingState';
import CurrentStepContainer from './steps/currentStep/currentStepContainer';
import { ManageConnectPayrollView } from './types';
import ValidationError from './validation/validationError';
import ValidationSuccess from './validation/validationSuccess';
import useRouteProtection, {
  defaultRedirectMap,
} from '../../../useRouteProtection';
import { normalizeSnakeCaseString } from '../../../../utils/string-helpers';
import { captureException } from '../../../../services/sentry';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { InfoModalCL } from '../../../../components/library/InfoModalCL';
import { TextCL } from '../../../../components/library/TextCL';
import ProviderError from './validation/providerError';
import { formatResourceDomain } from '../../../../formatters/string-formatters';
import { adpRun, adpWfn, team } from '../../../../ss-constants/onboarding';
import CredentialsContainer, {
  filterCredentialsByFieldNames,
} from './steps/credentials/credentialsContainer';
import PayGroups, { PayGroup } from './steps/payGroups/payGroups';
import { PROVIDERS_WITH_PAYGROUPS_SUPPORT } from './steps/payGroups/constants';
import { payGroupsValidationSchema } from '../../../../components/schema/system_schema';
import { TeamVendorRequirement } from './steps/vendorRequirement/team/teamVendorRequirement';
import { Moolah } from '../../../../theme';
import { formatSemiMonthlyPayGroup } from './steps/payGroups/utils';

export interface ConnectFormData {
  payGroups?: PayGroup[];
  [key: string]: string | PayGroup[] | undefined;
}

interface VerifyPayrollErrors {
  [key: string]: {
    [key: string]: string;
  };
}

const adpPayrollProviders = [adpRun, adpWfn];

const VERIFY_PAYROLL_ERRORS: VerifyPayrollErrors = {
  team: {
    TEAM_ERROR_INVALID_TENANT_ID: 'Error Team Software Token',
  },
};

export default function PayrollConfigForm() {
  const navigate = useNavigate();
  const { dispatch, state } = React.useContext(AppContext);
  const [index, setIndex] = React.useState<number>(0);
  const [activeView, setActiveView] = React.useState<ManageConnectPayrollView>(
    ManageConnectPayrollView.steps,
  );
  const [hasError, setHasError] = React.useState<boolean>(false);
  const [isConnecting, setIsConnecting] = React.useState<boolean>(false);

  const schemaObject: Record<string, yup.AnySchema> = useMemo(() => {
    return {};
  }, []);

  const schema = yup.object<ConnectFormData>(schemaObject);
  const [apiError, setApiError] = React.useState<string>('');
  const { t } = useTranslation();
  const [payrollProviderKey, setPayrollProviderKey] = React.useState<string>();
  const [payrollProviderContentfulId, setPayrollProviderContentfulId] =
    React.useState<string>('');
  const [credentials, setCredentials] = React.useState<CredentialField[]>();

  // Adding this additional parameter to route protection because when the user first changes
  // to completed status we want them to see the success page, but if they navigate away and come back we
  // want them to redirect to the admin dashboard.
  const [skipPageRedirect, setSkipPageRedirect] =
    React.useState<boolean>(false);

  useRouteProtection({
    redirectRules: defaultRedirectMap,
    context: 'Enter Payroll Config',
    disable: skipPageRedirect,
  });
  useEffect(() => {
    const payrollProviderKeySelection = (
      state.onboarding.prospectData?.data as ProspectDataIndicators
    )?.payrollProviderKey;

    if (!payrollProviderKeySelection) {
      navigate('/onboarding');
      return;
    }

    const payrollProviderContentfulId = (
      state.onboarding.prospectData?.data as ProspectDataIndicators
    )?.payrollProviderContentfulId;

    if (payrollProviderContentfulId) {
      setPayrollProviderContentfulId(payrollProviderContentfulId);
    }

    setPayrollProviderKey(payrollProviderKeySelection);
  }, [navigate, state.onboarding.prospectData?.data]);

  useEffect(() => {
    if (payrollProviderKey !== undefined) {
      if (activeView === ManageConnectPayrollView.steps) {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.ConnectProviderPageViewed,
          payload: {
            providerType: 'payroll',
            providerName: payrollProviderKey,
            pageNumber: index + 1,
          },
        });
      }

      if (activeView === ManageConnectPayrollView.validationSuccess) {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessPageViewed,
          payload: {
            providerType: 'payroll',
            providerName: payrollProviderKey,
            pageNumber: index + 1,
          },
        });
      }
    }
  }, [index, payrollProviderKey, activeView]);

  const methods = useForm<ConnectFormData>({
    resolver: yupResolver(schema),
  });

  const { control, trigger, handleSubmit, clearErrors } = methods;

  const supportsPayGroups =
    payrollProviderKey &&
    PROVIDERS_WITH_PAYGROUPS_SUPPORT.includes(payrollProviderKey);

  useEffect(() => {
    if (payrollProviderKey !== undefined) {
      const connectFormData: ConnectFormData = {};

      const credentialsForProvider = state.onboarding.integrations
        ? state.onboarding.integrations[payrollProviderKey].properties
            .credentials
        : undefined;

      if (credentialsForProvider) {
        credentialsForProvider.forEach((credential) => {
          const key = credential.name;
          connectFormData[key] = '';
          schemaObject[key] = yup.string().required(credential.display_name);
        });

        setCredentials(credentialsForProvider);
      }

      if (supportsPayGroups) {
        connectFormData['payGroups'] = [];
        schemaObject['payGroups'] = payGroupsValidationSchema;
      }
    }
  }, [
    payrollProviderKey,
    schemaObject,
    state.onboarding.integrations,
    supportsPayGroups,
  ]);

  const renderStepContent = () => {
    const stepConfig = payrollSetupStepConfiguration.find(
      (config) => config.providerName === payrollProviderKey,
    );

    if (!stepConfig || !payrollProviderKey) {
      return null;
    }

    const currentPage = stepConfig.pages[index];
    const isVendorRequirement =
      currentPage?.pageType === PageTypes.vendorRequirement;
    const totalSteps = !isVendorRequirement ? stepConfig.totalSteps : undefined;
    const step = !isVendorRequirement
      ? parseInt(currentPage.helpRef.slice(-1))
      : undefined;

    if (!state.onboarding.integrations) {
      return null;
    }

    const credentials =
      state.onboarding.integrations[payrollProviderKey].properties.credentials;

    const renderContent = (): React.ReactNode => {
      switch (currentPage.pageType) {
        case PageTypes.vendorRequirement:
          switch (payrollProviderKey) {
            case team:
              return (
                <TeamVendorRequirement
                  isConnecting={isConnecting}
                  onButtonPressed={onButtonPressed}
                />
              );
          }
          break;
        case PageTypes.payGroups:
          return <PayGroups />;
        default:
          return (
            <CredentialsContainer
              credentials={credentials}
              fieldNamesToInclude={currentPage.fields}
            />
          );
      }
    };

    return (
      <FormProvider {...methods}>
        <CurrentStepContainer
          index={index}
          currentPage={currentPage.pageTranslationKeys}
          payrollProviderKey={payrollProviderKey}
          step={step}
          totalSteps={totalSteps}
          isVendorRequirement={isVendorRequirement}
        >
          {renderContent()}
        </CurrentStepContainer>
      </FormProvider>
    );
  };

  const handleConnectPayroll = React.useCallback(async () => {
    const getLatestProspectData = state.onboarding.prospectData
      ?.data as ProspectDataIndicators;

    const body = [
      {
        integration_name: getLatestProspectData.payrollProviderKey || '',
        credentials: Object.entries(control._formValues)
          .filter(([key]) => key !== 'payGroups')
          .map(([name, value]) => {
            if (name === 'resource_domain') {
              value = formatResourceDomain(value);
            }
            return {
              name,
              value,
            };
          }),
        ...(supportsPayGroups && {
          payGroups: control._formValues['payGroups'].map(
            (payGroup: PayGroup) => {
              if (payGroup.payFrequency != 'semimonthly') return payGroup;
              return formatSemiMonthlyPayGroup(payGroup);
            },
          ),
        }),
      },
    ];

    try {
      const response = await verifyPayrollTMSIntegration(body);
      if (payrollProviderKey !== undefined) {
        const error = response[payrollProviderKey].error;

        if (error) {
          const errorKey = `payrollErrorCount-${error}`;
          const formattedError =
            VERIFY_PAYROLL_ERRORS[payrollProviderKey][error] ??
            normalizeSnakeCaseString(JSON.stringify(error));

          setApiError(formattedError);
          setHasError(true);
          if (getLatestProspectData && getLatestProspectData[errorKey]) {
            const errorCount = getLatestProspectData[errorKey];

            if ((errorCount as number) >= 3) {
              setActiveView(
                ManageConnectPayrollView.validationErrorMultipleAttempts,
              );
            } else {
              setActiveView(ManageConnectPayrollView.validationError);
              incrementPayrollConnectionFailedAction(dispatch, error);
            }
          } else {
            setActiveView(ManageConnectPayrollView.validationError);
            incrementPayrollConnectionFailedAction(dispatch, error);
          }
        } else {
          dispatchPayrollConnectionValidatedAction(dispatch);

          const registrationFormData = state.onboarding.prospectData
            ?.data as RegistrationFormData;

          setSkipPageRedirect(true);

          const createProviderPayload: CreateProviderRequest = {
            name: registrationFormData.companyName,
            payroll_system: registrationFormData.payrollProviderKey,
            timekeeping_system: registrationFormData.timeManagementProviderKey,
            contact_name: `${registrationFormData.firstName} ${registrationFormData.lastName}`,
            contact_email: registrationFormData.emailAddress,
            contact_phone: '',
            integrations: body,
          };
          const createProviderSuccess = await createProviderAction(
            dispatch,
            createProviderPayload,
          );
          if (createProviderSuccess) {
            setActiveView(ManageConnectPayrollView.validationSuccess);
          } else {
            setHasError(true);
            setActiveView(ManageConnectPayrollView.providerError);
          }
        }
        setIsConnecting(false);
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      captureException(err, {
        extra: {
          message: 'Error in createProviderAction api call on payrollForm',
        },
      });
      setIsConnecting(false);
      setHasError(true);
      setActiveView(ManageConnectPayrollView.validationError);
      setApiError('Something went wrong. Please try again.');
      if (payrollProviderKey !== undefined) {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.ConnectProviderNextButtonError,
          payload: {
            providerType: 'payroll',
            providerName: payrollProviderKey,
            pageNumber: index + 1,
            error: err,
          },
        });
      }
    }
  }, [
    control._formValues,
    dispatch,
    index,
    payrollProviderKey,
    state.onboarding.prospectData?.data,
    supportsPayGroups,
  ]);

  const onSubmit = async () => {
    const prospectData = state.onboarding.prospectData
      ?.data as ProspectDataIndicators;

    const stepConfig = payrollSetupStepConfiguration.find(
      (config) => config.providerName === prospectData.payrollProviderKey,
    );

    if (!stepConfig || !payrollProviderKey) {
      return null;
    }

    const currentPage = stepConfig.pages[index];

    if (!state.onboarding.integrations) {
      return null;
    }

    const fieldNamesToInclude = currentPage.fields;
    const filteredCredentials = filterCredentialsByFieldNames(
      state.onboarding.integrations[payrollProviderKey].properties.credentials,
      fieldNamesToInclude,
    );

    clearErrors();

    if (credentials) {
      for (const credential of filteredCredentials) {
        const key = credential.name;
        const val = control._formValues[key];
        if (val === '') {
          control.setError(key, {
            type: 'custom',
            message: `${credential.display_name} is required`,
          });
        }
      }
    }

    if (currentPage?.pageType === PageTypes.payGroups) {
      await trigger('payGroups');
    }

    if (
      control._formState.errors &&
      Object.keys(control._formState.errors).length === 0
    ) {
      if (index === stepConfig.pages.length - 1) {
        setIsConnecting(true);
        await handleSubmit(handleConnectPayroll)();
      } else {
        const newStep = index + 1;
        setIndex(newStep);
      }
    } else {
      return;
    }
  };

  const onBackPressed = () => {
    if (index === 0) {
      navigate('/onboarding');
    } else {
      if (payrollProviderKey !== undefined) {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.ConnectProviderBackButton,
          payload: {
            providerType: 'payroll',
            providerName: payrollProviderKey,
            pageNumber: index + 1,
          },
        });
      }
      const newStep = index - 1;
      setIndex(newStep);
    }
  };

  const onButtonPressed = () => {
    if (hasError) {
      if (activeView === ManageConnectPayrollView.validationError) {
        if (payrollProviderKey) {
          logAnalyticsEventForSelfService({
            type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessTryAgainButton,
            payload: {
              providerType: 'payroll',
              providerName: payrollProviderKey,
              pageNumber: index + 1,
            },
          });
        }
      } else if (
        activeView === ManageConnectPayrollView.validationErrorMultipleAttempts
      ) {
        if (payrollProviderKey) {
          logAnalyticsEventForSelfService({
            type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessOKButton,
            payload: {
              providerType: 'payroll',
              providerName: payrollProviderKey,
              pageNumber: index + 1,
            },
          });
        }
      }

      setIndex(0);
      setActiveView(ManageConnectPayrollView.steps);
      setHasError(false);
    } else if (activeView === ManageConnectPayrollView.validationSuccess) {
      if (payrollProviderKey) {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.ProviderConnectSuccessNextButton,
          payload: {
            providerType: 'payroll',
            providerName: payrollProviderKey,
            pageNumber: index + 1,
          },
        });
      }

      if (
        payrollProviderKey &&
        adpPayrollProviders.includes(payrollProviderKey)
      ) {
        window.open(
          `https://app.dailypay.com/orbit/success-hub/${payrollProviderContentfulId}`,
        );
      } else {
        setTimeout(() => {
          window.location.href = '/admin';
        }, 400);
      }
    } else if (activeView === ManageConnectPayrollView.steps) {
      if (payrollProviderKey !== undefined) {
        logAnalyticsEventForSelfService({
          type: SelfServiceAnalyticsEventTypes.ConnectProviderNextButton,
          payload: {
            providerType: 'payroll',
            providerName: payrollProviderKey,
            pageNumber: index + 1,
          },
        });
      }
      onSubmit();
    }
  };

  const buttonText = () => {
    if (activeView === ManageConnectPayrollView.steps) {
      return t('onboarding.connect.next');
    }

    if (activeView === ManageConnectPayrollView.validationSuccess) {
      return payrollProviderKey &&
        adpPayrollProviders.includes(payrollProviderKey)
        ? t('onboarding.connect.successAdp.goToSuccessHub')
        : t('onboarding.connect.success.goToPortal');
    }

    if (
      [
        ManageConnectPayrollView.validationError,
        ManageConnectPayrollView.providerError,
      ].includes(activeView)
    ) {
      return t('onboarding.connect.error.tryAgain');
    }

    if (
      activeView === ManageConnectPayrollView.validationErrorMultipleAttempts
    ) {
      return t('onboarding.connect.error.ok');
    }
  };

  const getProvider =
    payrollProviderKey &&
    t(`onboarding.connect.errors.${payrollProviderKey}.subtitle`, {
      provider: t(`integration.names.${payrollProviderKey}`),
    });

  return (
    <>
      <InfoModalCL
        isOpen={isConnecting}
        title={t('onboarding.loading.title')}
        text={t('onboarding.loading.description')}
      />
      {hasError && (
        <TextCL variant="h5" replayEncrypt={true}>
          {getProvider}
        </TextCL>
      )}
      <FormFoundations.FormWrapperGrid>
        {activeView === ManageConnectPayrollView.steps && (
          <Grid xs={12} md={12} lg={12}>
            {renderStepContent()}
          </Grid>
        )}

        {hasError && (
          <Grid container xs={12} md={12} lg={12} gap={4} padding={'48px'}>
            {activeView === ManageConnectPayrollView.providerError ? (
              <ProviderError payrollProviderKey={payrollProviderKey} />
            ) : (
              <ValidationError
                activeView={activeView}
                error={apiError}
                payrollProviderKey={payrollProviderKey}
              />
            )}
          </Grid>
        )}

        {activeView === ManageConnectPayrollView.validationSuccess && (
          <Grid container xs={12} md={12} lg={12} gap={4} padding={'48px'}>
            <ValidationSuccess payrollProviderKey={payrollProviderKey} />
          </Grid>
        )}
      </FormFoundations.FormWrapperGrid>
      <Grid
        container
        xs={12}
        md={12}
        lg={12}
        sx={{
          justifySelf:
            activeView === ManageConnectPayrollView.steps ? 'flex-end' : 'none',
          justifyContent:
            activeView === ManageConnectPayrollView.steps
              ? 'space-between '
              : 'center',
          overflow: 'wrap',
          display: 'flex',
        }}
      >
        {activeView === ManageConnectPayrollView.steps && (
          <ButtonCL
            color="neutral"
            onClick={() => onBackPressed()}
            text="Back"
            sx={{
              color: Moolah.navy[60],
              width: 300,
              height: 48,
              fontSize: 15,
            }}
          />
        )}
        <ButtonCL
          variant="solid"
          color="neutral"
          text={buttonText()}
          disabled={isConnecting}
          onClick={onButtonPressed}
          sx={{
            width: 300,
            height: 48,
            fontSize: 15,
          }}
        />
      </Grid>
    </>
  );
}
