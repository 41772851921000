import { useEffect, useState } from 'react';

type PasswordInfo = {
  score: number;
  feedback: {
    warning: string;
    suggestions: string[];
  };
};

type ZxcvbnType = {
  default: (
    password: string,
    userInputs?: string[] | undefined,
  ) => PasswordInfo;
};

export const usePasswordStrengthMeter = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [getLib, setLib] = useState<ZxcvbnType | null>(null);

  useEffect(() => {
    const loadLib = async () => {
      const zxcvbn = await import('zxcvbn');
      setLib(zxcvbn);
      setIsLoading(false);
    };

    loadLib();
  }, []);

  const getPasswordInfo = (password: string) => {
    if (!getLib) {
      return {
        score: 0,
        feedback: {
          warning: '',
          suggestions: [],
        },
      };
    }

    return getLib.default(password);
  };

  return {
    getPasswordInfo,
    isLoading,
  };
};
