import FormControl from '@mui/joy/FormControl';
import IconButton from '@mui/joy/IconButton';
import Link from '@mui/joy/Link';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Table from '@mui/joy/Table';
import * as React from 'react';
import {
  DailyPayStatus,
  EmployeeSummary,
  EmploymentStatus,
} from '@dailypay/self-service-ts-sdk';
import ArrowIcon from '@mui/icons-material/ExpandMore';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { Box, Grid, Stack, Tab, TabList, Tabs } from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { EmployeeListQueryParams } from '../../../../services/api-wrapper';
import { fetchEmployeesAction } from '../../../../state/admin/adminActions';
import { AppContext } from '../../../../state/appContext';
import { tabClasses } from '@mui/joy/Tab';
import {
  lightGrey,
  lightGreyCount,
} from '../../../../theme/self-service-joy-theme';
import { formatPhoneNumber } from '../../../../formatters/string-formatters';
import { EmployeeStatusChipCL } from '../../../../components/library/EmployeeStatusChipCL';
import { useTranslation } from 'react-i18next';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import {
  SortKeys,
  invertSortKey,
  sortKeyMap,
  sortKeyVisibilityMap,
  tooltipMap,
} from './types/EmployeeTableSortTypes';
import TooltipCL from '../../../../components/library/TooltipCL';
import { Moolah } from '../../../../theme';
import { TextCL } from '../../../../components/library/TextCL';
import useScreenQuery from '../../../../layouts/hooks/useScreenQuery';

function isListEmployeesQueryValid(query: EmployeeListQueryParams) {
  return !(
    query.employment_status === EmploymentStatus.Terminated &&
    query.dp_status !== undefined
  );
}
export default function EmployeesTable({
  searchBoxValue,
}: Readonly<EmployeesTableProps>) {
  const { state, dispatch } = React.useContext(AppContext);

  const { isLargerScreen } = useScreenQuery();

  const { t } = useTranslation();

  const [index, setIndex] = React.useState(4);

  const [employees, setEmployees] = React.useState<EmployeeSummary[]>();
  const [filteredEmployees, setFilteredEmployees] =
    React.useState<EmployeeSummary[]>();

  const [employeeStatusFilter, setEmployeeStatusFilter] =
    React.useState<EmploymentStatus | null>(EmploymentStatus.Employed);
  const [dpStatusFilter, setDpStatusFilter] = React.useState<
    DailyPayStatus | undefined
  >();
  const [pageSize, setPageSize] = React.useState<number>(25);
  const [pageNumber, setPageNumber] = React.useState<number>(1);

  const [sort, setSort] = React.useState<SortKeys>(SortKeys.LastName);

  React.useEffect(() => {
    switch (index) {
      case 0:
        setDpStatusFilter(DailyPayStatus.Active);
        break;
      case 1:
        setDpStatusFilter(DailyPayStatus.Pending);
        break;
      case 2:
        setDpStatusFilter(DailyPayStatus.Deactivated);
        break;
      case 3:
        setDpStatusFilter(DailyPayStatus.Canceled);
        break;
      default:
        setDpStatusFilter(undefined);
        break;
    }
    setPageNumber(1);
  }, [index]);

  React.useEffect(() => {
    if (employeeStatusFilter === EmploymentStatus.Terminated) {
      setDpStatusFilter(undefined);
    } else {
      setDpStatusFilter(undefined);
      setIndex(4);
    }
  }, [employeeStatusFilter]);

  const getEmployeesCallback = React.useCallback(() => {
    const providerUUID = state.admin.providerList?.[0].uuid;

    if (providerUUID !== undefined) {
      const query: EmployeeListQueryParams = {
        employment_status: EmploymentStatus.Employed,
      };

      if (employeeStatusFilter) {
        query.employment_status = employeeStatusFilter;
      }

      if (dpStatusFilter) {
        query.dp_status = dpStatusFilter;
      }

      if (pageSize) {
        query.page_size = pageSize;
      }

      if (pageNumber) {
        query.page = pageNumber;
      }

      if (sort) {
        query.sort =
          SortKeys.LastName === sort || SortKeys.LastNameDesc === sort
            ? sort
            : `${sort};last_name`;
      }

      if (isListEmployeesQueryValid(query)) {
        fetchEmployeesAction(dispatch, providerUUID, query);
      }
    }
  }, [
    state.admin.providerList,
    employeeStatusFilter,
    dpStatusFilter,
    pageSize,
    pageNumber,
    sort,
    dispatch,
  ]);

  React.useEffect(() => {
    getEmployeesCallback();
  }, [getEmployeesCallback]);

  const incrementPageNumber = React.useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const decrementPageNumber = React.useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  React.useEffect(() => {
    if (
      state.admin.employeeList &&
      state.admin.employeeList.employees.length > 0
    ) {
      setEmployees(state.admin.employeeList.employees);
    } else {
      setEmployees([]);
    }
  }, [state.admin.employeeList]);

  React.useEffect(() => {
    if (employees && employees.length > 0) {
      setFilteredEmployees(
        employees.filter(
          (x) =>
            x.email?.toLowerCase().includes(searchBoxValue.toLowerCase()) ||
            x.first_name
              ?.toLowerCase()
              .includes(searchBoxValue.toLowerCase()) ||
            x.last_name.toLowerCase().includes(searchBoxValue.toLowerCase()),
        ),
      );
    } else {
      setFilteredEmployees([]);
    }
  }, [employees, searchBoxValue]);

  React.useEffect(() => {
    setPageNumber(1);
  }, [pageSize]);

  React.useEffect(() => {
    const providerUUID = state.admin.providerList?.[0].uuid;
    if (providerUUID !== undefined) {
      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.EmployeeListPageViewed,
        payload: {
          providerUUID,
          dpStatusFilter,
          employeeStatusFilter,
        },
      });
    }
  }, [state.admin.providerList, dpStatusFilter, employeeStatusFilter]);

  const renderEmploymentFilters = React.useMemo(() => {
    function employmentFilters() {
      return (
        <FormControl size="sm">
          <Select
            size="sm"
            defaultValue={employeeStatusFilter}
            onChange={(e, v) => setEmployeeStatusFilter(v)}
            placeholder="Filter by status"
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          >
            <Option
              onSelect={() =>
                setEmployeeStatusFilter(EmploymentStatus.Employed)
              }
              value="employed"
            >
              {t('admin.employeeTable.employmentFilters.eligible')}
            </Option>
            <Option
              onSelect={() =>
                setEmployeeStatusFilter(EmploymentStatus.Terminated)
              }
              value="terminated"
            >
              {t('admin.employeeTable.employmentFilters.previous')}
            </Option>
          </Select>
        </FormControl>
      );
    }
    return employmentFilters;
  }, [employeeStatusFilter, t]);

  const renderNumberPerPageDropdown = React.useMemo(() => {
    function numberPerPage() {
      return (
        <FormControl size="sm" sx={{ margin: '8px' }}>
          <Select
            size="sm"
            defaultValue={pageSize.toString()}
            onChange={(e, v) => setPageSize(Number(v))}
            placeholder="View 25 per page"
            slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
          >
            <Option value="25">View 25 per page</Option>
            <Option value="50">View 50 per page</Option>
            <Option value="100">View 100 per page</Option>
          </Select>
        </FormControl>
      );
    }
    return numberPerPage;
  }, [pageSize]);

  if (!filteredEmployees) {
    return null;
  }

  return (
    <Stack>
      <TableActions
        renderEmploymentFilters={renderEmploymentFilters}
        index={index}
        setIndex={setIndex}
        employeeStatusFilter={employeeStatusFilter}
        isLargerScreen={isLargerScreen}
      />
      {filteredEmployees && (
        <EmployeeMainTable
          employeesData={filteredEmployees}
          sort={sort}
          setSort={setSort}
          showAllColumns={isLargerScreen}
        />
      )}
      <TableActionsBottom
        incrementPageNumber={incrementPageNumber}
        decrementPageNumber={decrementPageNumber}
        renderNumberPerPageDropdown={renderNumberPerPageDropdown}
        pageSize={pageSize}
      />
    </Stack>
  );
}

interface EmployeesTableProps {
  searchBoxValue: string;
}

interface TableFilterTabListProps {
  index: number;
  setIndex: (arg0: number) => void;
  isLargerScreen: boolean;
}

function TableFilterTabsList({
  index,
  setIndex,
  isLargerScreen,
}: Readonly<TableFilterTabListProps>) {
  const { t } = useTranslation();
  return (
    <Tabs
      onChange={(event, value) => setIndex(value as number)}
      value={index}
      sx={{ bgcolor: 'transparent' }}
    >
      <TabList
        tabFlex={1}
        disableUnderline
        size="sm"
        sx={[
          {
            maxWidth: '100hw',
            p: 0.5,
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: 'sm',
            },
          },
          !isLargerScreen && {
            '--ListItem-paddingX': '0px',
            fontSize: '9px',
          },
        ]}
      >
        <Tab
          variant={index === 0 ? 'solid' : 'plain'}
          color={index === 0 ? 'primary' : 'neutral'}
          disableIndicator
          indicatorInset
          sx={{ borderRadius: '5px 0px 0px 5px' }}
        >
          {t('admin.employeeTable.statusFilters.active')}
        </Tab>
        <Tab
          variant={index === 1 ? 'solid' : 'plain'}
          color={index === 1 ? 'primary' : 'neutral'}
          disableIndicator
          indicatorInset
        >
          {t('admin.employeeTable.statusFilters.pending')}
        </Tab>
        <Tab
          variant={index === 2 ? 'solid' : 'plain'}
          color={index === 2 ? 'primary' : 'neutral'}
          disableIndicator
          indicatorInset
        >
          {t('admin.employeeTable.statusFilters.deactivated')}
        </Tab>
        <Tab
          variant={index === 3 ? 'solid' : 'plain'}
          color={index === 3 ? 'primary' : 'neutral'}
          disableIndicator
          indicatorInset
        >
          {t('admin.employeeTable.statusFilters.canceled')}
        </Tab>
        <Tab
          variant={index === 4 ? 'solid' : 'plain'}
          color={index === 4 ? 'primary' : 'neutral'}
          disableIndicator
          indicatorInset
          sx={{ borderRadius: '0px 5px 5px 0px' }}
        >
          {t('admin.employeeTable.statusFilters.all')}
        </Tab>
      </TabList>
    </Tabs>
  );
}

function TableActions(
  props: Readonly<{
    renderEmploymentFilters: () => JSX.Element;
    index: number;
    setIndex: (arg0: number) => void;
    employeeStatusFilter: EmploymentStatus | null;
    isLargerScreen: boolean;
  }>,
) {
  return (
    <Grid
      container
      justifyContent="space-between"
      alignItems={'center'}
      xs={12}
      md={12}
      lg={12}
      className="formSection"
      sx={{ minHeight: '50px', marginBottom: '1em', maxWidth: '100hw' }}
    >
      <Grid xs={12} md={4} lg={3}>
        {props.renderEmploymentFilters()}
      </Grid>
      <Grid xs={12} md={12} lg={8} flexGrow={0}>
        {props.employeeStatusFilter === EmploymentStatus.Employed && (
          <TableFilterTabsList
            index={props.index}
            setIndex={props.setIndex}
            isLargerScreen={props.isLargerScreen}
          />
        )}
      </Grid>
    </Grid>
  );
}

interface EmployeeCountParams {
  pageSize?: number;
  employeeCount?: number;
  pageCount?: number;
  page?: number;
}

function getEmployeeCountString({
  pageSize,
  employeeCount,
  pageCount,
  page,
}: Readonly<EmployeeCountParams>) {
  if (pageSize && employeeCount && pageCount && page) {
    const start = (page - 1) * pageSize + 1;
    const end = Math.min(page * pageSize, employeeCount);

    return `${start}-${end} of ${employeeCount} Employees`;
  }
  return '';
}

interface TableActionsBottomProps {
  incrementPageNumber: () => void;
  decrementPageNumber: () => void;
  renderNumberPerPageDropdown: () => JSX.Element;
  pageSize: number;
}
function TableActionsBottom({
  incrementPageNumber,
  decrementPageNumber,
  renderNumberPerPageDropdown,
  pageSize,
}: Readonly<TableActionsBottomProps>) {
  const { state } = React.useContext(AppContext);

  const employeeCount = state.admin.employeeList?.employee_count;
  const pageCount = state.admin.employeeList?.page_count;
  const page = state.admin.employeeList?.page;

  const employeeCountString = React.useMemo(() => {
    return getEmployeeCountString({ pageSize, employeeCount, pageCount, page });
  }, [employeeCount, page, pageCount, pageSize]);

  return (
    <Grid
      container
      padding={0}
      justifyContent="space-between"
      alignItems={'center'}
      xs={12}
      md={12}
      lg={12}
      className="formSection"
      sx={{ borderTop: '1px solid', borderTopColor: lightGrey, padding: '0px' }}
    >
      <Grid xs={12} md={6} lg={6} sx={{ color: lightGreyCount }}>
        {employeeCountString}
      </Grid>

      <Grid
        xs={12}
        md={6}
        lg={6}
        display="flex"
        flexDirection={'row'}
        justifyContent={'flex-end'}
        alignItems="center"
      >
        {renderNumberPerPageDropdown()}
        {state.admin.employeeList?.page &&
          `Page ${state.admin.employeeList?.page} of ${state.admin.employeeList?.page_count}`}
        <IconButton
          onClick={decrementPageNumber}
          variant="plain"
          disabled={page == 1}
        >
          <ArrowBackOutlinedIcon />
        </IconButton>
        <IconButton
          disabled={
            page === undefined || pageCount === undefined || page >= pageCount
          }
          onClick={incrementPageNumber}
          variant="plain"
        >
          <ArrowForwardOutlinedIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
}

interface EmployeeMainTableProps {
  employeesData: EmployeeSummary[];
  sort: SortKeys;
  setSort: (arg0: SortKeys) => void;
  showAllColumns?: boolean;
}

function EmployeeMainTable({
  employeesData,
  sort,
  setSort,
  showAllColumns,
}: Readonly<EmployeeMainTableProps>) {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Table
      stickyHeader
      sx={[
        {
          '--Table-headerUnderlineThickness': '1px',
          '--TableCell-height': '60px',
          '--TableCell-paddingX': '8px',
          '--TableCell-paddingY': '8px',
          '--TableCell-align': 'right',
          '& thead th': { height: '40px', paddingY: '16px' },
        },
        showAllColumns
          ? {
              '& thead th:nth-of-type(1)': { width: '12.5%' },
              '& thead th:nth-of-type(2)': { width: '12.5%' },
              '& thead th:nth-of-type(3)': { width: '30%' },
              '& thead th:nth-of-type(4)': { width: '20%' },
              '& thead th:nth-of-type(5)': { width: '10%' },
              '& thead th:nth-of-type(6)': { width: '10%' },
            }
          : {
              '& thead th:nth-of-type(1)': { width: '35%' },
              '& thead th:nth-of-type(2)': { width: '35%' },
              '& thead th:nth-of-type(3)': { width: '30%' },
            },
      ]}
      noWrap
    >
      <thead>
        <tr style={{ alignItems: 'center' }}>
          {Object.entries(sortKeyMap)
            .filter((x) => showAllColumns || sortKeyVisibilityMap(x[0]))
            .map((sortKey) => {
              if (sortKey[1]) {
                return (
                  <th key={sortKey[0]} style={{ alignItems: 'center' }}>
                    <Box
                      flexDirection="row"
                      display="flex"
                      sx={{ justifyContent: 'flex-start' }}
                      flexWrap="wrap"
                    >
                      <Link
                        underline="none"
                        color="primary"
                        component="button"
                        onClick={() => {
                          if (sortKey[1] && sortKey[1] !== sort) {
                            setSort(sortKey[1]);
                          } else {
                            setSort(invertSortKey(sort));
                          }
                        }}
                        fontWeight="lg"
                        endDecorator={
                          (sortKey[1] === sort ||
                            invertSortKey(sortKey[1]) === sort) && <ArrowIcon />
                        }
                        sx={
                          sortKey[1] === sort ||
                          invertSortKey(sortKey[1]) === sort
                            ? {
                                '& svg': {
                                  transition: '0.2s',
                                  transform: sort.startsWith('-')
                                    ? 'rotate(0deg)'
                                    : 'rotate(180deg)',
                                },
                                paddingRight: '1em',
                              }
                            : { paddingRight: '1em' }
                        }
                      >
                        {t(sortKey[0])}
                      </Link>
                      {tooltipMap[sortKey[0]] && (
                        <TooltipCL
                          tooltipText={t(`${tooltipMap[sortKey[0]]}`)}
                          placement="top"
                          showCloseIcon={true}
                          iconColor={Moolah.navy[60]}
                        />
                      )}
                    </Box>
                  </th>
                );
              } else {
                return <th key={sortKey[0]}>{t(sortKey[0])}</th>;
              }
            })}
        </tr>
      </thead>
      <tbody>
        {employeesData &&
          employeesData.length > 0 &&
          employeesData.map((row) => (
            <tr key={row.employee_uuid}>
              <td>
                <TextCL replayEncrypt={true}>{row.first_name}</TextCL>
              </td>
              <td>
                <TextCL replayEncrypt={true}>{row.last_name}</TextCL>
              </td>
              {showAllColumns && (
                <>
                  <td>
                    <TextCL replayEncrypt={true}>{row.email}</TextCL>
                  </td>
                  <td>
                    <TextCL replayEncrypt={true}>
                      {row.phone_number !== undefined
                        ? formatPhoneNumber(row.phone_number)
                        : ''}
                    </TextCL>
                  </td>
                  <td>
                    <EmployeeStatusChipCL
                      dpStatus={row.dp_status}
                      replayEncrypt={true}
                    />
                  </td>{' '}
                </>
              )}
              <td>
                <Link
                  onClick={() =>
                    navigate(`/admin/employee/details/${row.employee_uuid}`)
                  }
                  underline="always"
                >
                  {t('admin.employeeTable.manage')}
                </Link>
              </td>
            </tr>
          ))}
        {!employeesData ||
          (employeesData.length === 0 && (
            <tr>
              <td colSpan={6} style={{ textAlign: 'center' }}>
                {t('admin.employeeTable.emptyState.title')}
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
