import * as React from 'react';
import { callStatusAPI } from '../../services/api-wrapper';
import { ServerStatus } from '@dailypay/self-service-ts-sdk';
import ENV_VARS from '../../environment/envvars';
import { captureException } from '../../services/sentry';

export default function ServerStatusViewer() {
  const [serverStatus, setServerStatus] = React.useState<ServerStatus>();
  const [error, setError] = React.useState<boolean>(false);

  const getServerStatus = React.useCallback(async () => {
    try {
      setError(false);
      setServerStatus(undefined);
      const response = await callStatusAPI();
      setServerStatus(response);
    } catch (error) {
      setError(true);
      captureException(error, {
        extra: {
          message: 'Error in callStatusAPI api call',
        },
      });
      console.error(error);
    }
  }, []);

  React.useEffect(() => {
    if (ENV_VARS.env === 'local') getServerStatus();
  }, [getServerStatus]);

  if (error) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'block',
          width: '100%',
          height: '100%',
          border: '2px red dotted',
          pointerEvents: 'none',
        }}
      ></div>
    );
  }

  if (serverStatus?.Running) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          display: 'block',
          width: '100%',
          height: '100%',
          border: '1px green solid',
          pointerEvents: 'none',
        }}
      ></div>
    );
  }

  return null;
}
