import { AggregateActions } from '../appContext';
import {
  createStripeCustomer,
  createstripeSubscription,
  getStripeConfig,
} from '../../services/api-wrapper';
import { Types } from './subscriptionReducer';
import { captureException } from '../../services/sentry';

export const getStripeConfiguration = async (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  try {
    const stripeConfig = await getStripeConfig();

    dispatch({
      type: Types.StripeConfigFetched,
      payload: { stripeConfig },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message:
          'Error in api call to getStripeConfig in getStripeConfiguration',
      },
    });
  }
};

export const setupSubscriptionAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  customerId: string | undefined,
  email: string,
  name: string,
  priceId: string,
) => {
  try {
    const customerReq = { email, name };

    if (!customerId) {
      const customer = await createStripeCustomer(customerReq);
      dispatch({
        type: Types.StripeCustomerCreated,
        payload: { stripeCustomer: customer },
      });
    }

    // Could just roll the createStripeCustomer call implicitly into createstripeSubscription on backend?
    const sub = await createstripeSubscription({ priceId });

    dispatch({
      type: Types.stripeSubscriptionCreated,
      payload: { stripeSubscription: sub },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message:
          'Error in api call to createstripeSubscription in setupSubscriptionAction',
      },
    });
  }
};
