import * as React from 'react';
import { Button, Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { useTranslation } from 'react-i18next';
import { Horizon, Moolah } from '../../../../../theme';
import InputBoxCL from '../../../../../components/library/forms/InputBoxCL';
import { TEST_IDS } from '../../../../../test-utils/test-ids';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { formatPhoneNumber } from '../../../../../formatters/string-formatters';
import { standardPhoneValidation } from '../../../../../utils/validation';
import { Admin } from './type';
import EditFormHeader from '../../../../../components/common/form-header/EditFormHeader';
import TooltipCL from '../../../../../components/library/TooltipCL';
import { EmployeeStatusChipCL } from '../../../../../components/library/EmployeeStatusChipCL';
import {
  checkDateExceeds24Hours,
  formatDateToLongFormat,
  formatTimeAndDateToLongFormat,
} from '../../../../../formatters/date-formatters';
import { captureException } from '../../../../../services/sentry';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import { AdminModal } from './AdminModal';
export interface AdminFormProps {
  admin: Admin;
  updateAdmin: (body: object) => void;
  hasError: boolean;
  setHasError: (hasError: boolean) => void;
}

const schema = yup
  .object({
    phone: standardPhoneValidation(),
    firstName: yup.string().required(),
    lastName: yup.string().required(),
  })
  .required();

export type AdminFormData = {
  phone?: string;
  firstName: string;
  lastName: string;
};

export default function AdminForm({
  admin,
  updateAdmin,
  hasError,
  setHasError,
}: AdminFormProps) {
  const { t } = useTranslation();
  const [isEditingAdmin, setIsEditingAdmin] = React.useState(false);
  const [displayedPhone, setDisplayedPhone] = React.useState<string>(
    admin.phone_number || '',
  );
  const [displayedFirstName, setDisplayedFirstName] = React.useState<string>(
    admin.first_name || '',
  );
  const [displayedLastName, setDisplayedLastName] = React.useState<string>(
    admin.last_name || '',
  );
  const [open, setOpen] = React.useState<boolean>(false);

  const { control, handleSubmit } = useForm<AdminFormData>({
    resolver: yupResolver(schema),
  });

  const handleUpdateAdmin = React.useCallback(async () => {
    const body = {
      phone_number: `+1${control._formValues.phone}`, // TODO: Followup to support multiple countries -> SELF-226
      firstName: control._formValues.firstName,
      last_name: control._formValues.lastName,
    };
    setDisplayedPhone(control._formValues.phone);
    setDisplayedFirstName(control._formValues.firstName);
    setDisplayedLastName(control._formValues.lastName);
    updateAdmin(body);
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.EditAdminContactSubmit,
      payload: {
        adminUUID: admin.admin_uuid,
      },
    });
  }, [
    admin.admin_uuid,
    control._formValues.firstName,
    control._formValues.lastName,
    control._formValues.phone,
    updateAdmin,
  ]);

  const confirmResendInvite = () => {
    try {
      // api call to resend admin invite
      console.log('placeholder for resend invite api call');
      // does this call the update method for admin to update invite date
      // or just need to send the email and refetch the admin info?
      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.ResendAdminConfirmed,
        payload: {
          adminUUID: admin.admin_uuid,
        },
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (err: any) {
      setHasError(true);
      captureException(err, {
        extra: {
          message: 'Error in rensendInvite api call on AdminForm',
        },
      });
    }
  };

  const renderInvite = () => {
    const rensendInvite = () => {
      setOpen(true);
    };

    const getInviteDate = (inviteDate: string | undefined) => {
      if (
        admin.invite_date &&
        checkDateExceeds24Hours(new Date(admin.invite_date)) &&
        admin.status === 'pending'
      ) {
        return t('admin.manageCompanyAccount.adminDetails.inviteExpired', {
          date: formatTimeAndDateToLongFormat(inviteDate),
        });
      } else if (inviteDate && admin.status === 'pending') {
        return t('admin.manageCompanyAccount.adminDetails.inviteExpires', {
          date: formatDateToLongFormat(inviteDate),
        });
      } else if (admin.status === 'active') {
        return '';
      }
    };

    return (
      <>
        <AdminModal
          title={t('admin.manageCompanyAccount.adminDetails.resendModal.title')}
          description={t(
            'admin.manageCompanyAccount.adminDetails.resendModal.description',
          )}
          confirmButtonText={t(
            'admin.manageCompanyAccount.adminDetails.resendModal.confirm',
          )}
          cancelButtonText={t(
            'admin.manageCompanyAccount.adminDetails.resendModal.cancel',
          )}
          isOpen={open}
          onCancel={() => {
            logAnalyticsEventForSelfService({
              type: SelfServiceAnalyticsEventTypes.ResendAdminCancelled,
              payload: {
                adminUUID: admin.admin_uuid,
              },
            });
            setOpen(false);
          }}
          onConfirm={() => {
            setOpen(false);
            confirmResendInvite();
          }}
        />
        <div
          style={{
            backgroundColor: Horizon.info[3],
            paddingTop: '10px',
            paddingBottom: '10px',
            minHeight: '34px',
            paddingLeft: '24px',
          }}
        >
          <TextCL variant="overline">
            {admin.invite_date && admin.status !== 'active'
              ? t('admin.manageCompanyAccount.adminDetails.headings.invite')
              : ''}
          </TextCL>
        </div>
        <div
          style={{
            paddingTop: '14px',
            paddingLeft: '24px',
          }}
        >
          <TextCL variant="input" replayEncrypt={true}>
            {getInviteDate(admin.invite_date)}
          </TextCL>
          {admin.invite_date &&
            checkDateExceeds24Hours(new Date(admin.invite_date)) &&
            admin.status === 'pending' && (
              <Button
                variant="solid"
                size="sm"
                sx={{
                  borderRadius: '50px',
                  width: '30%',
                  padding: '0px',
                  marginTop: '10px',
                }}
                onClick={rensendInvite}
                data-testid={TEST_IDS.admin.adminDetails.inviteButton}
              >
                {t('admin.manageCompanyAccount.adminDetails.inviteButton')}
              </Button>
            )}
        </div>
      </>
    );
  };

  React.useEffect(() => {
    if (hasError) {
      setIsEditingAdmin(true);
      setDisplayedPhone(admin.phone_number || '');
      setDisplayedFirstName(admin.first_name || '');
      setDisplayedLastName(admin.last_name || '');
    } else {
      setIsEditingAdmin(false);
    }
  }, [
    admin.first_name,
    admin.last_name,
    admin.phone_number,
    displayedFirstName,
    displayedLastName,
    displayedPhone,
    hasError,
  ]);

  const getTooltipInfo = () => {
    if (admin.status === 'active') {
      return t('admin.manageCompanyAccount.adminDetails.tooltip.active');
    } else if (
      admin.invite_date &&
      checkDateExceeds24Hours(new Date(admin.invite_date)) &&
      admin.status === 'pending'
    ) {
      return t('admin.manageCompanyAccount.adminDetails.tooltip.expired');
    } else if (admin.status === 'pending') {
      return t('admin.manageCompanyAccount.adminDetails.tooltip.pending');
    }
  };

  return (
    <div style={{ marginBottom: '24px' }}>
      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        sx={{ marginBottom: '-16px', paddingLeft: '40px' }}
      >
        <EditFormHeader
          titleKey={t('admin.manageCompanyAccount.adminDetails.headings.title')}
          isEditing={isEditingAdmin}
          setShowEditInputs={(val: boolean) => {
            setIsEditingAdmin(val);
            if (val) {
              logAnalyticsEventForSelfService({
                type: SelfServiceAnalyticsEventTypes.EditAdminContact,
                payload: {
                  adminUUID: admin.admin_uuid,
                },
              });
            }
          }}
          onSave={() => {
            handleSubmit(handleUpdateAdmin)();
          }}
          onCancel={() => {
            setIsEditingAdmin(false);
            setHasError(false);
          }}
        />
      </Grid>

      <Grid container spacing={0} xs={12} md={12} lg={12} rowSpacing={3}>
        <Grid xs={12} sm={6} md={6} lg={6} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.manageCompanyAccount.adminDetails.headings.firstName')}
            </TextCL>
          </div>
          {isEditingAdmin ? (
            <div style={{ marginLeft: '24px' }}>
              <InputBoxCL
                control={control}
                fieldName="firstName"
                datatestid={TEST_IDS.admin.adminDetails.firstNameInput}
                value={displayedFirstName}
                replayEncrypt={true}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  paddingTop: '14px',
                  paddingLeft: '24px',
                }}
              >
                <TextCL variant="input" replayEncrypt={true}>
                  {displayedFirstName}
                </TextCL>
              </div>
            </>
          )}
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={6} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline" replayEncrypt={true}>
              {t('admin.manageCompanyAccount.adminDetails.headings.lastName')}
            </TextCL>
          </div>

          {isEditingAdmin ? (
            <div style={{ marginLeft: '24px' }}>
              <InputBoxCL
                control={control}
                fieldName="lastName"
                datatestid={TEST_IDS.admin.adminDetails.lastNameInput}
                value={displayedLastName}
                replayEncrypt={true}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  paddingTop: '14px',
                  paddingLeft: '24px',
                }}
              >
                <TextCL variant="input" replayEncrypt={true}>
                  {displayedLastName}
                </TextCL>
              </div>
            </>
          )}
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={6} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.manageCompanyAccount.adminDetails.headings.phone')}
            </TextCL>
          </div>
          {isEditingAdmin ? (
            <div style={{ marginLeft: '24px' }}>
              <InputBoxCL
                control={control}
                fieldName="phone"
                datatestid={TEST_IDS.admin.employees.phoneInput}
                value={displayedPhone}
                replayEncrypt={true}
              />
            </div>
          ) : (
            <>
              <div
                style={{
                  paddingTop: '14px',
                  paddingLeft: '24px',
                }}
              >
                <TextCL variant="input" replayEncrypt={true}>
                  {formatPhoneNumber(displayedPhone)}
                </TextCL>
              </div>
            </>
          )}
        </Grid>
        <Grid xs={12} sm={6} md={6} lg={6} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.manageCompanyAccount.adminDetails.headings.email')}
            </TextCL>
          </div>
          <div
            style={{
              paddingTop: '14px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="input" replayEncrypt={true}>
              {admin.email}
            </TextCL>
          </div>
        </Grid>

        <Grid xs={12} sm={6} md={6} lg={6} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <TextCL variant="overline">
              {t('admin.manageCompanyAccount.adminDetails.headings.status')}
            </TextCL>
            <TooltipCL
              tooltipText={getTooltipInfo()}
              placement="top"
              showCloseIcon={true}
              iconColor={Moolah.navy[60]}
            />
          </div>
          <div
            style={{
              paddingLeft: '24px',
              paddingTop: '14px',
            }}
          >
            <EmployeeStatusChipCL
              dpStatus={
                admin.invite_date &&
                checkDateExceeds24Hours(new Date(admin.invite_date)) &&
                admin.status === 'pending'
                  ? 'expired'
                  : admin.status
              }
              replayEncrypt={true}
            />
          </div>
        </Grid>

        <Grid xs={12} sm={6} md={6} lg={6} className="formField">
          {renderInvite()}
        </Grid>
        {hasError && isEditingAdmin && (
          <Grid xs={12} md={12} lg={12} className="formField">
            <div style={{ color: 'red', textAlign: 'center' }}>
              Something went wrong, please try again.
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
