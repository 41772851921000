interface FormatMoney {
  amountCents: number | undefined;
  withCents?: boolean;
  displayNegativeSymbol?: boolean;
  displayPlusSymbol?: boolean;
}

export const formatMoney = ({
  amountCents,
  withCents = true,
  displayNegativeSymbol = false,
  displayPlusSymbol = false,
}: FormatMoney): string => {
  if (!amountCents) {
    return '$0.00';
  }

  let sign = '';

  if (displayPlusSymbol) {
    sign = '+';
  } else if (amountCents < 0 || displayNegativeSymbol) {
    sign = '-';
  }

  return sign
    ? `${`${sign}`} $${(Math.abs(amountCents) / 100).toFixed(
        withCents ? 2 : 0,
      )}`
    : `$${(Math.abs(amountCents) / 100).toFixed(withCents ? 2 : 0)}`;
};
