import React from 'react';
import { Button } from '@mui/joy';
import { styled } from '@mui/system';
import { Maybe } from '../../cms/contentful/generated/graphql';
import { Moolah } from '../../theme/common';

const Root = styled('ul')({
  backgroundColor: Moolah.white,
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: 16,
  paddingLeft: 16,
  marginLeft: -16,
  marginRight: -16,
});

const StyledButton = styled(Button)({
  borderRadius: 50,
  color: Moolah.navy[60],
  backgroundColor: Moolah.blue[30],
  height: '100%',
  alignSelf: 'center',
  fontSize: 'smaller',
  fontWeight: 600,
  padding: '8px 24px 8px 24px',
  '&:hover': {
    color: Moolah.white,
  },
});

export interface TextFieldWithButtonWrapperProps {
  content?: Maybe<string>;
  buttonText?: Maybe<string>;
  onClick?: () => void;
}

export const TextFieldWithButtonWrapper = ({
  content,
  buttonText,
  onClick,
}: TextFieldWithButtonWrapperProps) => {
  return (
    <Root>
      <p>{content}</p>
      <StyledButton size="sm" onClick={onClick}>
        {buttonText}
      </StyledButton>
    </Root>
  );
};
