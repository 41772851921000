import React from 'react';
import { Box, Link, Stack } from '@mui/joy';
import { dispatchResetAppStateAction } from '../../state/auth/authReducer';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { RegistrationFormData } from '../../formData/formDataTypes';
import { AppContext } from '../../state/appContext';
import { TEST_IDS } from '../../test-utils/test-ids';
import { TextCL } from '../library/TextCL';
import { Horizon } from '../../theme';
import { useTheme } from '@mui/joy';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../services/analytic-events/SelfServiceAnalyticsTypes';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
} from '../../utils/local-storage-utils';
import { LocalStorageKeys } from '../../ss-constants/local-storage';

interface AdminUserLogoutProps {
  forMobile?: boolean;
}
const AdminUserLogout: React.FC<AdminUserLogoutProps> = ({ forMobile }) => {
  const { state, dispatch } = React.useContext(AppContext);

  const theme = useTheme();

  const [name, setName] = React.useState<string>('');
  const [providerName, setProviderName] = React.useState<string>('');

  React.useEffect(() => {
    const prospect = state.onboarding.prospectData
      ?.data as RegistrationFormData;
    if (prospect?.firstName && prospect?.lastName) {
      setName(`${prospect.firstName} ${prospect.lastName}`);
    }
  }, [state.onboarding.prospectData?.data]);

  React.useEffect(() => {
    if (state.admin.mainProvider?.name) {
      setProviderName(state.admin.mainProvider.name);
    }
  }, [state.admin.mainProvider]);

  const { signOut } = useAuthenticator((context) => [context.user]);

  const handleLogout = React.useCallback(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.PortalLogoutClicked,
    });
    setTimeout(() => {
      try {
        signOut();
      } catch (error) {
        console.log('error signing out: ', error);
      }
    }, 0);
    setTimeout(() => {
      dispatchResetAppStateAction(dispatch);
    }, 600);

    if (getLocalStorageItem(LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE)) {
      removeLocalStorageItem(LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE);
    }
    setTimeout(() => {
      window.location.href = '/login';
    }, 1000);
  }, [dispatch, signOut]);

  return (
    <React.Fragment>
      {providerName && (
        <Stack
          sx={{
            padding: '8px',
            paddingLeft: forMobile ? '40px' : '0px',
            alignItems: forMobile ? 'flexStart' : 'flex-end',
            borderBottomWidth: forMobile ? 2 : 0,
            borderBottomColor: theme.palette.danger,
          }}
        >
          {name && <TextCL replayEncrypt={true}>{`${name}`}</TextCL>}

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: forMobile ? 'flex-start' : 'flex-end',
              alignItems: 'center',
            }}
          >
            <TextCL
              color={Horizon.gray[2]}
              replayEncrypt={true}
            >{`${providerName} | `}</TextCL>
            &nbsp;
            <Link
              underline="none"
              level="body-xs"
              onClick={handleLogout}
              data-testid={TEST_IDS.adminProfile.signOutLink}
            >
              <TextCL color={Horizon.gray[2]}>Logout</TextCL>
            </Link>
          </Box>
        </Stack>
      )}
    </React.Fragment>
  );
};

export default AdminUserLogout;
