import React from 'react';

import './analyticsTable.css';
import Table from '@mui/joy/Table';
import { analyticsTableValues } from '../../services/analytic-events/SelfServiceAnalyticsTypes';
import { Box } from '@mui/joy';
import { TextCL } from '../../components/library/TextCL';

export default function AnalyticsTable() {
  return (
    <Box sx={{ padding: '2rem' }}>
      <TextCL variant="h1">Analytics Events and Pages</TextCL>
      <TextCL>
        It is worth noting that when searching for these events on QM they are
        formatted like action-platform i.e. SelfSrv-Registration-NextButton is
        RegistrationNextButton - SSP etc.
      </TextCL>

      <Table aria-label="analytics event table">
        <thead>
          <tr>
            <th style={{ width: '40%' }}>Event Name</th>
            <th>Page</th>
          </tr>
        </thead>
        <tbody>
          {analyticsTableValues.map((row) => (
            <tr key={row.event}>
              <td>{row.event}</td>
              <td>{row.page}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Box>
  );
}
