import {
  AvailableIntegrations,
  ProspectData,
} from '@dailypay/self-service-ts-sdk';
import {
  GetCardWithButtonQuery,
  GetHelpTopicsQuery,
  GetTermsAndCondtionsQuery,
} from '../../cms/contentful/generated/graphql';

export type OnboardingStateType = {
  prospectData: ProspectData | null;
  transientOnboardingState: TransientOnboardingState;
  integrations: AvailableIntegrations | undefined;
  helpTopics: GetHelpTopicsQuery | undefined;
  cardWithButton: GetCardWithButtonQuery | undefined;
  shouldDisplaySidebarCards: boolean;
  termsAndConditions: GetTermsAndCondtionsQuery | undefined;
};

export type ProspectDataIndicators = {
  [fieldName: string]: number | string | boolean | undefined;
  paymentPlanSelected?: string;
  paymentMade?: boolean;
  subscriptionCreated?: boolean;
  payrollConnected?: boolean;
  payrollValidated?: boolean;
  providerAlreadyExists?: boolean;
  termsAccepted: boolean;
  termsVersion?: number;
  dailyPayAccountCreated?: boolean;
  payrollProviderContentfulId?: string;
  payrollProviderKey?: string;
  timeAttendanceProviderKey?: string;
  checkedEmployeesPage?: boolean;
  checkedInvites?: boolean;
  coreProviderUUID?: string;
};

export type TransientOnboardingState = {
  dailyPayAccountConnectionError?: string;
  dailyPayAccountConnectionSubmitting?: boolean;
  payrollValidated?: boolean;
  saveProspectRequired?: boolean;
  checkBusinessNameRequired?: boolean;
  remoteProspectFetched?: boolean;
  userLoggedIn?: boolean;
};

const initialOnboardingState = {
  prospectData: {
    company_name: '',
    data: {},
  },
  transientOnboardingState: {
    dailyPayAccountConnectionError: undefined,
    dailyPayAccountConnectionSubmitting: undefined,
    saveProspectRequired: undefined,
    payrollValidated: undefined,
    remoteProspectFetched: false,
    checkBusinessNameRequired: true,
    userLoggedIn: false,
  },
  integrations: undefined,
  helpTopics: undefined,
  cardWithButton: undefined,
  shouldDisplaySidebarCards: false,
  termsAndConditions: undefined,
};
export { initialOnboardingState };
