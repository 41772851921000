import * as yup from 'yup';
import { escape } from 'lodash';

export const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{10,}$/;

export const standardPasswordYupValidation = yup
  .string()
  .matches(
    passwordRegex,
    'Password must contain at least 10 characters, one uppercase letter, one lowercase letter, one number, and one special character.',
  )
  .required('Password is required.');

export const standardEmailYupValidation = yup
  .string()
  .email('Email Address must be valid')
  .required('Email Address is required');

export const standardConfirmPasswordValidation = yup
  .string()
  .matches(
    passwordRegex,
    'Confirm Password must contain at least 10 characters, one uppercase letter, one lowercase letter, one number, and one special character.',
  )
  .required('Confirm Password is required');

export const standard6DigitCodeValidation = yup
  .string()
  .length(6, 'Verification code must be 6 digits.')
  .required();

export const genericRequiredStringValidation = yup
  .string()
  .required('inputErrors.required');

export const minimalPhoneNumberValidation =
  genericRequiredStringValidation.test(
    'is-min-phone-length',
    'Please enter at least 10 digits.',
    (value) => (value ? value.replace(/\D/g, '').length >= 10 : false),
  );

// using this exact check as this is what core currently validate
export const exactPhoneNumberValidation = genericRequiredStringValidation.test(
  'is-exact-phone-length',
  'Please enter exactly 10 digits.',
  (value) => (value ? value.replace(/\D/g, '').length === 10 : false),
);

export const standardPhoneValidation = () =>
  yup
    .string()
    .test('standard-phone-validation', 'Invalid phone number.', (value) => {
      return exactPhoneNumberValidation.isValidSync(value);
    });

export const preventXSS = (input: string): string => {
  return escape(input);
};

export const checkPresence = (input: string): string => {
  if (input && !input.trim()) {
    return 'Input cannot be just whitespace.';
  }
  return '';
};

export const checkLength = (input: string): string => {
  if (input && (input.length < 2 || input.length > 50)) {
    return 'Input should be between 2 and 50 characters.';
  }
  return '';
};

export const checkFormat = (input: string): string => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const nameRegex = /^[a-zA-Z\s]+$/;
  if (input && !emailRegex.test(input) && !nameRegex.test(input)) {
    return 'Please enter a valid email address or a valid name.';
  }
  return '';
};

export const checkCharacters = (input: string): string => {
  const allowedCharactersRegex = /^[a-zA-Z0-9\s@.-]+$/;
  if (input && !allowedCharactersRegex.test(input)) {
    return 'Input contains invalid characters.';
  }
  return '';
};

export const validateSearchInput = (input: string): string => {
  input = preventXSS(input);

  let errorMessage = checkPresence(input);
  if (errorMessage) return errorMessage;

  errorMessage = checkLength(input);
  if (errorMessage) return errorMessage;

  const formatError = checkFormat(input);
  const characterError = checkCharacters(input);

  if (formatError && characterError) {
    return 'Input contains invalid characters.';
  }

  if (formatError) return formatError;
  if (characterError) return characterError;

  return '';
};
