const appName = process.env.REACT_APP_SELF_SERVICE_NAME;
const env = process.env.REACT_APP_ENV;
const apiBaseURL = process.env.REACT_APP_SELF_SERVICE_API_BASE_URL;
const contentfulURL = process.env.REACT_APP_CONTENTFUL_URL;
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY;
const landingPageURL = process.env.REACT_APP_SELF_SERVICE_LANDING_PAGE_URL;

const ENV_VARS = {
  appName: appName,
  env,
  apiBaseURL,
  contentfulURL,
  amplitudeApiKey,
  landingPageURL,
};

export const AWS_ENV = {
  REGION: process.env.REACT_APP_AWS_COGNITO_REGION || 'us-east-1',
  USER_POOL_ID: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID || '',
  USER_POOL_APP_CLIENT_ID:
    process.env.REACT_APP_AWS_COGNITO_APP_CLIENT_ID || '',
};

export default ENV_VARS;
