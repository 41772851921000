import {
  CreateProviderRequest,
  IntegrationData,
} from '@dailypay/self-service-ts-sdk';

export const sampleVerifyIntegrationsRequest: IntegrationData = [
  {
    integration_name: 'ukg_ready',
    credentials: [
      { name: 'resource_domain', value: 'partner.saashr.com' },
      { name: 'username', value: '1617' },
      { name: 'company_short_name', value: 'DailyPay1' },
      { name: 'password', value: '' },
      { name: 'api_key', value: '' },
    ],
  },
];

export const sampleCreateProviderRequest: CreateProviderRequest = {
  name: 'UKG Ready',
  payroll_system: 'ukg_ready',
  timekeeping_system: 'ukg_ready',
  contact_name: 'John Doe',
  contact_email: '',
  contact_phone: '',
  integrations: sampleVerifyIntegrationsRequest,
};
