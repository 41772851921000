import React, { useEffect } from 'react';
import { Button, Divider, Grid, Box } from '@mui/joy';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { signUp } from 'aws-amplify/auth';
import { AppContext } from '../../../../state/appContext';
import { dispatchIdentityCreatedAction } from '../../../../state/auth/authReducer';
import { TEST_IDS } from '../../../../../src/test-utils/test-ids';
import {
  standardConfirmPasswordValidation,
  standardEmailYupValidation,
  standardPasswordYupValidation,
} from '../../../../utils/validation';
import { Horizon } from '../../../../theme';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import TooltipCL from '../../../../components/library/TooltipCL';
import { TextCL } from '../../../../components/library/TextCL';
import InputBoxCL from '../../../../components/library/forms/InputBoxCL';
import FormFoundations from '../../../../layouts/foundations/FormFoundations';
import './signupForm.css';
import { ButtonCL } from '../../../../components/library/ButtonCL';
import { dawnOrangeLight } from '../../../../theme/self-service-joy-theme';
import PasswordStrengthMeter from '../passwordStrengthMeter/passwordStrengthMeter';

export type SignupFormData = {
  emailAddress: string;
  password: string;
  confirmPassword: string;
};

const schema = yup
  .object({
    emailAddress: standardEmailYupValidation,
    password: standardPasswordYupValidation,
    confirmPassword: standardConfirmPasswordValidation,
  })
  .required();

const SignupForm: React.FC = () => {
  const { state, dispatch } = React.useContext(AppContext);
  const [errorText, setErrorText] = React.useState<string | null>(null);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { control, handleSubmit, reset, formState, watch } =
    useForm<SignupFormData>({
      resolver: yupResolver(schema),
    });

  useEffect(() => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.CreateAccountPageViewed,
    });
  }, []);

  useEffect(() => {
    reset(state.onboarding.prospectData?.data as SignupFormData);
  }, [state.onboarding.prospectData?.data, reset]);

  const handleSignupRequest = React.useCallback(async () => {
    setErrorText(null);

    if (control._formValues.password !== control._formValues.confirmPassword) {
      setErrorText('Passwords do not match');
      logAnalyticsEventForSelfService({
        type: SelfServiceAnalyticsEventTypes.CreateAccountFailure,
        payload: {
          email: control._formValues.emailAddress,
          reason: 'passwords.do.not.match',
        },
      });
    } else {
      const signupParams = {
        username: control._formValues.emailAddress,
        password: control._formValues.password,
        attributes: {
          email: control._formValues.emailAddress,
        },
        autoSignIn: {
          enabled: true,
        },
      };

      signUp(signupParams)
        .then(() => {
          dispatchIdentityCreatedAction(dispatch, true);

          logAnalyticsEventForSelfService({
            type: SelfServiceAnalyticsEventTypes.CreateAccountSuccess,
            payload: {
              email: control._formValues.emailAddress,
            },
          });

          navigate('/onboarding/checkYourEmail');
          setErrorText(null);
        })
        .catch((err) => {
          if (err.code === 'UsernameExistsException') {
            dispatchIdentityCreatedAction(dispatch, true);
            navigate('/onboarding/checkYourEmail');
          }

          logAnalyticsEventForSelfService({
            type: SelfServiceAnalyticsEventTypes.CreateAccountFailure,
            payload: {
              email: control._formValues.emailAddress,
              reason: err.code,
            },
          });

          setErrorText(err.message);
        });
    }
  }, [
    control._formValues.confirmPassword,
    control._formValues.emailAddress,
    control._formValues.password,
    dispatch,
    navigate,
  ]);

  const handleSignupRequestInvalid = React.useCallback(async () => {
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.CreateAccountNextInvalid,
    });
  }, []);

  return (
    <>
      <FormFoundations.FormWrapperGrid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h3">{t('onboarding.createAccount.heading')}</TextCL>
        </Grid>
        <Grid xs={12} md={12} lg={12} className="formSection">
          <TextCL variant="h5">
            {t('onboarding.createAccount.description')}
          </TextCL>
          <Divider sx={{ marginTop: '16px' }} />
        </Grid>

        <Grid xs={12} md={12} lg={6}>
          <Grid xs={12} md={12} lg={6} sx={{ marginBottom: '48px' }}>
            <TextCL variant="h6">
              {t('onboarding.createAccount.subHeading').toUpperCase()}
            </TextCL>
          </Grid>

          <Grid xs={12} md={12} lg={6} sx={{ paddingBottom: '46px' }}>
            <InputBoxCL
              control={control}
              fieldName="emailAddress"
              label="USERNAME"
              type="email"
              replayEncrypt={true}
            />
          </Grid>
          <Grid
            container
            xs={12}
            md={12}
            lg={12}
            sx={{
              paddingBottom: '46px',
              marginLeft: '0',
            }}
            spacing={5}
          >
            <InputBoxCL
              control={control}
              fieldName="password"
              label="PASSWORD"
              type="password"
              addDivider
              replayBlock={true}
            />
            <TooltipCL
              tooltipText={t('onboarding.createAccount.tooltip')}
              placement="right"
              infoBackgroundColor={true}
              showCloseIcon={false}
              iconColor={Horizon.info[2]}
            />
          </Grid>
          <PasswordStrengthMeter password={watch('password')} />
          <Grid xs={12} md={12} lg={6}>
            <InputBoxCL
              control={control}
              fieldName="confirmPassword"
              label="CONFIRM PASSWORD"
              type="password"
              addDivider
              replayBlock={true}
            />
          </Grid>
          {errorText && (
            <Grid
              xs={12}
              md={12}
              lg={12}
              sx={{
                padding: '10px 24px',
                marginTop: '24px',
                marginBottom: '24px',
                backgroundColor: dawnOrangeLight,
                borderRadius: '4px',
              }}
            >
              <TextCL variant="body">{errorText}</TextCL>
            </Grid>
          )}
        </Grid>
      </FormFoundations.FormWrapperGrid>
      <Box
        sx={{
          gridColumn: '1/-1',
          justifySelf: 'flex-end',
          justifyContent: 'space-between',
          overflow: 'wrap',
          display: 'flex',
          gap: 1,
        }}
      >
        <ButtonCL
          color="neutral"
          onClick={() => {
            logAnalyticsEventForSelfService({
              type: SelfServiceAnalyticsEventTypes.CreateAccountBack,
              payload: {
                email: control._formValues.emailAddress,
              },
            });
            navigate(-1);
          }}
          text="Cancel"
        />
        <Button
          variant="solid"
          color="neutral"
          size="sm"
          sx={{ borderRadius: '50px' }}
          onClick={handleSubmit(
            handleSignupRequest,
            handleSignupRequestInvalid,
          )}
          disabled={!formState.isDirty}
          data-testid={TEST_IDS.register.nextButton}
        >
          Next
        </Button>
      </Box>
    </>
  );
};

export default SignupForm;
