import React from 'react';
import { createBrowserRouter } from 'react-router-dom';
import WizardLayout from '../layouts/WizardLayout';
import ErrorPage from '../error-page';
import AcceptTerms from './onboarding/acceptTerms/acceptTerms';
import LandingPage from './landingPage/landingPage';
import MarketingLayout from '../layouts/MarketingLayout';
import EnterPayrollConfig from './onboarding/enterPayrollConfig/enterPayrollConfig';
import ValidatePayroll from './onboarding/validatePayroll/validatePayroll';
import UnsupportedPayrollProvider from './onboarding/unsupportedPayrollProvider/unsupportedPayrollProvider';
import Dashboard from './admin/dashboard/dashboard';
import AdminLayout from '../layouts/AdminLayout';
import Demo from './demo/demo';
import ProvideCompanyDetails from './onboarding/provideCompanyDetails/provideCompanyDetails';
import RegistrationPage from './onboarding/registrationPage/registrationPage';
import SignupPage from './onboarding/signupPage/signupPage';
import VerifyPage from './onboarding/verifySignupPage/verifySignupPage';
import SubscriptionPage from './onboarding/subscribePage/subscriptionPage';
import OnlyUnauthedRoute from './onlyUnauthedRoute';
import CheckEmailVerificationPage from './onboarding/checkEmailVerificationPage.tsx/checkEmailVerificationPage';
import EnterpriseProgram from './onboarding/enterprisePage/enterprisePage';
import SigninPage from './auth/signInPage/signinPage';
import Employees from './admin/employees/employees';
import Support from './admin/support/support';
import EmployeeDetails from './admin/employees/employeeDetails';
import OnlyAuthedRoute from './onlyAuthedRoute';
import DuplicateCompany from './onboarding/duplicateCompanyPage/duplicateCompanyPage';
import AnalyticsTable from './analyticsTable/analyticsTable';
import AcceptUpdatedTerms from './admin/acceptUpdatedTerms/acceptUpdatedTerms';
import InviteExpiredPage from './onboarding/inviteExpired/inviteExpired';
import ManageCompanyAccount from './admin/manageCompanyAccount/manageCompanyAccount';
import ManageAdminDetails from './admin/manageCompanyAccount/manageAdminDetails';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MarketingLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/',
        index: true,
        element: <LandingPage />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    index: true,
    path: '/login',
    element: <SigninPage />,
    errorElement: <ErrorPage />,
  },
  {
    index: true,
    path: '/expired',
    element: (
      <OnlyUnauthedRoute>
        <InviteExpiredPage />
      </OnlyUnauthedRoute>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/demo',
    element: <Demo />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/analyticsTable',
    element: <AnalyticsTable />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/onboarding',
    element: <WizardLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        path: '/onboarding',
        element: <RegistrationPage />,
        errorElement: <ErrorPage />,
      },
      {
        index: true,
        path: '/onboarding/createAccount',
        element: <SignupPage />,
        errorElement: <ErrorPage />,
      },
      {
        index: true,
        path: '/onboarding/checkYourEmail',
        element: <CheckEmailVerificationPage />,
        errorElement: <ErrorPage />,
      },
      {
        index: true,
        path: '/onboarding/verifyAccount',
        element: <VerifyPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/duplicateCompany',
        element: <DuplicateCompany />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/acceptTerms',
        element: <AcceptTerms />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/connectPayroll',
        element: <EnterPayrollConfig />,
        errorElement: <ErrorPage />,
      },
      {
        index: true,
        path: '/onboarding/subscribe',
        element: <SubscriptionPage />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/unsupportedPayrollProvider',
        element: <UnsupportedPayrollProvider />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/enterpriseProgram',
        element: <EnterpriseProgram />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/companyDetails',
        element: <ProvideCompanyDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/onboarding/validate',
        element: <ValidatePayroll />,
        errorElement: <ErrorPage />,
      },
    ],
  },
  {
    path: '/admin',
    element: (
      <OnlyAuthedRoute>
        <AdminLayout />
      </OnlyAuthedRoute>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        path: '/admin',
        index: true,
        element: <Dashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/employees',
        element: <Employees />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/employee/details/:employeeUUID',
        element: <EmployeeDetails />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/support',
        element: <Support />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/acceptTerms',
        element: <AcceptUpdatedTerms />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/secureTest',
        element: <Dashboard />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/manageCompanyAccount',
        element: <ManageCompanyAccount />,
        errorElement: <ErrorPage />,
      },
      {
        path: '/admin/manageCompanyAccount/adminDetails/:adminUUID',
        element: <ManageAdminDetails />,
        errorElement: <ErrorPage />,
      },
    ],
  },
]);

export { router };
