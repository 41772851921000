import React from 'react';
import './provideCompanyDetails.css';
import Box from '@mui/joy/Box';
import ProvideCompanyDetailsForm from './forms/provideCompanyDetailsForm';
import { Divider, Stack, Typography } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import useScreenQuery from '../../../layouts/hooks/useScreenQuery';

function ProvideCompanyDetails() {
  const { t } = useTranslation();
  const { isLargerScreen } = useScreenQuery();

  return (
    <>
      <Helmet>
        <title>Self Service - Company Information</title>
      </Helmet>
      <Stack spacing={4} sx={{ padding: isLargerScreen ? '5em' : '1em' }}>
        <Typography level="h1" id="screen.companyInfo.title">
          {t('companyInformation.title')}
        </Typography>
        <Typography level="title-md">
          {t('companyInformation.usage')}
        </Typography>
        <Typography level="h3">
          {t('companyInformation.companyDetails')}
        </Typography>
        <Divider />

        {false && (
          <>
            <Box
              sx={{
                pt: 3,
                pb: 10,
                display: 'grid',
                gridTemplateColumns: {
                  xs: '100%',
                  sm: 'minmax(120px, 30%) 1fr',
                  lg: '280px 1fr minmax(120px, 208px)',
                },
                columnGap: { xs: 2, sm: 3, md: 4 },
                rowGap: { xs: 2, sm: 2.5 },
                '& > hr': {
                  gridColumn: '1/-1',
                },
                borderRadius: '8px',
                padding: '16px',
              }}
            >
              <ProvideCompanyDetailsForm />
            </Box>

            <Typography level="h3">
              {t('companyInformation.additionalCompanyDetails')}
            </Typography>

            <Divider />

            <Typography level="h2">Congratulations!</Typography>
            <Typography level="h2">
              Your company is eligible for DailyPay
            </Typography>

            <Divider />
          </>
        )}
      </Stack>
    </>
  );
}

export default ProvideCompanyDetails;
