import * as React from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import { DailyPayStatus, Employee } from '@dailypay/self-service-ts-sdk';
import { useTranslation } from 'react-i18next';
import { Horizon, Moolah } from '../../../../../theme';
import { TEST_IDS } from '../../../../../test-utils/test-ids';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { AutocompleteCL } from '../../../../../components/library/AutocompleteCL';
import { supportedDpStatus } from './types';
import { EmployeeStatusChipCL } from '../../../../../components/library/EmployeeStatusChipCL';
import { formatDateToLongFormat } from '../../../../../formatters/date-formatters';
import TooltipCL from '../../../../../components/library/TooltipCL';
import useScreenQuery from '../../../../../layouts/hooks/useScreenQuery';
import EditFormHeader from '../../../../../components/common/form-header/EditFormHeader';

export interface DailyPayInfoProps {
  employee: Employee;
  updateEmployee?: (body: object) => void;
  hasError?: boolean;
}

const schema = yup
  .object({
    dpStatus: yup.string().required('DailyPay status is required'),
  })
  .required();

export type FormData = {
  dpStatus: string;
};

export default function DailyPayInfo({
  employee,
  hasError,
}: DailyPayInfoProps) {
  const { t } = useTranslation();
  const [isEditingDailypay, setIsEditingDailypay] = React.useState(false);
  const { isMediumScreen, isLargerScreen } = useScreenQuery();

  const { control } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  React.useEffect(() => {
    if (hasError) {
      setIsEditingDailypay(true);
    }
  }, [hasError]);

  return (
    <div style={{ marginBottom: '24px' }}>
      <Grid
        xs={12}
        md={12}
        lg={12}
        sx={{ marginBottom: '-32px', paddingLeft: '40px' }}
      >
        <EditFormHeader
          titleKey={t('admin.employees.accountInfo.dailypay.title')}
          isEditing={isEditingDailypay}
        />
      </Grid>
      <Grid container spacing={0} xs={12} md={12} lg={12} rowSpacing={3}>
        <Grid xs={12} md={12} lg={12} className="formField">
          {hasError && isEditingDailypay && (
            <div style={{ color: 'red', textAlign: 'center' }}>
              Something went wrong, please try again.
            </div>
          )}
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
              display: 'flex',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.dailypay.status')}
            </TextCL>

            <div style={{ paddingLeft: '4px' }}>
              <TooltipCL
                tooltipText={t('terminology.tooltip.dailypayStatus')}
                placement="top"
                showCloseIcon={true}
                iconColor={Moolah.navy[60]}
              />
            </div>
          </div>
          {isEditingDailypay ? (
            <div
              style={{
                paddingLeft: '24px',
                paddingTop: '14px',
              }}
            >
              <AutocompleteCL
                fieldName={'dpStatus'}
                control={control}
                datatestid={TEST_IDS.admin.employees.dpStatusDropdown}
                options={supportedDpStatus}
                defaultValue={supportedDpStatus.find(
                  (status) => status.id === employee.dp_status,
                )}
                isStatusDropdown={true}
                replayEncrypt={true}
              />
            </div>
          ) : (
            <div
              style={{
                paddingLeft: '24px',
                paddingTop: '14px',
              }}
            >
              <EmployeeStatusChipCL
                dpStatus={employee.dp_status}
                replayEncrypt={true}
              />
            </div>
          )}
        </Grid>
        {(employee.consent_date || isLargerScreen) && (
          <Grid xs={12} sm={6} md={3} lg={3} className="formField">
            <div
              style={{
                backgroundColor: Horizon.info[3],
                paddingTop: '10px',
                paddingBottom: '10px',
                minHeight: '34px',
                paddingLeft: '24px',
              }}
            >
              <TextCL variant="overline">
                {employee.dp_status !== DailyPayStatus.Pending
                  ? t('admin.employees.accountInfo.dailypay.joined')
                  : ''}
              </TextCL>
            </div>
            <div
              style={{
                paddingTop: '14px',
                paddingLeft: '24px',
              }}
            >
              <TextCL variant="input" replayEncrypt={true}>
                {formatDateToLongFormat(employee.consent_date)}
              </TextCL>
            </div>
          </Grid>
        )}
        {(employee.first_transfer_date || isLargerScreen) && (
          <Grid xs={12} sm={6} md={3} lg={3} className="formField">
            <div
              style={{
                backgroundColor: Horizon.info[3],
                paddingTop: '10px',
                paddingBottom: '10px',
                paddingLeft: '24px',
                minHeight: '34px',
              }}
            >
              <TextCL variant="overline">
                {employee.dp_status !== DailyPayStatus.Pending
                  ? t('admin.employees.accountInfo.dailypay.firstTransfer')
                  : ''}
              </TextCL>
            </div>
            <div
              style={{
                paddingTop: '14px',
                paddingLeft: '24px',
              }}
            >
              <TextCL variant="input" replayEncrypt={true}>
                {formatDateToLongFormat(employee.first_transfer_date)}
              </TextCL>
            </div>
          </Grid>
        )}
        {isMediumScreen && (
          <Grid xs={12} sm={6} md={3} lg={3} className="formField">
            <div
              style={{
                backgroundColor: Horizon.info[3],
                minHeight: '34px',
              }}
            >
              <TextCL variant="overline">{''}</TextCL>
            </div>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
