import { startCase } from 'lodash';
import { team } from '../../../../../../ss-constants/onboarding';

export const PROVIDERS_WITH_PAYGROUPS_SUPPORT = [team];

export const PAY_FREQUENCY_OPTS = [
  'weekly',
  'biweekly',
  'semimonthly',
  'monthly',
] as const;

export const DEFAULT_PAY_FREQUENCY = PAY_FREQUENCY_OPTS[1];

export const DEFAULT_PAY_GROUP = {
  payFrequency: DEFAULT_PAY_FREQUENCY,
  startDate: '',
  endDate: '',
  processDate: '',
  payDate: '',
};

export const DEFAULT_ADDITIONAL_PAY_GROUP = {
  startDate2: '',
  endDate2: '',
  processDate2: '',
  payDate2: '',
};

export const DATE_FIELDS = ['startDate', 'endDate', 'processDate', 'payDate'];

export const PAY_GROUP_KEYS = Object.keys(DEFAULT_PAY_GROUP);

export const ADDITIONAL_PAY_GROUP_KEYS = Object.keys(
  DEFAULT_ADDITIONAL_PAY_GROUP,
);

export const MAX_PAYGROUPS_LENGTH = 2;

export const LABELS = Object.fromEntries([
  ...PAY_GROUP_KEYS.map((k) => [
    k,
    k === 'payFrequency'
      ? 'Payroll Frequency'
      : `Last Pay Period ${startCase(k)}`,
  ]),
  ...ADDITIONAL_PAY_GROUP_KEYS.map((k) => [
    k,
    `Two Pay Period Ago ${startCase(k.slice(0, -1))}`,
  ]),
]);

export const getFieldLabel = (key: string): string => {
  return LABELS[key].toUpperCase();
};
