/* eslint-disable @typescript-eslint/no-explicit-any */
import * as React from 'react';
import { Grid } from '@mui/joy';
import { TextCL } from '../../../../../components/library/TextCL';
import {
  Employee,
  Employment,
  EmploymentStatus,
} from '@dailypay/self-service-ts-sdk';
import { useTranslation } from 'react-i18next';
import { Horizon } from '../../../../../theme';
import { TEST_IDS } from '../../../../../test-utils/test-ids';
import { EmployeeStatusChipCL } from '../../../../../components/library/EmployeeStatusChipCL';
import { ModalCL } from '../../../../../components/library/ModalCL';
import { supportedEmploymentStatus } from './types';
import { SelectCL } from '../../../../../components/library/SelectCL';
import {
  SelfServiceAnalyticsEventTypes,
  logAnalyticsEventForSelfService,
} from '../../../../../services/analytic-events/SelfServiceAnalyticsTypes';
import EditFormHeader from '../../../../../components/common/form-header/EditFormHeader';

export interface EmploymentInfoProps {
  employee: Employee;
  updateEmployee: (body: object) => void;
  confirmTermination: boolean;
  hasError: boolean;
  isLoading?: boolean;
  employmentStatus?: EmploymentStatus;
  setHasError: (hasError: boolean) => void;
  onSuccess: boolean;
}

export default function EmploymentInfo({
  employee,
  updateEmployee,
  confirmTermination,
  hasError,
  isLoading,
  employmentStatus,
  setHasError,
  onSuccess,
}: EmploymentInfoProps) {
  const { t } = useTranslation();
  const [isEditingEmployment, setIsEditingEmployment] = React.useState(false);
  const [confirmTerminationOpen, setConfirmTerminationOpen] =
    React.useState<boolean>(false);
  const employment = employee.employments[0];
  const [displayedEmploymentStatus, setDisplayedEmploymentStatus] =
    React.useState<EmploymentStatus>(employmentStatus as EmploymentStatus);

  React.useEffect(() => {
    setDisplayedEmploymentStatus(employmentStatus as EmploymentStatus);
  }, [employmentStatus]);

  React.useEffect(() => {
    if (!confirmTermination) {
      setConfirmTerminationOpen(false);
    }
  }, [confirmTermination]);

  const handleUpdateEmployee = React.useCallback(async () => {
    const body = {
      employment_status: displayedEmploymentStatus,
    };
    updateEmployee(body);
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.EmploymentStatusChanged,
      payload: {
        employeeUUID: employee.employee_uuid,
        employmentStatus: displayedEmploymentStatus,
      },
    });
  }, [displayedEmploymentStatus, employee.employee_uuid, updateEmployee]);

  const handleEmployeeStatusChange = () => {
    setConfirmTerminationOpen(true);
    logAnalyticsEventForSelfService({
      type: SelfServiceAnalyticsEventTypes.TerminateEmployeePanelViewed,
      payload: {
        employeeUUID: employee.employee_uuid,
      },
    });
  };

  React.useEffect(() => {
    if (hasError && !onSuccess) {
      setIsEditingEmployment(true);
      setDisplayedEmploymentStatus(employmentStatus as EmploymentStatus);
    } else {
      setIsEditingEmployment(false);
    }
  }, [employmentStatus, hasError, onSuccess]);

  return (
    <div style={{ marginBottom: '24px' }}>
      <ModalCL
        isOpen={confirmTerminationOpen}
        onCancel={() => {
          setConfirmTerminationOpen(false);
          setDisplayedEmploymentStatus(employmentStatus as EmploymentStatus);
          logAnalyticsEventForSelfService({
            type: SelfServiceAnalyticsEventTypes.TerminateEmployeeCancelled,
            payload: {
              employeeUUID: employee.employee_uuid,
            },
          });
        }}
        onConfirm={() => {
          setConfirmTerminationOpen(false);
          handleUpdateEmployee();
          logAnalyticsEventForSelfService({
            type: SelfServiceAnalyticsEventTypes.TerminateEmployeeConfirmed,
            payload: {
              employeeUUID: employee.employee_uuid,
            },
          });
        }}
        terminateEmployee={displayedEmploymentStatus === 'terminated'}
        isLoading={isLoading}
      />
      <Grid
        xs={12}
        md={12}
        lg={12}
        className="formSection"
        sx={{ marginBottom: '-32px', paddingLeft: '40px' }}
      >
        <EditFormHeader
          titleKey={t('admin.employees.accountInfo.employment.title')}
          isEditing={isEditingEmployment}
          setShowEditInputs={(val: boolean) => {
            setIsEditingEmployment(val);
          }}
          onSave={() => {
            handleEmployeeStatusChange();
          }}
          onCancel={() => {
            setIsEditingEmployment(false);
            setDisplayedEmploymentStatus(employmentStatus as EmploymentStatus);
            setHasError(false);
          }}
        />
      </Grid>
      <Grid container spacing={0} xs={12} md={12} lg={12} rowSpacing={3}>
        <Grid xs={12} md={12} lg={12} className="formField"></Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.employment.employeeId')}
            </TextCL>
          </div>
          <div
            style={{
              paddingLeft: '24px',
              paddingTop: '14px',
            }}
          >
            <TextCL variant="input" replayEncrypt={true}>
              {employment.external_id}
            </TextCL>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.employment.status')}
            </TextCL>
          </div>
          {isEditingEmployment ? (
            <div
              style={{
                paddingTop: '14px',
                paddingLeft: '24px',
              }}
            >
              <SelectCL
                size="sm"
                fieldName="employmentStatus"
                options={supportedEmploymentStatus}
                value={displayedEmploymentStatus}
                replayEncrypt={true}
                datatestid={TEST_IDS.admin.employees.employmentStatusDropdown}
                onChangeCallback={(v) => {
                  setDisplayedEmploymentStatus(v as EmploymentStatus);
                }}
              />
            </div>
          ) : (
            <div
              style={{
                paddingTop: '14px',
                paddingLeft: '24px',
              }}
            >
              <EmployeeStatusChipCL
                employeeStatus={displayedEmploymentStatus}
                replayEncrypt={true}
              />
            </div>
          )}
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.employment.location')}
            </TextCL>
          </div>
          <div
            style={{
              paddingTop: '14px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="input" replayEncrypt={true}>
              {employment.location}
            </TextCL>
          </div>
        </Grid>
        <Grid xs={12} sm={6} md={3} lg={3} className="formField">
          <div
            style={{
              backgroundColor: Horizon.info[3],
              paddingTop: '10px',
              paddingBottom: '10px',
              paddingLeft: '24px',
            }}
          >
            <TextCL variant="overline">
              {t('admin.employees.accountInfo.employment.payGroups')}
            </TextCL>
          </div>
          {employee.employments.map((value: Employment, index) => (
            <div
              style={{
                paddingTop: '14px',
                paddingLeft: '24px',
              }}
              key={index}
            >
              <TextCL variant="input" replayEncrypt={true}>
                {value.pay_group}
              </TextCL>
            </div>
          ))}
        </Grid>
        {hasError && isEditingEmployment && (
          <Grid xs={12} md={12} lg={12} className="formField">
            <TextCL variant="error" color="red">
              Something went wrong, please try again.
            </TextCL>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
