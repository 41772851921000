import * as React from 'react';
import { Stack } from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AdminNavLink } from './components/AdminNavLink';
import { AppContext } from '../../state/appContext';
import { CardWithButton } from '../../cms/contentful/custom/CustomTypes';
import { iconLibrary } from '../../assets/icons/icon-library';
import { helpCenterUrl } from '../../ss-constants/admin';
import { SidebarCards } from '../common/card/sidebar-cards';

const adminNavList = [
  {
    key: 'dashboard',
    navPath: '/admin',
    navIcon: iconLibrary.adminNav.home,
  },
  {
    key: 'employees',
    navPath: '/admin/employees',
    navIcon: iconLibrary.adminNav.employees,
  },
];

if (process.env.NODE_ENV === 'development') {
  adminNavList.push({
    key: 'manage',
    navPath: '/admin/manageCompanyAccount',
    navIcon: iconLibrary.adminNav.manage,
  });
}

function AdminSidebar() {
  const navigate = useNavigate();
  const { state } = React.useContext(AppContext);
  const [cardInfo, setCardInfo] = React.useState<CardWithButton[] | undefined>(
    undefined,
  );
  const { t } = useTranslation();
  const [payrollProviderContentfulId, setPayrollProviderContentfulId] =
    React.useState<string>('');

  React.useEffect(() => {
    const info = state.admin.cardWithButton?.cardWithButtonCollection
      ?.items as CardWithButton[];
    setCardInfo(info);
  }, [state.admin.cardWithButton?.cardWithButtonCollection?.items]);

  const location = useLocation();
  const currentPath = location.pathname;

  React.useEffect(() => {
    const payrollPorviderContentfulId = state.admin.mainProvider?.contentful_id;

    if (!payrollPorviderContentfulId) {
      return;
    }

    setPayrollProviderContentfulId(payrollPorviderContentfulId);
  }, [state.admin.mainProvider]);

  function modifyCardUrl(card: CardWithButton) {
    if (card.buttonText?.includes('Success Hub')) {
      card.buttonUrl = `${card.buttonUrl}${payrollProviderContentfulId}`;
    } else if (card.buttonText?.includes('Help Center')) {
      card.buttonUrl = helpCenterUrl;
    }
  }

  return (
    <>
      <Stack spacing={1} sx={{ marginLeft: '2em' }}>
        {adminNavList.map((nav) => (
          <AdminNavLink
            key={nav.key}
            title={t(`admin.navigation.${nav.key}`)}
            iconDetails={nav.navIcon}
            linkFunction={() => navigate(nav.navPath)}
            currentPath={currentPath} // Pass the current path here
            navPath={nav.navPath}
            dataTestId={`admin-nav-link-${nav.key}`}
          />
        ))}
      </Stack>

      {cardInfo && (
        <SidebarCards cardInfo={cardInfo} modifyCardUrl={modifyCardUrl} />
      )}
    </>
  );
}

export default AdminSidebar;
