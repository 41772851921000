import * as React from 'react';
import Box, { BoxProps } from '@mui/joy/Box';

function Root(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
        { display: 'flex' },
      ]}
    />
  );
}

function TopRight(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          position: 'absolute',

          top: 10,
          right: 10,
          minHeight: '100vh',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function LeftSider(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        {
          minHeight: '100vh',
          minWidth: '290px',
          width: '290px',
          backgroundColor: '#f5f5f5',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

function MainContent(props: BoxProps) {
  return (
    <Box
      {...props}
      sx={[
        { backgroundColor: '#f5f5f5', flexGrow: 1 },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    />
  );
}

export default {
  Root,
  LeftSider,
  MainContent,
  TopRight,
};
