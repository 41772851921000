import * as yup from 'yup';
import {
  LABELS,
  PAY_GROUP_KEYS,
  ADDITIONAL_PAY_GROUP_KEYS,
  DATE_FIELDS,
} from '../../routes/onboarding/enterPayrollConfig/payrollForms/steps/payGroups/constants';

const requiredError = (key: string) => {
  return `${LABELS[key]} is required`;
};

const historicalDateTest = (key: string) => yup.string().required(requiredError(key)).test(
  'is-historical',
  'Please select a date in the past.',
  (value) => {
    return checkHistoricalDate(value);
  }
);

const checkHistoricalDate = (value: string) => {
  if (!value) return false;
  const date = new Date(value);
  const now = new Date();
  if (date.toLocaleDateString() === now.toLocaleDateString()) return false;
  return date < now;
};

const isSemiMonthly = (requiredText: string) => ({
  is: 'semimonthly',
  then: () => historicalDateTest(requiredText),
});

export const payGroupsValidationSchema = yup
  .array()
  .of(
    yup.object({
      ...Object.fromEntries(
        PAY_GROUP_KEYS.map((key) =>
        DATE_FIELDS.includes(key) ? [key, historicalDateTest(key)] : [key, yup.string().required(requiredError(key))])
      ),
      ...Object.fromEntries(
        ADDITIONAL_PAY_GROUP_KEYS.map((key) =>
        [key, yup.string().when('payFrequency', isSemiMonthly(requiredError(key)))]),
      ),
    }),
  )
  .min(1);
