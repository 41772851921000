import {
  logAnalyticsEventForSelfService,
  SelfServiceAnalyticsEvent,
} from '../services/analytic-events/SelfServiceAnalyticsTypes';
import { useMutation } from '@tanstack/react-query';

interface UpdateAdminPayload {
  // TODO refactor/split out update payloads and possibly different mutations.
  // Current "mock" implementation overloading.
  payload: unknown;
  event: SelfServiceAnalyticsEvent;
}

const useUpdateAdmin = () => {
  const handleUpdateAdmin = async ({ event }: UpdateAdminPayload) => {
    logAnalyticsEventForSelfService(event);

    // TODO add actual API call here when good to go.
    // e.g. await updateAdmin(payload)
  };

  return useMutation({
    mutationFn: handleUpdateAdmin,
  });
};

export default useUpdateAdmin;
