import {
  CreateProviderRequest,
  IntegrationData,
  ProspectData,
} from '@dailypay/self-service-ts-sdk';
import { AggregateActions } from '../appContext';
import {
  checkProviderExists,
  createProvider,
  getIntegrations,
  getProspectData,
  stageProspect,
  verifyPayrollTMSIntegration,
} from '../../services/api-wrapper';
import {
  Types,
  dispatchFetchSavedProspectAction,
  dispatchPayrollConnectionValidatedAction,
} from './onboardingReducer';
import {
  GetHelpTopicsQuery,
  GetTermsAndCondtionsQuery,
} from '../../cms/contentful/generated/graphql';
import { captureException } from '../../services/sentry';
import { getDateInTimestamp, getToday } from '../../formatters/date-formatters';
import { LocalStorageKeys } from '../../ss-constants/local-storage';
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '../../utils/local-storage-utils';

export const verifyIntegrationAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  integrationData: IntegrationData,
) => {
  let success = true;
  try {
    await verifyPayrollTMSIntegration(integrationData);

    dispatchPayrollConnectionValidatedAction(dispatch);
  } catch (error) {
    success = false;
    captureException(error, {
      extra: {
        message:
          'Error in verifyPayrollTMSIntegration call in verifyIntegrationAction',
      },
    });
  }
  return success;
};

export const createProviderAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  createProviderRequest: CreateProviderRequest,
) => {
  let success = true;
  try {
    dispatch({ type: Types.DailyPayProviderCreationSubmitting });

    const response = await createProvider(createProviderRequest);
    const payrollProviderContentfulId = response.contentful_id;
    const coreProviderUUID = response.uuid;

    dispatch({
      type: Types.DailyPayProviderCreationSuccess,
      payload: { payrollProviderContentfulId, coreProviderUUID },
    });
  } catch (error: unknown) {
    success = false;
    captureException(error, {
      extra: {
        message: 'Error in createProvider call in createProviderAction',
      },
    });
    dispatch({
      type: Types.DailyPayProviderCreationFailure,
      payload: { error },
    });
  }
  return success;
};

const injectMockIntegrationForADP = false;

export const getAvailableIntegrations = async (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  try {
    const availableIntegrations = await getIntegrations();

    if (injectMockIntegrationForADP) {
      const adpRunIntegration = availableIntegrations['adp_run'];
      const adpRunIntegrationCopy = { ...adpRunIntegration };
      adpRunIntegrationCopy.display_name = 'adp_run';
      availableIntegrations['adp_run'] = adpRunIntegrationCopy;
    }

    dispatch({
      type: Types.AvailableIntegrationsFetched,
      payload: { availableIntegrations },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in getIntegrations call in getAvailableIntegrations',
      },
    });
  }
};

export const getHelpTopicsAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  helpTopics: GetHelpTopicsQuery | undefined,
) => {
  try {
    dispatch({
      type: Types.HelpTopicsFetched,
      payload: { helpTopics },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in getHelpTopicsAction',
      },
    });
  }
};

export const checkProviderExistsAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  companyName: string,
) => {
  let response = undefined;
  try {
    response = await checkProviderExists(companyName);
    dispatch({
      type: Types.ProviderAlreadyExists,
      payload: {
        providerAlreadyExists: response.found === true ? true : false,
      },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in checkProviderExistsAction',
      },
    });
  }
  return response;
};

export const getTermsAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  termsAndConditons: GetTermsAndCondtionsQuery | undefined,
) => {
  try {
    dispatch({
      type: Types.TermsFetched,
      payload: { termsAndConditons },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in getTermsAction',
      },
    });
  }
};

export const acceptTermsAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  termsAccepted: boolean,
  version: number,
) => {
  try {
    dispatch({
      type: Types.AcceptTerms,
      payload: {
        termsAccepted,
        version,
      },
    });

    if (
      version === -1 &&
      getLocalStorageItem(LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE) ===
        null
    ) {
      const getTodaysDate = getToday('end');
      const todaysTimestamp = getDateInTimestamp(getTodaysDate);

      setLocalStorageItem(
        LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE,
        todaysTimestamp.toString(),
      );
    }

    if (version > -1) {
      removeLocalStorageItem(LocalStorageKeys.AGREE_TO_NEW_TERMS_PROMPT_DATE);
    }
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in acceptTermsAction',
      },
    });
  }
};

export const saveProspectAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  prospectData: ProspectData,
) => {
  let success = true;
  try {
    console.error(`saveProspectAction>>>>>> ${JSON.stringify(prospectData)}`);

    await stageProspect(prospectData);

    dispatch({ type: Types.AckSavedProspect });
  } catch (error: unknown) {
    success = false;
    captureException(error, {
      extra: {
        message: 'Error in api call to stageProspect in saveProspectAction',
      },
    });
  }
  return success;
};

export const scheduleSaveProspectAction = async (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  try {
    dispatch({ type: Types.QueueSaveProspect });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in scheduleSaveProspectAction',
      },
    });
  }
};

export const checkedEmployeesPageAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  checkedEmployeesPage: boolean,
) => {
  try {
    dispatch({
      type: Types.ThingsToDoCheckEmployeesPage,
      payload: {
        checkedEmployeesPage,
      },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in checkedEmployeesPageAction',
      },
    });
  }
};

export const checkedInvitesAction = async (
  dispatch: React.Dispatch<AggregateActions>,
  checkedInvites: boolean,
) => {
  try {
    dispatch({
      type: Types.ThingsToDoCheckInvites,
      payload: {
        checkedInvites,
      },
    });
  } catch (error: unknown) {
    captureException(error, {
      extra: {
        message: 'Error in checkedInvitesAction',
      },
    });
  }
};

export const fetchProspectAction = async (
  dispatch: React.Dispatch<AggregateActions>,
) => {
  let prospectData = undefined;
  try {
    prospectData = await getProspectData();

    dispatchFetchSavedProspectAction(dispatch, prospectData);
  } catch (error: unknown) {
    console.error(error);

    dispatch({
      type: Types.NoRemoteProspectFound,
    });
  }
  return prospectData;
};
