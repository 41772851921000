export const TEST_IDS = {
  onboardingNav: {
    onboardingNavSection: 'onboardingNav-section',
    registerLink: 'register-link',
    termsAndConditionsLink: 'termsAndConditions-link',
    companyInformationLink: 'companyInformation-link',
    connectPayrollLink: 'connectPayroll-link',
    employeeDataTransferLink: 'employeeDataTransfer-link',
    validateDataLink: 'validateData-link',
  },
  register: {
    firstNameInput: 'firstName-input',
    lastNameInput: 'lastName-input',
    emailAddressInput: 'emailAddress-input',
    passwordInput: 'password-input',
    verificationCodeInput: 'verificationCode-input',
    confirmPasswordInput: 'confirmPassword-input',
    companyNameInput: 'companyName-input',
    companyRoleInput: 'companyRole-input',
    companyCountDropdown: 'companyCount-dropdown',
    payrollProviderDropdown: 'payrollProvider-dropdown',
    timeManagementProviderDropdown: 'timeManagementProvider-dropdown',
    cancelButton: 'cancel-button',
    backButton: 'back-button',
    nextButton: 'next-button',
    letMeKnowCheckbox: 'letMeKnow-checkbox',
    okayButton: 'okay-button',
  },
  terms: {
    termsCheckbox: 'termsAccepted-checkbox',
    backButton: 'back-button',
    nextButton: 'accept-next-button',
  },
  companyInfo: {
    submissionReceivedCloseButton: 'submissionReceived-close-button',
  },
  connectProvider: {
    backButton: 'back-button',
    nextButton: 'next-button',
    stepsContainer: 'steps-container-testid',
    linkWrapper: 'link-wrapper-testid',
    addOrRemovePayGroupButton: 'add-or-remove-paygroup-button',
    ukgReady: {
      apiKeyInput: 'apiKey-input',
      baseURLInput: 'resource_domain-input',
      usernameInput: 'username-input',
      passwordInput: 'password-input',
      customerAPIKeyInput: 'api_key-input',
      terminationsReportedYesCheckbox: 'terminationsReportedYes-checkbox',
      terminationsReportedNoCheckbox: 'terminationsReportedNo-checkbox',
      excludedTimeOffCodesYesCheckbox: 'excludedTimeOffCodesYes-checkbox',
      excludedTimeOffCodesNoCheckbox: 'excludedTimeOffCodesNo-checkbox',
      companyShortNameInput: 'company_short_name-input',
    },
    adpRun: {
      subcriberEmailInput: 'subscriber_email-input',
    },
    errors: {
      unableToValidateCredentialsAlert: 'unableToValidateCredentials-alert',
      unableToValidateCrenentialsDismissButton:
        'unableToValidateCrenentials-dismiss-button',
    },
  },
  connectionValidation: {
    successfulConnectionTick: 'successfulConnection-tick',
    successfulAccessEmployeesTick: 'successfulAccessEmployees-tick',
    nextButton: 'next-button',
    connectButton: 'connect-button',
  },
  sideBar: {
    signOutLink: 'signOut-link',
  },
  adminProfile: {
    signOutLink: 'signOut-link-admin',
  },
  admin: {
    employees: {
      payPeriodDropdown: 'payPeriod-dropdown',
      dpStatusDropdown: 'dpStatus-dropdown',
      employmentStatusDropdown: 'employmentStatus-dropdown',
      phoneInput: 'phone-input',
      emailAddressInput: 'emailAddress-input',
    },
    card: {
      button: 'button',
    },
    thingsToDo: {
      employeesChecked: 'viewedManageEmployees-checkbox',
      letMeKnowCheckbox: 'viewedTerms-checkbox',
    },
    adminDetails: {
      inviteButton: 'invite-button',
      deleteButton: 'delete-button',
      phoneInput: 'phone-input',
      firstNameInput: 'firstName-input',
      lastNameInput: 'lastName-input',
    },
  },
  modal: {
    modalConfirmButton: 'modal-confirm-button',
    modalCloseButton: 'modal-close-button',
    modalViewTermsButton: 'modal-view-terms-button',
  },
};
