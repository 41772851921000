import {
  CreateCustomerRequest,
  CreateProviderRequest,
  CreateSubscriptionRequest,
  IntegrationData,
  PatchRequest,
  ProspectData,
  EmployeeSummary,
  EmploymentStatus,
  DailyPayStatus,
} from '@dailypay/self-service-ts-sdk';
import { callApiAndResolve, getTrackingID } from './use-self-service-api';
import { getCurrentUser } from 'aws-amplify/auth';

const callStatusAPI = async () => {
  const { resolve } = callApiAndResolve();

  return resolve((api) => api.admin.adminStatus());
};

const getProspectData = async () => {
  const { resolve } = callApiAndResolve();

  try {
    await getCurrentUser();
  } catch (err) {
    return Promise.resolve({} as ProspectData);
  }

  return resolve(
    (api) => api.prospects.getProspectData(),
    getTrackingID(),
    true,
  );
};

const getIntegrations = async () => {
  const { resolve } = callApiAndResolve();

  return resolve(
    (api) => api.integrations.getAvailableIntegrations(),
    getTrackingID(),
    true,
  );
};

const stageProspect = async (prospectData?: ProspectData) => {
  const { resolve } = callApiAndResolve();

  try {
    await getCurrentUser();
  } catch (err) {
    // Can't save when we're not logged in
    return;
  }

  return resolve(
    (api) =>
      api.prospects.stageProspectData(
        prospectData ?? { company_name: 'ABC', data: { owner: 'def' } },
      ),
    getTrackingID(),
    true,
  );
};

const verifyPayrollTMSIntegration = async (
  integrationData: IntegrationData,
) => {
  const { resolve } = callApiAndResolve();

  return resolve(
    (api) => api.integrations.verifyIntegration(integrationData),
    getTrackingID(),
    true,
  );
};

const createProvider = async (createProviderRequest: CreateProviderRequest) => {
  const { resolve } = callApiAndResolve();

  return resolve(
    (api) => api.providers.createProvider(createProviderRequest),
    getTrackingID(),
    true,
  );
};

const getProviders = async () => {
  const { resolve } = callApiAndResolve();

  return resolve((api) => api.providers.getProviders());
};

const checkProviderExists = async (name: string) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) => api.providers.checkProviderExists({ name }));
};

const getStripeConfig = async () => {
  const { resolve } = callApiAndResolve();

  return resolve((api) => api.stripe.getConfig());
};

const createStripeCustomer = async (
  createCustomerRequest: CreateCustomerRequest,
) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) => api.stripe.createCustomer(createCustomerRequest));
};

const createstripeSubscription = async (
  createSubscriptionRequest: CreateSubscriptionRequest,
) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) =>
    api.stripe.createSubscription(createSubscriptionRequest),
  );
};

export interface EmployeeListQueryParams {
  /**
   * The page number to retrieve
   * @min 1
   */
  page?: number;
  /**
   * The number of employees to retrieve per page
   * @min 1
   * @max 100
   */
  page_size?: number;
  /** Field to sort by. Prefix with '-' for descending order. e.g. '?sort=-name;email' sorts by name in descending order and email by ascending order Allowable fields for filtering are first_name, last_name, email, dp_status, employment_status */
  sort?: string;
  /** Filter employees by name */
  name?: string;
  /** Filter employees by email */
  email?: string;
  /** Filter employees by DailyPay status */
  dp_status?: DailyPayStatus;
  /** Filter employees by employment status */
  employment_status: EmploymentStatus;
}

const getEmployeeData = async (
  providerUUID: string,
  query: EmployeeListQueryParams,
) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) => api.providers.listEmployees(providerUUID, query));
};

const getEmployeeIndividualData = async (
  providerUUID: string,
  employeeUUID: string,
) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) =>
    api.providers.getEmployee(providerUUID, employeeUUID),
  );
};

const sendEmployeeOnboardLink = async (
  providerUUID: string,
  employeeUUID: string,
  employmentUUID: string,
) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) =>
    api.providers.sendEmployeeOnboardingEmail(
      providerUUID,
      employeeUUID,
      employmentUUID,
    ),
  );
};

const getEmployeePayPeriods = async (
  providerUUID: string,
  employeeUUID: string,
  employmentUUID: string,
) => {
  const { resolve } = callApiAndResolve();
  return resolve((api) =>
    api.providers.getPayPeriods(providerUUID, employeeUUID, employmentUUID),
  );
};

const updateEmployee = async (
  providerUUID: string,
  employeeUUID: string,
  employmentUUID: string,
  data: PatchRequest,
) => {
  const { resolve } = callApiAndResolve();

  return resolve((api) =>
    api.providers.updateEmployment(
      providerUUID,
      employeeUUID,
      employmentUUID,
      data,
    ),
  );
};

const iterativelyGetEmployees = async (providerUUID: string) => {
  const query: EmployeeListQueryParams = {
    employment_status: EmploymentStatus.Employed,
    page_size: 100,
  };

  let employeeListArray: EmployeeSummary[] = [];

  let firstPageEmployeeList;

  if (providerUUID !== undefined) {
    firstPageEmployeeList = await getEmployeeData(providerUUID, query);

    if (
      firstPageEmployeeList !== undefined &&
      firstPageEmployeeList.page_count !== undefined
    ) {
      const pageCount = firstPageEmployeeList.page_count;

      const employeeList = firstPageEmployeeList.employees;
      employeeListArray = [...employeeList];

      for (let i = 1; i < pageCount; i++) {
        const employeeList = await getEmployeeData(providerUUID, {
          ...query,
          page: i + 1,
        });
        employeeListArray.push(...employeeList.employees);
      }
    }
  }
  return employeeListArray;
};

export {
  callStatusAPI,
  getProspectData,
  getIntegrations,
  stageProspect,
  verifyPayrollTMSIntegration,
  createProvider,
  checkProviderExists,
  getStripeConfig,
  createStripeCustomer,
  createstripeSubscription,
  getEmployeeData,
  getProviders,
  getEmployeeIndividualData,
  sendEmployeeOnboardLink,
  getEmployeePayPeriods,
  updateEmployee,
  iterativelyGetEmployees,
};
