import {
  WizardIconKeysAll,
  iconLibrary,
} from '../../assets/icons/icon-library';
import {
  SelfServiceProgressOrder,
  SelfServiceProgressStatus,
} from '../../state/auth/authState';

export type Constraints = {
  [key: string]: boolean;
};

export type StatusConstraints = {
  progressStatus: SelfServiceProgressStatus;
};

// Create a type for the step list
export type WizardStepListType = {
  key: string;
  stepId: string;
  navIconSet: WizardIconKeysAll;
  navPath: string;
  description: string;
  constraints?: Constraints;
  orConstraints?: Constraints[];
  orStatusConstraints?: StatusConstraints[];
  navState?: keyof typeof iconLibrary.onboardingNav;
  lowestAllowedClickableStatus?: SelfServiceProgressStatus;
}[];

export const isStepClickable = (
  currentStatus: SelfServiceProgressStatus,
  lowestAllowedStatus: SelfServiceProgressStatus | undefined,
) => {
  if (!lowestAllowedStatus) {
    return false;
  }

  return (
    SelfServiceProgressOrder[currentStatus] >=
    SelfServiceProgressOrder[lowestAllowedStatus]
  );
};

// Create a sequential list of steps.
const WizardStepList = [
  {
    key: 'register',
    stepId: '5.5.5',
    navIconSet: 'register',
    navPath: '/onboarding',
    description:
      'Pre-Authenticated Data about your name, company name and payroll provider',
    lowestAllowedClickableStatus: SelfServiceProgressStatus.ProspectUnsaved,
  },
  {
    stepId: '10.5.5',
    key: 'createAccount',
    navIconSet: 'createYourAccount',
    navPath: '/onboarding/createAccount',
    description: 'Email and Password Entry. Pre-Verification',
    orStatusConstraints: [
      {
        progressStatus: SelfServiceProgressStatus.ProspectUnsaved,
      },
    ],
    lowestAllowedClickableStatus: SelfServiceProgressStatus.ProspectUnsaved,
  },
  {
    stepId: '10.7.5',
    key: 'checkYourEmail',
    navIconSet: 'createYourAccount',
    navPath: '/onboarding/checkYourEmail',
    description: 'Notice page to go check email to verify before continuing.',
    orStatusConstraints: [
      {
        progressStatus: SelfServiceProgressStatus.IdentityCreated,
      },
    ],
    lowestAllowedClickableStatus: SelfServiceProgressStatus.IdentityCreated,
  },
  {
    stepId: '10.10.5',
    navIconSet: 'createYourAccount',
    key: 'verifyAccount',
    navPath: '/onboarding/verifyAccount',
    description: 'Password Validation and check that email is verified',
    orStatusConstraints: [
      {
        progressStatus: SelfServiceProgressStatus.IdentityCreated,
      },
    ],
    lowestAllowedClickableStatus: SelfServiceProgressStatus.IdentityCreated,
  },
  {
    stepId: '10.15.5',
    navIconSet: 'createYourAccount',
    key: 'duplicateCompany',
    navPath: '/onboarding/duplicateCompany',
    description: 'Duplicate Company',
    orStatusConstraints: [
      {
        progressStatus: SelfServiceProgressStatus.NotStarted,
      },
      {
        progressStatus: SelfServiceProgressStatus.ProviderAlreadyExists,
      },
    ],
    lowestAllowedClickableStatus: SelfServiceProgressStatus.IdentityVerified,
  },
  {
    stepId: '10.20.5',
    navIconSet: 'createYourAccount',
    key: 'terms',
    navPath: '/onboarding/acceptTerms',
    description: 'Terms and Conditions',
    lowestAllowedClickableStatus: SelfServiceProgressStatus.IdentityVerified,
  },
  {
    stepId: '20.5.5',
    key: 'connectPayroll',
    navIconSet: 'connectPayroll',
    navPath: '/onboarding/connectPayroll',
    description: 'Payroll Provider Connection',
    lowestAllowedClickableStatus: SelfServiceProgressStatus.TermsAccepted,
  },
  {
    stepId: '20.10.5',
    key: 'congratulations',
    navIconSet: 'connectPayroll',
    navPath: '/onboarding/congratulations',
    description: 'Success page after accepting terms and connecting to DP',
    orStatusConstraints: [
      {
        progressStatus: SelfServiceProgressStatus.Complete,
      },
    ],
  },
  {
    stepId: '20.15.5',
    key: 'payrollValidationError',
    navIconSet: 'connectPayroll',
    navPath: '/onboarding/connectPayroll/validationError',
    description: 'Success page after accepting terms and connecting to DP',
  },
] as WizardStepListType;

/**
 * The getCurrentNavigationSteps function takes in the state, the step list and the route.  It returns an array of
 * steps that meet the constraints.  If the route is provided then it will set the navState of the step to active
 * and any previous steps to filled and any following steps to inactive.  If the route is not provided then it will
 * set the navState of the step to inactive.  It will then return the array of steps that meet the constraints.
 *
 * @param state - the state is the current application state for any relevant constraint keys
 * @param stepList - the overall step list that are to be filtered to currently valid steps
 * @param route - the current route that is being navigated to used to set the navState of the step
 * @returns - an array of steps that meet the constraints
 */
const getCurrentNavigationSteps = (
  state: Constraints,
  stepList: WizardStepListType,
  route: string,
  statusConstraints?: StatusConstraints,
) => {
  const filtered = stepList.filter((step) => {
    if (step.orStatusConstraints) {
      return step.orStatusConstraints.some((constraint) => {
        if (constraint.progressStatus == statusConstraints?.progressStatus) {
          return true;
        }
        return false;
      });
    }
    if (step.orConstraints) {
      return step.orConstraints.some((constraint) => {
        const constraintKeys = Object.entries(constraint);
        return constraintKeys.every((key) => {
          if (!state[key[0]]) {
            return true;
          }

          if (state[key[0]] !== key[1]) {
            return false;
          }

          return true;
        });
      });
    }

    if (step.constraints) {
      // Get the keys of the constraints
      const constraintKeys = Object.entries(step.constraints);

      // Check every key in the state against the constraint keys
      return constraintKeys.every((key) => {
        // If the key is not in the state then return false
        if (!state[key[0]]) {
          return true;
        }

        if (state[key[0]] !== key[1]) {
          return false;
        }

        return true;
      });
    } else {
      return true;
    }
  });

  // If the current route matches the element set that and any previous steps to active
  // and any following steps to filled
  if (route) {
    const currentStep = filtered.find((step) => step.navPath === route);

    filtered.forEach((step, index) => {
      if (step.navPath === route) {
        step.navState = 'active';
      }
      if (index < filtered.indexOf(currentStep!)) {
        step.navState = 'filled';
      }
      if (index > filtered.indexOf(currentStep!)) {
        step.navState = 'inactive';
      }
    });
  }

  // Re-filter the steps to only take the first element that has a particular navIconSet
  // value from the filtered array
  const filteredNavIconSet = filtered.filter(
    (step, index, array) =>
      array.findIndex((t) => t.navIconSet === step.navIconSet) === index,
  );

  //alert(JSON.stringify(filteredNavIconSet));

  return filteredNavIconSet;
};

// export it all
export { WizardStepList, getCurrentNavigationSteps };
