import ActiveCompInfo from './icon-active-CompInfo.svg';
import ActiveConnectPayroll from './icon-active-ConnectPayroll.svg';
import ActiveCreateYourAccount from './icon-active-CreateYourAccount.svg';
import ActiveEmployeeDataTransfer from './icon-active-EmployeeDataTransfer.svg';
import ActiveRegister from './icon-active-Register.svg';
import ActiveTerms from './icon-active-Terms.svg';
import ActiveValidateData from './icon-active-ValidateData.svg';
import Add from './icon-add.svg';
import ArrowDown from './icon-arrow-down.svg';
import FilledCompInfo from './icon-filled-CompInfo.svg';
import FilledConnectPayroll from './icon-filled-ConnectPayroll.svg';
import FilledCreateYourAccount from './icon-filled-CreateYourAccount.svg';
import FilledEmployeeDataTransfer from './icon-filled-EmployeeDataTransfer.svg';
import FilledRegister from './icon-filled-Register.svg';
import FilledTerms from './icon-filled-Terms.svg';
import FilledValidateData from './icon-filled-ValidateData.svg';
import InactiveCompInfo from './icon-inactive-CompInfo.svg';
import InactiveConnectPayroll from './icon-inactive-ConnectPayroll.svg';
import InactiveCreateYourAccount from './icon-inactive-CreateYourAccount.svg';
import InactiveEmployeeDataTransfer from './icon-inactive-EmployeeDataTransfer.svg';
import InactiveTerms from './icon-inactive-Terms.svg';
import InactiveValidateData from './icon-inactive-ValidateData.svg';
import ActiveSubscribe from './icon-active-Subscribe.svg';
import FilledSubscribe from './icon-filled-Subscribe.svg';
import InactiveSubscribe from './icon-inactive-Subscribe.svg';
import HeadingConnectPayroll from './icon-heading-ConnectPayroll.svg';
import HeadingRegister from './icon-heading-register.svg';
import HeadingCreateAccount from './icon-heading-CreateAccount.svg';
import HeadingEmployees from './icon-heading-Employees.svg';
import HeadingDashboard from './icon-heading-Dashboard.svg';
import HeadingTerms from './icon-heading-terms.svg';
import Menu from './icon-menu.svg';
import MenuWhite from './icon-menu-white.svg';
import Rectangle from './rectangle.svg';
import Remove from './icon-remove.svg';
import Back from './icon-back.svg';
import Calendar from './icon-calendar.svg';
import ChevronDown from './chevron-down-white.svg';
import ChevronUp from './chevron-up-white.svg';
import ChevronDownCircle from './chevron-down.svg';
import ChevronUpCircle from './chevron-up.svg';
import AlertCircle from './icon-alert-circle.svg';
import CircleTimes from './icon-times.svg';
import CircleEquals from './icon-equals.svg';
import CircleSubtract from './icon-subtract.svg';
import CircleInfo from './icon-info-circle.svg';
import CircleInfoBlue from './icon-info-circle-blue.svg';
import CircleInfoNavy from './icon-info-navy-circle.svg';
import Cross from './icon-close.svg';
import EditCircle from './icon-edit-circle.svg';
import Eye from './icon-eye.svg';
import HomeOutine from './icon-outline-home.svg';
import AvatarOutine from './icon-outline-avatar.svg';
import Warning from './icon-warning.svg';
import Cog from './icon-cog.svg';
import CogHeading from './icon-cog-heading.svg';

export const iconLibrary = {
  onboardingNav: {
    active: {
      register: ActiveRegister,
      terms: ActiveTerms,
      compInfo: ActiveCompInfo,
      connectPayroll: ActiveConnectPayroll,
      validateData: ActiveValidateData,
      employeeDataTransfer: ActiveEmployeeDataTransfer,
      subscribe: ActiveSubscribe,
      createYourAccount: ActiveCreateYourAccount,
    },
    filled: {
      register: FilledRegister,
      terms: FilledTerms,
      compInfo: FilledCompInfo,
      connectPayroll: FilledConnectPayroll,
      validateData: FilledValidateData,
      employeeDataTransfer: FilledEmployeeDataTransfer,
      subscribe: FilledSubscribe,
      createYourAccount: FilledCreateYourAccount,
    },
    inactive: {
      register: ActiveRegister,
      compInfo: InactiveCompInfo,
      connectPayroll: InactiveConnectPayroll,
      employeeDataTransfer: InactiveEmployeeDataTransfer,
      terms: InactiveTerms,
      validateData: InactiveValidateData,
      subscribe: InactiveSubscribe,
      createYourAccount: InactiveCreateYourAccount,
    },
  },
  adminNav: {
    home: HomeOutine,
    employees: AvatarOutine,
    manage: Cog,
  },
  heading: {
    register: HeadingRegister,
    connectPayroll: HeadingConnectPayroll,
    createAccount: HeadingCreateAccount,
    acceptTerms: HeadingTerms,
    employees: HeadingEmployees,
    manageCompanyAccount: CogHeading,
    dashboard: HeadingDashboard,
  },
  common: {
    back: Back,
    menu: Menu,
    menuWhite: MenuWhite,
    rectangle: Rectangle,
    calendar: Calendar,
    chevronDown: ChevronDown,
    chevronDownCircle: ChevronDownCircle,
    chevronUpCircle: ChevronUpCircle,
    chevronUp: ChevronUp,
    add: Add,
    alertCircle: AlertCircle,
    circleTimes: CircleTimes,
    circleEquals: CircleEquals,
    circleSubtract: CircleSubtract,
    circleInfo: CircleInfo,
    circleInfoBlue: CircleInfoBlue,
    circleInfoNavy: CircleInfoNavy,
    cross: Cross,
    editCircle: EditCircle,
    eye: Eye,
    remove: Remove,
    warning: Warning,
  },
  table: {
    arrowDown: ArrowDown,
  },
};

// Access the keys of a nested type to use for the icon prop
type WizardIconKeys = keyof typeof iconLibrary.onboardingNav.inactive;
type WizardIconKeysActive = keyof typeof iconLibrary.onboardingNav.active;
type WizardIconKeysFilled = keyof typeof iconLibrary.onboardingNav.filled;

// Create an aggregate type
export type WizardIconKeysAll =
  | WizardIconKeys
  | WizardIconKeysActive
  | WizardIconKeysFilled;

export interface WizardIconType {
  width: number;
  icon: WizardIconKeysAll;
  state: keyof typeof iconLibrary.onboardingNav | undefined;
}
