import * as React from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import AccordionGroup from '@mui/joy/AccordionGroup';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionSummary from '@mui/joy/AccordionSummary';
import ENV_VARS, { AWS_ENV } from '../../environment/envvars';
import { Typography } from '@mui/joy';

export default function EnvironmentViewer() {
  const [open, setOpen] = React.useState(false);
  const [index, setIndex] = React.useState<number | null>(0);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        paddingTop: '2em',
        paddingLeft: '1em',
        paddingRight: '1em',
      }}
    >
      <Button variant="outlined" color="neutral" onClick={() => setOpen(!open)}>
        {open ? 'Close' : 'Open'} environment viewer
      </Button>

      {open && (
        <AccordionGroup sx={{ overflow: 'wrap', paddingTop: '1em' }}>
          <Accordion
            sx={{ overflow: 'wrap' }}
            expanded={index === 0}
            onChange={(event, expanded) => {
              setIndex(expanded ? 0 : null);
            }}
          >
            <AccordionSummary>ENV VARS</AccordionSummary>
            <AccordionDetails>
              {' '}
              {Object.entries(ENV_VARS).map((envvar, index) => (
                <Typography key={index} sx={{ lineHeight: '2em' }}>
                  <b>{envvar[0]}</b>: {envvar[1]}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
          <Accordion
            sx={{ overflow: 'wrap' }}
            expanded={index === 1}
            onChange={(event, expanded) => {
              setIndex(expanded ? 1 : null);
            }}
          >
            <AccordionSummary>AWS ENV VARS</AccordionSummary>
            <AccordionDetails>
              {' '}
              {Object.entries(AWS_ENV).map((envvar, index) => (
                <Typography key={index} sx={{ lineHeight: '2em' }}>
                  <b>{envvar[0]}</b>: {envvar[1]}
                </Typography>
              ))}
            </AccordionDetails>
          </Accordion>
        </AccordionGroup>
      )}
    </Box>
  );
}
