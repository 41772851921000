import { logUserPropertiesForSelfService } from '../services/analytic-events/SelfServiceAnalyticsTypes';

function useUtmParams() {
  const fetchAndStoreUtmParams = () => {
    localStorage.removeItem('utmProps');
    try {
      const urlParams = new URLSearchParams(location.search);
      const utmProps: { [key: string]: string } = {};

      for (const param of urlParams.keys()) {
        const value = urlParams.get(param);
        if (value) {
          utmProps[param] = value;
        }
      }

      if (Object.keys(utmProps).length > 0) {
        localStorage.setItem('utmProps', JSON.stringify(utmProps));
      }

      logUserPropertiesForSelfService(utmProps);
    } catch (error) {
      console.error(error);
    }
  };
  return { fetchAndStoreUtmParams };
}

export default useUtmParams;
