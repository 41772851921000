import React from 'react';

import FormControl from '@mui/joy/FormControl';

import { Control, Controller } from 'react-hook-form';
import { Checkbox, Typography } from '@mui/joy';

interface InputCLProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any, any>;
  fieldName: string;
  label?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  datatestid?: string;
  value?: boolean;
}

export default function CheckboxCL({
  onChange,
  control,
  fieldName,
  label,
  datatestid,
  value,
}: InputCLProps) {
  return (
    <Controller
      name={fieldName}
      control={control}
      defaultValue={value || false}
      render={({ field, fieldState }) => (
        <>
          <FormControl sx={{ flex: 1, margin: 0 }} color="neutral">
            <Checkbox
              label={label}
              name={field.name}
              checked={value === undefined ? field.value : value}
              variant="outlined"
              slotProps={{
                input: {
                  'data-testid': datatestid,
                  value: field.value,
                },
              }}
              onChange={onChange}
            />

            {fieldState.error && (
              <Typography level="body-sm" color="danger">
                {fieldState.error.message}
              </Typography>
            )}
          </FormControl>
        </>
      )}
    />
  );
}
